import React from "react"

const GlobeIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.75 28C23.3774 28 28.75 22.6274 28.75 16C28.75 9.37258 23.3774 4 16.75 4C10.1226 4 4.75 9.37258 4.75 16C4.75 22.6274 10.1226 28 16.75 28Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5206 21.2965L20.7348 17.1229C20.6158 17.0498 20.4829 17.0023 20.3445 16.9837L17.4921 16.5992C17.278 16.5703 17.0602 16.6116 16.8714 16.7169C16.6827 16.8221 16.5331 16.9857 16.4452 17.1831L14.7328 21.0254C14.6532 21.2041 14.6277 21.4022 14.6595 21.5953C14.6914 21.7883 14.7791 21.9677 14.9119 22.1114L17.2619 24.6532C17.3685 24.7684 17.4464 24.9071 17.4894 25.058C17.5324 25.209 17.5393 25.3679 17.5095 25.522L17.0308 27.9965"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.86901 6.95081L7.75099 9.59452C7.6513 9.83025 7.64579 10.0952 7.73559 10.3349L9.17194 14.1684C9.23076 14.3254 9.3281 14.4651 9.45502 14.5746C9.58193 14.6841 9.73434 14.76 9.89824 14.7952L12.577 15.371C12.7256 15.4029 12.865 15.4683 12.9846 15.5622C13.1041 15.6562 13.2007 15.7761 13.2669 15.9129L13.7426 16.8957C13.8245 17.0648 13.9524 17.2074 14.1116 17.3072C14.2708 17.4071 14.4548 17.46 14.6427 17.46H16.3218"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8073 4.39355L20.974 6.48335C21.0843 6.68091 21.1243 6.91003 21.0876 7.13329C21.0508 7.35654 20.9394 7.56073 20.7716 7.7125L17.4077 10.7547C17.3506 10.8063 17.2878 10.8512 17.2204 10.8884L15.6902 11.7334C15.5422 11.8152 15.3758 11.8581 15.2068 11.8581H12.5386C12.3418 11.8581 12.1494 11.9161 11.9855 12.0249C11.8216 12.1338 11.6934 12.2885 11.617 12.4699L10.5761 14.9409"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GlobeIcon
