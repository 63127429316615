import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import CustomModal from "../../CustomModal"

const MessageImage = ({ currentMessage }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <>
      <Wrapper onClick={() => setIsModalOpen(true)}>
        <Thumb
          alt="image thumb"
          src={currentMessage.thumb}
          isLoaded={isLoaded}
        />
        <Image
          onLoad={() => {
            setIsLoaded(true)
          }}
          alt={currentMessage._id}
          src={currentMessage.image}
          isLoaded={isLoaded}
        />
      </Wrapper>
      {isModalOpen && (
        <CustomModal modalOpen={isModalOpen} setModalOpen={setIsModalOpen}>
          <ModalWrapper>
            <ImageModal alt={currentMessage._id} src={currentMessage.image} />
          </ModalWrapper>
        </CustomModal>
      )}
    </>
  )
}

const Wrapper = styled.div`
  height: 150px;
  position: relative;
`

const ModalWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Thumb = styled.img`
  width: auto;
  height: 100%;
  border-radius: 13px;
  filter: blur(3px);
  transition: visibility 0ms ease 400ms;
  visibility: ${(props) => (props.isLoaded ? "hidden" : "visible")};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

const Image = styled.img`
  cursor: pointer;
  width: auto;
  height: ${(props) => (props.isFull ? "80vh" : "100%")};
  border-radius: 13px;
  opacity: ${(props) => (props.isLoaded ? "1" : "0")};
  transition: opacity 400ms ease 0ms;
  z-index: 0;
`

const ImageModal = styled.img`
  height: 80vh;
  border-radius: 10px;
`

MessageImage.propTypes = {
  currentMessage: PropTypes.shape({
    _id: PropTypes.string,
    thumb: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
}

export default MessageImage
