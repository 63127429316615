import styled from "styled-components"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"

export const StyledForm = styled.form`
  margin: 20px 0 0 40px;
  height: calc(100vh - 140px);
  overflow: auto;
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-top: 12px;
`

export const HeaderText = styled.span`
  font-size: 2.5rem;
  & > svg {
    width: 69px;
    height: 30px;
  }
`
