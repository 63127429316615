import React, { useState, useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useToasts } from "react-toast-notifications"
import snakeCase from "lodash.snakecase"
import { useTranslation } from "react-i18next"
import { CameraAlt } from "@styled-icons/material/CameraAlt"
import ImageLoadable from "../../atoms/ImageLoadable"

const ImageUploader = ({
  src,
  disabled,
  onImageLoaded,
  hideControls,
  width,
  height,
  fieldName,
  emptyMessage,
  showError,
  maxSize,
  isRegister,
}) => {
  const [file, setFile] = useState({})
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const currentFile = acceptedFiles[0]

      if (!currentFile) {
        return
      }
      const data = {
        blobUrl: URL.createObjectURL(currentFile),
        name: `${currentFile.name}_${Date.now()}`,
      }
      // https://pablorocha.me/blog/firebase-storage-react-dropzone-2
      const blob = await fetch(data.blobUrl).then((r) => r.blob())
      setFile(
        Object.assign(file, {
          preview: URL.createObjectURL(currentFile),
        })
      )
      if (onImageLoaded) {
        onImageLoaded(fieldName, { ...data, blob })
      }
    },
    [onImageLoaded, file, fieldName]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/jpeg, image/jpg",
    multiple: false,
    disabled: disabled || hideControls,
    maxSize, // 2mb
    onDrop,
    onDropRejected: (e) => {
      if (e.length > 0) {
        const first = e[0]
        addToast(t(snakeCase(first.errors[0].code)), { appearance: "error" })
      }
    },
  })

  const getSrc = () => {
    if (Object.keys(file).length > 0) {
      return file.preview
    }
    return src && src !== "local" ? src : null
  }

  useEffect(
    () => () => {
      URL.revokeObjectURL(file.preview)
    },
    [file]
  )
  return (
    <Container
      {...getRootProps({ refKey: "ref" })}
      hideControls={hideControls}
      showError={showError}
      isRegister={isRegister}
    >
      {!hideControls && <input {...getInputProps()} />}
      <ImageLoadable
        width={width}
        height={height}
        src={getSrc()}
        hideControls={hideControls}
        emptyMessage={isRegister ? "" : emptyMessage}
      />
      {!hideControls && (
        <CamContainer>
          <CameraAlt size={18} />
        </CamContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  background: #f2f2f2;
  position: relative;
  border-radius: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.hideControls ? "0px" : props.isRegister ? "100%" : "22px"};
  margin-bottom: 8px;
  margin-right: 8px;
  padding: ${(props) => (props.hideControls ? "0px" : "4px")};
  box-sizing: border-box;
  cursor: ${(props) => (props.hideControls ? "default" : "pointer")};
  outline: none !important;
  border: ${(props) =>
    props.showError ? `1px solid ${props.theme.color.alert}` : "none"};
  & > div {
    display: flex;
    min-width: 0;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    & > img {
      display: block;
      width: ${(props) => (props.hideControls ? "100%" : "auto")};
      height: ${(props) => (props.hideControls ? "auto" : "100%")};
    }
  }
`

const CamContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1;
  cursor: pointer;
`

ImageUploader.defaultProps = {
  text: "",
  src: null,
  disabled: false,
  onImageLoaded: () => null,
  hideControls: false,
  width: 194,
  height: 207,
  fieldName: "mainPhotoURL",
  emptyMessage: "Cargar Foto",
  showError: false,
  maxSize: 6291456, // 6mb
  isRegister: false,
}

ImageUploader.propTypes = {
  text: PropTypes.string,
  src: PropTypes.string,
  disabled: PropTypes.bool,
  onImageLoaded: PropTypes.func,
  hideControls: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  fieldName: PropTypes.string,
  emptyMessage: PropTypes.string,
  showError: PropTypes.bool,
  maxSize: PropTypes.number,
  isRegister: PropTypes.bool,
}

export default ImageUploader
