import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const MessageText = ({ currentMessage, isLoadMore, isBold }) => {
  const { t } = useTranslation()
  const getFormattedMessage = () => {
    let msg = /^(system|session|inactivity)_/.test(currentMessage.text)
      ? t(currentMessage.text)
      : currentMessage.text

    if (/^video_session_started\|/.test(currentMessage.text)) {
      const msgArr = currentMessage.text.split("|")
      msg = t("video_session_started", {
        user: msgArr[2],
      })
    }

    return msg
  }

  return (
    <Text isBold={isBold} isLoadMore={isLoadMore}>
      {getFormattedMessage()}
    </Text>
  )
}

const Text = styled.span`
  ${(props) => props.theme.font_size.xsmall};
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
  text-transform: ${(props) => (props.isBold ? "uppercase" : "none")};
  border: ${(props) => (props.isLoadMore ? "1px solid #cfcfcf" : "none")};
  background-color: ${(props) =>
    props.isLoadMore ? props.theme.color.background.gray30 : "transparent"};
  border-radius: ${(props) => (props.isLoadMore ? "8px" : "0")};
  cursor: ${(props) => (props.isLoadMore ? "pointer" : "default")};
  padding: ${(props) => (props.isLoadMore ? "5px 14px !important" : "0 14px")};
  transition: all 200ms ease-in-out;
  &:hover {
    transform: ${(props) => (props.isLoadMore ? "scale(1.05)" : "scale(1)")};
  }
`

MessageText.defaultProps = {
  isLoadMore: false,
}

MessageText.propTypes = {
  currentMessage: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
  isLoadMore: PropTypes.bool,
}

export default MessageText
