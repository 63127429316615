/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-console */
import React, { useEffect, useContext } from "react"
import ErrorBoundary from "@honeybadger-io/react"
import Honeybadger from "@honeybadger-io/js"
import get from "lodash.get"
import { AuthUserContext } from "../../context/Session"
import ErrorPageComponent from "../../components/organisms/ErrorPage"

export const honeybadgerConfig = {
  environment: process.env.REACT_APP_DOC_ENV || "production",
  apiKey: "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5",
  breadcrumbsEnabled: true,
}

const HoneybadgerWrapper = ({ children }) => {
  const authUser = useContext(AuthUserContext)
  if (process.env.NODE_ENV === "development") {
    return <>{children}</>
  }
  console.log(
    `Wrapping App with Honeybadger Error Boundary. (${process.env.NODE_ENV})`
  )

  const hb = Honeybadger.configure(honeybadgerConfig)

  hb.beforeNotify(() => {
    // eslint-disable-next-line no-param-reassign
    // notice.url = location.pathname
    hb.setContext({
      user_uid: get(authUser, "uid", "no provided"),
      user_email: get(authUser, "email", "no provided"),
      user_name: get(authUser, ["medicProfile", "displayName"]),
      user_country: get(authUser, ["medicProfile", "countryBusiness"]),
    })
  })

  useEffect(() => {
    window.Honeybadger = hb
  }, [])

  return (
    <ErrorBoundary honeybadger={hb} ErrorComponent={ErrorPageComponent}>
      {children}
    </ErrorBoundary>
  )
}

export default HoneybadgerWrapper
