const businessEmployees = [
  { value: 1, label: "0-200" },
  { value: 2, label: "201-400" },
  { value: 3, label: "401-600" },
  { value: 4, label: "601-800" },
  { value: 5, label: "801-1000" },
  { value: 6, label: "1001-1200" },
  { value: 7, label: "1201-1400" },
  { value: 8, label: "1401-1600" },
  { value: 9, label: "1601-1800" },
  { value: 10, label: "1801-2000" },
  { value: 11, label: "2001-2200" },
  { value: 12, label: "2201-2400" },
  { value: 13, label: "2401-2600" },
  { value: 14, label: "2601-2800" },
  { value: 15, label: "2801-3000" },
  { value: 16, label: "3001-3200" },
  { value: 17, label: "3201-3400" },
  { value: 18, label: "3401-3600" },
  { value: 19, label: "3601-3800" },
  { value: 20, label: "3801-4000" },
  { value: 21, label: "4001-4200" },
  { value: 22, label: "4201-4400" },
  { value: 23, label: "4401-4600" },
  { value: 24, label: "4601-4800" },
  { value: 25, label: "4801-5000" },
  { value: 26, label: "5001-5200" },
  { value: 27, label: "5201-5400" },
  { value: 28, label: "5401-5600" },
  { value: 29, label: "5601-5800" },
  { value: 30, label: "5801-6000" },
  { value: 31, label: "6001-6200" },
  { value: 32, label: "6201-6400" },
  { value: 33, label: "6401-6600" },
  { value: 34, label: "6601-6800" },
  { value: 35, label: "6801-7000" },
]

export default businessEmployees
