/* eslint-disable react/forbid-prop-types */
import "moment/locale/es"
import moment from "moment"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import React, { useState, useEffect, useContext } from "react"
import { useToasts } from "react-toast-notifications"

// Icons
import { Bolt } from "@styled-icons/fa-solid/Bolt"
import { Event } from "@styled-icons/material/Event"
import { withFirebase } from "../../../utils/Firebase"

// Context
import { MODALS, toggleModal } from "../../../context/Chat/reducer"
import ChatContext from "../../../context/Chat"

// Components
import BubbleText from "../BubbleText"
import {
  CardWrapper,
  BubblesWrapper,
  InfoWrapper,
  Name,
  Service,
  InitialAvatar,
  PersonalWrapper,
  StatusIndicator,
  Type,
  ApponitmentType,
  AptTypeText,
  Actions,
  Action,
} from "./styles"

const Card = ({
  session,
  handleCardClick,
  withDate,
  isActive,
  service,
  type,
  readOnlyChat,
  status,
  sessionType,
  isFromContacts,
  cardHasBorder = true,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { dispatch } = useContext(ChatContext)
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  useEffect(() => {
    moment.locale(language)
    setLoading(!loading)
  }, [language])

  const getStatusMessage = (noShow) => {
    const aptStatus = {
      finished:
        session.clientNoShowWarning && session.providerMsgCount > 0
          ? t("finished_client")
          : t("finished"),
      rescheduled: t("web_client.rescheduled"),
      // eslint-disable-next-line no-nested-ternary
      cancelled: noShow
        ? session.providerMsgCount === 0
          ? t("no_show")
          : t("no_show_client")
        : t("cancelled"),
      confirmed: t("confirmed"),
      pending: t("pending"),
    }
    if (session.donated) {
      return t("donated")
    }

    return aptStatus[session.status]
  }

  const getAppointmentHeaderType = () => {
    const aptType = {
      virtual: t("apt_virtual"),
      faceToFace: t("apt_face_to_face"),
    }

    return session?.paymentType === "cash"
      ? `${aptType[sessionType]} - ${t("cash")}`
      : aptType[sessionType]
  }

  // const currentMinutesDifference = moment
  //   .unix(session.slotStart)
  //   .diff(moment(), "minutes")

  return (
    <CardWrapper
      onClick={() => handleCardClick(session, type)}
      isActive={isActive}
      isReadOnlyChat={readOnlyChat}
      hasBorder={cardHasBorder}
    >
      <ApponitmentType
        bgColor={sessionType === "virtual" ? "#EBF8FF" : "#FAF5FF"}
      >
        <AptTypeText color={sessionType === "virtual" ? "#3182CE" : "#805AD5"}>
          {getAppointmentHeaderType()}
        </AptTypeText>
      </ApponitmentType>
      <BubblesWrapper withDate={withDate}>
        {withDate || type === "agenda" ? (
          <>
            <BubbleText withDate>
              {moment.unix(session.slotStart).format("DD/MM/yyyy")}
            </BubbleText>
            <BubbleText withDate>
              {`${moment.unix(session.slotStart).format("HH:mm")} -
                ${moment.unix(session.slotEnd).format("HH:mm")}`}
            </BubbleText>
            {status === "rescheduled" ? (
              <BubbleText withDate>{t("web_client.rescheduled")}</BubbleText>
            ) : (
              <BubbleText>
                {getStatusMessage(session.providerNoShowWarning)}
              </BubbleText>
            )}
          </>
        ) : (
          <>
            <BubbleText withDate={false}>
              {moment(session.modifiedAt).fromNow()}
            </BubbleText>
            {readOnlyChat && (
              <BubbleText withDate>
                {getStatusMessage(session.providerNoShowWarning)}
              </BubbleText>
            )}
          </>
        )}
      </BubblesWrapper>

      <InfoWrapper>
        <InitialAvatar
          isFromContacts={isFromContacts || session?.status === "active"}
        >
          <>
            {session.status === "active" && <StatusIndicator />}
            <span>{session.clientEmail[0]}</span>
          </>
        </InitialAvatar>
        <PersonalWrapper>
          <Name>
            {withDate || type === "agenda"
              ? session.clientEmail
              : session.clientEmail}
          </Name>
          <Service>{service}</Service>
        </PersonalWrapper>
      </InfoWrapper>
      <Type onClick={() => handleCardClick(session, type)}>
        {type === "agenda" ? <Event size="18" /> : <Bolt size="18" />}
      </Type>
      {/* {!readOnlyChat &&
        session.paymentType === "cash" &&
        session.status !== "active" && (
          <Actions singleAction>
            <Action
              disabled={session?.paymentStatus === "paid"}
              onClick={async (e) => {
                e.stopPropagation()
                try {
                  await dispatch(
                    toggleModal(MODALS.CONFIRM_PAYMENT, true, session)
                  )
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              Confirmar Pago
            </Action>
            <Action
              disabled={session?.paymentStatus !== "paid"}
              onClick={async (e) => {
                e.stopPropagation()
                try {
                  await dispatch(
                    toggleModal(MODALS.ACTIVATE_SESSION, true, session)
                  )
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              Activar Cita
            </Action>
          </Actions>
        )} */}
      {!readOnlyChat &&
        session.paymentType !== "cash" &&
        session.type === "faceToFace" &&
        session.status !== "active" &&
        session.status !== "pending" && (
          <Actions singleAction>
            <Action
              // disabled={currentMinutesDifference > 60}
              onClick={async (e) => {
                e.stopPropagation()
                try {
                  await dispatch(
                    toggleModal(MODALS.ACTIVATE_SESSION, true, session)
                  )
                } catch {
                  addToast(t("unexpected_error"), {
                    appearance: "error",
                    placement: "top-right",
                  })
                }
              }}
            >
              Activar Cita
            </Action>
          </Actions>
        )}
    </CardWrapper>
  )
}

Card.defaultProps = {
  service: "General",
  type: "agenda",
  withDate: false,
  sessionType: "virtual",
  readOnlyChat: false,
  isFromContacts: false,
}

Card.propTypes = {
  service: PropTypes.string,
  handleCardClick: PropTypes.func.isRequired,
  session: PropTypes.any.isRequired,
  withDate: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isFromContacts: PropTypes.bool,
  type: PropTypes.string,
  readOnlyChat: PropTypes.bool,
  sessionType: PropTypes.string,
}

export default withFirebase(Card)
