import React from "react"

const MedicImages = ({ className, height, width }) => (
  <svg
    className={className}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      d="M20.47 8.647h-6.027C16.439 6.522 14.94 3 12 3 9.061 3 7.56 6.522 9.557 8.647H3.53c-.292 0-.529.237-.529.53V20.47c0 .292.237.529.53.529h16.94c.293 0 .53-.237.53-.53V9.177c0-.292-.237-.529-.53-.529zM12 4.06c1.265 0 2.294 1.029 2.294 2.294 0 1.265-1.029 2.294-2.294 2.294-1.265 0-2.294-1.03-2.294-2.294 0-1.265 1.029-2.294 2.294-2.294zm7.941 15.882H4.06V9.706H7.84c-.372.432-.633.965-.748 1.541-.877 4.384-.55 2.599-1.262 6.878-.048.288.146.56.435.609.288.048.561-.147.61-.435.708-4.256.424-2.687 1.255-6.844.215-1.073 1.092-1.75 1.91-1.75h1.43v2.33h-.415c-.2 0-.382-.09-.463-.227-.148-.253-.472-.337-.724-.19-.252.148-.337.473-.19.725.272.463.8.751 1.377.751h.415v1.765h-.835c-.336 0-.664-.282-.835-.719-.107-.272-.414-.406-.686-.299-.273.107-.406.415-.3.687.337.857 1.035 1.39 1.821 1.39h.835v1.764h-1.045c-.455 0-.864-.232-1.066-.607-.14-.257-.46-.353-.718-.214-.257.14-.353.46-.214.718.388.717 1.153 1.162 1.998 1.162h3.148c.845 0 1.61-.445 1.998-1.162.139-.257.043-.579-.214-.718-.258-.139-.579-.043-.718.214-.202.375-.61.607-1.066.607h-1.045v-1.764h.835c.786 0 1.484-.533 1.82-1.39.107-.272-.026-.58-.299-.687-.272-.107-.579.027-.686.3-.171.436-.5.718-.835.718h-.835v-1.765h.415c.577 0 1.105-.288 1.376-.751.148-.252.063-.577-.189-.724-.252-.148-.576-.064-.724.189-.081.138-.263.227-.463.227h-.415v-2.33h1.43c.818 0 1.695.676 1.91 1.75.831 4.157.547 2.588 1.256 6.844.043.259.267.442.521.442.33 0 .577-.294.523-.616-.713-4.277-.385-2.492-1.262-6.878-.115-.576-.376-1.11-.748-1.541h3.782V19.94z"
    />
  </svg>
)

MedicImages.defaultProps = {
  width: 24,
  height: 24,
  fill: "#000",
}

export default MedicImages
