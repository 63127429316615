import React from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"

// Components
import { Flex, Button, Box } from "@chakra-ui/react"
import { RemoveRedEye } from "@styled-icons/material/RemoveRedEye"
import UploadButton from "../../../../atoms/UploadButton"

// Styles
import { CustomText, DataRow, EmptyMessage } from "../../styles"

const MedicalDocuments = ({
  contactDocuments,
  handleReadDocument,
  isUploading,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {contactDocuments.length === 0 ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 240px)"
          width="100%"
        >
          <EmptyMessage>{t("add_documents")}</EmptyMessage>
          <Box mt="20px">
            <UploadButton
              onChange={handleReadDocument}
              isUploading={isUploading}
              onlyImages={false}
            >
              {t("attach")}
            </UploadButton>
          </Box>
        </Flex>
      ) : (
        <Flex
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="calc(100vh - 200px)"
          width="100%"
        >
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            height="calc(100vh - 260px)"
            width="100%"
            overflowY="auto"
            pt="20px"
          >
            {contactDocuments
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((c) => (
                <DataRow mb="15px" key={c.id}>
                  <CustomText>{c.name}</CustomText>
                  <CustomText>
                    {moment(c.createdAt).format("MMM DD, YYYY").toUpperCase()}
                  </CustomText>
                  <CustomText>
                    {moment(c.createdAt).format("HH:mm:ss").toUpperCase()}
                  </CustomText>
                  <Button
                    h="35px"
                    width="48px"
                    borderRadius="8px"
                    padding="10px, 12px, 10px, 12px"
                    background="#EDF2F7"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin="0px"
                    opacity="0.8"
                    _hover={{
                      background: "#EDF2F7",
                      transform: "scale(1.1)",
                      opacity: 1,
                    }}
                    onClick={() => window.open(c.url, "_blank")}
                  >
                    <RemoveRedEye size="22px" />
                  </Button>
                </DataRow>
              ))}
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            width="100%"
          >
            <UploadButton
              onChange={handleReadDocument}
              isUploading={isUploading}
              onlyImages={false}
            >
              {t("attach")}
            </UploadButton>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default MedicalDocuments
