import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import get from "lodash.get"
import { useHistory, withRouter, useLocation } from "react-router-dom"
import {
  Box,
  Image,
  Text,
  Button,
  VStack,
  useMediaQuery,
  Flex,
} from "@chakra-ui/react"

import Background from "../../static/images/lines_background.svg"
import LogoV2 from "../../static/images/logoV2.svg"
import ProviderPicture from "./imgs/provider.jpeg"

import { WithChakra } from "../../components/atoms/WithChakra"
import HelpButton from "../../components/chakra/HelpButton"
import LanguageSelector from "../../components/atoms/LanguageSelector"

const WhoRegisterBase = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { state } = useLocation()
  const [isMobile] = useMediaQuery("(max-width: 768px)")
  const userEmail = get(state, "userEmail")
  const [whoRegister, setWhoRegisterSelected] = useState("provider")
  const [planType, setPlanType] = useState(null)

  const goToCreateAccount = () => {
    if (whoRegister === "provider") {
      history.push(`/setup-account`, {
        userType: whoRegister,
        planType,
        userEmail,
      })
    }
  }

  const goToHelp = () => {
    history.push("/help", { isFromLogin: true })
  }

  const goBack = () => {
    history.goBack()
  }

  return (
    <VStack
      backgroundSize="cover"
      background={`url(${Background}) no-repeat center / cover, linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)`}
      alignItems="center"
      justify="center"
      height="100vh"
      overflow="scroll"
    >
      <Box
        boxSize="sm"
        position="fixed"
        top="12px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="50px"
        padding="0px 20px"
      >
        <Box cursor="pointer">
          <Image src={LogoV2} alt="logoV2" onClick={() => history.push("/")} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="200px"
        >
          <HelpButton onClick={() => goToHelp()} />
          <LanguageSelector isFromLogin />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginTop={isMobile ? "0" : "82px !important"}
          margin="20px"
        >
          <Image
            borderRadius="100%"
            objectFit="cover"
            boxSize={isMobile ? "200px" : "300px"}
            src={ProviderPicture}
            alt="provider"
            onClick={() => setWhoRegisterSelected("provider")}
            style={{
              border: whoRegister === "provider" ? "solid white 11px" : "none",
              cursor: "pointer",
              boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
            }}
          />
          <Text
            fontWeight="400"
            fontSize="20px"
            fontFamily="Circular Std Book"
            lineHeight="30px"
            marginTop="20px"
            width="150px"
            textAlign="center"
          >
            {t("type_provider")}
          </Text>
        </Box>
      </Box>
      {whoRegister === "provider" && (
        <Flex direction="row">
          <Flex
            borderRadius="15%"
            border={`4px solid ${planType === "basic" ? "#576EF5" : "white"}`}
            cursor="pointer"
            p="2"
            m="2"
            minHeight="150px"
            width="150px"
            justifyContent="center"
            alignItems="center"
            onClick={() => setPlanType("basic")}
            background="gray.100"
          >
            <Text fontWeight="800">Plan Básico</Text>
          </Flex>
          <Flex
            borderRadius="15%"
            border={`4px solid ${planType === "premium" ? "#576EF5" : "white"}`}
            cursor="pointer"
            p="2"
            m="2"
            minHeight="150px"
            width="150px"
            justifyContent="center"
            alignItems="center"
            onClick={() => setPlanType("premium")}
            background="gray.100"
          >
            <Text fontWeight="800">Plan Premium</Text>
          </Flex>
        </Flex>
      )}

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="220px"
        height="200px"
      >
        <Button
          colorScheme="blue"
          width="104px"
          height="40px"
          color="#000"
          fontSize="16px"
          fontWeight="bold"
          variant="ghost"
          onClick={goBack}
        >
          {t("web_client.back")}
        </Button>
        <Button
          colorScheme="blue"
          background="#576EF5"
          width="104"
          height="40px"
          borderRadius="6px"
          color="#FFF"
          fontSize="18px"
          fontWeight="normal"
          onClick={goToCreateAccount}
          isDisabled={whoRegister !== "provider" || !planType}
        >
          {t("web_client.continue")}
        </Button>
      </Box>
    </VStack>
  )
}

const WhoRegister = compose(withRouter, WithChakra)(WhoRegisterBase)
export default WhoRegister
