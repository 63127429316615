import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import loadable from "@loadable/component"
// import Select from "react-select"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { useHistory, useLocation } from "react-router-dom"
import qs from "query-string"
import get from "lodash.get"
import constants from "../../../../../utils/constants"
import {
  cronofyAccessTokenUrl,
  appointmentsCreateCalendarUrl,
} from "../../../../../utils/App"

import { AuthUserContext } from "../../../../../context/Session"
import { withFirebase } from "../../../../../utils/Firebase"

// import Tooltip from "../../../../atoms/Tooltip"
import CustomModal from "../../../../atoms/CustomModal"
import EmailImg from "../../../../../static/images/settings/emailCircle.svg"

import {
  Container,
  CalendarAccountsContainer,
  Section,
  ItemsColumn,
  ModalWrapper,
  ModalOptions,
  CalendarLink,
  // SaveChangesButton,
} from "./styles"

const LoadableCronofyWrapper = loadable(() =>
  import("../../../../atoms/CronofyWrapper")
)

const TimeSettings = ({ firebase }) => {
  const user = useContext(AuthUserContext)
  const { t } = useTranslation()
  const history = useHistory()
  const { addToast } = useToasts()
  const { search } = useLocation()
  const [syncingCronofy, setSyncingCronofy] = useState(false)
  const { code } = qs.parse(search)

  const [cronofySub, setCronofySub] = useState(
    get(user, ["medicProfile", "cronofySub"])
  )

  useEffect(() => {
    if (firebase && code && get(user, "medicProfile")) {
      const getToken = async () => {
        try {
          const token = await firebase.getIdToken()
          if (token) {
            setSyncingCronofy(true)
            try {
              const response = await fetch(cronofyAccessTokenUrl, {
                method: "POST",
                body: JSON.stringify({
                  redirectUrl: `${constants.cronofy.redirectURL}/settings`,
                  user: user.email,
                  code,
                }),
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              })
              const data = await response.json()
              if (response.status === 200) {
                const tmpSub = get(data, "sub", null)
                setCronofySub(tmpSub)
                user.setUser({
                  ...user,
                  medicProfile: {
                    ...get(user, "medicProfile", {}),
                    cronofyAccessToken: data.access_token,
                    cronofyRefreshToken: data.refresh_token,
                    cronofySub: data.sub,
                    cronofyAccountId: data.account_id,
                  },
                })
                addToast(t("web_client.add_rules_settings"), {
                  appearance: "info",
                })
                // setActiveTab(3)
                setSyncingCronofy(false)
                history.push("/settings")
              } else {
                const msg = t(data.i18nMessage)
                addToast(msg, {
                  appearance: "info",
                })
                setSyncingCronofy(false)
                history.push("/settings")
              }
            } catch (err) {
              setSyncingCronofy(false)
              addToast(t("unexpected_error_link_account"), {
                appearance: "error",
              })
            }
          }
        } catch {
          setSyncingCronofy(false)
          addToast(t("unexpected_error"), {
            appearance: "error",
          })
        }
      }
      if (!cronofySub || (code && !syncingCronofy)) {
        getToken()
      }
    }
  }, [firebase, code, get(user, "medicProfile"), cronofySub])

  // useEffect(() => {
  //   const getTimeSettings = async () => {
  //     const getSettings = await firebase.getSettings({
  //       email: user.email,
  //       key: "times",
  //     })
  //     setTimeSettings(getSettings.data())
  //   }
  //   if (firebase) {
  //     getTimeSettings()
  //   }
  // }, [firebase])

  // const onChangeDuration = async (selectData, key) => {
  //   setTimeSettings((timeSettingsState) => ({
  //     ...timeSettingsState,
  //     [key]: selectData.value,
  //   }))
  // }

  // const handleSaveChanges = async () => {
  //   try {
  //     await firebase.saveProviderSettings(timeSettings, "times", user.email)
  //     addToast(t("web_client.saved_successfully"), { appearance: "success" })
  //   } catch (error) {
  //     addToast(t("unexpected_error"), { appearance: "error" })
  //   }
  // }

  const handleCreateCalendar = async () => {
    const token = await firebase.getIdToken()
    if (token) {
      setSyncingCronofy(true)
      try {
        const response = await fetch(appointmentsCreateCalendarUrl, {
          method: "POST",
          body: JSON.stringify({
            user: user.email,
            calendar: user.uid,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        const data = await response.json()
        if (response.status === 200) {
          const tmpSub = get(data, "sub", null)
          setCronofySub(tmpSub)
          user.setUser({
            ...user,
            medicProfile: {
              ...get(user, "medicProfile", {}),
              cronofyAccessToken: data.access_token,
              cronofyRefreshToken: data.refresh_token,
              cronofySub: data.sub,
              cronofyAccountId: data.application_calendar_id,
            },
          })
          addToast(t("web_client.add_rules_settings"), {
            appearance: "info",
          })
          setSyncingCronofy(false)
          history.push("/settings")
        } else {
          addToast(t("unexpected_error_link_account"), {
            appearance: "error",
          })
          setSyncingCronofy(false)
        }
      } catch (err) {
        addToast(t("unexpected_error_link_account"), {
          appearance: "error",
        })
        setSyncingCronofy(false)
      }
    }
  }

  return (
    <Container>
      <Section>
        <img src={EmailImg} alt="placeHolderImg" />
        <ItemsColumn>
          <h5>{t("web_client.settings_time_header")}</h5>
          <p>{t("web_client.settings_time_subHeader")}</p>
        </ItemsColumn>
      </Section>
      <CalendarAccountsContainer>
        {!cronofySub ? (
          <>
            <CalendarLink onClick={handleCreateCalendar}>
              <p>{t("doc_calendar")}</p>
            </CalendarLink>
            <a
              href={`https://app.cronofy.com/oauth/authorize?response_type=code&client_id=${constants.cronofy.clientId}&redirect_uri=${constants.cronofy.redirectURL}/settings&scope=read_write&state=${user.email}`}
            >
              <p>{t("web_client.calendar_sync_label")}</p>
            </a>
          </>
        ) : (
          <LoadableCronofyWrapper
            withOptions
            cronofyElement="CalendarSync"
            cronofyTargetId="cronofy-calendar-sync"
            cronofyPermissions={["account_management"]}
            key="cronofy-calendar-sync"
            {...(cronofySub ? { tmpCronofySub: cronofySub } : {})}
          >
            <div
              style={{ width: "100%", height: "auto" }}
              id="cronofy-calendar-sync"
            />
          </LoadableCronofyWrapper>
        )}
      </CalendarAccountsContainer>
      <CustomModal
        modalOpen={syncingCronofy}
        setModalOpen={setSyncingCronofy}
        showClose={false}
      >
        <ModalWrapper isFull>
          <ModalOptions>
            <section>
              <p>{t("cronofy_in_progress")}</p>
            </section>
          </ModalOptions>
        </ModalWrapper>
      </CustomModal>
    </Container>
  )
}

TimeSettings.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(TimeSettings)
