import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import get from "lodash.get"

import { Refresh } from "@styled-icons/material/Refresh"
import { TrashAlt } from "@styled-icons/fa-solid/TrashAlt"

import { withFirebase } from "../../../../../utils/Firebase"

import ImageWithText from "../../../../molecules/ImageWithText"
import AccessDrawer from "../../../AccessDrawer"
import CustomModal from "../../../../atoms/CustomModal"

import {
  Container,
  Section,
  ItemsColumn,
  AddCircle,
  CardsContainer,
  ImagesContainer,
  TrashContainer,
  ResetPassword,
  ModalContainer,
  Title,
  RegularFont,
  RowContainer,
  ActionButton,
} from "./styles"

const Access = ({ user, firebase }) => {
  const deleteUserUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserOnDelete`
  const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false)
  const [assistants, setAssistants] = useState([])
  const [resetting, setResetting] = useState(false)
  const [isAssistantRemoved, setIsAssistantRemoved] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [assistantData, setAssistantData] = useState({})

  const getAssistants = async () => {
    const Assistants = await firebase.getAssistants({
      email: user.email,
    })
    if (Assistants && Assistants.docs) {
      const data = Assistants.docs.map((d) => d.data())
      setAssistants(data)
    }
  }

  useEffect(() => {
    if (firebase) {
      getAssistants()
    }
  }, [openDrawerCreate, firebase, isAssistantRemoved])

  const sendEmail = async (mailId, emailSubject) => {
    const token = await firebase.getIdToken()
    // This timeout gives time for the firebase trigger to be executed
    setTimeout(async () => {
      await fetch(mailerUrl, {
        method: "POST",
        body: JSON.stringify({
          data: {
            templateId: mailId, // remove-assitant
            email: assistantData.email,
            variables: {
              subject: emailSubject,
              providerName: `${user?.medicProfile?.title}${user?.medicProfile?.displayName}`,
              displayName: assistantData.displayName,
              websiteLink: process.env.REACT_APP_CRONOFY_REDIRECT_URL,
              assistantName: assistantData.displayName,
            },
          },
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "App-Environment":
            process.env.NODE_ENV === "development" ? "stage" : "prod",
          "Content-Type": "application/json",
        },
      })
    }, 2500)
  }

  const removeAssistant = async () => {
    setIsAssistantRemoved(true)
    try {
      const providersData = await firebase.getAssistantsProviders(
        assistantData.email
      )
      if (providersData.docs.length <= 1) {
        const token = await firebase.getIdToken()
        await fetch(deleteUserUrl, {
          method: "POST",
          body: JSON.stringify({ userToDeleteEmail: assistantData.email }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        await sendEmail(
          4388903,
          "Te han eliminado como asistente de la plataforma DOC"
        )
      } else {
        firebase.onDeleteAssistantOneProvider(assistantData.email, user.email)
        firebase.deleteAssistantProvider(assistantData.email, user.uid)
        await sendEmail(
          4388905,
          "Te han eliminado como asistente de un médico en la plataforma DOC"
        )
      }
      setTimeout(() => {
        setIsAssistantRemoved(false)
        getAssistants()
        setOpenConfirmationModal(false)
        addToast(t("web_client.removed_assistant_successfully"), {
          appearance: "success",
          placement: "top-right",
        })
      }, 1000)
    } catch (error) {
      setIsAssistantRemoved(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "top-right",
      })
    }
  }

  const openRemoveAssistantModal = (assistant) => {
    setOpenConfirmationModal(true)
    setAssistantData(assistant)
  }

  return (
    <>
      <Container>
        <Section>
          <ItemsColumn>
            <h5>{t("web_client.settings_access_title_text")}</h5>
            <p>{t("web_client.settings_access_title_subText")}</p>
          </ItemsColumn>
          <AddCircle onClick={() => setOpenDrawerCreate(true)}>
            <p>+</p>
          </AddCircle>
        </Section>
        <CardsContainer>
          {assistants &&
            assistants.map((assistant) => (
              <div key={assistant.email}>
                <TrashContainer
                  onClick={() => openRemoveAssistantModal(assistant)}
                >
                  <TrashAlt size="24" />
                </TrashContainer>
                <ImagesContainer>
                  <ImageWithText
                    src={get(
                      assistant,
                      "photoURL",
                      "https://firebasestorage.googleapis.com/v0/b/doc-app-prod.appspot.com/o/emails%2Fportrait_placeholder.png?alt=media&token=512bd65c-4f2c-45c2-a388-e3857fb2c489"
                    )}
                  />
                </ImagesContainer>
                <p style={{ marginTop: 20 }}>
                  {t("web_client.settings_access_contact_info")}
                </p>
                <h5>{get(assistant, "displayName")}</h5>
                <p style={{ opacity: 1 }}>{t("web_client.assistant")}</p>
                <ResetPassword
                  resetting={resetting}
                  onClick={async (e) => {
                    setResetting(true)
                    e.stopPropagation()
                    try {
                      await firebase.doPasswordReset(assistant.email)
                      setResetting(false)
                      addToast(t("web_client.email_reset_send"), {
                        appearance: "success",
                        placement: "top-right",
                      })
                    } catch (error) {
                      setResetting(false)
                      addToast(t("unexpected_error"), {
                        appearance: "error",
                        placement: "top-right",
                      })
                    }
                  }}
                >
                  <span>{t("web_client.reset_password")}</span>
                  <Refresh size="18" />
                </ResetPassword>
              </div>
            ))}
        </CardsContainer>
      </Container>
      <CustomModal
        key="deleteConfirmationModal"
        modalOpen={openConfirmationModal}
        setModalOpen={setOpenConfirmationModal}
        showClose={false}
      >
        <ModalContainer>
          <Title>{t("web_client.settings_modal_title")}</Title>
          <RegularFont>{t("web_client.settings_modal_paragraph")}</RegularFont>
          <RowContainer>
            <ActionButton onClick={() => setOpenConfirmationModal(false)}>
              <p>{t("web_client.exit")}</p>
            </ActionButton>
            <ActionButton
              onClick={() => removeAssistant()}
              disabled={isAssistantRemoved}
            >
              <p>{t("web_client.delete")}</p>
            </ActionButton>
          </RowContainer>
        </ModalContainer>
      </CustomModal>
      <AccessDrawer
        open={openDrawerCreate}
        closeDrawer={() => setOpenDrawerCreate(false)}
        provider={get(user, "medicProfile")}
      />
    </>
  )
}

Access.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(Access)
