import React from "react"
import { compose } from "recompose"
import { useParams } from "react-router-dom"
import { SectionsContainer, GridContainer } from "../../components/atoms/Global"
import ProfileDetail from "../../components/organisms/ProfileDetail"
import { withAuthorization } from "../../context/Session"

const Profile = () => {
  const { id } = useParams()
  return (
    <SectionsContainer>
      <GridContainer pt="0px" pl="30px">
        <ProfileDetail user={id} />
      </GridContainer>
    </SectionsContainer>
  )
}

const condition = (authUser) => !!authUser
const CompProfile = compose(withAuthorization(condition))(Profile)

export default CompProfile
