import isNumber from "lodash.isnumber"
import isEmpty from "lodash.isempty"
import get from "lodash.get"

const checkMissingData = (profile, specificSection) => {
  const DEFAULT_PLAN_TYPE = "basic"
  const DEFAULT_PAYMENT_METHOD = "excludeDoc"
  const NA = "N/A"

  const { plan = DEFAULT_PLAN_TYPE, academic = {}, business = {} } = profile
  const isBasicPlan = plan === DEFAULT_PLAN_TYPE

  const getValueFromObject = (dataObject, key, defaultValue = null) =>
    get(dataObject, key, defaultValue)

  const dataBank = {
    personal_data: {
      name: getValueFromObject(profile, "name"),
      surname1: getValueFromObject(profile, "surname1"),
      surname2: getValueFromObject(profile, "surname2"),
      gender: getValueFromObject(profile, "gender"),
      country: getValueFromObject(profile, "country"),
      idCard: getValueFromObject(profile, "idCard"),
      phoneNumber: getValueFromObject(profile, "phoneNumber"),
      email: getValueFromObject(profile, "email"),
      photoURL: getValueFromObject(profile, "photoURL"),
    },
    academic: {
      personalSign: getValueFromObject(profile, "personalSign"),
      title: getValueFromObject(academic, "title"),
      university: getValueFromObject(academic, "university"),
      license: getValueFromObject(academic, "license"),
    },
    business: {
      countryBusiness: getValueFromObject(profile, "countryBusiness"),
      address: getValueFromObject(business, "address"),
      offices: getValueFromObject(business, "offices"),
      bankName:
        !isBasicPlan && DEFAULT_PAYMENT_METHOD !== "excludeDoc"
          ? getValueFromObject(business, "bankName")
          : NA,
      bankAccountType:
        !isBasicPlan && DEFAULT_PAYMENT_METHOD !== "excludeDoc"
          ? getValueFromObject(business, "bankAccountType")
          : NA,
      bankAccountCurrency:
        !isBasicPlan && DEFAULT_PAYMENT_METHOD !== "excludeDoc"
          ? getValueFromObject(business, "bankAccountCurrency")
          : NA,
      bankAccount:
        !isBasicPlan && DEFAULT_PAYMENT_METHOD !== "excludeDoc"
          ? getValueFromObject(business, "bankAccount")
          : NA,
    },
    fees: {},
  }

  const findMissingProperties = (data) => {
    const propertiesMissing = []
    const sectionsMissing = []

    Object.keys(data).forEach((section) => {
      Object.keys(data[section]).forEach((property) => {
        if (
          !isNumber(data[section][property]) &&
          isEmpty(data[section][property])
        ) {
          propertiesMissing.push(property)
          sectionsMissing.push(section)
        }
      })
    })

    return { propertiesMissing, sectionsMissing }
  }

  return specificSection
    ? findMissingProperties({ [specificSection]: dataBank[specificSection] })
    : findMissingProperties(dataBank)
}

export default checkMissingData
