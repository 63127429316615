import styled from "styled-components"

export const RoomWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
export const MicWrapper = styled.button`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  color: white;
  background-color: gray;
`

export const FinishWrapper = styled.button`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  color: white;
  background-color: red;
  transform: rotate(135deg);
`

export const Header = styled.div`
  position: absolute;
  top: 0;
  height: 70px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 15px;
  & > div:first-child {
    margin-left: 13px;
    margin-right: 10px;
  }
`

export const Tools = styled.div`
  position: absolute;
  bottom: 0;
  height: 80px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > button {
    margin: 0 5px;
  }
`
export const LocalWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  video {
    width: 100%;
    max-width: 300px;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
    border: 1px solid white;
  }
`

export const ParticipantsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
