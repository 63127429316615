import React from "react"

const ToothIcon = ({ isInitial, id, onClick, teethData, isDisabled }) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6"
      y="6.60376"
      width="20"
      height="20"
      rx="3"
      fill="black"
      fillOpacity={
        teethData?.[id]?.oclusal_incisalHasProcedures ? "0.48" : "0.06"
      }
      onClick={() =>
        isDisabled ? null : onClick(id, "oclusal_incisal", isInitial)
      }
    />
    <rect
      y="0.60376"
      width="32"
      height="10"
      rx="5"
      fill="black"
      fillOpacity={teethData?.[id]?.vestibularHasProcedures ? "0.48" : "0.06"}
      onClick={() => (isDisabled ? null : onClick(id, "vestibular", isInitial))}
    />
    <rect
      y="22.6038"
      width="32"
      height="10"
      rx="5"
      fill="black"
      fillOpacity={
        teethData?.[id]?.palatina_ingualHasProcedures ? "0.48" : "0.06"
      }
      onClick={() =>
        isDisabled ? null : onClick(id, "palatina_ingual", isInitial)
      }
    />
    <rect
      x="10"
      y="0.60376"
      width="32"
      height="10"
      rx="5"
      transform="rotate(90 10 0.60376)"
      fill="black"
      fillOpacity={teethData?.[id]?.distalHasProcedures ? "0.48" : "0.06"}
      onClick={() => (isDisabled ? null : onClick(id, "distal", isInitial))}
    />
    <rect
      x="32"
      y="0.60376"
      width="32"
      height="10"
      rx="5"
      transform="rotate(90 32 0.60376)"
      fill="black"
      fillOpacity={teethData?.[id]?.mesialHasProcedures ? "0.48" : "0.06"}
      onClick={() => (isDisabled ? null : onClick(id, "mesial", isInitial))}
    />
  </svg>
)

export default ToothIcon
