import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

import {
  UnorderedList,
  Checkbox,
  Text,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Button,
} from "@chakra-ui/react"

import { SearchIcon, ArrowDownIcon } from "@chakra-ui/icons"

const Search = ({ onSelectValue, defaultValue, options }) => {
  const { t } = useTranslation()
  const [showList, setShowList] = useState(false)
  const [valueToFilter, setValueToFilter] = useState("")
  const [dataUpdated, setUpdateData] = useState(options)

  useEffect(() => {
    if (defaultValue?.length > 0) {
      const data = dataUpdated?.map((obj) => {
        const getValue = defaultValue.find((item) => item?.id === obj.id)
        // eslint-disable-next-line no-param-reassign
        obj.isChecked = !!getValue?.isChecked
        return obj
      })
      setUpdateData(data)
    } else {
      const data = options?.map((obj) => ({
        ...obj,
        isChecked: false,
      }))
      setUpdateData(data)
    }
  }, [])

  const handleInput = (e) => {
    setValueToFilter(e?.target?.value)
    setShowList(true)
    if (!e?.target?.value) {
      setShowList(false)
    }
  }

  const handleSelect = (prediction) => () => {
    const updateOptions = dataUpdated?.map((obj) =>
      obj.id === prediction?.id
        ? { ...obj, isChecked: !prediction?.isChecked }
        : obj
    )
    const filterSelectedProcedures = updateOptions?.filter(
      (procedure) => procedure?.isChecked
    )
    setUpdateData(updateOptions)
    onSelectValue(filterSelectedProcedures)
  }

  const filterResults = (arr, query) =>
    arr?.filter((result) =>
      result?.name?.toLowerCase()?.includes(query?.toLowerCase())
    )

  const handleShowAll = () => {
    setShowList(!showList)
  }

  const renderSuggestions = () => {
    const getData = filterResults(dataUpdated, valueToFilter)
    return getData?.length > 0 && showList ? (
      getData?.map((suggestion) => (
        <Flex
          flexDirection="row"
          mb="16px"
          key={`${suggestion?.id}-${suggestion?.name}`}
        >
          <Checkbox
            isChecked={suggestion?.isChecked}
            onChange={handleSelect(suggestion)}
            borderColor="rgba(0, 0, 0, 0.08) !important"
            borderRadius="2px"
          />
          <Text
            fontSize="16px"
            fontWeight="500"
            color="#000"
            lineHeight="24px"
            ml="10px"
          >
            {suggestion?.name}
          </Text>
        </Flex>
      ))
    ) : (
      <Text
        fontSize="14px"
        fontWeight="500"
        color="rgba(0, 0, 0, 0.5)"
        lineHeight="24px"
      >
        {t("no_search_results")}
      </Text>
    )
  }

  return (
    <>
      <InputGroup>
        <InputLeftElement>
          <SearchIcon color="#A0AEC0" />
        </InputLeftElement>
        <Input
          onChange={handleInput}
          placeholder={t("search_procedure")}
          border="1px solid #E2E8F0 !important"
          color="#000 !important"
          _placeholder={{
            color: "#000",
          }}
        />
        <InputRightElement>
          <Button
            h="1.75rem"
            size="sm"
            _focus={{ outline: "none" }}
            onClick={handleShowAll}
          >
            <ArrowDownIcon />
          </Button>
        </InputRightElement>
      </InputGroup>
      {showList && (
        <Flex
          display="table-column"
          w={{ base: "auto", md: "250px" }}
          h={{ base: "auto", md: "250px" }}
          overflowY="scroll"
          zIndex={9999}
          position="absolute"
          backgroundColor="white"
          opacity="0.9"
          textAlign="left"
          mt="2px"
          padding="15px"
          borderRadius="8px"
          boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)"
        >
          <UnorderedList>{renderSuggestions()}</UnorderedList>
        </Flex>
      )}
    </>
  )
}

export default Search
