import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Title = styled.p`
  margin: 0;
  padding-left: ${(props) => (props.withPadding ? "30px" : "0")};
  ${(props) => props.theme.font_size[props.size]};
`

const SectionTitle = ({ children, size, withPadding }) => (
  <header>
    <Title withPadding={withPadding} size={size}>
      {children}
    </Title>
  </header>
)

SectionTitle.defaultProps = {
  size: "large",
  withPadding: false,
}

SectionTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element.isRequired,
  ]).isRequired,
  size: PropTypes.oneOf(["large", "larger", "xlarge"]),
  withPadding: PropTypes.bool,
}

export default SectionTitle
