import styled from "styled-components"

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const CardsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 245px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  grid-auto-rows: 300px;
  min-height: 100px;
  margin: 10px;
  padding: 10px;
  overflow-y: scroll;
  @media (min-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${(props) => props.theme.screen.xlg}) {
    grid-template-columns: repeat(5, 1fr);
  }
  & > div {
    position: relative;
    box-sizing: border-box;
    height: 300px;
    min-width: 322px;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #f2f2f2;
    margin-bottom: 10px;
    overflow: hidden;
    & > p {
      margin: 0;
      text-transform: uppercase;
      ${(props) => props.theme.font_size.xxxsmall};
      color: ${(props) => props.theme.color.black.normal};
      font-family: ${(props) => props.theme.font.primary};
      font-weight: 300;
      opacity: 0.4;
    }
    & > h5 {
      margin: 0;
      text-transform: uppercase;
      ${(props) => props.theme.font_size.small};
      color: ${(props) => props.theme.color.black.normal};
      font-family: ${(props) => props.theme.font.secondary};
      font-weight: 400;
    }
  }
`

export const ResetPassword = styled.div`
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  bottom: 20px;
  right: 24px;
  display: inline-flex;
  cursor: pointer;
  z-index: 1001;
  transition: all 200ms ease-in-out;
  color: ${(props) => props.theme.color.onSurface};
  &:hover {
    transform: scale(1.1);
  }
  & > svg {
    animation: ${(props) =>
      props.resetting ? "rotate 2s linear infinite" : "none"};
    color: ${(props) =>
      props.resetting
        ? props.theme.color.primary
        : props.theme.color.onSurface};
  }
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  & > div {
    margin: 0 15px;
  }
`

export const TrashContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 56px;
  width: 100%;
  cursor: pointer;
  &:hover {
    transform: scale(1, 1.1);
  }
`

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin: 42px 0 42px 50px;
`

export const AddCircle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 48px;
  height: 48px;
  background: ${(props) => props.theme.color.background.gray100};
  border-radius: 100px;
  margin: 0px 30px;
  cursor: pointer;
  > p {
    margin: 0;
  }
`

export const ItemsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & > h5 {
    margin: 0;
    ${(props) => props.theme.font_size.regular};
    color: ${(props) => props.theme.color.black.normal};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: 400;
  }
  & > p {
    margin: 0;
    ${(props) => props.theme.font_size.xxsmall};
    color: ${(props) => props.theme.color.black.normal};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: 400;
    opacity: 0.6;
  }
`

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.theme.color.white.regular};
  width: 500px;
  height: 260px;
  padding: 22px;
`

export const Title = styled.h5`
  margin: 0;
  ${(props) => props.theme.font_size.large};
  color: ${(props) => props.theme.color.black.normal};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
`

export const RegularFont = styled.p`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.medium};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
`

export const ActionButton = styled.button`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.medium};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
  & > p:hover {
    color: ${(props) => props.theme.color.secondary};
    cursor: pointer;
  }
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 25px;
  & > p {
    font-weight: bold;
  }
  & > p:hover {
    color: ${(props) => props.theme.color.secondary};
    cursor: pointer;
  }
`
