import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { PlayCircleOutline } from "@styled-icons/material/PlayCircleOutline"
import CustomModal from "../../CustomModal"

const MessageVideo = ({ currentMessage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <>
      <Wrapper onClick={() => setIsModalOpen(true)}>
        <PlayCircleOutline size="80" />
      </Wrapper>
      {isModalOpen && (
        <CustomModal modalOpen={isModalOpen} setModalOpen={setIsModalOpen}>
          <ModalWrapper isFull>
            <VideoModal id={currentMessage._id} autoplay controls>
              {currentMessage.video && (
                <source src={currentMessage.video} type={currentMessage.mime} />
              )}
            </VideoModal>
          </ModalWrapper>
        </CustomModal>
      )}
    </>
  )
}

const Wrapper = styled.div`
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & > svg {
    color: white;
  }
`

const ModalWrapper = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
`

const VideoModal = styled.video`
  height: 100%;
  border-radius: 10px;
  outline: none;
`

MessageVideo.propTypes = {
  currentMessage: PropTypes.shape({
    _id: PropTypes.string,
    video: PropTypes.string,
    mime: PropTypes.string,
  }).isRequired,
}

export default MessageVideo
