import React from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { withFirebase } from "../../utils/Firebase"
import { activeProvider } from "../../utils/App"
import NavLayout from "../../components/organisms/NavLayout"

const Confirm = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = new URLSearchParams(history.location.search)

  const code = params.get("code")
  const token = params.get("token")

  React.useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await fetch(activeProvider, {
          method: "POST",
          body: JSON.stringify({
            token,
            code,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })

        const activateUser = await response.json()

        if (!activateUser.status.ok) throw new Error("user not active")
      } catch (error) {
        history.push("/")
      }
    }
    validateToken()
  }, [])

  if (!token) return null
  return (
    <NavLayout isFullPage>
      <Section id="login">
        <div>{t("confirm_title")}</div>
        <div>{t("confirm_desc")}</div>
      </Section>
    </NavLayout>
  )
}
const Section = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default withFirebase(Confirm)
