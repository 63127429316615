import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import get from "lodash.get"
import { Close } from "@styled-icons/material/Close"
import { useToasts } from "react-toast-notifications"
import ProgressBar from "react-progress-bar-plus"
import {
  MedicalHeader,
  CloseButton,
  HeaderTools,
  MedicalInfo,
} from "../../atoms/Forms"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"
import { Name } from "../../atoms/Card/styles"
import { secondsToDate } from "../../../utils/helpers"
import constants from "../../../utils/constants"
import Theme from "../../../styles/theme"
import {
  PreviewContainer,
  Avatar,
  Thumb,
  Image,
  Content,
  PreviewSection,
  Title,
  Option,
  Images,
  InfoCard,
} from "./styles"

const PreviewMessage = ({
  content,
  type,
  user,
  firebase,
  handleClose,
  info,
  messageKey,
  fullMessage,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const getPdfLink = `${constants.firebase.project}/pdfCreate`
  const currentYear = new Date().getFullYear()
  const [isButtonLoading, setIsButtonLoading] = useState(
    !fullMessage.isReadyToDownload
  )
  const [company, setCompany] = useState({})
  const [academic, setAcademic] = useState({})
  const [target, setTarget] = useState({})
  const [messageRef, setMessageRef] = useState(null)
  const [progress, setProgress] = useState(
    fullMessage.isReadyToDownload ? 100 : 0
  )
  const [isLoadedImage, setIsLoadedImage] = useState({
    sign: false,
    company: false,
  })
  const decryptedContent = JSON.parse(content)
  const Titles = {
    clinical: t("clinical_trials"),
    medicalImages: t("web_client.medical_images"),
    prescription: t("web_client.prescription"),
  }

  const onError = () => {
    addToast(t("unexpected_error"), { appearance: "error" })
  }

  useEffect(() => {
    const getBusinessForm = async () => {
      const Business = await firebase.getBusiness({ email: user.email })
      setCompany(Business.data())
      const Academic = await firebase.getAcademic({ email: user.email })
      setAcademic(Academic.data())
      const Target = await firebase.getProfile({
        email: get(info, "clientEmail"),
      })
      setTarget(Target.data())
    }
    if (firebase) {
      getBusinessForm()
    }
  }, [firebase])

  useEffect(() => {
    if (firebase) {
      const unsubscribe = firebase.subscribeToSpecificMessage({
        chat: info.id,
        messageId: messageKey,
      })
      setMessageRef(unsubscribe)
    }
  }, [firebase])

  useEffect(() => {
    const onChildAdded = async (snapshot) => {
      const keyChanged = snapshot.key
      const isReady = snapshot.val()
      if (keyChanged === "isReadyToDownload" && isReady) {
        setIsButtonLoading(false)
        if (isReady) {
          setProgress(100)
        }
      }
    }

    if (messageRef) {
      messageRef
        .orderByChild("createdAt")
        .on("child_added", onChildAdded, onError)
    }

    return () => {
      if (messageRef) {
        messageRef.off("child_added", onChildAdded)
      }
    }
  }, [messageRef])

  useEffect(() => {
    const onChildChanged = async (snapshot) => {
      const keyChanged = snapshot.key
      const isReady = snapshot.val()

      if (keyChanged === "isReadyToDownload") {
        setIsButtonLoading(!isReady)
        if (isReady) {
          setProgress(100)
        }
      }
    }

    if (messageRef) {
      messageRef
        .orderByChild("createdAt")
        .on("child_changed", onChildChanged, onError)
    }

    return () => {
      if (messageRef) {
        messageRef.off("child_changed", onChildChanged)
      }
    }
  }, [messageRef])

  const getBirthdate = () => {
    let age = "0"
    const birthDate = secondsToDate(get(target, "birthDate", null), "seconds")
    if (birthDate) {
      age = currentYear - birthDate.getFullYear()
    }
    return age
  }

  const downloadPDF = (url) => {
    // const newURL = url.split(".pdf")
    const a = document.createElement("a")
    a.setAttribute(
      "download",
      `${type}-${get(decryptedContent, "code", "file")}.pdf`
    )
    a.href = url // `${newURL[0]}.pdf`
    a.target = "_blank"
    a.rel = "noreferrer"
    document.body.appendChild(a)
    a.click()
    a.parentNode.removeChild(a)
  }

  const handleClick = async () => {
    try {
      setIsButtonLoading(true)
      if (decryptedContent.pdfURL) {
        downloadPDF(decryptedContent.pdfURL)
        setIsButtonLoading(false)
      } else {
        const authToken = await firebase.getIdToken()
        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat: {
              id: info.id,
              messageId: messageKey,
            },
            type,
          }),
        }
        const response = await fetch(getPdfLink, options)
        const data = await response.json()
        const newData = JSON.parse(fullMessage[type])
        newData.pdfURL = data.pdfUrl
        await firebase.updateMessage({
          chat: info.id,
          messageId: messageKey,
          field: type,
          newData: JSON.stringify(newData),
        })
        setTimeout(() => {
          downloadPDF(data.pdfUrl)
          setIsButtonLoading(false)
        }, 2700) // Gives some time for the file to be authorized on the Firebase storage
      }
    } catch (error) {
      setIsButtonLoading(false)
    }
  }

  return (
    <PreviewContainer>
      <ProgressBar
        spinner={false}
        percent={progress}
        autoIncrement
        intervalTime={417}
      />
      <MedicalHeader>
        <CloseButton onClick={handleClose}>
          <Close size="36" />
        </CloseButton>
        <span>{`${Titles[type]}`} </span>
        <HeaderTools>
          <ButtonWithLoader
            isLoading={isButtonLoading}
            type="button"
            onClick={handleClick}
            color={Theme.color.secondary}
          >
            {t("web_client.download")}
          </ButtonWithLoader>
        </HeaderTools>
      </MedicalHeader>
      <MedicalInfo>
        <p>{company.companyName}</p>
        <div>
          <InfoCard>
            <Name>{`${get(academic, "title", "")} ${get(
              user,
              ["medicProfile", "displayName"],
              ""
            )}`}</Name>
            <span>
              {`${t("q2_academic_profession_label")}: ${get(
                academic,
                "professionLangLabel",
                ""
              )}`}
            </span>
            <span>
              {`${t("q4_academic_license_label")}: ${get(
                academic,
                "license",
                ""
              )}`}
            </span>
          </InfoCard>
          <InfoCard>
            <Name>{get(info, "clientName", "")}</Name>
            <span>{t("target_age", { age: getBirthdate() })}</span>
            <span>
              {`${t("q4_info_gender_label")}: ${get(target, "gender", "")}`}
            </span>
          </InfoCard>
          <InfoCard>
            <Images>
              <Avatar isLoaded={isLoadedImage.company}>
                <Thumb isLoaded={isLoadedImage.company}>
                  <span>Logo</span>
                </Thumb>
                <Image
                  onLoad={() => {
                    setIsLoadedImage((currentValues) => ({
                      ...currentValues,
                      company: true,
                    }))
                  }}
                  alt={get(company, "companyName", "")}
                  src={get(company, "companyLogo", "")}
                  isLoaded={isLoadedImage.company}
                />
              </Avatar>
              <Avatar isLoaded={isLoadedImage.company}>
                <Thumb isLoaded={isLoadedImage.company}>
                  <span>{t("web_client.medical_stamp_label")}</span>
                </Thumb>
                <Image
                  onLoad={() => {
                    setIsLoadedImage((currentValues) => ({
                      ...currentValues,
                      company: true,
                    }))
                  }}
                  alt={get(company, "companyName", "")}
                  src={get(company, "companyStamp", "")}
                  isLoaded={isLoadedImage.company}
                />
              </Avatar>
              <Avatar isLoaded={isLoadedImage.sign}>
                <Thumb isLoaded={isLoadedImage.sign}>
                  <span>{t("web_client.signature")}</span>
                </Thumb>
                <Image
                  onLoad={() => {
                    setIsLoadedImage((currentValues) => ({
                      ...currentValues,
                      sign: true,
                    }))
                  }}
                  alt={get(user, "displayName", "")}
                  src={get(user, ["medicProfile", "personalSign"], "")}
                  isLoaded={isLoadedImage.sign}
                  isSign
                />
              </Avatar>
            </Images>
          </InfoCard>
        </div>
        <div>
          {type === "prescription" && (
            <Content>
              <PreviewSection>
                <Title>{t("diagnosis")}</Title>
                <span>{get(decryptedContent, "diagnostic")}</span>
              </PreviewSection>
              <PreviewSection>
                <Title>{t("recommendations")}</Title>
                <span>{get(decryptedContent, "recommendations")}</span>
              </PreviewSection>
              {decryptedContent &&
                decryptedContent.medicines.length > 0 &&
                decryptedContent.medicines.map((m) => (
                  <PreviewSection key={m.id}>
                    <Title>{m.name}</Title>
                    <ul>
                      <Option>{m.dci}</Option>
                      <Option>{m.type}</Option>
                      <Option>{m.dosis}</Option>
                      <Option>{m.instructions}</Option>
                    </ul>
                  </PreviewSection>
                ))}
            </Content>
          )}
          {(type === "clinical" || type === "medicalImages") && (
            <Content>
              {Object.keys(decryptedContent.data).map((m) => {
                const isChildError = get(decryptedContent, [
                  "data",
                  m,
                  "title",
                  "title",
                ])
                if (
                  isChildError ||
                  get(decryptedContent, ["data", m, "options"], []).length <= 0
                )
                  return null
                return (
                  <PreviewSection key={m}>
                    <Title>
                      {get(decryptedContent, ["data", m, "title"], "N/A")}
                    </Title>
                    <ul>
                      {get(decryptedContent, ["data", m, "options"], []).map(
                        (opt) => (
                          <Option key={opt.key}>{opt.text}</Option>
                        )
                      )}
                    </ul>
                  </PreviewSection>
                )
              })}
            </Content>
          )}
        </div>
      </MedicalInfo>
    </PreviewContainer>
  )
}

export default PreviewMessage
