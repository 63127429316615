import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import { Box, Text, Flex, useMediaQuery } from "@chakra-ui/react"

import CustomSelect from "../../atoms/CustomSelect"
import CustomInput from "../CustomInput"

// Utils
import constants from "../../../utils/constants"

const Reference = ({
  values,
  setFieldValue,
  firebase,
  accessMode,
  userOrgs,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isMobile] = useMediaQuery("(max-width: 800px)")

  const [organizations, setOrganizationData] = useState([])
  const [organizationAgents, setOrganizationAgents] = useState([])
  const [loadingAgents, setLoadingAgents] = useState(false)
  const [selectedReferenceCountry, setSelectedReferenceCountry] = useState("")
  const [getOrganizationAgents, setGetOrganizationAgents] = useState(false)
  const [referenceType, setReferenceType] = useState("noReference")

  const orgId =
    accessMode !== "private" ? Object.keys(userOrgs || {})?.[0] : null

  const isOrganization =
    referenceType &&
    (referenceType === "drugstore" ||
      referenceType === "pharmaceutical_company" ||
      referenceType === "medical_imaging" ||
      referenceType === "clinical_labs" ||
      referenceType === "business")

  const socialMedialOptions = [
    { value: "facebook", label: "Facebook" },
    { value: "instagram", label: "Instagram" },
    { value: "youTube", label: "YouTube" },
    { value: "google", label: "Google" },
  ]

  const referencesOptions = [
    { value: "noReference", label: t("referencesOptions.noReference") },
    {
      value: "pharmaceutical_company",
      label: t("referencesOptions.pharmaceutical_company"),
    },
    {
      value: "medical_imaging",
      label: t("referencesOptions.medical_imaging"),
    },
    {
      value: "clinical_labs",
      label: t("referencesOptions.clinical_labs"),
    },
    { value: "workMate", label: t("referencesOptions.workMate") },
    { value: "social", label: t("referencesOptions.social") },
    { value: "email", label: t("referencesOptions.email") },
    { value: "business", label: t("referencesOptions.business") },
  ]

  useEffect(() => {
    if (values.referenceType) {
      setReferenceType(values.referenceType)
    }
    if (values.referenceCountry) {
      setSelectedReferenceCountry(values.referenceCountry)
    }
  }, [values])

  useEffect(() => {
    if (firebase && isOrganization) {
      firebase
        .getOrganizations(referenceType)
        .then((r) => {
          const organizationsArray = []
          r.forEach((doc) => {
            const organizationData = doc.data()
            organizationsArray.push({
              value: doc.id,
              label: organizationData.businessName,
              referenceCode: organizationData.referenceCode,
              email: organizationData.email,
              country: organizationData.country,
            })
          })
          setOrganizationData(organizationsArray)
        })
        .catch((e) => {
          window.Honeybadger?.notify(e)
          addToast(t("unexpected_error"), { appearance: "error" })
        })
    }
  }, [firebase, referenceType])

  const organizationsByProviderCountry = React.useMemo(
    () =>
      organizations.filter(
        (organization) => organization.country === selectedReferenceCountry
      ),
    [organizations, selectedReferenceCountry]
  )

  useEffect(() => {
    const getAgents = async () => {
      try {
        setLoadingAgents(true)
        const agents = await firebase.getAgents(values?.organizationReference)

        const selectAgents = []
        agents.forEach((doc) => {
          const dataAgents = doc.data()
          selectAgents.push({ value: doc.id, label: dataAgents.displayName })
        })
        setOrganizationAgents(selectAgents)
        setLoadingAgents(false)
      } catch (e) {
        window.Honeybadger?.notify(e)
        setLoadingAgents(false)
        addToast(t("unexpected_error"), { appearance: "error" })
      }
    }

    if (!loadingAgents && values?.organizationReference) {
      setGetOrganizationAgents(false)
      getAgents()
    }
  }, [getOrganizationAgents])

  const getReferencerCode = async (value) => {
    const referenceProfile = await firebase.getProfile({ email: value })
    const getProfileData = referenceProfile.data()
    if (getProfileData) {
      setFieldValue("reference", getProfileData?.referenceCode || null)
      setFieldValue("organizationAgent", getProfileData?.email || null)
    }
  }

  return (
    <>
      <Flex
        alignItems="center"
        borderBottom="1px solid #EDF2F7"
        minHeight="84px"
        padding="10px 0"
      >
        <Text
          fontSize="18px"
          fontWeight="700"
          color="#171923"
          marginLeft="24px"
        >
          {accessMode === "private"
            ? t("web_client.reference_title")
            : t("web_client.referenced_by")}
        </Text>
      </Flex>
      {accessMode === "private" && (
        <Box
          width="100%"
          backgroundColor="#F7FAFC"
          borderBottomRightRadius="10px"
          borderBottomLeftRadius="10px"
        >
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
            width={isMobile ? "100%" : "80%"}
            minHeight="84px"
            padding={isMobile ? "10px 20px" : "10px 0"}
          >
            <Flex
              flexDirection="row"
              alignItems="center"
              marginLeft={isMobile ? "0" : "24px"}
            >
              <Text fontSize="14px" fontWeight="400" color="#1A202C">
                {t("web_client.reference_type")}
              </Text>
            </Flex>
            <Box
              width="320px"
              height="32px"
              justifyContent="center"
              alignItems="center"
            >
              <CustomSelect
                items={referencesOptions}
                formKey="referenceType"
                lngKey="referencesOptions.noReference"
                handleChange={(key, value) => {
                  setFieldValue(key, value)
                  setReferenceType(value)
                  setFieldValue("reference", null)
                  setFieldValue("referenceCountry", null)
                  setFieldValue("organizationAgent", null)
                  setFieldValue("organizationReference", null)
                }}
                value={values.referenceType}
              />
            </Box>
          </Flex>
        </Box>
      )}
      {accessMode !== "private" && (
        <Box
          width="100%"
          backgroundColor="#F7FAFC"
          borderBottomRightRadius="10px"
          borderBottomLeftRadius="10px"
        >
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
            width={isMobile ? "100%" : "80%"}
            minHeight="84px"
            padding={isMobile ? "10px 20px" : "10px 0"}
          >
            <Flex
              flexDirection="row"
              alignItems="center"
              marginLeft={isMobile ? "0" : "24px"}
            >
              <Text fontSize="14px" fontWeight="400" color="#1A202C">
                {orgId ? userOrgs[orgId]?.businessName : ""}
              </Text>
            </Flex>
          </Flex>
        </Box>
      )}

      {values.referenceType && isOrganization && accessMode === "private" && (
        <Flex
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          alignItems={isMobile ? "flex-start" : "center"}
          width={isMobile ? "100%" : "80%"}
          minHeight="84px"
          padding={isMobile ? "10px 20px" : "10px 0"}
        >
          <Flex
            flexDirection="row"
            alignItems="center"
            marginLeft={isMobile ? "0" : "24px"}
          >
            <Text fontSize="14px" fontWeight="400" color="#1A202C">
              {t("select_a_country")}
            </Text>
          </Flex>
          <Box
            width="320px"
            height="32px"
            justifyContent="center"
            alignItems="center"
          >
            <CustomSelect
              items={constants?.countries}
              formKey="referenceCountry"
              lngKey="select_country"
              handleChange={(key, value) => {
                setFieldValue(key, value)
                setSelectedReferenceCountry(value)
              }}
              value={values.referenceCountry}
            />
          </Box>
        </Flex>
      )}

      {values.referenceType &&
        isOrganization &&
        selectedReferenceCountry &&
        accessMode === "private" && (
          <Box width="100%" backgroundColor="#F7FAFC">
            <Flex
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="space-between"
              alignItems={isMobile ? "flex-start" : "center"}
              width={isMobile ? "100%" : "80%"}
              minHeight="84px"
              padding={isMobile ? "10px 20px" : "10px 0"}
            >
              <Box width="135px" />
              <Box
                width="320px"
                height="32px"
                justifyContent="center"
                alignItems="center"
              >
                <CustomSelect
                  items={organizationsByProviderCountry}
                  formKey="organizationReference"
                  lngKey="web_client.organization"
                  handleChange={(key, value) => {
                    setFieldValue(key, value)
                    const organizationCode = organizations.find(
                      (org) => org.value === value
                    )
                    setFieldValue(
                      "reference",
                      organizationCode?.referenceCode || null
                    )
                    setFieldValue("organizationAgent", null)
                    setGetOrganizationAgents(true)
                  }}
                  value={values.organizationReference}
                />
              </Box>
            </Flex>
          </Box>
        )}

      {values.referenceType &&
        values.referenceType === "business" &&
        accessMode === "private" && (
          <Box m="10px">
            <Text
              fontSize="0.9rem"
              fontWeight="700"
              color="rgb(98, 0, 238)"
              pb="10px"
            >
              {`Si la empresa para la que labora aún no aparece registrada en DOC
            seleccione la opción "Sin Referencia" y avance con su registro.`}
            </Text>
          </Box>
        )}

      {loadingAgents && (
        <span style={{ marginLeft: 10, color: "#ABA8AF" }}>
          {t("web_client.loading_agents")}
        </span>
      )}

      {isOrganization && organizationAgents.length > 0 && !loadingAgents && (
        <Box
          borderBottomRightRadius="10px"
          borderBottomLeftRadius="10px"
          width="100%"
        >
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isMobile ? "flex-start" : "center"}
            width={isMobile ? "100%" : "80%"}
            minHeight="84px"
            padding={isMobile ? "10px 20px" : "10px 0"}
          >
            <Box width="135px" />
            <Box
              width="320px"
              height="32px"
              justifyContent="center"
              alignItems="center"
            >
              <CustomSelect
                formKey="organizationAgent"
                lngKey="web_client.pharmaceutical_agent"
                handleChange={(key, value) => {
                  getReferencerCode(value)
                }}
                items={organizationAgents}
                value={values.organizationAgent}
              />
            </Box>
          </Flex>
        </Box>
      )}

      {values.referenceType &&
      values.referenceType !== "noReference" &&
      !isOrganization &&
      values.referenceType !== "social" ? (
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          minHeight="84px"
          padding="10px 0"
          width="80%"
          borderBottomRightRadius="10px"
          borderBottomLeftRadius="10px"
        >
          <Text
            fontSize="14px"
            fontWeight="400"
            color="#1A202C"
            marginLeft="24px"
          >
            {t("title_capture_refcode")}
          </Text>
          <Box
            width="320px"
            height="32px"
            justifyContent="center"
            alignItems="center"
          >
            <CustomInput
              placeholder=""
              width="320px"
              height="32px"
              type="text"
              name="reference"
              id="reference"
              handleInputChange={(e) =>
                setFieldValue("reference", e.target.value)
              }
            />
          </Box>
        </Flex>
      ) : null}

      {values.referenceType &&
      values.referenceType !== "noReference" &&
      !isOrganization &&
      values.referenceType === "social" ? (
        <Box
          borderBottomRightRadius="10px"
          borderBottomLeftRadius="10px"
          width="100%"
        >
          <Flex
            minHeight="84px"
            padding="10px 0"
            justifyContent="space-between"
            alignItems="center"
            width="80%"
          >
            <Box width="135px" />
            <Box
              width="320px"
              height="32px"
              justifyContent="center"
              alignItems="center"
            >
              <CustomSelect
                formKey="reference"
                lngKey="web_client.select_social_media"
                handleChange={(key, value) => {
                  setFieldValue("reference", value)
                }}
                items={socialMedialOptions}
                value={values.reference}
              />
            </Box>
          </Flex>
        </Box>
      ) : null}
    </>
  )
}

Reference.propTypes = {
  values: PropTypes.shape().isRequired,
  setFieldValue: PropTypes.func.isRequired,
  firebase: PropTypes.shape().isRequired,
}

export default Reference
