import styled from "styled-components"

export const Label = styled.label``

export const Container = styled.div`
  background-color: ${(props) => props.theme.color.iceBlue};
  height: 100%;
  width: 100%;
  padding: 10px 15px;
  overflow: auto;
  display: ${(props) => (props.isNotesVisible ? "flex" : "block")};
  filter: ${(props) => (props.isImpersonating ? "blur(5px)" : "unset")};
`

export const ChatMessagesContainer = styled.div`
  width: ${(props) => (props.isNotesVisible ? "calc(100% - 35%)" : "100%")};
  padding-right: ${(props) => (props.isNotesVisible ? "5px" : "0px")};
  transition: all 0.2s ease-in-out;
`

export const NotesContainer = styled.div`
  top: ${(props) => (props.topPosition ? props.topPosition : "auto")};
  width: 20%;
  right: 20px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2px 5px 2px 2px;
  position: fixed;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition: all 0.7s ease-in-out;
  z-index: 3000;
`

export const NotesList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  height: 35vh;
  overflow-y: auto;
  overflow-x: hidden;
`

export const NoteRow = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  margin: 3px 1px;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  border-bottom: ${({ isActive }) =>
    isActive ? "1px solid rgba(0, 0, 0, 0.2)" : "none"};
  color: ${({ isCurrentNoteSession }) =>
    isCurrentNoteSession ? "#6200ee" : "black"}
  :hover {
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  > span {
    padding-right: 5px;
  }
`
