import React from "react"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { compose } from "recompose"
import { Formik, Form } from "formik"
import { useHistory, withRouter } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import {
  Stack,
  VStack,
  Box,
  Image,
  Flex,
  Button,
  FormControl,
  FormLabel,
} from "@chakra-ui/react"

import { withFirebase } from "../../utils/Firebase"

import Background from "../../static/images/lines_background.svg"
import LogoV2 from "../../static/images/logoV2.svg"
import Brand from "../../static/images/brand.svg"

import { WithChakra } from "../../components/atoms/WithChakra"
import CustomInput from "../../components/chakra/CustomInput"
import HelpButton from "../../components/chakra/HelpButton"
import LanguageSelector from "../../components/atoms/LanguageSelector"

const ForgotPasswordBase = ({ firebase }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()

  const goToHelp = () => {
    history.push("/help", { isFromLogin: true })
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      justify="center"
      width="full"
      height="100vh"
      backgroundSize="cover"
      background={`url(${Background}) no-repeat center / cover, linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)`}
    >
      <Box
        boxSize="sm"
        position="absolute"
        right="20px"
        top="12px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="200px"
        height="50px"
      >
        <HelpButton onClick={() => goToHelp()} />
        <LanguageSelector isFromLogin />
      </Box>
      <Box
        boxSize="sm"
        position="absolute"
        left="22px"
        top="12px"
        cursor="pointer"
      >
        <Image src={LogoV2} alt="logoV2" onClick={() => history.push("/")} />
      </Box>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true)
          firebase
            .reset({ email: values.email })
            .then(() => {
              history.push("/sessions")
            })
            .catch(() => {
              addToast(t("unexpected_error"), { appearance: "error" })
            })

          actions.setSubmitting(false)
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("error_auth_invalid_email"))
            .required(t("error_all_fields_required")),
        })}
      >
        {(props) => (
          <Form>
            <VStack
              background="white"
              borderRadius="8px"
              width="468px"
              height="338px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
              padding="60px"
            >
              <Box>
                <Image src={Brand} alt="DOC" />
              </Box>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel htmlFor="email" color="gray.700">
                    {t("recovering_password")}
                  </FormLabel>
                  <CustomInput
                    type="email"
                    name="email"
                    id="email"
                    placeholder={t("email_address")}
                    showErrorMessage={props.errors.email}
                    errorMessage={props.errors.email}
                    handleInputChange={(e) =>
                      props.setFieldValue("email", e.target.value)
                    }
                  />
                </FormControl>
              </Stack>
              <Button
                colorScheme="blue"
                width="348px"
                height="48px"
                background="#071435"
                borderRadius="6px"
                color="#FFF"
                fontSize="18px"
                fontWeight="normal"
                type="submit"
                isLoading={props.isSubmitting}
              >
                {t("forgot_password_button")}
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

ForgotPasswordBase.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const ForgotPassword = compose(
  withRouter,
  withFirebase,
  WithChakra
)(ForgotPasswordBase)

export default ForgotPassword
