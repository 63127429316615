import styled from "styled-components"
import { EditableSection, InfoCard as Info } from "../../atoms/Forms"
import { InitialAvatar } from "../../atoms/Card/styles"

export const PreviewContainer = styled.div`
  height: 100vh;
  width: 100%;
`

export const Avatar = styled.div`
  margin: 10px;
  width: 100px;
  min-height: 100px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.04);
  & > span {
    pointer-events: none;
    position: absolute;
    left: 50px;
    top: 4px;
    ${(props) => props.theme.font_size.xxxsmall};
    line-height: 1.33;
    transition: opacity 0ms ease 400ms;
    color: ${(props) => props.theme.color.darkPurple};
    opacity: ${(props) => (props.isLoaded ? "1" : "0")};
  }
`

export const Thumb = styled(InitialAvatar)`
  width: 100px;
  height: 100px;
  transition: opacity 0ms ease 400ms;
  background-color: rgba(0, 0, 0, 0.04);
  opacity: ${(props) => (props.isLoaded ? "0" : "1")};
  position: absolute;
  top: 0;
  left: 0;
`

export const Image = styled.img`
  cursor: pointer;
  width: 100px;
  height: ${(props) => (props.isSign ? "90px" : "100px")};
  opacity: ${(props) => (props.isLoaded ? "1" : "0")};
  transition: opacity 400ms ease 0ms;
  z-index: 0;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Item = styled.div`
  width: 400px;
  height: auto;
  min-height: 100px;
  border: 1px solid gray;
`

export const PreviewSection = styled(EditableSection)`
  width: 33.33%;
  max-width: 50%;
  min-height: 30px;
  margin: 0 27px 15px 30px;
  padding: 10px;
  background-color: white;
`

export const Title = styled.p`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.darkPurple};
`

export const Option = styled.li`
  padding-left: 7px;
`

export const Images = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const InfoCard = styled(Info)`
  margin-bottom: 30px;
`
