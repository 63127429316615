import React from "react"
import moment from "moment"
import Select from "react-select"
import get from "lodash.get"
import { useHistory } from "react-router-dom"

// Components
import { Flex, Button, Box } from "@chakra-ui/react"
import { ChatIcon } from "../../../../atoms/Common"

// Styles
import { CustomText, DataRow, EmptyMessage } from "../../styles"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "#E2E8F0", // Theme.color.iceBlue,
    marginTop: "5px",
    marginBottom: "2px",
    marginLeft: 10,
    marginRight: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"
    return { ...provided, opacity, transition }
  },
}

const Conversations = ({
  chats,
  contact,
  isFromQuestions,
  session,
  currentDependent,
  dependents,
  dependentsWithOwner,
  setCurrentDependent,
}) => {
  const history = useHistory()

  const getChatTime = (chat) => {
    if (chat?.startedAt && chat?.finishedAt) {
      return `${moment(chat.finishedAt).diff(
        moment(chat.startedAt),
        "minutes"
      )} minutos`
    }
    if (chat?.startedAt && !chat?.finishedAt && chat?.modifiedAt) {
      return `${moment(chat.modifiedAt).diff(
        moment(chat.startedAt),
        "minutes"
      )} minutos`
    }

    return "Dato no disponible"
  }

  return (
    <Flex
      key="main-notes"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      height="calc(100vh - 200px)"
      width="100%"
      position="relative"
      overflow="hidden"
    >
      <Flex
        key="notes-container"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        height="calc(100vh - 260px)"
        width="100%"
        overflowY="auto"
      >
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          borderBottom="1px solid #edf2f7;"
          width="100%"
        >
          {dependents?.length > 0 && (
            <Flex justifyContent="center" alignItems="center" my="5px">
              <Box as="span" ml="10px">
                Filtrar:
              </Box>
              <Select
                styles={customStyles}
                defaultValue={currentDependent}
                value={dependentsWithOwner.find(
                  (option) => option.value === get(currentDependent, "value")
                )}
                options={dependentsWithOwner}
                onChange={(option) => setCurrentDependent(option || null)}
                placeholder="Seleccione para filtrar"
                isClearable
                width="240px"
              />
            </Flex>
          )}
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent={chats && chats.length === 0 ? "center" : "flex-start"}
          alignItems={chats && chats.length === 0 ? "center" : "flex-start"}
          height="calc(100vh - 240px)"
          width="100%"
          overflow="auto"
          mt="30px"
        >
          {chats && chats.length === 0 ? (
            <EmptyMessage>{`${
              contact?.type === "medical_visitor" ? "Visitador" : "Paciente"
            } no tiene histórico de citas aún.`}</EmptyMessage>
          ) : (
            chats
              .filter((n) => {
                if (currentDependent && !currentDependent.isParent) {
                  return n.dependentContact === currentDependent.value
                }
                if (currentDependent && currentDependent.isParent) {
                  return !n.dependentContact
                }
                return true
              })
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((c) => (
                <DataRow mb="15px" key={c?.id}>
                  <CustomText>
                    {c?.startedAt &&
                      moment(c.startedAt).format("MMM DD, YYYY").toUpperCase()}
                  </CustomText>
                  <CustomText>
                    {c?.startedAt &&
                      moment(c.startedAt).format("HH:mm:ss").toUpperCase()}
                  </CustomText>
                  <CustomText>{getChatTime(c)}</CustomText>
                  <Button
                    h="35px"
                    width="48px"
                    borderRadius="8px"
                    padding="10px, 12px, 10px, 12px"
                    background="#EDF2F7"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin="0px"
                    opacity="0.8"
                    _hover={{
                      background: "#EDF2F7",
                      transform: "scale(1.1)",
                      opacity: 1,
                    }}
                    onClick={() =>
                      history.push("/history", {
                        selectedSession: c.id,
                        contact,
                        isFromQuestions,
                        question: session,
                      })
                    }
                  >
                    <ChatIcon />
                  </Button>
                </DataRow>
              ))
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Conversations
