import React, { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Formik } from "formik"
import * as Yup from "yup"
import get from "lodash.get"
import CustomInput from "../../../../atoms/CustomInput"
import ImageUploader from "../../../../molecules/ImageUploader"
import Theme from "../../../../../styles/theme"
import { withFirebase } from "../../../../../utils/Firebase"
import { AuthUserContext } from "../../../../../context/Session"
import { StyledForm, SubmitButton, ImageContainer } from "./styles"

const Profile = ({ firebase }) => {
  const { t } = useTranslation()
  const user = useContext(AuthUserContext)
  const { addToast } = useToasts()
  const [isSaving, setIsSaving] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState(null)

  return (
    <div>
      <Formik
        // innerRef={formRef}
        initialValues={{
          email: user.medicProfile.email,
          displayName: user.medicProfile.displayName,
          phoneNumber: user.medicProfile.phoneNumber,
          photoURL: user.medicProfile.photoURL,
        }}
        onSubmit={async (values) => {
          setIsSaving(true)
          try {
            const uploadDir = avatarUrl
              ? `medicalAssistants/avatar/${
                  user.isImpersonating ? user.impersonatingEmail : user.email
                }/${values.photoURL.name}`
              : null
            const snapshot =
              uploadDir && Object.keys(values.photoURL).length > 0
                ? await firebase.uploadToStorage(
                    uploadDir,
                    values.photoURL.blob
                  )
                : null
            const tempImageURL = snapshot
              ? await snapshot.ref.getDownloadURL()
              : get(values, "photoURL", null)
            await firebase.updateAssistant(
              { ...values, photoURL: tempImageURL },
              user.isImpersonating ? user.impersonatingEmail : user.email
            )

            // Update the picture of the assitant in provider assistant collection
            const getProviders = await firebase.getAssistantsProviders(
              user.isImpersonating ? user.impersonatingEmail : user.email
            )

            getProviders.docs.forEach(async (provider) => {
              const providerData = provider.data()
              await firebase.upsertMedicalAssistantProvider({
                providerEmail: providerData.email,
                assistantData: {
                  email: user.isImpersonating
                    ? user.impersonatingEmail
                    : user.email,
                  photoURL: tempImageURL,
                },
              })
            })

            user.setUser({
              ...user,
              medicProfile: {
                ...user.medicProfile,
                ...values,
                photoURL: tempImageURL,
              },
            })
          } finally {
            setIsSaving(false)
          }

          addToast(t("web_client.saved_successfully"), {
            appearance: "success",
          })
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("error_auth_invalid_email"))
            .required(t("error_all_fields_required")),
          displayName: Yup.string().required(t("error_all_fields_required")),
          phoneNumber: Yup.string().required(t("error_all_fields_required")),
        })}
      >
        {(props) => (
          <StyledForm onSubmit={props.handleSubmit}>
            <ImageContainer>
              <ImageUploader
                src={get(
                  props,
                  ["values", "photoURL", "blobUrl"],
                  props.values.photoURL
                )}
                onImageLoaded={(field, data) => {
                  setAvatarUrl(data)
                  props.setFieldValue(field, data)
                }}
                fieldName="photoURL"
                width={130}
                height={130}
                emptyMessage="Avatar"
              />
            </ImageContainer>
            <CustomInput
              touched={props.touched.email}
              errors={props.errors.email}
              icon="user"
              value={props.values.email}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              labelName={t("web_client.settings_table_email")}
              type="text"
              id="email"
              name="email"
              readonly
            />
            <CustomInput
              touched={props.touched.displayName}
              errors={props.errors.displayName}
              icon="user"
              value={props.values.displayName}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              labelName={t("name")}
              type="text"
              id="displayName"
              name="displayName"
            />
            <CustomInput
              touched={props.touched.phoneNumber}
              errors={props.errors.phoneNumber}
              icon="user"
              value={props.values.phoneNumber}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              labelName={t("q4_business_companyPhone_label")}
              type="text"
              id="phoneNumber"
              name="phoneNumber"
            />
            <SubmitButton
              isLoading={isSaving}
              type="submit"
              color={Theme.color.secondary}
            >
              {t("save")}
            </SubmitButton>
          </StyledForm>
        )}
      </Formik>
    </div>
  )
}

export default withFirebase(Profile)
