import styled from "styled-components"
import { GridContainer } from "../../atoms/Global"

// eslint-disable-next-line import/prefer-default-export
export const Container = styled(GridContainer)`
  padding: 0;
  background-color: ${(props) => props.theme.color.background.gray30};
`

export const HelpContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  padding-left: 22px;
`

export const VideoBackground = styled.video`
  position: fixed;
  z-index: -1;
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: opacity 1s ease-in-out;
  background-color: ${(props) => props.theme.color.background.gray30};
  @media (min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }
  @media (max-aspect-ratio: 16/9) {
    width: auto;
    height: 100%;
  }
`

export const ChatWrapper = styled.div`
  padding-top: 49px;
  padding-left: 60px;
  height: 100%;
  width: 100%;
`

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 22px;
  width: 370px;
  border: 1px solid ${(props) => props.theme.color.black.twelve};
  box-sizing: border-box;
  border-radius: 4px;
  margin: 12px 0px;
  min-height: ${(props) => props.minHeight};
`

export const Item = styled.div`
  margin-bottom: 16px;
  margin-left: 16px;
  & > a {
    display: flex;
    align-items: center;
    &:visited {
      color: black;
    }
    & > svg {
      cursor: pointer;
      color: ${(props) =>
        props.active
          ? props.theme.color.secondary
          : props.theme.color.black.regular};
      margin-right: 10px;
    }
  }
`

export const ItemContact = styled.div`
  margin-bottom: 16px;
  margin-left: 16px;
  & > a {
    display: flex;
    align-items: center;
    &:visited {
      color: black;
    }
    & > svg {
      cursor: pointer;
      color: ${(props) => props.theme.color.secondary};
      margin-right: 10px;
    }
  }
`

export const TitleContainer = styled.div`
  margin: 20px 0;
`
