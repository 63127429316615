import React, { useContext, useState, useEffect } from "react"
import get from "lodash.get"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { AuthUserContext } from "../../../context/Session"
import Sidebar from "../../atoms/Sidebar"
import { withFirebase } from "../../../utils/Firebase"

function Layout(props) {
  const { children, firebase } = props
  const [allowedUser, setAllowedUser] = useState(false)
  const authUser = useContext(AuthUserContext)
  const history = useHistory()

  const hideSideBar = [
    "/login",
    "/setup-account",
    "/verify",
    "/setup-account/userType",
    "/forgot-password",
  ]

  useEffect(() => {
    if (
      get(authUser, ["medicProfile", "userType"]) === "provider" ||
      get(authUser, ["medicProfile", "userType"]) === "medicalAssistant"
    ) {
      if (
        !hideSideBar.includes(history.location.pathname) &&
        !get(props, "location.state.isFromLogin", false)
      ) {
        setAllowedUser(true)
      } else {
        setAllowedUser(false)
      }
    } else {
      setAllowedUser(false)
    }
  }, [
    history.location.pathname,
    get(authUser, ["medicProfile", "active"]),
    get(authUser, ["medicProfile", "userType"]),
  ])

  return (
    <LayoutContainer>
      {allowedUser && <Sidebar history={history} firebase={firebase} />}
      <MainContainer
        withSidebar={
          get(authUser, ["medicProfile", "sessionPrice"]) && allowedUser
        }
      >
        {children}
      </MainContainer>
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
}

const LayoutContainer = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 100%;
  overflow: hidden;
`

const MainContainer = styled.section`
  width: ${(props) => (props.withSidebar ? "calc(100% - 71px)" : "100%")};
  height: 100%;
`

export default withFirebase(Layout)
