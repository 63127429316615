import styled from "styled-components"

export const PasswordMessagesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px !important;
`

export const PasswordInfoMessage = styled.li`
  ${(props) => props.theme.font_size.small};
  color: ${(props) =>
    props.isEmpty ? "gray" : props.isValid ? "green" : "red"};
`
