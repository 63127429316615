import "moment/locale/es"
import * as Yup from "yup"
import "react-datepicker/dist/react-datepicker.css"
import es from "date-fns/locale/es"
import PropTypes from "prop-types"
import moment from "moment"
import { utils, writeFile } from "xlsx"
import React, { useEffect, useState, useContext, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Formik, Form } from "formik"
import { registerLocale } from "react-datepicker"
import { v4 as uuidv4 } from "uuid"
import get from "lodash.get"

// Components
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  VStack,
  FormControl,
  Button,
} from "@chakra-ui/react"

import CustomInput from "../../chakra/CustomInput"
import UploadButton from "../../atoms/UploadButton"
import { secondsToDate } from "../../../utils/helpers"
import { createCustomTokenUrl, linksCreateUrl } from "../../../utils/App"

// Children
import {
  MedicalProfile,
  Notes,
  Conversations,
  Prescriptions,
  Labs,
  MedicalImages,
  Instructions,
  MedicalMonitoring,
  MedicalDocuments,
  Odontogram,
} from "./Children"

// Utils
import { withFirebase } from "../../../utils/Firebase"
import { AuthUserContext } from "../../../context/Session"

const ContactInfo = ({
  contact,
  firebase,
  chats,
  subpath,
  session,
  isFromQuestions,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const user = useContext(AuthUserContext)
  const { addToast } = useToasts()
  const formRef = useRef()

  const [currentTarget, setCurrentTarget] = useState({})
  const [medicalData, setMedicalData] = useState(null)
  const [dependents, setDependents] = useState([])
  const [dependentsWithOwner, setDependentsWithOwner] = useState([])
  const [currentDependent, setCurrentDependent] = useState(null)
  const [medicalNotes, setMedicalNotes] = useState([])
  const [isAddNoteVisible, setIsAddNoteVisible] = useState(false)
  const [currentNote, setCurrentNote] = useState(null)
  const [prescriptions, setPrescriptions] = useState([])
  const [medicalImages, setMedicalImages] = useState([])
  const [clinical, setClinical] = useState([])
  const [currentCode, setCurrentCode] = useState(null)
  const [newResultUrl, setNewResultUrl] = useState(null)
  const [tabType, setTabType] = useState(null)
  const [isAddingResult, setIsAddingResult] = useState(false)
  const [uploadingFile, setUploadingFile] = useState(false)
  const [uploadingDocument, setUploadingDocument] = useState(false)
  const [contactDocuments, setContactDocuments] = useState([])
  const [recommendations, setRecommendations] = useState([])

  const { isOpen, onOpen, onClose } = useDisclosure()

  const currentYear = new Date().getFullYear()
  const getBirthdate = (target) => {
    let age = "0"
    const birthDate = secondsToDate(get(target, "birthDate", null), "seconds")
    if (birthDate) {
      age = currentYear - birthDate.getFullYear()
    }
    return age
  }

  const isOdontoUser =
    user?.medicProfile?.profession === "odontology" ||
    user?.medicProfile?.profession === "orthodontics"

  const exportToExcel = async () => {
    const general = [
      {
        Tipo: "Nombre",
        Data: get(contact, ["displayName"], "Nombre"),
      },
      {
        Tipo: "Email",
        Data: contact.email,
      },
      {
        Tipo: "Telefono",
        Data: contact.phoneNumber,
      },
      {
        Tipo: "Edad",
        Data: t("target_age", {
          age: getBirthdate(currentTarget),
        }),
      },
      {
        Tipo: "Genero",
        Data:
          currentDependent && !currentDependent.isParent
            ? get(currentDependent, "dependentGender", "N/A")
            : get(currentTarget, "gender", "N/A"),
      },
    ]

    const medicalDataParsed = Object.keys(medicalData).map((key) => ({
      Tipo: key,
      Data: medicalData[key],
    }))

    const prescriptionsData = prescriptions.map((p) => ({
      Fecha: moment(p.createdAt).format("DD/MM/YYYY"),
      Codigo: p.code,
      Diagnostico: p.diagnostic,
      Instrucciones: p.recommendations,
      Medicamentos: p.medicines
        .map(
          (m) =>
            `Nombre:${m.name}, Dosis: ${m.dosis}, Frecuencia: ${m.instructions}`
        )
        .join(", "),
      Pdf: p.pdfUrl,
    }))

    const medicalImagesData = medicalImages.map((m) => ({
      Fecha: moment(m.createdAt).format("DD/MM/YYYY"),
      Codigo: m.code,
      Notas: m.notes,
      Pdf: m.pdfUrl,
    }))

    const clinicalData = clinical.map((c) => ({
      Fecha: moment(c.createdAt).format("DD/MM/YYYY"),
      Codigo: c.code,
      Notas: c.notes,
      Pdf: c.pdfUrl,
    }))

    let ucerCt
    let shortUrl
    let odontogramData = []

    if (isOdontoUser) {
      try {
        const response = await fetch(createCustomTokenUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: contact.email,
            provider: user.isImpersonating
              ? user.impersonatingEmail
              : user.email,
          }),
        })

        if (response.ok) {
          const data = await response.json()
          const { createdToken } = data
          ucerCt = createdToken
        } else {
          console.error("Error fetching token")
        }
      } catch (e) {
        console.error(e)
      }

      try {
        const response = await fetch(linksCreateUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            originalLink: `${window.location.origin}/check/odontogram/${ucerCt}`,
            domain: process.env.SHORT_DOMAIN || "link.app.develop.doc.cr",
          }),
        })

        if (response.ok) {
          const data = await response.json()
          const { shortLink } = data
          shortUrl = shortLink
        } else {
          console.error("Error fetching token")
        }
      } catch (e) {
        console.error(e)
      }

      odontogramData = [
        {
          Fecha: moment().format("DD/MM/YYYY"),
          Codigo: "ODONTOGRAMA",
          URL: shortUrl,
        },
      ]
    }

    const formattedDate = new Date().toLocaleDateString("deafult", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })

    const fileName = `Expediente-${get(
      contact,
      ["displayName"],
      ""
    ).toUpperCase()}-${formattedDate}`

    const wsGeneral = utils.json_to_sheet(general, { skipHeader: true })
    const wsMedical = utils.json_to_sheet(medicalDataParsed, {
      skipHeader: true,
    })
    const wsPrescriptions = utils.json_to_sheet(prescriptionsData)
    const wsMedicalImages = utils.json_to_sheet(medicalImagesData)
    const wsClinical = utils.json_to_sheet(clinicalData)
    const wsOdontogram = utils.json_to_sheet(odontogramData)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, wsGeneral, "Informacion General")
    utils.book_append_sheet(wb, wsMedical, "Historial Medico")
    utils.book_append_sheet(wb, wsPrescriptions, "Recetas")
    utils.book_append_sheet(wb, wsMedicalImages, "Imagenes Medicas")
    utils.book_append_sheet(wb, wsClinical, "Estudios Clinicos")
    if (isOdontoUser) {
      utils.book_append_sheet(wb, wsOdontogram, "Odontograma")
    }

    // workSheets.forEach(({ sheetName, data, columnNames }, i) => {
    //   const ws = XLSX.utils.json_to_sheet(data)
    //   if (columnNames?.length) {
    //     XLSX.utils.sheet_add_aoa(ws, [columnNames], { origin: "A1" })
    //   }
    //   XLSX.utils.book_append_sheet(wb, ws, sheetName || `Sheet${i + 1}`)
    // })
    // XLSX.writeFile(wb, `${fileName}.xlsx`)
    writeFile(wb, `${fileName}.xlsx`)
  }

  useEffect(() => {
    moment.locale(language)
    registerLocale("es", es)
  }, [language])

  useEffect(() => {
    const getNotes = async () => {
      const localNotes = await firebase.getNotes(
        user.isImpersonating ? user.impersonatingEmail : user.email,
        contact.id
      )
      if (localNotes) {
        setMedicalNotes(
          localNotes.docs.map((d) => ({
            id: d.id,
            ...d.data(),
          }))
        )
      }
    }
    if (!isAddNoteVisible) {
      if (!currentNote) {
        getNotes()
      }
      setCurrentNote(null)
    }
  }, [isAddNoteVisible, contact.id])

  useEffect(() => {
    const getPrescriptions = async () => {
      const data = await firebase.getPrescriptions(
        contact.email,
        user.isImpersonating ? user.impersonatingEmail : user.email
      )
      if (data && data.docs) {
        const prescriptionsData = data.docs.map((d) => d.data())
        setPrescriptions(prescriptionsData)
      }
    }

    const getRecommendations = async () => {
      const data = await firebase.getRecommendations(
        contact.email,
        user.isImpersonating ? user.impersonatingEmail : user.email
      )

      if (data && data.docs) {
        const recommendationsData = data.docs.map((d) => d.data())
        setRecommendations(recommendationsData)
      }
    }

    const getMedicalImages = async () => {
      const data = await firebase.getMedicalImages(
        contact.email,
        user.isImpersonating ? user.impersonatingEmail : user.email
      )
      if (data && data.docs) {
        const medicalImagesData = data.docs.map((d) => ({
          id: d.id,
          ...d.data(),
        }))
        setMedicalImages(medicalImagesData)
      }
    }

    const getClinical = async () => {
      const data = await firebase.getClinical(
        contact.email,
        user.isImpersonating ? user.impersonatingEmail : user.email
      )
      if (data && data.docs) {
        const clinicalData = data.docs.map((d) => ({
          id: d.id,
          ...d.data(),
        }))
        setClinical(clinicalData)
      }
    }
    if (contact || newResultUrl) {
      getPrescriptions()
      getMedicalImages()
      getClinical()
      getRecommendations()
    }
  }, [contact, newResultUrl])

  useEffect(() => {
    const getTarget = async () => {
      try {
        setCurrentDependent(null)
        setMedicalData(null)
        const target = await firebase.getProfile({
          email: contact.email,
        })
        setCurrentTarget(target.data())
        const medical = await firebase.getMedicalForm(contact.email)
        setMedicalData(medical.data())
        const dep = await firebase.getDependents(contact.email)
        if (dep) {
          const newDeps = dep.docs.map((d) => ({
            value: d.id,
            label: `${d.data().dependentName} (${
              d.data().dependentRelationship
            })`,
            ...d.data(),
            isParent: false,
          }))
          setDependents(newDeps)
          setDependentsWithOwner([
            { value: contact.id, isParent: true, label: "Encargado" },
            ...newDeps,
          ])
        }
      } catch {
        addToast(t("unexpected_error"), {
          appearance: "error",
        })
      }
    }
    if (contact) {
      getTarget()
    }
  }, [contact])

  useEffect(() => {
    const getDocuments = async () => {
      const data = await firebase.getDocuments(
        user.isImpersonating ? user.impersonatingEmail : user.email,
        contact.id
      )
      if (data && data.docs) {
        const documentsData = data.docs.map((d) => d.data())
        setContactDocuments(documentsData)
      }
    }

    if (contact || uploadingDocument) {
      getDocuments()
    }
  }, [contact, uploadingDocument])

  const handleSaveLocalSignature = (resultFile) => {
    const fileName = `${currentCode?.code}-${uuidv4()}.png`
    const uploadDir = `clients/${contact.email}/results/${tabType}`
    try {
      const uploadTask = firebase.uploadProviderFile({
        file: resultFile,
        uploadDir,
        fileName,
        // metadata
      })
      uploadTask.on(
        "state_changed",
        null,
        () => {
          addToast(t("unexpected_error"), {
            appearance: "error",
          })
        },
        () => {
          firebase
            .getMediaURL({ uploadDir, fileName })
            .then((url) => {
              setUploadingFile(false)
              formRef?.current?.setFieldValue("fileName", fileName)
              formRef?.current?.setFieldValue("fileUrl", url)
            })
            .catch(() => {
              setUploadingFile(false)
              addToast(t("error_failed_file_upload"), { appearance: "error" })
            })
        }
      )
    } catch {
      setUploadingFile(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  const handleSaveDocument = (resultFile, fileName) => {
    const uploadDir = `clients/${contact.email}/documents`
    try {
      const uploadTask = firebase.uploadProviderFile({
        file: resultFile,
        uploadDir,
        fileName,
        // metadata
      })
      uploadTask.on(
        "state_changed",
        null,
        () => {
          addToast(t("unexpected_error"), {
            appearance: "error",
          })
        },
        () => {
          firebase
            .getMediaURL({ uploadDir, fileName })
            .then((url) => {
              firebase.AddMedicalDocument({
                doctor: user.isImpersonating
                  ? user.impersonatingEmail
                  : user.email,
                contact: contact.id,
                document: { name: fileName, url, createdAt: Date.now() },
              })
              setUploadingDocument(false)
            })
            .catch(() => {
              setUploadingDocument(false)
              addToast(t("error_failed_file_upload"), { appearance: "error" })
            })
        }
      )
    } catch {
      setUploadingDocument(false)
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  const handleReadDocument = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    setUploadingDocument(true)
    reader.addEventListener("load", (e) => {
      handleSaveDocument(e.target.result, file?.name)
    })
    reader.readAsDataURL(file)
  }

  const handleReadFile = (event) => {
    const file = event.target.files[0]
    const reader = new FileReader()
    setUploadingFile(true)
    reader.addEventListener("load", (e) => {
      formRef?.current?.setFieldValue("fileName", e.target.result)
      handleSaveLocalSignature(e.target.result)
    })
    reader.readAsDataURL(file)
  }

  const handleSubmit = async (formRefValue) => {
    try {
      if (formRefValue.current) {
        formRefValue.current.handleSubmit()
      }
    } catch {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const currentTargetInfo =
    currentDependent && !currentDependent.isParent
      ? currentDependent
      : medicalData

  const hasSubPath = subpath === "medical_follow" ? 7 : 9
  return (
    <>
      <Tabs
        defaultIndex={
          subpath === "odontogram" || subpath === "odontogram" ? hasSubPath : 0
        }
      >
        <TabList>
          {contact?.type !== "medical_visitor" && (
            <Tab
              _focus={{ boxShadow: "none" }}
              onClick={() => setTabType(null)}
            >
              {t("medical_profile")}
            </Tab>
          )}
          <Tab
            _focus={{ boxShadow: "none" }}
            onClick={() => setTabType(null)}
            isDisabled={user?.isImpersonating}
          >
            {t("add_medical_note")}
          </Tab>
          <Tab _focus={{ boxShadow: "none" }} onClick={() => setTabType(null)}>
            {t("web_client.conversations")}
          </Tab>
          {contact?.type !== "medical_visitor" && (
            <>
              <Tab
                _focus={{ boxShadow: "none" }}
                onClick={() => setTabType(null)}
              >
                {t("web_client.prescriptions")}
              </Tab>
              <Tab
                _focus={{ boxShadow: "none" }}
                onClick={() => {
                  setTabType("clinical")
                  setCurrentCode(null)
                  setNewResultUrl(null)
                }}
              >
                {t("labs_label")}
              </Tab>
              <Tab
                _focus={{ boxShadow: "none" }}
                onClick={() => {
                  setTabType("medicalImages")
                  setCurrentCode(null)
                  setNewResultUrl(null)
                }}
              >
                {t("web_client.medical_images_label")}
              </Tab>
              <Tab
                _focus={{ boxShadow: "none" }}
                onClick={() => {
                  setTabType("instructions")
                  setCurrentCode(null)
                  setNewResultUrl(null)
                }}
                isDisabled={user?.isImpersonating}
              >
                {t("recommendations")}
              </Tab>
              <Tab
                _focus={{ boxShadow: "none" }}
                onClick={() => setTabType(null)}
                isDisabled={user?.isImpersonating}
              >
                {t("add_medical_follow")}
              </Tab>
              <Tab
                _focus={{ boxShadow: "none" }}
                onClick={() => setTabType(null)}
                isDisabled={user?.isImpersonating}
              >
                {t("documents")}
              </Tab>
              {isOdontoUser && (
                <Tab
                  _focus={{ boxShadow: "none" }}
                  onClick={() => setTabType(null)}
                  isDisabled={user?.isImpersonating}
                >
                  {t("add_odontogram")}
                </Tab>
              )}
            </>
          )}
        </TabList>
        <TabPanels>
          {contact?.type !== "medical_visitor" && (
            <TabPanel maxH="calc(100vh - 210px)" overflowX="auto">
              <MedicalProfile
                contact={contact}
                dependents={dependents}
                currentDependent={currentDependent}
                dependentsWithOwner={dependentsWithOwner}
                currentTarget={currentTarget}
                currentTargetInfo={currentTargetInfo}
                medicalData={medicalData}
                setCurrentDependent={setCurrentDependent}
                exportToExcel={exportToExcel}
              />
            </TabPanel>
          )}
          <TabPanel position="relative" overflow="hidden">
            <Notes
              contact={contact}
              currentDependent={currentDependent}
              currentNote={currentNote}
              dependents={dependents}
              dependentsWithOwner={dependentsWithOwner}
              medicalNotes={medicalNotes}
              isAddNoteVisible={isAddNoteVisible}
              setIsAddNoteVisible={setIsAddNoteVisible}
              setCurrentDependent={setCurrentDependent}
              setCurrentNote={setCurrentNote}
              isImpersonating={user?.isImpersonating}
            />
          </TabPanel>
          <TabPanel>
            <Conversations
              chats={chats}
              contact={contact}
              isFromQuestions={isFromQuestions}
              session={session}
              currentDependent={currentDependent}
              dependents={dependents}
              dependentsWithOwner={dependentsWithOwner}
              setCurrentDependent={setCurrentDependent}
            />
          </TabPanel>
          {contact?.type !== "medical_visitor" && (
            <TabPanel>
              <Prescriptions
                prescriptions={prescriptions}
                currentDependent={currentDependent}
                dependents={dependents}
                dependentsWithOwner={dependentsWithOwner}
                setCurrentDependent={setCurrentDependent}
              />
            </TabPanel>
          )}
          {contact?.type !== "medical_visitor" && (
            <TabPanel>
              <Labs
                clinical={clinical}
                setCurrentCode={setCurrentCode}
                onOpen={onOpen}
                currentDependent={currentDependent}
                dependents={dependents}
                dependentsWithOwner={dependentsWithOwner}
                setCurrentDependent={setCurrentDependent}
              />
            </TabPanel>
          )}
          {contact?.type !== "medical_visitor" && (
            <TabPanel>
              <MedicalImages
                medicalImages={medicalImages}
                setCurrentCode={setCurrentCode}
                onOpen={onOpen}
                currentDependent={currentDependent}
                dependents={dependents}
                dependentsWithOwner={dependentsWithOwner}
                setCurrentDependent={setCurrentDependent}
              />
            </TabPanel>
          )}
          {contact?.type !== "medical_visitor" && (
            <TabPanel maxH="calc(100vh - 210px)" overflowX="auto">
              <Instructions
                recommendations={recommendations}
                currentDependent={currentDependent}
                dependents={dependents}
                dependentsWithOwner={dependentsWithOwner}
                setCurrentDependent={setCurrentDependent}
              />
            </TabPanel>
          )}
          <TabPanel maxH="calc(100vh - 210px)" overflowX="auto">
            <MedicalMonitoring
              session={session}
              firebase={firebase}
              user={user}
              contact={contact}
              isFromQuestions={isFromQuestions}
            />
          </TabPanel>
          <TabPanel position="relative" overflow="hidden">
            <MedicalDocuments
              contactDocuments={contactDocuments}
              handleReadDocument={handleReadDocument}
              isUploading={uploadingDocument}
            />
          </TabPanel>
          {isOdontoUser && (
            <TabPanel position="relative" overflow="hidden">
              <Odontogram firebase={firebase} user={user} contact={contact} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>

      {/* Drawer para añadir un resultado empieza aca */}
      <Drawer size="sm" isOpen={isOpen} onClick={onClose}>
        <DrawerOverlay>
          <DrawerContent background="white">
            <DrawerHeader>Agregar un resultado</DrawerHeader>
            <DrawerBody>
              <Formik
                innerRef={formRef}
                initialValues={{
                  fileUrl: undefined,
                  fileName: undefined,
                  comment: undefined,
                  type: tabType,
                  createdBy: user.isImpersonating
                    ? user.impersonatingEmail
                    : user.email,
                }}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true)
                  setIsAddingResult(true)
                  try {
                    // const token = await firebase.getIdToken()
                    await firebase.addResult({
                      type: tabType,
                      resultUrl: values.fileUrl,
                      id: currentCode.id,
                    })
                    onClose()
                    actions.setSubmitting(false)
                    setIsAddingResult(false)
                    setNewResultUrl(values.fileName)
                  } catch (error) {
                    setIsAddingResult(false)
                    addToast(t("unexpected_error"), { appearance: "error" })
                  }
                }}
                validationSchema={Yup.object({
                  comment: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  type: Yup.string().required(t("error_all_fields_required")),
                  fileUrl: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                  fileName: Yup.string().required(
                    t("error_all_fields_required")
                  ),
                })}
              >
                {(props) => (
                  <Form>
                    <VStack spacing={7}>
                      <FormControl>
                        <CustomInput
                          name="comment"
                          id="comment"
                          placeholder="Comentarios"
                          showErrorMessage={props.errors.comment}
                          errorMessage={props.errors.comment}
                          handleInputChange={(e) =>
                            props.setFieldValue("comment", e.target.value)
                          }
                          props={{
                            value: props.values.comment,
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <CustomInput
                          name="fileName"
                          id="fileName"
                          placeholder="Seleccionar archivo"
                          showErrorMessage={props.errors.fileName}
                          errorMessage={props.errors.fileName}
                          disabled
                          props={{
                            readOnly: true,
                            disabled: true,
                            value: props.values.fileName,
                          }}
                        />
                      </FormControl>
                      <UploadButton
                        onChange={handleReadFile}
                        isUploading={uploadingFile}
                        onlyImages={false}
                      >
                        Seleccionar Resultado
                      </UploadButton>
                    </VStack>
                  </Form>
                )}
              </Formik>
            </DrawerBody>
            <DrawerFooter>
              <Button variant="solid" color="black" mr={3} onClick={onClose}>
                {t("cancel")}
              </Button>
              <Button
                background="brand.primary"
                color="white"
                _hover={{
                  background: "brand.primary",
                }}
                mr={3}
                onClick={() => handleSubmit(formRef)}
                isLoading={isAddingResult}
              >
                Agregar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

ContactInfo.defaultProps = {
  subpath: null,
  session: null,
}

ContactInfo.propTypes = {
  contact: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    birthDate: PropTypes.string,
    gender: PropTypes.string,
  }).isRequired,
  subpath: PropTypes.string,
  session: PropTypes.string,
}

export default withFirebase(ContactInfo)
