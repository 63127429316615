const getPrescriptionTemplate = (product) => {
  if (product) {
    const { sku, pharmaceuticalPresentation, composition } = product

    return {
      name: sku,
      type: pharmaceuticalPresentation || "",
      dci: composition || "",
      dosis: "",
    }
  }

  return {
    name: "",
    type: "",
    dosis: "",
    dci: "",
    instructions: "",
  }
}

export default getPrescriptionTemplate
