import React, { useContext } from "react"
import { compose } from "recompose"

// Components
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  HStack,
  Text,
} from "@chakra-ui/react"

// hooks
import { withFirebase } from "../../../utils/Firebase"
import { WithChakra } from "../../../components/atoms/WithChakra"

// utils
import ChatContext from "../../../context/Chat"
import { MODALS, toggleModal } from "../../../context/Chat/reducer"

const ConfirmPaymentModal = ({ firebase }) => {
  const { state, dispatch } = useContext(ChatContext)
  return (
    <Modal
      closeOnOverlayClick={false}
      onClose={() => dispatch(toggleModal(MODALS.CONFIRM_PAYMENT, false, null))}
      isOpen={state.modals[MODALS.CONFIRM_PAYMENT]}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader backgroundColor="white">
          Confirmar pago de cita
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody backgroundColor="white">
          <p>Totales:</p>
          <p>
            {state?.currentSession?.sessionTotal?.toLocaleString("default", {
              style: "currency",
              currency: state?.currentSession?.sessionCurrency,
            })}
          </p>
          <p>
            {state?.currentSession?.localSessionTotal?.toLocaleString(
              "default",
              {
                style: "currency",
                currency: state?.currentSession?.localSessionCurrency || "USD",
              }
            )}
          </p>
          <p>{`Duración de la cita: ${
            state?.currentSession?.slotDuration || 0
          } minutos.`}</p>
          <p style={{ marginTop: 10 }}>
            ¿Estás seguro que quieres confirmar el pago de la cita?
          </p>
          <button
            type="button"
            onClick={() =>
              dispatch(toggleModal(MODALS.CONFIRM_PAYMENT, false, null))
            }
          />
        </ModalBody>
        <ModalFooter backgroundColor="white">
          <HStack>
            <Button
              onClick={async () => {
                await firebase.confirmAppointmentPayment(
                  state?.currentSession?.chatId
                )
                await dispatch(toggleModal(MODALS.CONFIRM_PAYMENT, false, null))
              }}
            >
              <Text>Confirmar</Text>
            </Button>
            <Button
              onClick={() =>
                dispatch(toggleModal(MODALS.CONFIRM_PAYMENT, false, null))
              }
            >
              <Text>Cancelar</Text>
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const CompConfirmPaymentModal = compose(
  WithChakra,
  withFirebase
)(ConfirmPaymentModal)

export default CompConfirmPaymentModal
