/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import get from "lodash.get"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Formik } from "formik"
import * as Yup from "yup"
import { withFirebase } from "../../../utils/Firebase"
import { generatePassword } from "../../../utils/App"
import CustomInput from "../../atoms/CustomInput"
import Drawer from "../../atoms/Drawer"
import Theme from "../../../styles/theme"
import { StyledForm, SubmitButton } from "./styles"

const AccessDrawer = ({ firebase, open, closeDrawer, provider }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const formRef = useRef()
  const [isCreating, setIsCreating] = useState(false)
  const createUserUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserCreateNew`
  const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`

  const createAssistant = async (formValues) => {
    try {
      const token = await firebase.getIdToken()
      const getAssistant = await firebase.getProfile({
        email: get(formValues, "email"),
      })
      const assistantData = getAssistant.data()
      if (get(assistantData, "email") !== formValues.email) {
        await fetch(createUserUrl, {
          method: "POST",
          body: JSON.stringify({
            user: {
              email: formValues.email,
              password: formValues.password,
              displayName: `${get(
                assistantData,
                "name",
                formValues?.name
              )} ${get(assistantData, "surname1", formValues?.surname1)}`,
              phoneNumber: formValues.phoneNumber,
              userType: "medicalAssistant",
            },
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }).then(async () => {
          try {
            const displayAssistantName = `${get(
              assistantData,
              "name",
              formValues?.name
            )} ${get(assistantData, "surname1", formValues?.surname1)}`

            // This timeout gives time for the firebase trigger to be executed
            setTimeout(async () => {
              await fetch(mailerUrl, {
                method: "POST",
                body: JSON.stringify({
                  data: {
                    templateId: 2870998, // invite-assitant
                    email: formValues.email,
                    variables: {
                      subject:
                        "Te han invitado como asistente de la plataforma DOC",
                      providerName: provider.name,
                      displayName: displayAssistantName,
                      password: formValues.password,
                      websiteLink: process.env.REACT_APP_CRONOFY_REDIRECT_URL,
                      assistantName: get(
                        assistantData,
                        "name",
                        formValues?.name
                      ),
                    },
                  },
                }),
                headers: {
                  Authorization: `Bearer ${token}`,
                  "App-Environment":
                    process.env.NODE_ENV === "development" ? "stage" : "prod",
                  "Content-Type": "application/json",
                },
              })
            }, 2500)
          } catch (e) {
            addToast(t("unexpected_error"), {
              appearance: "error",
              placement: "bottom-center",
            })
          }
        })
      } else {
        const displayAssistantName = `${get(
          assistantData,
          "name",
          formValues?.name
        )} ${get(assistantData, "surname1", formValues?.surname1)}`

        // This timeout gives time for the firebase trigger to be executed
        setTimeout(async () => {
          await fetch(mailerUrl, {
            method: "POST",
            body: JSON.stringify({
              data: {
                templateId: 4322695, // invite-previously-created-assistant
                email: formValues.email,
                variables: {
                  subject:
                    "Te han invitado como asistente de la plataforma DOC",
                  providerName: provider.name,
                  displayName: displayAssistantName,
                  websiteLink: process.env.REACT_APP_CRONOFY_REDIRECT_URL,
                  assistantName: get(assistantData, "name", formValues?.name),
                },
              },
            }),
            headers: {
              Authorization: `Bearer ${token}`,
              "App-Environment":
                process.env.NODE_ENV === "development" ? "stage" : "prod",
              "Content-Type": "application/json",
            },
          })
        }, 2500)
      }

      const displayAssistantName = `${get(
        assistantData,
        "name",
        formValues?.name
      )} ${get(assistantData, "surname1", formValues?.surname1)}`

      await firebase.upsertMedicalAssistantProvider({
        providerEmail: provider.email.toLowerCase(),
        assistantData: {
          displayName: displayAssistantName,
          surname1: formValues?.surname1,
          name: formValues?.name,
          phoneNumber: formValues.phoneNumber,
          email: formValues.email,
          active: true,
        },
      })

      await firebase.saveAssistantData({
        email: formValues.email,
        data: {
          displayName: displayAssistantName,
          surname1: formValues?.surname1,
          name: formValues?.name,
          email: formValues.email,
          active: true,
          status: "active",
          country: provider.country,
          userType: "medicalAssistant",
          phoneNumber: formValues.phoneNumber,
        },
        provider,
      })

      addToast(t("web_client.saved_successfully"), {
        appearance: "success",
        placement: "bottom-center",
      })

      setIsCreating(false)
      formRef.current.resetForm()
      closeDrawer()
    } catch (e) {
      addToast(t("unexpected_error"), {
        appearance: "error",
        placement: "bottom-center",
      })
      setIsCreating(false)
    }
  }

  return (
    <Drawer
      openDrawer={open}
      closeDrawer={closeDrawer}
      title={t("web_client.create_assistant_access")}
      internal
    >
      <div>
        <Formik
          innerRef={formRef}
          initialValues={{
            email: undefined,
            password: generatePassword(),
            phoneNumber: undefined,
            userType: "medicalAssistant",
            name: undefined,
            surname1: undefined,
          }}
          onSubmit={async (values) => {
            setIsCreating(true)
            try {
              const updatedValues = {
                email: values.email.toLowerCase(),
                ...values,
              }
              await createAssistant(updatedValues)
            } catch (e) {
              setIsCreating(false)
            }
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("error_auth_invalid_email"))
              .required(t("error_all_fields_required")),
            name: Yup.string().required(t("error_all_fields_required")),
            surname1: Yup.string().required(t("error_all_fields_required")),
            phoneNumber: Yup.string().required(t("error_all_fields_required")),
          })}
        >
          {(props) => (
            <StyledForm onSubmit={props.handleSubmit}>
              <CustomInput
                touched={props.touched.name}
                errors={props.errors.name}
                icon="user"
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("name")}
                type="text"
                id="name"
                name="name"
              />
              <CustomInput
                touched={props.touched.surname1}
                errors={props.errors.surname1}
                icon="user"
                value={props.values.surname1}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("lastname")}
                type="text"
                id="surname1"
                name="surname1"
              />
              <CustomInput
                touched={props.touched.email}
                errors={props.errors.email}
                icon="email"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("email_address")}
                type="email"
                id="email"
                name="email"
              />
              <CustomInput
                touched={props.touched.phoneNumber}
                errors={props.errors.phoneNumber}
                icon="phone"
                value={props.values.phoneNumber}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                labelName={t("web_client.phone")}
                type="text"
                id="phoneNumber"
                name="phoneNumber"
              />
              <SubmitButton
                iconName="login"
                isLoading={isCreating}
                type="submit"
                color={Theme.color.black.normal}
              >
                {t("web_client.create")}
              </SubmitButton>
            </StyledForm>
          )}
        </Formik>
      </div>
    </Drawer>
  )
}

AccessDrawer.defaultProps = {
  open: false,
}

AccessDrawer.propTypes = {
  firebase: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  provider: PropTypes.shape().isRequired,
}
export default withFirebase(AccessDrawer)
