import styled from "styled-components"

export const ButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;

  & input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`

export const Button = styled.button`
  ${(props) => props.theme.font_size.xsmall};
  color: ${(props) => props.theme.color.white.lessdark};
  padding: 1rem 2rem;
  font-weight: 600;
  border-radius: 6px;
  background-color: ${(props) =>
    props.color ? props.color : props.theme.color.secondary};

  &:disabled {
    background-color: ${(props) => props.theme.color.palePuple};
    cursor: not-allowed;
  }
`
