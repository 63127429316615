import React from "react"

const CaelndarIcon = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.875 2.1875H3.125C2.88338 2.1875 2.6875 2.38338 2.6875 2.625V11.375C2.6875 11.6166 2.88338 11.8125 3.125 11.8125H11.875C12.1166 11.8125 12.3125 11.6166 12.3125 11.375V2.625C12.3125 2.38338 12.1166 2.1875 11.875 2.1875Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.125 1.3125V3.0625"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.875 1.3125V3.0625"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.6875 4.8125H12.3125"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.53125 6.99982H7.0625L6.1875 8.09357C6.33137 8.09357 6.47302 8.12904 6.59991 8.19685C6.7268 8.26467 6.83501 8.36272 6.91496 8.48233C6.99491 8.60195 7.04413 8.73943 7.05827 8.8826C7.0724 9.02578 7.05101 9.17023 6.996 9.30317C6.94098 9.43611 6.85403 9.55343 6.74285 9.64474C6.63167 9.73605 6.49969 9.79854 6.3586 9.82668C6.2175 9.85481 6.07165 9.84772 5.93395 9.80602C5.79625 9.76433 5.67095 9.68933 5.56916 9.58766"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.375 7.6561L9.25 6.99985V9.8436"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CaelndarIcon
