import styled, { keyframes } from "styled-components"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"
import { InitialAvatar } from "../../atoms/Card/styles"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const Header = styled.div`
  width: 100%;
  height: 90px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 270px;
`

export const SignButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 60px;
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 200px;
  margin: 5px 0;
  margin-right: ${(props) => (props.noRightMargin ? "0" : "10px")};
`

export const SignButton = styled(ButtonWithLoader)`
  min-width: 120px;
  margin-right: ${(props) => (props.noRightMargin ? "0" : "10px")};
`

export const SignButtonsLabel = styled.p`
  margin-right: 10px;
`

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 44px;
  width: 600px;
`

export const Tab = styled.div`
  width: 210px;
  border-bottom: ${(props) =>
    props.isActive ? `1px solid ${props.theme.color.black.normal}` : "none"};
  text-align: center;
  padding-bottom: 7.5px;
  cursor: pointer;
  & > span {
    ${(props) => props.theme.font_size.small};
    color: ${(props) =>
      props.withChanges && !props.isActive
        ? `${props.theme.color.black.lightest} !important`
        : props.theme.color.black.normal};
  }
`

export const Section = styled.section`
  height: calc(100vh - 250px);
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const Avatar = styled.div`
  margin: 10px;
  width: 130px;
  min-height: 130px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.04);
  border: ${(props) =>
    props.showError ? `1px solid ${props.theme.color.alert}` : "none"};
  & > span {
    pointer-events: none;
    position: absolute;
    top: 4px;
    ${(props) => props.theme.font_size.xxxsmall};
    line-height: 1.33;
    transition: opacity 0ms ease 400ms;
    color: ${(props) => props.theme.color.darkPurple};
    opacity: ${(props) => (props.isLoaded ? "1" : "0")};
  }
`

export const Thumb = styled(InitialAvatar)`
  width: 130px;
  height: 130px;
  transition: opacity 0ms ease 400ms;
  background-color: rgba(0, 0, 0, 0.04);
  opacity: ${(props) => (props.isLoaded ? "0" : "1")};
  position: absolute;
  top: 0;
  left: 0;
`

export const Image = styled.img`
  cursor: pointer;
  width: 130px;
  height: ${(props) => (props.isSign ? "90px" : "130px")};
  opacity: ${(props) => (props.isLoaded ? "1" : "0")};
  transition: opacity 400ms ease 0ms;
  z-index: 0;
`
export const StyledForm = styled.form`
  width: 350px;
  margin-top: 20px;
  padding-bottom: 100px;
`

export const TextAreaWrapper = styled.div`
  position: relative;
  background: rgba(237, 237, 237, 0.6);
  border-radius: 4px;
  & > span {
    pointer-events: none;
    position: relative;
    left: 50px;
    ${(props) => props.theme.font_size.xxxsmall};
    line-height: 1.33;
    color: ${(props) => props.theme.color.darkPurple};
  }

  & > textarea {
    padding-left: 50px;
    padding-top: 16px;
    line-height: 1.5rem;
  }
`

export const ModalWrapper = styled.div`
  width: 60vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > canvas {
    border: 1px solid black;
    width: 100%;
    min-height: 300px;
    background: white;
  }
`

export const Buttons = styled.div`
  height: 90px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const Images = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  flex-wrap: wrap;
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: row;
    margin-bottom: 5px;
    flex-wrap: nowrap;
    min-height: 155px;
  }
`

export const SessionPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const ConvertCurrencyButton = styled.button`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px;
  background-color: ${(props) => props.theme.color.background.lightGray};
  border: 1px solid ${(props) => props.theme.color.background.lightGray};
  box-sizing: border-box;
  border-radius: 100px;
  margin: 0 0 25px 0;
`

export const NavLabel = styled.span`
  color: ${(props) =>
    props.missingData
      ? props.theme.color.alert
      : props.theme.color.primary} !important;
`

export const CitizenshipLabel = styled.p`
  color: ${(props) => props.theme.color.darkPurple} !important;
  margin-bottom: 20px;
  font-size: 16px;
`

export const SessionPriceInputContainer = styled.div`
  min-width: 350px;
`

export const ModalOptions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  & > section {
    width: 100%;
    padding: 25px;
    p {
      text-align: center;
      ${(props) => props.theme.font_size.small};
    }
    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        background-color: ${(props) => props.theme.color.paleGrey};
        border: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
        border-radius: 5px;
        width: 125px;
        height: 45px;
        margin: 0 8px;
        &:hover {
          color: ${(props) => props.theme.color.white.regular};
          background-color: ${(props) => props.theme.color.black.light};
        }
      }
    }
  }
`

export const StyledText = styled.p`
  color: #1a202c;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
`

export const StyledBoldText = styled.p`
  color: #1a202c;
  font-weight: 600;
  font-size: 16px;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const FeeSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 20px;
`

export const AllCenter = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: ${loaderAnimation} 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
`

export const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
