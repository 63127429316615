import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import DocLogo from "../../../static/images/doc-logo.svg"
import FailIcon from "../../atoms/FailIcon"
import {
  ErrorPageContent,
  ErrorPageWrapper,
  IconWrapper,
  StyledLink,
} from "./styles"

const ERROR_MESSAGES = {
  FOUR_OH_FOUR: "Sorry, the page you are looking for does not exist.",
  FOUR_OH_THREE: "Your user account is not enabled. Please contact support.",
  GENERIC:
    "Try again using an incognito or private tab. If successful, clearing your browser cache and cookies may resolve the issue.",
}

export const ErrorPage = ({ statusCode = 500 }) => {
  const [errorId, setErrorId] = useState("")
  const { t } = useTranslation()

  const handleError = (_err, notice) => {
    setErrorId(notice.id)
  }

  useEffect(() => {
    window.Honeybadger?.notify({
      message: `Error page shown ${statusCode}`,
      name: statusCode,
      afterNotify: (err, notice) => handleError(err, notice),
    })
  }, [])

  const backLink = (code) => {
    if (code === 403) {
      return {
        path: "/account",
        label: t("web_client.back_to_account"),
      }
    }
    return {
      path: "/",
      label: t("web_client.back_to_dashboard"),
    }
  }

  return (
    <ErrorPageWrapper>
      <ErrorPageContent>
        <IconWrapper>
          {statusCode === 404 ? (
            <FailIcon src={DocLogo} />
          ) : (
            <FailIcon src={DocLogo} />
          )}
        </IconWrapper>
        <h2>{t("wrong_label")}</h2>
        <StyledLink href={backLink(statusCode).path}>
          {backLink(statusCode).label}
        </StyledLink>
        <ol>
          <li>{t("error_logged")}</li>

          {statusCode === 404 && <li>{ERROR_MESSAGES.FOUR_OH_FOUR}</li>}
          {statusCode === 403 && <li>{ERROR_MESSAGES.FOUR_OH_THREE}</li>}
          {statusCode === 500 && <li>{ERROR_MESSAGES.GENERIC}</li>}
          {statusCode === 200 && <li>{ERROR_MESSAGES.GENERIC}</li>}

          <li>{t("web_client.error_msg_contact")}</li>
          <li>
            {t("web_client.error_msg_contact_command")}
            <ul>
              <li>Status Code: {statusCode}</li>
              {errorId && <li>Error ID: {errorId}</li>}
            </ul>
          </li>
        </ol>
      </ErrorPageContent>
    </ErrorPageWrapper>
  )
}

export default ErrorPage
