import React from "react"

const DocGroup = ({ className, height, width, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
  >
    <g fill={fill} fillRule="evenodd">
      <path d="M0 316h79c43.63 0 79 35.37 79 79s-35.37 79-79 79H0V316zM316 0h79c43.63 0 79 35.37 79 79s-35.37 79-79 79h-79V0z" />
      <rect width="158" height="158" x="158" y="316" rx="79" />
      <path d="M395 316h79v158h-79c-43.63 0-79-35.37-79-79s35.37-79 79-79z" />
      <rect width="158" height="158" x="158" y="158" rx="79" />
      <path d="M316 158h158v158h-58c-55.228 0-100-44.772-100-100v-58z" />
    </g>
  </svg>
)

DocGroup.defaultProps = {
  height: 474,
  width: 474,
  fill: "#FFF",
}

export default DocGroup
