import React from "react"

const ChatIcon = ({
  className,
  height = "21",
  width = "20",
  fill = "none",
}) => (
  <svg
    className={className}
    height={height}
    width={width}
    viewBox={`0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0002 0.750244C8.30676 0.750643 6.64258 1.19202 5.17149 2.03092C3.70041 2.86983 2.47313 4.07735 1.61046 5.53462C0.747782 6.9919 0.279454 8.6487 0.251567 10.3419C0.22368 12.0352 0.637196 13.7065 1.45141 15.1914L0.649411 17.9958C0.576058 18.2531 0.57279 18.5252 0.639946 18.7841C0.707102 19.043 0.84224 19.2792 1.03137 19.4684C1.2205 19.6575 1.45674 19.7926 1.71564 19.8598C1.97454 19.927 2.24669 19.9237 2.5039 19.8503L5.30835 19.0491C6.61167 19.7643 8.06129 20.1721 9.54637 20.2412C11.0315 20.3103 12.5127 20.0389 13.8768 19.4477C15.2409 18.8565 16.4517 17.9613 17.4168 16.8304C18.3818 15.6995 19.0755 14.3629 19.4447 12.9228C19.814 11.4827 19.849 9.97719 19.5472 8.52146C19.2454 7.06573 18.6147 5.69829 17.7033 4.5237C16.792 3.3491 15.6241 2.39845 14.289 1.74443C12.9539 1.09042 11.4869 0.750354 10.0002 0.750244Z"
      fill="black"
    />
  </svg>
)

export default ChatIcon
