import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Image, { Shimmer } from "react-shimmer"

const ImageLoadable = ({ width, height, src, hideControls, emptyMessage }) => (
  <Container width={width} height={height} hideControls={hideControls}>
    <Image
      src={src || ""}
      errorFallback={() => (
        <ErrorFallback>
          <span>{emptyMessage}</span>
        </ErrorFallback>
      )}
      fallback={<Shimmer width={width} height={height} />}
    />
  </Container>
)

const ErrorFallback = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.38);
  background: #f2f2f2;
  & > span {
    text-align: center;
  }
`

const Container = styled.div`
  border-radius: ${(props) => (props.hideControls ? "0px" : "22px")};
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  overflow: hidden;
  & > img {
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};
  }
`

ImageLoadable.defaultProps = {
  width: 100,
  height: 100,
  src: undefined,
  hideControls: false,
  emptyMessage: "Cargar Foto",
}

ImageLoadable.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  hideControls: PropTypes.bool,
  emptyMessage: PropTypes.string,
}

export default ImageLoadable
