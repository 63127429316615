import React from "react"
import PropTypes from "prop-types"
import { ExitToApp } from "@styled-icons/material/ExitToApp"
import Tooltip from "../Tooltip"

import { withFirebase } from "../../../utils/Firebase"

const SignOutButton = ({ firebase, tooltip, providerId }) => (
  <button
    type="button"
    onClick={() => firebase.doSignOut(providerId)}
    style={{
      marginBottom: 20,
    }}
  >
    <Tooltip title={tooltip}>
      <ExitToApp size={24} />
    </Tooltip>
  </button>
)

SignOutButton.defaultProps = {
  providerId: "",
}

SignOutButton.propTypes = {
  firebase: PropTypes.shape().isRequired,
  providerId: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
}

export default withFirebase(SignOutButton)
// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial
