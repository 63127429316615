import React, { useState, useEffect, useContext } from "react"
import { compose } from "recompose"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import get from "lodash.get"
import { NotificationsNone } from "@styled-icons/material/NotificationsNone"
import { CalendarToday } from "@styled-icons/material/CalendarToday"
import { People } from "@styled-icons/material/People"
import { ShieldAlt } from "@styled-icons/fa-solid/ShieldAlt"
import { Person } from "@styled-icons/material/Person"

import { withAuthorization, AuthUserContext } from "../../../context/Session"

import Security from "./Sections/Security"
import Notifications from "./Sections/Notifications"
import TimeSettings from "./Sections/TimeSettings"
import Access from "./Sections/Access"
import Profile from "./Sections/Profile"
import { Wrapper, Header, Container, Tabs, Tab } from "./styles"

const Settings = () => {
  const user = useContext(AuthUserContext)
  const { t } = useTranslation()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(
    get(user, ["medicProfile", "userType"]) === "medicalAssistant" ? 4 : 0
  )

  const displayActiveTab = () => {
    const tabs = {
      0: <Notifications user={user} />,
      1: <TimeSettings />,
      2: <Access user={user} />,
      3: <Security user={user} />,
      4: <Profile user={user} />,
    }
    return tabs[activeTab]
  }

  useEffect(() => {
    const currentLocation = window.location
    if (currentLocation.href.includes("/settings?code=")) {
      setActiveTab(1)
    }
  }, [history])

  return (
    <Wrapper>
      <Header>
        <Tabs>
          {get(user, ["medicProfile", "userType"]) === "medicalAssistant" && (
            <Tab onClick={() => setActiveTab(4)} isActive={activeTab === 4}>
              <Person size="24" color="#323232" />
              <span>{t("web_client.settings_navbar_profile")}</span>
            </Tab>
          )}
          <Tab onClick={() => setActiveTab(0)} isActive={activeTab === 0}>
            <NotificationsNone size="24" color="#323232" />
            <span>{t("web_client.settings_navbar_notifications")}</span>
          </Tab>
          {get(user, ["medicProfile", "userType"]) !== "medicalAssistant" && (
            <>
              <Tab onClick={() => setActiveTab(1)} isActive={activeTab === 1}>
                <CalendarToday size="24" color="#323232" />
                <span>{t("web_client.settings_navbar_time")}</span>
              </Tab>
              <Tab onClick={() => setActiveTab(2)} isActive={activeTab === 2}>
                <People size="24" color="#323232" />
                <span>{t("web_client.settings_navbar_access")}</span>
              </Tab>
              <Tab onClick={() => setActiveTab(3)} isActive={activeTab === 3}>
                <ShieldAlt size="24" color="#323232" />
                <span>{t("web_client.settings_navbar_security")}</span>
              </Tab>
            </>
          )}
        </Tabs>
      </Header>
      <Container>{displayActiveTab()}</Container>
    </Wrapper>
  )
}

const condition = (authUser) => !!authUser
const CompSettings = compose(withAuthorization(condition))(Settings)

export default CompSettings
