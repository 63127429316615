import styled from "styled-components"

export const Container = styled.div`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "150px")};
  width: 100%;
  height: ${(props) => (props.heightSize ? props.heightSize : "144px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.paleGrey};
  border-top: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
  padding: 16px 30px;
  & > div {
    width: 100%;
  }
`

export const Tools = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 47px;
  height: 47px;
  width: 100%;
  margin-bottom: 10px;

  & > div {
    cursor: ${(props) => (props.isEnabled ? "pointer" : "not-allowed")};
    height: 44px;
    width: auto;
    padding: 10px;
    border: solid 1px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: ${(props) => props.theme.color.paleGrey};
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    & > a,
    div {
      ${(props) => (props.isEnabled ? "" : "pointer-events:none")};
      cursor: ${(props) => (props.isEnabled ? "pointer" : "not-allowed")};
      color: ${(props) => props.theme.color.primary};
      text-decoration: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  @media (max-width: ${(props) => props.theme.screen.lg}) {
    span {
      margin-left: 5px;
      font-size: 10px;
    }
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    span {
      margin-left: 10px;
      ${(props) => props.theme.font_size.xxxsmall};
    }
  }

  @media (min-width: ${(props) => props.theme.screen.xlg}) {
    span {
      margin-left: 10px;
      ${(props) => props.theme.font_size.xsmall};
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  & > input {
    width: 100%;
    border: none;
    ${(props) => props.theme.font_size.regular};
    outline: none;
    background-color: ${(props) => props.theme.color.paleGrey};
  }
`

export const StyledSpan = styled.span`
  margin-right: ${(props) => (props.right ? props.right : "0")};
  ${(props) => props.theme.font_size.xsmall};
`
