import React from "react"
import { Email } from "@styled-icons/material/Email"
import { Security } from "@styled-icons/material/Security"
import { Youtube } from "@styled-icons/fa-brands/Youtube"
import { Person } from "@styled-icons/material/Person"
import { IdCard } from "@styled-icons/fa-solid/IdCard"
import { LocationOn } from "@styled-icons/material/LocationOn"
import { Phone } from "@styled-icons/material/Phone"
import { LocalHospital } from "@styled-icons/material/LocalHospital"
import { Class } from "@styled-icons/material/Class"
import { MedicalServices } from "@styled-icons/material/MedicalServices"
import { Map } from "@styled-icons/material/Map"
import { School } from "@styled-icons/material/School"
import { University } from "@styled-icons/fa-solid/University"
import { Payment } from "@styled-icons/material/Payment"
import { AccountBalance } from "@styled-icons/material/AccountBalance"
import { Description } from "@styled-icons/material/Description"
import { PersonSearch } from "@styled-icons/material/PersonSearch"
import { Search } from "@styled-icons/material/Search"
import { UserFriends } from "@styled-icons/fa-solid/UserFriends"
import { MoneyCheckAlt } from "@styled-icons/fa-solid/MoneyCheckAlt"
import { Visibility } from "@styled-icons/material/Visibility"
import { VisibilityOff } from "@styled-icons/material/VisibilityOff"
import { AttachMoney } from "@styled-icons/material/AttachMoney"
import { Calculate } from "@styled-icons/material/Calculate"

import {
  InputWrapper,
  CustomInput,
  CustomInputLabel,
  Bar,
  Highlight,
  Error,
  PasswordIcon,
  PrefixText,
  HelpText,
} from "./styles"

const renderIcon = (icon) => {
  const icons = {
    email: <Email size="24" />,
    password: <Security size="24" />,
    youtube: <Youtube size="24" />,
    user: <Person size="24" />,
    id: <IdCard size="24" />,
    location: <LocationOn size="24" />,
    phone: <Phone size="24" />,
    hospital: <LocalHospital size="24" />,
    class: <Class size="24" />,
    medical: <MedicalServices size="24" />,
    school: <School size="24" />,
    university: <University size="24" />,
    payment: <Payment size="24" />,
    bank: <AccountBalance size="24" />,
    account: <MoneyCheckAlt size="24" />,
    subject: <Description size="24" />,
    search: <Search size="24" />,
    reference: <UserFriends size="24" />,
    "search-person": <PersonSearch size="24" />,
    map: <Map size="24" />,
    visibility: <Visibility size="24" />,
    visibilityOff: <VisibilityOff size="24" />,
    money: <AttachMoney size="24" />,
    calculador: <Calculate size="24" />,
  }
  return icons[icon]
}
const Input = ({
  name,
  value,
  onChange,
  onBlur,
  type,
  required,
  labelName,
  id,
  noMargin,
  icon,
  disabled,
  multiple,
  small,
  readonly,
  errors,
  touched,
  showPassword,
  onClickShowPassword,
  prefix,
  showError,
  helpText,
  fullWidth,
  helpTextColor,
  defaultValue,
}) => {
  const handleInputChange = (e) => {
    if (type === "tel" || type === "number") {
      const re = /^[0-9\b]+$/
      if (e.target.value === "" || re.test(e.target.value)) {
        onChange(e)
      }
    } else {
      onChange(e)
    }
  }

  return (
    <InputWrapper noMargin={noMargin} fullWidth={fullWidth}>
      {icon && renderIcon(icon)}
      <CustomInput
        name={name}
        value={value}
        onChange={handleInputChange}
        onBlur={onBlur}
        type={type}
        required={required}
        id={id}
        disabled={disabled}
        multiple={multiple}
        isSmall={small}
        readOnly={readonly}
        errors={(touched && errors) || showError}
        autoComplete="off"
        defaultValue={defaultValue}
        onWheel={(e) => {
          e.target.blur()
          e.stopPropagation()
        }}
      />
      <PasswordIcon onClick={onClickShowPassword}>
        {name === "password" &&
          renderIcon(showPassword ? "visibilityOff" : "visibility")}
      </PasswordIcon>
      <Highlight />
      <Bar />
      <CustomInputLabel readOnly={readonly}>{labelName}</CustomInputLabel>
      {prefix && <PrefixText>{prefix}</PrefixText>}
      {touched && errors && <Error>{errors}</Error>}
      {helpText && (
        <HelpText helpTextColor={helpTextColor}>{helpText}</HelpText>
      )}
    </InputWrapper>
  )
}

export default Input
