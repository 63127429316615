import React, { useState, useEffect, useContext } from "react"
import { Flex, Button, Box } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import Select from "react-select"
import get from "lodash.get"

import { TextArea } from "../../atoms/Forms"

import { withFirebase } from "../../../utils/Firebase"
import { AuthUserContext } from "../../../context/Session"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "#E2E8F0",
    marginTop: "5px",
    marginBottom: "2px",
    marginLeft: 10,
    marginRight: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"
    return { ...provided, opacity, transition }
  },
}

const AddMedicalNotes = ({
  contact,
  firebase,
  isAddNoteVisible,
  contactDependents,
  setIsAddNoteVisible,
  currentNote = "",
}) => {
  const user = useContext(AuthUserContext)
  const [dependents, setDependents] = useState([])
  const [currentDependent, setCurrentDependent] = useState(null)
  const [note, setNote] = useState("")
  const { addToast } = useToasts()
  const { t } = useTranslation()

  useEffect(() => {
    if (contactDependents && contactDependents.length > 0) {
      const contactsList = [
        {
          ...contact,
          dependentName: "Encargado",
          label: `Encargado`,
          value: null,
        },
        ...contactDependents,
      ]
      setDependents(contactsList)
    } else {
      setDependents([])
    }
  }, [contactDependents])

  useEffect(() => {
    if (isAddNoteVisible) {
      setNote("")
      setCurrentDependent(null)
    }
  }, [isAddNoteVisible])

  useEffect(() => {
    const getDependents = async () => {
      try {
        setCurrentDependent(null)
        const dep = await firebase.getDependents(contact.email)
        if (dep) {
          setDependents(
            dep.docs.map((d) => ({
              value: d.id,
              label: `${d.data().dependentName} (${
                d.data().dependentRelationship
              })`,
              ...d.data(),
            }))
          )
        }
      } catch {
        addToast(t("unexpected_error"), {
          appearance: "error",
        })
      }
    }
    if (contact && dependents.length === 0) {
      getDependents()
    }
  }, [contact])

  useEffect(() => {
    setNote(currentNote)
  }, [currentNote])

  const handleAddNote = async () => {
    try {
      const currentTime = Date.now()
      let formattedNote = {
        note,
        contact: contact.id,
        contactEmail: contact.email,
        createdAt: currentTime,
      }
      if (currentDependent) {
        formattedNote = Object.assign(formattedNote, {
          dependentContact: currentDependent.value,
          dependentName: currentDependent.dependentName,
        })
      }
      await firebase.AddMedicalNotes({
        doctor: user.isImpersonating ? user.impersonatingEmail : user.email,
        contact: contact.id,
        medicalNote: formattedNote,
      })
      setIsAddNoteVisible(!isAddNoteVisible)
    } catch {
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  return (
    <Flex
      position="absolute"
      right="0"
      bottom={isAddNoteVisible ? "15px" : "-300px"}
      transition="all 0.5s ease-in-out"
      opacity={isAddNoteVisible ? "1" : "0"}
      border="1px solid #ccc"
      zIndex={isAddNoteVisible ? 1006 : 1002}
      width="calc(100vw - 645px)"
      boxShadow="1px 1px 20px -6px rgba(0, 0, 0, 0.54)"
      height="400px"
      bgColor="white"
    >
      <Flex flexDirection="column" width="100%">
        {dependents.length > 0 && (
          <Flex flexDirection="row" alignItems="center">
            {!currentNote && (
              <>
                <Select
                  styles={customStyles}
                  defaultValue={currentDependent}
                  value={dependents.find(
                    (option) => option.value === get(currentDependent, "value")
                  )}
                  options={dependents}
                  onChange={(option) => {
                    if (option?.value) {
                      setCurrentDependent(option || null)
                    } else {
                      setCurrentDependent(null)
                    }
                  }}
                  placeholder="Seleccione"
                  isClearable
                  width="230px"
                />
                <Box
                  as="span"
                  ml="10px"
                  fontWeight="bold"
                  color={
                    currentDependent && currentDependent.value ? "red" : "black"
                  }
                >
                  {`Dependiente (${
                    dependents.filter((d) => d.value).length
                  }): ${
                    currentDependent
                      ? `Notas para ${currentDependent.dependentName}`
                      : "Notas para Encargado"
                  }`}
                </Box>
              </>
            )}
          </Flex>
        )}
        <TextArea
          key="new-note"
          mt="10px"
          mb="0px"
          height="22vh"
          value={note || ""}
          onChange={(e) => {
            const { value } = e.target
            setNote(value)
          }}
          placeholder={`${t("web_client.add_notes")} ${
            contact?.type === "medical_visitor" ? "visitador" : "paciente"
          }`}
          id="note"
          name="note"
          disabled={currentNote}
        />
      </Flex>
      <Flex
        position="absolute"
        justifyContent="flex-end"
        bottom="5"
        width="100%"
        padding="10px"
      >
        <Button
          width="100px"
          padding="10px, 16px"
          border="1px solid rgba(226, 232, 240, 1)"
          onClick={() => setIsAddNoteVisible(!isAddNoteVisible)}
          marginRight={5}
        >
          {t("cancel")}
        </Button>
        {!currentNote && (
          <Button
            width="100px"
            padding="10px, 16px"
            border="1px solid rgba(226, 232, 240, 1)"
            onClick={handleAddNote}
            disabled={currentNote}
          >
            {currentNote ? t("edit") : t("save")}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default withFirebase(AddMedicalNotes)
