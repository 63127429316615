import { Computer } from "@styled-icons/material/Computer"
import { ChatBubble } from "@styled-icons/material/ChatBubble"
import { Event } from "@styled-icons/material/Event"
import { Help } from "@styled-icons/material/Help"
import { Payment } from "@styled-icons/material/Payment"
import { Settings } from "@styled-icons/material/Settings"
import { Users } from "@styled-icons/fa-solid/Users"
import { PatchQuestionFill } from "@styled-icons/bootstrap/PatchQuestionFill"
import { SupervisedUserCircle } from "@styled-icons/material/SupervisedUserCircle"

const SidebarItems = [
  {
    name: "Dashboard",
    route: "/dashboard",
    icon: SupervisedUserCircle,
    key: "dashboard",
    lang: "web_client.doctors",
    onlyAssistant: true,
  },
  {
    name: "Sessions",
    route: "/sessions",
    icon: Computer,
    key: "sessions",
    lang: "web_client.menu_next",
    allowImpersonated: true,
  },
  {
    name: "History",
    route: "/history",
    icon: ChatBubble,
    key: "history",
    lang: "web_client.menu_hist",
    allowImpersonated: true,
  },
  {
    name: "Questions",
    route: "/questions",
    icon: PatchQuestionFill,
    key: "questions",
    lang: "web_client.menu_questions",
    allowImpersonated: true,
  },
  {
    name: "Agenda",
    route: "/agenda",
    icon: Event,
    key: "agenda",
    lang: "web_client.menu_agenda",
    allowImpersonated: true,
  },
  {
    name: "Payments",
    route: "/payment",
    icon: Payment,
    key: "payment",
    protected: true,
    lang: "web_client.menu_payment",
  },
  {
    name: "Contacts",
    route: "/contacts",
    icon: Users,
    key: "contacts",
    lang: "web_client.menu_contacts",
    allowImpersonated: true,
  },
  {
    name: "Settings",
    route: "/settings",
    alternateRoue: "/account",
    icon: Settings,
    key: "settings",
    lang: "web_client.menu_settings",
  },
  {
    name: "Help",
    route: "/help",
    isFooter: true,
    icon: Help,
    key: "help",
    lang: "web_client.menu_help",
  },
]

export default SidebarItems
