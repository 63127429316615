import styled, { css } from "styled-components"
import { GridContainer } from "../../atoms/Global"
import { HeaderTools } from "../../atoms/Forms"

// eslint-disable-next-line import/prefer-default-export
export const Container = styled(GridContainer)`
  padding: 0;
  background-color: ${(props) => props.theme.color.background.gray30};
`
export const CommonContainer = css`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  padding-left: 18px;
`
export const PaymentsContainer = styled.div`
  ${CommonContainer};
  height: 300px;
`

export const RecipesContainer = styled.div`
  ${CommonContainer};
  height: auto;
  padding: 18px;
`

export const Recipe = styled.div`
  width: 100%;
  height: 160px;
  background: ${(props) => props.theme.color.cream};
  padding: 14px;
  cursor: pointer;
  & > div {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & > span {
        margin-left: 10px;
      }
    }
  }
`

export const HistoryContainer = styled.div`
  padding-top: 49px;
  padding-left: 60px;
  height: 100%;
  width: 100%;
`

export const HeaderOrderTools = styled(HeaderTools)`
  & > span {
    margin: 0 8px;
  }
`
