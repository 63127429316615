/* eslint-disable react/no-danger */
import "moment/locale/es"
import React, { useEffect } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"

import { Flex, Button, Divider, Text } from "@chakra-ui/react"

// Styles
import { CustomText, DataRow } from "../../styles"

const AllMedicalNotes = ({
  isShowAllNotes,
  medicalNotes,
  setShowMedicalNotes,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    moment.locale(language)
  }, [language])

  const formatTextSpace = (text) => text.replace(/\n/g, "<br />")

  const createMarkup = (paragraph) => ({
    __html: paragraph,
  })

  const sortNotes = medicalNotes.sort((a, b) => b.createdAt - a.createdAt)
  return (
    <Flex
      position="fixed"
      right="10px"
      bottom={isShowAllNotes ? "15px" : "-300px"}
      transition="all 0.5s ease-in-out"
      opacity={isShowAllNotes ? "1" : "0"}
      border="1px solid #ccc"
      borderRadius="10px"
      width="calc(100vw - 645px)"
      boxShadow="1px 1px 20px -6px rgba(0, 0, 0, 0.54)"
      height="90.6vh"
      bgColor="white"
      zIndex="10000"
      overflow="auto"
    >
      <Flex flexDirection="column" width="100%">
        <Flex flexDirection="row" justifyContent="flex-end" alignItems="center">
          <Button
            width="146px"
            height="36px"
            mt="30px"
            mb="30px"
            mr="20px"
            padding="10px 16px"
            border="1px solid rgba(226, 232, 240, 1)"
            _focus={{ outline: "none" }}
            _hover={{
              background: "#4d66f5",
              color: "#FFF",
              opacity: 1,
            }}
            onClick={() => setShowMedicalNotes(false)}
          >
            {t("close")}
          </Button>
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          height="40px"
          mb="40px"
        >
          <Text
            fontSize="32px"
            fontWeight="600"
            lineHeight="20px"
            color="#1A202C"
            mt="5px"
            textDecoration="underline"
          >
            {t("web_client.notes_title")}
          </Text>
        </Flex>
        {sortNotes.map((note) => (
          <Flex key={note?.id} flexDirection="column">
            <DataRow mb="15px">
              <CustomText color="#00CCCC">
                {moment(note?.createdAt)
                  .format("MMM DD, YYYY - HH:mm:ss")
                  .toUpperCase()}
              </CustomText>
            </DataRow>
            <DataRow mb="15px">
              <CustomText flex={2}>
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    formatTextSpace(note?.note)
                  )}
                />
              </CustomText>
            </DataRow>
            <Divider
              orientation="horizontal"
              borderColor="#E2E8F0"
              mt="12px"
              mb="12px"
            />
          </Flex>
        ))}
        <Flex flexDirection="row" justifyContent="flex-end" alignItems="center">
          <Button
            width="146px"
            height="36px"
            mt="30px"
            mb="30px"
            mr="20px"
            padding="10px 16px"
            border="1px solid rgba(226, 232, 240, 1)"
            _focus={{ outline: "none" }}
            _hover={{
              background: "#4d66f5",
              color: "#FFF",
              opacity: 1,
            }}
            onClick={() => setShowMedicalNotes(false)}
          >
            {t("close")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AllMedicalNotes
