import styled from "styled-components"
import { GridContainer } from "../../components/atoms/Global"
import ButtonWithLoader from "../../components/atoms/ButtonWithLoader"

export const Container = styled(GridContainer)`
  padding: 0;
  background-color: ${(props) => props.theme.color.background.lightGray};
`

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - 90px);
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding-left: 18px;
  padding-right: 18px;

  & > p {
    text-align: center;
    ${(props) => props.theme.font_size.large};
    line-height: normal;
    width: 374px;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  max-width: 300px;
  position: absolute;
  bottom: -4px;
  right: 0;
  opacity: 0.8;
`

export const ChatWrapper = styled.div`
  padding-top: ${(props) => (props.isChat ? "0" : "49px")};
  padding-left: ${(props) => (props.isChat ? "0" : "60px")};
  height: 100%;
  width: 100%;
`

export const Waiting = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > p {
    width: 100%;
    text-align: center;
    ${(props) => props.theme.font_size.regular};
    color: ${(props) => props.theme.color.primary};
    z-index: 2;
  }
`

export const ImportantWrapper = styled.div`
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  background-color: rgba(220, 220, 220, 0.5);
  padding: 10px;
  > div {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`

export const ImportantText = styled.span`
  color: red;
  font-style: italic;
  padding: 0 30px;
  width: 75%;
`

export const ModalWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalOptions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 500px;
  height: 450px;
  background-color: white;
  border-radius: 10px;
  & > section {
    width: 100%;
    padding: 25px;
    p {
      text-align: center;
      ${(props) => props.theme.font_size.small};
    }
    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 200px;
  height: 60px;
  margin: 5px 0;
  margin-right: ${(props) => (props.noRightMargin ? "0" : "10px")};
`
