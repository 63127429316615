import React, { useState, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { compose } from "recompose"
import { Formik, Form } from "formik"
import { useToasts } from "react-toast-notifications"
import { useHistory, useLocation, withRouter } from "react-router-dom"
import * as Yup from "yup"
import moment from "moment"
import PropTypes from "prop-types"
import get from "lodash.get"

// Components
import { Visibility } from "@styled-icons/material/Visibility"
import { VisibilityOff } from "@styled-icons/material/VisibilityOff"
import {
  Stack,
  VStack,
  Box,
  Image,
  Flex,
  InputGroup,
  InputRightElement,
  Button,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import CustomInput from "../../components/chakra/CustomInput"
import HelpButton from "../../components/chakra/HelpButton"
import LanguageSelector from "../../components/atoms/LanguageSelector"

// Sources
import Background from "../../static/images/lines_background.svg"
import LogoV2 from "../../static/images/logoV2.svg"
import Brand from "../../static/images/brand.svg"

// Utils
import packageJson from "../../../package.json"
import * as ROUTES from "../../utils/Constants/Routes"
import useDeviceDetect from "../../utils/useDeviceDetect"

import { WithChakra } from "../../components/atoms/WithChakra"
import { withFirebase } from "../../utils/Firebase"
import { honeybadger } from "../../utils/App"

import { AuthUserContext } from "../../context/Session"

const LoginBase = ({ firebase }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const user = useContext(AuthUserContext)
  const { state } = useLocation()
  const { isMobile } = useDeviceDetect()
  const [loginCompleted, setLoginCompleted] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (isMobile) {
      history.push("/setup-account/userType")
    }
  }, [isMobile])

  useEffect(() => {
    if (get(state, ["fromWebsite"], false) && user) {
      history.push("/sessions")
    }
  }, [get(state, ["fromWebsite"])])

  useEffect(() => {
    const getUserInfo = () => {
      try {
        const profile = get(user, "medicProfile")
        setLoginCompleted(false)
        setSubmitting(false)
        if (profile.userType === "provider") {
          if (profile?.status === "disabled_by_provider") {
            onOpen()
          } else {
            history.push(ROUTES.HOME)
          }
        } else if (profile.userType === "medicalAssistant") {
          history.push(ROUTES.DASHBOARD)
        } else {
          setLoginCompleted(false)
          firebase.logout()
        }
      } catch (e) {
        honeybadger.notify("Login - Get User Info", {
          message: "Error on login / get User Info Func",
          action: "submit",
          context: {
            error: e.message,
          },
        })
        setLoginCompleted(false)
        firebase.logout()
      }
    }
    if (loginCompleted && user) {
      getUserInfo()
    }
  }, [loginCompleted, user])

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const goToForgotPassword = () => {
    history.push("/forgot-password")
  }

  const goToCreateAccount = (email) => {
    history.push("/setup-account/userType", { userEmail: email })
  }

  const goToHelp = () => {
    history.push("/help", { isFromLogin: true })
  }

  const activateMyAccount = async () => {
    try {
      await firebase.savePersonalData({
        email: user?.email,
        data: {
          status: "active",
          deactivation_day: null,
          deactivation_day_period_end: null,
          active: true,
          online: true,
        },
      })
      user.setUser({
        ...user,
        medicProfile: {
          ...user?.medicProfile,
          online: true,
          active: true,
          deactivation_day_period_end: null,
          deactivation_day: null,
          status: "active",
        },
      })
      onClose()
      history.push(ROUTES.HOME)
    } catch (error) {
      window.Honeybadger?.notify(error)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      justify="center"
      width="full"
      height="100vh"
      backgroundSize="cover"
      background={`url(${Background}) no-repeat center / cover, linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)`}
    >
      <Box
        boxSize="sm"
        position="absolute"
        right="20px"
        top="12px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="200px"
        height="50px"
      >
        <HelpButton onClick={() => goToHelp()} />
        <LanguageSelector isFromLogin />
      </Box>
      <Box boxSize="sm" position="absolute" left="20px" top="12px">
        <Image src={LogoV2} alt="logoV2" />
      </Box>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("error_auth_invalid_email"))
            .required(t("error_all_fields_required")),
          password: Yup.string().required(t("error_all_fields_required")),
        })}
        onSubmit={(values) => {
          setSubmitting(true)
          firebase
            .doSignInWithEmailAndPassword(values.email, values.password)
            .then(() => {
              setLoginCompleted(true)
            })
            .catch((error) => {
              if (
                error.message.includes(
                  "There is no user record corresponding to this identifier. The user may have been deleted"
                )
              ) {
                addToast(t("unexpected_error_no_account"), {
                  appearance: "error",
                })
              } else if (
                error.message.includes(
                  "The password is invalid or the user does not have a password."
                )
              ) {
                addToast(t("wrong_password_error"), {
                  appearance: "error",
                })
              } else {
                addToast(t("unexpected_error"), { appearance: "error" })
              }
              setSubmitting(false)
            })
        }}
      >
        {(props) => (
          <Form>
            <VStack
              background="white"
              borderRadius="8px"
              width="468px"
              height="510px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
              padding="60px 0 30px"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Image src={Brand} alt="DOC" />
                <span
                  style={{
                    position: "relative",
                    top: 15,
                    left: 5,
                    fontSize: "0.5rem",
                  }}
                >
                  {packageJson.version}
                </span>
              </Box>
              <Stack spacing={6}>
                <FormControl>
                  <CustomInput
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="on"
                    placeholder={t("email_address")}
                    showErrorMessage={
                      props.errors.email && props.submitCount > 0
                    }
                    errorMessage={props.errors.email}
                    handleInputChange={(e) =>
                      props.setFieldValue("email", e.target.value)
                    }
                  />
                </FormControl>
                <FormControl>
                  <InputGroup size="md">
                    <CustomInput
                      id="password"
                      name="password"
                      autoComplete="on"
                      type={showPassword ? "text" : "password"}
                      placeholder={t("password")}
                      showErrorMessage={
                        props.errors.password && props.submitCount > 0
                      }
                      errorMessage={props.errors.password}
                      handleInputChange={(e) =>
                        props.setFieldValue("password", e.target.value)
                      }
                    />

                    <InputRightElement>
                      <Button size="sm" onClick={toggleShowPassword}>
                        {showPassword ? (
                          <Visibility size="24" />
                        ) : (
                          <VisibilityOff size="24" />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Stack>
              <Button
                colorScheme="blue"
                width="348px"
                height="48px"
                background="#071435"
                borderRadius="6px"
                color="#FFF"
                fontSize="18px"
                fontWeight="normal"
                type="submit"
                isLoading={isSubmitting}
              >
                {t("log_in")}
              </Button>
              <Button
                width="348px"
                height="48px"
                color="#000"
                fontSize="18px"
                fontWeight="bold"
                border="none"
                variant="link"
                onClick={goToForgotPassword}
              >
                {t("recover_password")}
              </Button>
              <Box width="100%" height="1px" backgroundColor="#E2E8F0" />
              <Button
                colorScheme="blue"
                width="348px"
                height="48px"
                border="1px solid #E2E8F0"
                borderRadius="6px"
                color="#000"
                fontSize="18px"
                fontWeight="bold"
                onClick={() => goToCreateAccount(props.values.email)}
                variant="ghost"
              >
                {t("create_register")}
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size="xl"
        onOverlayClick={() => firebase.logout()}
      >
        <ModalOverlay />
        <ModalContent
          minW={isMobile ? "auto" : "690px"}
          minH={isMobile ? "auto" : "400px"}
          padding="20px"
          backgroundColor="white"
        >
          <ModalHeader backgroundColor="white">
            {`${t("hello")}, ${user?.medicProfile?.title} ${
              user?.medicProfile?.name
            } ${user?.medicProfile?.surname1}`}
          </ModalHeader>
          <ModalCloseButton
            _focus={{ outline: "none" }}
            onClick={() => firebase.logout()}
          />
          <ModalBody backgroundColor="white">
            <Text lineHeight="20px" fontSize="16px">
              {t("web_client.login_modal")}
            </Text>
            <Text
              color="black"
              mt="30px"
              mb="30px"
              lineHeight="25px"
              fontSize="16px"
            >
              {`${t("web_client.login_modal1")} ${moment()
                .add(30, "days")
                .format("DD/MM/YYYY")}.`}
            </Text>
            <Text mt="30px" color="black" fontSize="16px">
              {t("web_client.login_modal2")}
            </Text>
            <Text color="red" mt="30px" decoration="underline" fontSize="12px">
              {t("web_client.login_modal3")}
            </Text>
          </ModalBody>
          <ModalFooter backgroundColor="white">
            <Button
              onClick={() => activateMyAccount()}
              backgroundColor="#38A169"
              color="#FFFFFF"
              borderRadius="5px"
              opacity={0.8}
              mr={3}
              _focus={{ outline: "none" }}
              _hover={{
                background: "#38A169",
                opacity: 1,
              }}
            >
              {t("web_client.recover_account")}
            </Button>
            <Button
              backgroundColor="#071435"
              onClick={() => {
                onClose()
                history.push(ROUTES.HOME)
              }}
              color="#FFFFFF"
              _focus={{ outline: "none" }}
              opacity={0.8}
              _hover={{
                backgroundColor: "#071435",
                opacity: 1,
              }}
            >
              {t("web_client.continue_without_account")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

LoginBase.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const Login = compose(withRouter, withFirebase, WithChakra)(LoginBase)
export default Login
