/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/prefer-default-export */
import * as React from "react"
import { ColorModeScript, ChakraProvider } from "@chakra-ui/react"
import theme from "./theme"

/* To migrate a page to Chakra please wrap the page component with this WithChakra HOC */

export function WithChakra(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <ChakraProvider theme={theme}>
          <ColorModeScript
            useSystemColorMode={false}
            initialColorMode={theme.config.initialColorMode}
          />
          <WrappedComponent {...this.props} />
        </ChakraProvider>
      )
    }
  }
}
