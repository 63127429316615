import styled, { keyframes } from "styled-components"

const inputHighlighter = (background) => keyframes`
  from {
    background: ${background};
  }
  to {
    width: 0;
    background: transparent;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.noMargin ? 0 : "25px")};
  > svg {
    position: absolute;
    top: 18px;
    left: 18px;
  }
`

export const CustomInputLabel = styled.label`
  color: ${(props) => (props.readOnly ? props.theme.color.darkPurple : "#999")};
  font-size: ${(props) => (props.readOnly ? "0.75rem !important" : "1rem")};
  line-height: ${(props) => (props.readOnly ? "1.33 !important" : "1.5")};
  letter-spacing: 0.15px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 50px;
  top: ${(props) => (props.readOnly ? "4px !important" : "18px")};
  transition: 0.2s ease all;
`

export const Bar = styled.span`
  position: relative;
  display: block;
  width: 100%;
  &:after,
  &:before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: ${(props) => props.theme.color.purple};
    transition: 0.2s ease all;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
`
export const Highlight = styled.span`
  position: absolute;
  height: 80%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
`

export const CustomInput = styled.input`
  ${(props) => props.theme.font_size.xsmall};
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.color.background.black80};
  height: 56px;
  display: block;
  width: ${(props) => (props.isSmall ? "calc(100% - 50px);" : "100%")};
  border: none;
  background: ${(props) =>
    props.disabled
      ? props.theme.color.white.normal
      : props.theme.color.background.gray30};
  border-radius: 4px;
  padding-left: ${(props) => (props.disabled ? "25px" : "50px")};
  padding-top: 8px;
  &:focus {
    outline: none;
    input:focus ~ ${Bar}:before, input:focus ~ ${Bar}:after {
      width: 50%;
    }

    & ~ ${CustomInputLabel} {
      top: 4px;
      ${(props) => props.theme.font_size.xxxsmall};
      line-height: 1.33;
      color: ${(props) => props.theme.color.darkPurple};
    }
    & ~ ${Bar} {
      &:before,
      &:after {
        width: 50%;
      }
    }
    & ~ ${Highlight} {
      animation: ${(props) => inputHighlighter(props.theme.color.secondary)}
        0.3s ease;
    }
  }
  &:valid {
    & ~ ${CustomInputLabel} {
      top: 4px;
      ${(props) => props.theme.font_size.xxxsmall};
      line-height: 1.33;
      color: ${(props) => props.theme.color.darkPurple};
    }
  }
`

export const Error = styled.span`
  ${(props) => props.theme.font_size.xxxsmall};
  color: ${(props) => props.theme.color.darkPurple};
`
