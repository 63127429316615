/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from "react"

const Participant = ({ participant }) => {
  const [videoTracks, setVideoTracks] = useState([])
  const [audioTracks, setAudioTracks] = useState([])

  const videoRef = useRef()
  const audioRef = useRef()

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null)

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks))
    setAudioTracks(trackpubsToTracks(participant.audioTracks))

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((currentVideoTracks) => [...currentVideoTracks, track])
      } else if (track.kind === "audio") {
        setAudioTracks((currentAudioTracks) => [...currentAudioTracks, track])
      }
    }

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((currentVideoTracks) =>
          currentVideoTracks.filter((v) => v !== track)
        )
      } else if (track.kind === "audio") {
        setAudioTracks((currentAudioTracks) =>
          currentAudioTracks.filter((a) => a !== track)
        )
      }
    }

    participant.on("trackSubscribed", trackSubscribed)
    participant.on("trackUnsubscribed", trackUnsubscribed)

    return () => {
      setVideoTracks([])
      setAudioTracks([])
      participant.removeAllListeners()
    }
  }, [participant])

  useEffect(() => {
    const videoTrack = videoTracks[0]
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
      return () => {
        videoTrack.detach()
      }
    }
  }, [videoTracks])

  useEffect(() => {
    const audioTrack = audioTracks[0]
    if (audioTrack) {
      audioTrack.attach(audioRef.current)
      return () => {
        audioTrack.detach()
      }
    }
  }, [audioTracks])

  return (
    <div className="participant">
      <video ref={videoRef} autoPlay />
      <audio ref={audioRef} autoPlay />
    </div>
  )
}

export default Participant
