import React from "react"

const ClockIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.25 28C22.8774 28 28.25 22.6274 28.25 16C28.25 9.37258 22.8774 4 16.25 4C9.62258 4 4.25 9.37258 4.25 16C4.25 22.6274 9.62258 28 16.25 28Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 9V16H23.25"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ClockIcon
