import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const MessageTip = ({ currentMessage, isLoadMore }) => (
  <Text isLoadMore={isLoadMore}>{currentMessage.tip}</Text>
)

const Text = styled.span`
  ${(props) => props.theme.font_size.xsmall};
  border: ${(props) => (props.isLoadMore ? "1px solid #cfcfcf" : "none")};
  background-color: ${(props) =>
    props.isLoadMore ? props.theme.color.background.gray30 : "transparent"};
  border-radius: ${(props) => (props.isLoadMore ? "8px" : "0")};
  cursor: ${(props) => (props.isLoadMore ? "pointer" : "default")};
  padding: ${(props) => (props.isLoadMore ? "5px 14px !important" : "0 14px")};
  transition: all 200ms ease-in-out;
  min-width: 170px;
  text-align: right;
  &:hover {
    transform: ${(props) => (props.isLoadMore ? "scale(1.05)" : "scale(1)")};
  }
`

MessageTip.defaultProps = {
  isLoadMore: false,
}

MessageTip.propTypes = {
  currentMessage: PropTypes.shape({
    tip: PropTypes.string,
  }).isRequired,
  isLoadMore: PropTypes.bool,
}

export default MessageTip
