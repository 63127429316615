import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import get from "lodash.get"
import { compose } from "recompose"
import { useTranslation } from "react-i18next"
import { withAuthorization, AuthUserContext } from "../../../context/Session"
import { withFirebase } from "../../../utils/Firebase"
import SectionTitle from "../../atoms/SectionTitle"
import { SectionsContainer } from "../../atoms/Global"
import ProviderCard from "../../atoms/ProviderCard"
import { Container, CardsContainer } from "./styles"

const Dashboard = ({ firebase }) => {
  const [providers, setProviders] = useState([])
  const authUser = useContext(AuthUserContext)
  const { t } = useTranslation()

  useEffect(() => {
    const getProviders = async () => {
      const providersData = await firebase.getAssistantsProviders(
        authUser.email
      )
      setProviders(providersData.docs.map((d) => ({ id: d.id, ...d.data() })))
    }
    if (firebase && get(authUser, "email")) {
      getProviders()
    }
  }, [firebase, authUser])

  return (
    <SectionsContainer>
      <Container>
        <SectionTitle withPadding>{t("web_client.doctors")}</SectionTitle>
        <CardsContainer>
          {providers.map((provider) => (
            <ProviderCard provider={provider} key={get(provider, "id")} />
          ))}
        </CardsContainer>
      </Container>
    </SectionsContainer>
  )
}

Dashboard.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const AuthDashboard = compose(
  withFirebase,
  withAuthorization(condition)
)(Dashboard)

export default AuthDashboard
