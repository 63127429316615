import React from "react"
import { ThemeProvider } from "styled-components"
import { compose } from "recompose"
import { ToastProvider } from "react-toast-notifications"
import Routes from "./components/molecules/Routes"
import { withTrans } from "./i18n/withTrans"
import { withAuthentication } from "./context/Session"
import HoneyBadgerErrorBoundary from "./utils/Honeybadger"
import { chatReducer, initialChatState } from "./context/Chat/reducer"
import ChatContext from "./context/Chat"
import "./static/fonts/fonts.css"
import GlobalStyle from "./styles/GlobalStyles"
import theme from "./styles/theme"
import "./App.css"

const App = () => {
  const [state, dispatch] = React.useReducer(chatReducer, initialChatState)
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <div className="App">
          <ChatContext.Provider value={{ state, dispatch }}>
            <ToastProvider autoDismiss>
              <HoneyBadgerErrorBoundary>
                <Routes />
              </HoneyBadgerErrorBoundary>
            </ToastProvider>
          </ChatContext.Provider>
        </div>
      </>
    </ThemeProvider>
  )
}

const RootApp = compose(withTrans, withAuthentication)(App)
export default RootApp
