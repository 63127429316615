import styled from "styled-components"
import { GridContainer } from "../../atoms/Global"

export const Container = styled(GridContainer)`
  padding: 15px;
`
export const ModalWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalOptions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  & > section {
    width: 100%;
    padding: 25px;
    p {
      text-align: center;
      ${(props) => props.theme.font_size.small};
    }
    & > div {
      width: 100%100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        background-color: ${(props) => props.theme.color.paleGrey};
        border: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
        border-radius: 5px;
        width: 125px;
        height: 45px;
        margin: 0 8px;
        &:hover {
          color: ${(props) => props.theme.color.white.regular};
          background-color: ${(props) => props.theme.color.black.light};
        }
      }
    }
  }
`

export const ButtonTab = styled.button`
  background: ${(props) =>
    props.isActive ? props.theme.color.gray["200"] : "transparent"};
  border-radius: 100px;
  padding: 8px 12px;
  height: 40px;
  margin-right: 10px;
  min-width: ${(props) => (props.withMinWidth ? "150px" : "unset")};
  color: ${(props) =>
    props.isActive
      ? props.theme.color.black.normal
      : props.theme.color.black.medium};
`

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
`

export const CronofyOverlay = styled.div`
  transition: opacity 600ms ease-in-out;
  width: 100%;
  height: 100%;
  background: white;
  opacity: ${(props) => (props.isEdit ? 0 : 0.3)};
  z-index: ${(props) => (props.isEdit ? -1000 : 999)};
  position: absolute;
  cursor: ${(props) => (props.isEdit ? "default" : "not-allowed")};
  top: 0;
  left: 0;
`

export const CronofyElementsContainer = styled.div`
  margin-top: 10px;
  .DocRules__calendars {
    display: none;
  }
  .DocRules__grid-border {
    border: none;
  }
  .DocRules__slots-background-column {
    border: 0.5px dashed rgba(0, 0, 0, 0.12);
  }
  .DocRules__label--time {
    text-align: center;
    font-size: 1rem;
  }
  .DocRules__label-row {
    margin-top: 30px;
  }

  .DocRules__label--day {
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    height: 30px;
  }

  .DocRules__submit {
    display: ${(props) => (props.isEdit ? "flex" : "none")};
    position: absolute;
    top: -15px;
    right: 0;
    background: transparent;
    color: black;
    z-index: 0;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.375rem;
    letter-spacing: 0.46px;
    text-transform: uppercase;

    &:before {
      content: "";
      display: inline-block;
      transform: rotate(45deg);
      height: 16px;
      width: 8px;
      border-bottom: 2px solid #78b13f;
      border-right: 2px solid #78b13f;
      margin-right: 15px;
    }
    &:hover {
      font-weight: bold;
    }
  }
`

export const ToolboxContainer = styled.div`
  width: 100%;
  padding: 8px 6px;
`

export const ToolWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Tool = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;
  flex: 1;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  align-self: stretch;
  flex-grow: 1;
  margin: 12px 6px;
`

export const ItemsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: ${(props) => (props.isFirstRow ? "3" : "2")};
  & > p {
    margin: 0;
    padding-left: 10px;
    ${(props) => props.theme.font_size.xxsmall};
    color: ${(props) => props.theme.color.black.normal};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: 400;
    opacity: 0.6;
  }
`

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const Action = styled.div`
  display: inherit;
  margin: 0px 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  &:hover {
    font-weight: bold;
  }
  & svg {
    margin-right: 10px;
  }
`
