import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import get from "lodash.get"
import { useToasts } from "react-toast-notifications"

import Switch from "../../../../atoms/Switch"
import Tooltip from "../../../../atoms/Tooltip"

import { withFirebase } from "../../../../../utils/Firebase"

import NotificationImg from "../../../../../static/images/settings/notificaciones.svg"
import PeopleInvitation from "../../../../../static/images/settings/peopleInvitation.svg"
import EnvelopeIcon from "../../../../../static/images/settings/envelope.svg"

import {
  Container,
  RowContainer,
  ItemsRow,
  ItemsColumn,
  Table,
  TableContainer,
  ItemsRowTable,
  Option,
  TableItem,
  SaveChangesButton,
} from "./styles"

const Notifications = ({ firebase, user }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [settings, setSettings] = useState({
    showNotifications: false,
    onBookedEmail: false,
    onBookedBrowser: false,
    onCancelDateEmail: false,
    onCancelDateBrowser: false,
    onNoShowEmail: false,
    onNoShowBrowser: false,
    sendEmailsToAssitants: false,
  })
  const [assistants, setAssistants] = useState([])

  const getAssistants = async () => {
    const Assistants = await firebase.getAssistants({
      email: user.email,
    })
    if (Assistants && Assistants.docs) {
      const data = Assistants.docs.map((d) => d.data())
      setAssistants(data)
    }
  }

  useEffect(() => {
    if (firebase) {
      getAssistants()
    }
  }, [firebase])

  const setProviderSettings = async (data, id) => {
    const disableBrowerNotifications = {
      onBookedBrowser: data.showNotifications,
      onCancelDateBrowser: data.showNotifications,
      onNoShowBrowser: data.showNotifications,
    }

    if (data.showNotifications) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission()
      }
    }

    const getValues =
      data.showNotifications ||
      id === "onAcceptedInvitations" ||
      id === "sendEmailsToAssitants"
        ? { ...data }
        : { ...data, ...disableBrowerNotifications }

    setSettings((settingsState) => ({
      ...settingsState,
      ...getValues,
    }))
  }

  async function handleInputChange(e) {
    e.persist()
    const disableBrowerNotifications = {
      onBookedBrowser: get(settings, "showNotifications", false),
      onCancelDateBrowser: get(settings, "showNotifications", false),
      onNoShowBrowser: get(settings, "showNotifications", false),
    }
    const getValues = get(settings, "showNotifications", false)
      ? { [e.target.name]: !settings[e.target.name] || !![e.target.name] }
      : {
          [e.target.name]: !settings[e.target.name],
          ...disableBrowerNotifications,
        }

    setSettings((settingsState) => ({
      ...settingsState,
      ...getValues,
    }))
  }

  useEffect(() => {
    const getSettings = async () => {
      const Settings = await firebase.getSettings({
        email: user.email,
        key: "notifications",
      })
      if (Settings.data()) {
        setSettings(Settings.data())
      }
    }
    if (firebase) {
      getSettings()
    }
  }, [firebase])

  const handleSaveChanges = async () => {
    try {
      await firebase.saveProviderSettings(settings, "notifications", user.email)
      addToast(t("web_client.saved_successfully"), { appearance: "success" })
    } catch (error) {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  return (
    <Container>
      <RowContainer>
        <ItemsRow style={{ justifyContent: "flex-start" }}>
          <img src={NotificationImg} alt="placeHolderImg" />
          <ItemsColumn>
            <h5>{t("web_client.settings_nav_notification")}</h5>
            <p>{t("web_client.settings_show_notification")}</p>
          </ItemsColumn>
        </ItemsRow>
        <Switch
          value={get(settings, "showNotifications", false)}
          checked={get(settings, "showNotifications", false)}
          id="showNotifications"
          onChange={(event) =>
            setProviderSettings({
              showNotifications: event.target.checked,
            })
          }
        />
      </RowContainer>
      <Table>
        <TableContainer>
          <ItemsRowTable style={{ border: "none" }}>
            <TableItem style={{ alignItems: "flex-start", width: "40%" }}>
              <h5>{t("web_client.settings_table_notify_about")}</h5>
            </TableItem>
            <TableItem>
              <h5>{t("web_client.settings_table_email")}</h5>
            </TableItem>
            <TableItem>
              <h5>{t("web_client.settings_table_browser")}</h5>
            </TableItem>
          </ItemsRowTable>
          <ItemsRowTable>
            <TableItem style={{ alignItems: "flex-start", width: "40%" }}>
              <p>{t("web_client.settings_table_reservation_date")}</p>
            </TableItem>
            <TableItem>
              <Option>
                <input
                  type="checkbox"
                  name="onBookedEmail"
                  checked={get(settings, "onBookedEmail", false)}
                  onChange={handleInputChange}
                />
              </Option>
            </TableItem>
            <TableItem>
              <Option>
                <input
                  type="checkbox"
                  name="onBookedBrowser"
                  checked={get(settings, "onBookedBrowser", false)}
                  onChange={handleInputChange}
                />
              </Option>
            </TableItem>
          </ItemsRowTable>
          <ItemsRowTable>
            <TableItem style={{ alignItems: "flex-start", width: "40%" }}>
              <p>{t("web_client.settings_table_cancel_date")}</p>
            </TableItem>
            <TableItem>
              <Option>
                <input
                  type="checkbox"
                  name="onCancelDateEmail"
                  checked={get(settings, "onCancelDateEmail", false)}
                  onChange={handleInputChange}
                />
              </Option>
            </TableItem>
            <TableItem>
              <Option>
                <input
                  type="checkbox"
                  name="onCancelDateBrowser"
                  checked={get(settings, "onCancelDateBrowser", false)}
                  onChange={handleInputChange}
                />
              </Option>
            </TableItem>
          </ItemsRowTable>
          <ItemsRowTable style={{ border: "none" }}>
            <TableItem style={{ alignItems: "flex-start", width: "40%" }}>
              <p>{t("web_client.settings_table_pacient_noShow")}</p>
            </TableItem>
            <TableItem>
              <Option>
                <input
                  type="checkbox"
                  name="onNoShowEmail"
                  checked={get(settings, "onNoShowEmail", false)}
                  onChange={handleInputChange}
                />
              </Option>
            </TableItem>
            <TableItem>
              <Option>
                <input
                  type="checkbox"
                  name="onNoShowBrowser"
                  checked={get(settings, "onNoShowBrowser", false)}
                  onChange={handleInputChange}
                />
              </Option>
            </TableItem>
          </ItemsRowTable>
        </TableContainer>
      </Table>
      <RowContainer style={{ marginTop: 40 }}>
        <ItemsRow style={{ justifyContent: "flex-start" }}>
          <img src={PeopleInvitation} alt="peopleImg" />
          <ItemsColumn>
            <h5>{t("web_client.settings_invitations_accepted_text")}</h5>
            <p>{t("web_client.settings_invitations_accepted")}</p>
          </ItemsColumn>
        </ItemsRow>
        <Switch
          value={get(settings, "onAcceptedInvitations", false)}
          checked={get(settings, "onAcceptedInvitations", false)}
          id="onAcceptedInvitations"
          onChange={(event) =>
            setProviderSettings(
              {
                onAcceptedInvitations: event.target.checked,
              },
              "onAcceptedInvitations"
            )
          }
        />
      </RowContainer>
      <RowContainer style={{ marginTop: 40 }}>
        <ItemsRow style={{ justifyContent: "flex-start" }}>
          <img src={EnvelopeIcon} alt="EnvelopeIcon" />
          <ItemsColumn>
            <h5>{t("web_client.settings_access_send_assistant_email")}</h5>
            <p>{t("web_client.settings_access_send_assistant_email_copy")}</p>
          </ItemsColumn>
        </ItemsRow>
        <Tooltip title={t("web_client.settings_active_assitants_switch")}>
          <Switch
            disabled={assistants.length < 1}
            value={get(settings, "sendEmailsToAssitants", false)}
            checked={get(settings, "sendEmailsToAssitants", false)}
            id="sendEmailsToAssitants"
            onChange={(event) =>
              setProviderSettings(
                {
                  sendEmailsToAssitants: event.target.checked,
                },
                "sendEmailsToAssitants"
              )
            }
          />
        </Tooltip>
      </RowContainer>
      <SaveChangesButton onClick={handleSaveChanges}>
        <span>{t("save_changes")}</span>
      </SaveChangesButton>
    </Container>
  )
}

Notifications.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(Notifications)
