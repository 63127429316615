import styled from "styled-components"

export const EntriesSection = styled.div`
  min-height: 140px;
  background-color: ${(props) =>
    props.isWhite
      ? props.theme.color.white.regular
      : props.theme.color.black.regular};
  height: calc(100% / 4.5);
  border-radius: 5px;
  width: 100%;
  margin-bottom: 30px;
  background-image: ${(props) =>
    props.isFooter ? `url(${props.backgroundImage})` : "none"};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
`

export const Wrapper = styled.div`
  height: calc(100% - 90px);
  width: 100%;
  padding-right: 60px;
  header {
    margin-bottom: 30px;
  }
  & > ${EntriesSection}:last-child {
    margin-bottom: 10px;
  }
`

export const TitleIcon = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background-color: ${(props) =>
    props.isWhite
      ? props.theme.color.black.regular
      : props.theme.color.white.regular};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: ${(props) =>
      props.isWhite
        ? props.theme.color.white.regular
        : props.theme.color.black.regular};
  }
`
export const Title = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) =>
    props.isWhite
      ? props.theme.color.black.regular
      : props.theme.color.white.regular};
  padding: 15px;
  height: 40%;
  & > span {
    margin-left: 10px;
    ${(props) => props.theme.font_size.regular};
  }
`

export const Data = styled.div`
  width: 100%;
  height: ${(props) => (props.isFooter ? "100%" : "60%")};
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) =>
    props.isWhite
      ? props.theme.color.black.regular
      : props.theme.color.white.regular};
  & > section {
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.isFooter ? "33.33%" : "33.33%")};
    text-align: ${(props) => (props.isFooter ? "left" : "center")};
    padding: ${(props) => (props.isFooter ? "0 30px" : "0")};
    & > span {
      &:first-child {
        ${(props) => props.theme.font_size.large};
      }
      ${(props) => props.theme.font_size.small};
    }
    & > div {
      margin-left: 20px;
    }
  }
`
export const Share = styled.div`
  height: 56px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${(props) => `solid 1px ${props.theme.color.veryLightGrey}`};
  background-color: ${(props) => props.theme.color.white.regular};
  width: 50%;
  margin-bottom: 25px;
  & > span {
    padding-left: 5px;
    width: 100%;
    overflow-wrap: break-word;
  }

  @media (min-width: ${(props) => props.theme.screen.md}) {
    width: 90%;
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    width: 100%;
  }
`

export const IncompleteProfileBanner = styled.div`
  height: 60px;
  width: 100%;
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center;

  & > a {
    color: black;
    font-size: 24px;
  }
`

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 10spx;
  width: 100%;
`

export const Tab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
  border-radius: 100px;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
  background: ${(props) =>
    props.isActive ? props.theme.color.accentV3 : "none"};
  & > span {
    ${(props) => props.theme.font_size.xxxsmall};
    color: ${(props) => props.theme.color.black.normal};
    opacity: 0.6;
    margin-left: 16.5px;

    @media (min-width: ${(props) => props.theme.screen.md}) {
      ${(props) => props.theme.font_size.xsmall};
    }
    @media (min-width: ${(props) => props.theme.screen.xlg}) {
      ${(props) => props.theme.font_size.xsmall};
    }
  }
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.375rem;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  padding: 5px 0;
  &:hover {
    font-weight: bold;
  }
  & svg {
    margin-right: 10px;
  }
`
