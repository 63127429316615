import React, { useContext } from "react"
import PropTypes from "prop-types"
import get from "lodash.get"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import ImageWithText from "../../molecules/ImageWithText"
import { AuthUserContext } from "../../../context/Session"
import {
  Card,
  ImagesContainer,
  DisplayNameLabel,
  ProfessionLabel,
} from "./styles"

const ProviderCard = ({ provider }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useContext(AuthUserContext)

  const handleCardClick = () => {
    user.setUser({
      ...user,
      isImpersonating: true,
      impersonatingEmail: provider.email,
      impersonatingUID: provider.id,
    })
    history.push("/sessions")
  }
  return (
    <Card onClick={handleCardClick}>
      <ImagesContainer>
        <ImageWithText src={get(provider, "photoURL")} />
      </ImagesContainer>
      <p style={{ marginTop: 20 }}>
        {t("web_client.settings_access_contact_info")}
      </p>
      <DisplayNameLabel>
        {provider?.name && provider?.name.replace("undefined", "")}{" "}
        {provider?.surname1 && provider?.surname1.replace("undefined", "")}{" "}
        {provider?.surname2 && provider?.surname2.replace("undefined", "")}
      </DisplayNameLabel>
      <ProfessionLabel>
        {provider?.professionLangLabel
          ? get(provider, "professionLangLabel")
          : get(provider, "profession")}
      </ProfessionLabel>
    </Card>
  )
}

ProviderCard.propTypes = {
  provider: PropTypes.shape({
    displayName: PropTypes.string,
  }).isRequired,
}

export default ProviderCard
