import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import * as Yup from "yup"

import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
} from "@chakra-ui/react"

// Utils
import {
  honeybadger,
  createTokenUrl,
  customerCreateUrl,
} from "../../../../../utils/App"

const CreditCard = ({ provider, onCloseModal, firebase }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()

  const ERROR_MESSAGES = {
    emptyCardNumber: t("card_errors.emptyCardNumber"),
    invalidCardNumber: t("card_errors.invalidCardNumber"),
    emptyExpiryDate: t("card_errors.emptyExpiryDate"),
    monthOutOfRange: t("card_errors.monthOutOfRange"),
    yearOutOfRange: t("card_errors.yearOutOfRange"),
    dateOutOfRange: t("card_errors.dateOutOfRange"),
    invalidExpiryDate: t("card_errors.invalidExpiryDate"),
    emptyCVC: t("card_errors.emptyCVC"),
    invalidCVC: t("card_errors.invalidCVC"),
  }

  // const toast = useToast()
  const [isSaving, setIsSaving] = useState(false)

  const {
    meta,
    getCardNumberProps,
    // getCardImageProps,
    getCVCProps,
    getExpiryDateProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
  })

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: {
      cardNumber: "",
      expiryDate: "",
      cvc: "",
      name: "",
    },
    validationSchema: Yup.object().shape({
      cvc: Yup.string().min(3).max(4).required("Campo Requerido"),
      expiryDate: Yup.string().required("Campo Requerido"),
      name: Yup.string().required("Campo Requerido"),
      cardNumber: Yup.string().max(19).min(15).required("Campo Requerido"),
    }),

    onSubmit: async (values) => {
      try {
        const authToken = await firebase.getIdToken()
        setIsSaving(true)
        const expiry = values.expiryDate.split("/")
        const createPayload = {
          client: {
            uid: provider?.medicProfile?.uid,
            email: provider?.medicProfile?.email,
            userId: provider?.medicProfile?.stripeUserId,
          },
          card: {
            number: values.cardNumber.replace(/ /g, ""),
            cvc: values.cvc,
            name: values.name,
            exp_month: expiry[0].trim(),
            exp_year: `20${expiry[1].trim()}`,
            address_line1:
              provider?.medicProfile?.businessForm?.address?.district,
            address_line2:
              provider?.medicProfile?.businessForm?.address?.addressDetails,
            address_city: provider?.medicProfile?.businessForm?.address?.city,
            address_state: provider?.medicProfile?.businessForm?.address?.state,
            address_zip: "",
            address_country:
              provider?.medicProfile?.businessForm?.address?.country,
          },
          isOrg: false,
        }

        const card = await fetch(createTokenUrl, {
          method: "POST",
          body: JSON.stringify(createPayload),
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })

        if (card.ok) {
          const parsedCard = await card.json()
          if (parsedCard.status === "created") {
            addToast("Información almacenada exitosamente!", {
              appearance: "success",
            })
          }
        } else {
          addToast(t("unexpected_error"), { appearance: "error" })
        }
        setIsSaving(false)
        if (onCloseModal) onCloseModal()
      } catch (error) {
        addToast(t("unexpected_error"), { appearance: "error" })
        honeybadger.notify("Register - Credit card component", {
          message: "Error on create stripe card",
          action: "submit",
          context: {
            error,
          },
        })
      }
    },
  })

  useEffect(() => {
    if (provider?.medicProfile?.uid) {
      const createStripeUser = async () => {
        try {
          const authToken = await firebase.getIdToken()
          const customer = await fetch(customerCreateUrl, {
            method: "POST",
            body: JSON.stringify({
              client: {
                uid: provider?.medicProfile?.uid,
                email: provider?.medicProfile?.email,
                name: `${provider?.medicProfile?.name} ${provider?.medicProfile.surname1} ${provider?.medicProfile.surname2}`,
                country: provider?.medicProfile?.country,
              },
              isOrg: false,
            }),
            headers: {
              Authorization: `Bearer ${authToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })

          const parsedCustomer = await customer.json()

          if (parsedCustomer && parsedCustomer.stripeUserId == null) {
            addToast(t("unexpected_error"), { appearance: "error" })
          } else {
            provider.setUser({
              ...provider,
              medicProfile: {
                ...provider.medicProfile,
                stripeUserId: parsedCustomer.stripeUserId,
              },
            })
          }
        } catch (error) {
          addToast(t("unexpected_error"), { appearance: "error" })
          honeybadger.notify("Register - Credit card component", {
            message: "Error on create stripe user",
            action: "submit",
            context: {
              error,
            },
          })
        }
      }

      if (!provider?.medicProfile?.uid) {
        createStripeUser()
      }
    }
  }, [provider])

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl mb={2} mt={2} isInvalid={!!formik.errors.name}>
        <Input
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          placeholder="Nombre Titular"
        />
        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
      </FormControl>

      <PaymentInputsWrapper {...meta}>
        <input
          {...getCardNumberProps({
            value: formik.values.cardNumber,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          })}
          placeholder={t("card_number")}
        />
        <input
          {...getExpiryDateProps({
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          })}
          value={formik.values.expiryDate}
          placeholder="MM/AA"
        />
        <input
          {...getCVCProps({
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          })}
          value={formik.values.cvc}
          placeholder="CVC"
        />
      </PaymentInputsWrapper>
      <HStack
        spacing={2}
        mt={4}
        ml={10}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          variant="solid"
          type="submit"
          mr={2}
          size="md"
          colorScheme="blue"
          isLoading={isSaving}
        >
          {t("save")}
        </Button>
        <Button onClick={onCloseModal}>{t("close")}</Button>
      </HStack>
    </form>
  )
}

export default CreditCard
