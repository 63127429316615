import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin-top: 54px;
  & > img {
    display: block;
    width: 66px;
    height: 54px;
  }
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 70%;
  margin-left: 16%;
  margin-top: 34px;
  padding: 22px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const TableItem = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  & > h5 {
    margin: 0;
    ${(props) => props.theme.font_size.xxxsmall};
    color: ${(props) => props.theme.color.black.normal};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: 400;
    opacity: 0.6;
    text-align: center;
    line-height: inherit;
  }
  & > p {
    margin: 25px 0;
    flex-wrap: wrap;
    ${(props) => props.theme.font_size.xxxsmall};
    color: ${(props) => props.theme.color.black.normal};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: 400;
    line-height: inherit;
  }
  @media (min-width: ${(props) => props.theme.screen.md}) {
    & > h5 {
      ${(props) => props.theme.font_size.xsmall};
    }
    & > p {
      ${(props) => props.theme.font_size.xxsmall};
    }
  }
  @media (min-width: ${(props) => props.theme.screen.xlg}) {
    & > h5 {
      ${(props) => props.theme.font_size.regular};
    }
    & > p {
      ${(props) => props.theme.font_size.xsmall};
    }
  }
`

export const ItemsRowTable = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 5px;
  border-bottom: 1px solid #e2e8f0;
`

export const Option = styled.div`
  input[type="checkbox"] {
    border: ${(props) => `solid 1px ${props.theme.color.veryLightGrey}`};
  }
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 68%;
  margin-left: 17%;
`

export const ItemsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
`

export const ItemsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & > h5 {
    margin-left: 22px !important;
    margin: 0;
    ${(props) => props.theme.font_size.regular};
    color: ${(props) => props.theme.color.black.normal};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: 400;
  }
  & > p {
    margin-left: 22px !important;
    margin: 0;
    ${(props) => props.theme.font_size.xxsmall};
    color: ${(props) => props.theme.color.black.normal};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: 400;
    opacity: 0.6;
  }
`

export const SaveChangesButton = styled.button`
  width: 247px;
  height: 36px;
  margin-top: 22px;
  margin-left: 16%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  background-color: transparent;
  color: ${(props) => props.theme.color.secondary};
  ${(props) => props.theme.font_size.small};
  &:hover {
    background-color: ${(props) => props.theme.color.secondary};
    & > span {
      color: ${(props) => props.theme.color.white.regular};
    }
  }
`
