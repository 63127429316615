import get from "lodash.get"
import Select from "react-select"
import PropTypes from "prop-types"
import { compose } from "recompose"
import loadable from "@loadable/component"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import React, { useState, useEffect, useContext, useMemo } from "react"
import { useHistory, withRouter, Prompt } from "react-router-dom"
import { LocalHospital } from "@styled-icons/material/LocalHospital"
import { Business } from "@styled-icons/material/Business"

// Components
import { SectionsContainer } from "../../atoms/Global"
import CustomModal from "../../atoms/CustomModal"
import {
  UserIcon,
  GlobeIcon,
  VideoIcon,
  ClockIcon,
  EditIcon,
  GearIcon,
  CheckIcon,
} from "../../atoms/Common"
import TimeZoneModal from "../../molecules/TimeZoneModal"
import Scheduler from "../../molecules/Scheduler"

// Utils
import constants from "../../../utils/constants"
import { withFirebase } from "../../../utils/Firebase"
import { getLocalTimeZone, getTimeZoneList } from "../../../utils/helpers"
import { withAuthorization, AuthUserContext } from "../../../context/Session"
import {
  appointmentsGetUrl,
  appointmentsUpdateUrl,
  honeybadger,
} from "../../../utils/App"
import { timeOptions } from "./options"
import Theme from "../../../styles/theme"
import {
  Container,
  ModalWrapper,
  ModalOptions,
  TabsContainer,
  ButtonTab,
  CronofyElementsContainer,
  ToolboxContainer,
  ToolWrapper,
  Tool,
  ItemsColumn,
  ActionsContainer,
  Action,
  CronofyOverlay,
} from "./styles"

const LoadableCronofyWrapper = loadable(() =>
  import("../../atoms/CronofyWrapper")
)
// const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "transparent",
    marginTop: "1px",
    marginBottom: "2px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}

const Agenda = ({ firebase }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { addToast } = useToasts()
  const user = useContext(AuthUserContext)
  const [ruleToShow, setRuleToShow] = useState(null)
  const [userTypeView, setUserTypeView] = useState("medical_work_hours")
  const [isModalOpen, setIsModalOpen] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [notification, setNotification] = useState(false)
  const [isTimezoneModalOpen, toggleTimezoneModal] = useState(false)
  const [userTimezone, setUserTimezone] = useState("")
  const [isModified, setIsModified] = useState(false)
  const [rulesToSave, setRulesToSave] = useState([])
  const [currentRule, setCurrentRule] = useState([])
  const [saveRulesModal, setSaveRulesModal] = useState(false)
  const [locationPathToGo, setLocationPathToGo] = useState("")
  const [userOffices, setUserOffices] = useState([])
  const [currentOffice, setCurrentOffice] = useState()
  const [currentOrg, setCurrentOrg] = useState("private")
  const [timeSettings, setTimeSettings] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [rulesHourChanged, setRulesHourChanged] = useState(false)
  const [loadingAgendaData, setLoadingAgendaData] = useState(true)
  const [appointmentType, setAppointmentType] = useState("in_person")
  const [editMode, setEditMode] = useState(false)
  const userType = get(user, ["medicProfile", "userType"])
  const accessMode = get(user, ["medicProfile", "accessMode"], "blocked")
  const [profile, setProfile] = useState(null)
  const [feesFormValues, setFeesFormValues] = useState({
    // Consulta Virtual
    virtualAppointmentEnabled: false,
    virtualConsultation: 0,
    patient_time: 0,

    // Consulta Presencial
    facingConsultation: 0,
    patient_time_in_person: 0,

    // Visita a domicilio
    homeAppointmentEnabled: false,
    homeConsultation: 0,
    homeVisitTime: 0,
    homeConsultationRange: 0,

    // Servicio de preguntas
    questions: 0,
    questionsEnabled: false,

    chargeAdvanceAppointments: false,
    chargeCashAppointments: false,
  })

  const docOrgs = useMemo(() => {
    const organizations = get(user, ["medicProfile", "organizations"], {})

    const parsedOrgs =
      accessMode === "business"
        ? []
        : [
            {
              value: "private",
              label: "Privado",
            },
          ]

    Object.keys(organizations)
      ?.filter((o) => organizations[o].active)
      ?.forEach((org) =>
        parsedOrgs.push({
          label: organizations[org].businessName,
          value: organizations[org].id,
        })
      )

    return parsedOrgs
  }, [user])

  const userTypeOptions = [
    {
      value: "medical_work_hours",
      label: t("type_patient"),
    },
    {
      value: "medical_visitors_hours",
      label: t("type_visitor"),
    },
  ]

  const appointmentTypeOptions = React.useMemo(() => {
    let options
    const userPlan = get(profile, ["plan"])
    if (userPlan === "basic") {
      options = [
        {
          value: "in_person",
          label: t("apt_face_to_face"),
        },
        {
          ...(feesFormValues?.homeAppointmentEnabled
            ? {
                value: "home",
                label: t("apt_home"),
              }
            : null),
        },
      ]
    } else {
      options = [
        {
          value: "in_person",
          label: t("apt_face_to_face"),
        },
        {
          value: "virtual",
          label: t("apt_virtual"),
        },
        {
          ...(feesFormValues?.homeAppointmentEnabled
            ? {
                value: "home",
                label: t("apt_home"),
              }
            : null),
        },
      ]
    }
    return options.filter((option) => Object.keys(option).length > 0)
  }, [profile, feesFormValues])

  const durationOptions = [
    {
      value: 15,
      label: t("web_client.minutes", { minutes: "15" }),
    },
    {
      value: 20,
      label: t("web_client.minutes", { minutes: "20" }),
    },
    {
      value: 30,
      label: t("web_client.minutes", { minutes: "30" }),
    },
    {
      value: 45,
      label: t("web_client.minutes", { minutes: "45" }),
    },
    {
      value: 60,
      label: t("web_client.minutes", { minutes: "60" }),
    },
  ]

  const homeVisitDurations = [
    ...durationOptions,
    {
      value: 90,
      label: t("web_client.minutes", { minutes: "90" }),
    },
  ]

  const customdDurationOptions = React.useMemo(() => {
    if (appointmentType === "home") {
      return homeVisitDurations
    }

    return durationOptions
  }, [appointmentType])

  const currentRuleId = useMemo(
    () =>
      userOffices?.length >= 1 || docOrgs?.length >= 1
        ? `${userTypeView}_${appointmentType}${
            currentOrg === "private"
              ? ""
              : `_${currentOrg?.toString()?.toLowerCase()}`
          }${
            currentOffice ? `_${currentOffice?.toString()?.toLowerCase()}` : ""
          }`
        : `${userTypeView}_${appointmentType}`,
    [userOffices, userTypeView, appointmentType, currentOrg, currentOffice]
  )

  const timezones = React.useMemo(() => getTimeZoneList(), [])
  const localTimeZone = React.useMemo(() => getLocalTimeZone(), [])

  useEffect(() => {
    if (!isTimezoneModalOpen) {
      const userTZ = get(
        user,
        "timezone",
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      if (userTZ) {
        setUserTimezone(userTZ)
      } else {
        const defaultTimezone = timezones.find(
          (timeZone) =>
            localTimeZone.name() === timeZone.name ||
            timeZone.group.includes(localTimeZone.name())
        )
        setUserTimezone(defaultTimezone.name)
      }
    }
  }, [user])

  const handleModalCancel = () => {
    setIsModalOpen(false)
    history.push("/sessions")
  }

  const handleFinish = () => {
    setIsModalOpen(false)
    const userEmail = user.isImpersonating
      ? user.impersonatingEmail
      : user.email
    window.location.href = `https://app.cronofy.com/oauth/authorize?response_type=code&client_id=${constants.cronofy.clientId}&redirect_uri=${constants.cronofy.redirectURL}/settings&scope=read_write&state=${userEmail}`
  }

  // eslint-disable-next-line no-unused-vars
  const checkRules = async () => {
    try {
      const authToken = await firebase.getIdToken()
      if (authToken) {
        const response = await fetch(appointmentsGetUrl, {
          method: "POST",
          body: JSON.stringify({
            user: user.isImpersonating ? user.impersonatingEmail : user.email,
          }),
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })
        const rules = await response.json()
        if (rules && rules.availability_rules.length > 0) {
          if (
            rules?.availability_rules?.some(
              (r) => r?.availability_rule_id === currentRuleId
            )
          ) {
            setCurrentRule(
              rules?.availability_rules?.find(
                (r) => r?.availability_rule_id === currentRuleId
              )
            )
          }
          await firebase.updateProvider(
            { allowScheduling: true, indexDocument: true },
            user.isImpersonating
              ? user.impersonatingEmail.toLowerCase()
              : user.email.toLowerCase()
          )
        } else {
          await firebase.updateProvider(
            { allowScheduling: false, indexDocument: true },
            user.isImpersonating
              ? user.impersonatingEmail.toLowerCase()
              : user.email.toLowerCase()
          )
          addToast(t("web_client.add_rules"), { appearance: "info" })
        }
      }
    } catch (e) {
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  useEffect(() => {
    const getData = async () => {
      const getProfile = async () => {
        if (userType === "provider") {
          return get(user, ["medicProfile"])
        }
        const local = await firebase.getProfile({
          email: user.impersonatingEmail,
        })
        return local.data()
      }
      const Profile = await getProfile()
      setProfile(Profile)
    }
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      getData()
    }
  }, [user, userType])

  useEffect(() => {
    const getData = async () => {
      const getBusinessForm = async () => {
        const business = await firebase.getBusiness({ email: user.email })
        if (business && !business.empty) {
          const data = business.data()
          const offices = get(data, "offices", [])
          if (offices.length >= 1 && appointmentType === "in_person") {
            setUserOffices(
              offices
                ?.filter((f) =>
                  accessMode === "business" ? f.officeOwner !== "private" : true
                )
                .map((office) => ({
                  label: `${office.state} - ${office.alias || office.city}`,
                  value: office.id,
                  owner: office.officeOwner,
                }))
            )

            const filteredOffices =
              accessMode === "business"
                ? offices?.filter((of) => of.officeOwner !== "private")
                : offices

            setCurrentOrg(filteredOffices[0]?.officeOwner)
            setCurrentOffice(filteredOffices[0]?.id)
          } else {
            setUserOffices([])
            setCurrentOffice(null)
          }
        } else {
          setUserOffices([])
          setCurrentOffice(null)
        }
      }
      await getBusinessForm()
    }
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      getData()
    }
  }, [appointmentType])

  useEffect(() => {
    if (profile) {
      if (get(profile, ["cronofySub"])) {
        checkRules()
        setRuleToShow(0)
      } else {
        setIsModalOpen(true)
      }
    }
  }, [profile])

  useEffect(() => {
    const getTimeSettings = async () => {
      try {
        const fees = await firebase.getFees({
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
        })
        const feesData = fees.data()
        const virtualEnabled = get(feesData, "virtual.enabled", true)
        const planType = get(profile, "plan")
        setFeesFormValues((currentValues) => ({
          ...currentValues,
          virtualConsultation: get(feesData, "virtual.consultationFee"),
          patient_time: get(feesData, "virtual.consultationTime"),
          virtualAppointmentEnabled: virtualEnabled,
          facingConsultation: get(feesData, "faceToFace.consultationFee"),
          patient_time_in_person: get(feesData, "faceToFace.consultationTime"),
          homeConsultation: get(feesData, "home.consultationFee"),
          homeVisitTime: get(feesData, "home.consultationTime"),
          homeConsultationRange: get(feesData, "home.range"),
          homeAppointmentEnabled: get(feesData, "home.enabled"),
          questions: get(feesData, "questions.consultationFee"),
          questionsEnabled: get(feesData, "questions.enabled"),
          chargeAdvanceAppointments: get(feesData, "chargeAdvanceAppointments"),
          chargeCashAppointments: get(feesData, "chargeCashAppointments"),
        }))

        if (!virtualEnabled && !!planType && planType !== "basic") {
          addToast(t("no_virtual_sessions"), {
            appearance: "info",
            id: "virtual",
          })
        }

        const getSettings = await firebase.getSettings({
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
          key: "times",
        })

        if (getSettings.data()) {
          setTimeSettings(getSettings.data())
        }
        setLoadingAgendaData(false)
      } catch (error) {
        setLoadingAgendaData(false)
      }
    }
    if (firebase) {
      setLoadingAgendaData(true)
      getTimeSettings()
      checkRules()
    }
  }, [firebase, userTypeView, appointmentType, currentOffice, currentOrg])

  const onChangeDuration = async (selectData, key) => {
    setFeesFormValues((currentValues) => ({
      ...currentValues,
      [key]: selectData.value,
    }))
    setTimeSettings((timeSettingsState) => ({
      ...timeSettingsState,
      [key]: selectData.value,
    }))
    setRulesHourChanged(true)
  }

  const timeKeys = useMemo(() => {
    const keys = {
      virtual: {
        start_time: "start_time",
        end_time: "end_time",
      },
      home: {
        start_time: "start_time_home",
        end_time: "end_time_home",
      },
      in_person: {
        start_time: "start_time_in_person",
        end_time: "end_time_in_person",
      },
    }
    return keys[appointmentType]
  }, [appointmentType])

  const durationPatientKey = useMemo(() => {
    const keys = {
      virtual: "patient_time",
      home: "homeVisitTime",
      in_person: "patient_time_in_person",
    }
    return keys[appointmentType]
  }, [appointmentType])

  const updateMultiDay = (prevRule) => {
    const newRule = { ...prevRule }
    // bloquear no habilitar
    const startKey = timeKeys.start_time
    const endKey = timeKeys.end_time

    const newStartHourFormatted =
      timeSettings?.[startKey]?.toString()?.substring(0, 2) || 0
    const newEndHourFormatted =
      timeSettings?.[endKey]?.toString()?.substring(0, 2) || 0
    const prevRuleStartHourFormatted =
      prevRule.start_time?.toString()?.substring(0, 2) || 0
    const prevRuleEndHourFormatted =
      prevRule.end_time?.toString()?.substring(0, 2) || 0
    // Si el nuevo inicio es mayor a la hora de fin del horario actual
    // Si el nuevo fin es menor a la hora de inicio del horario actual
    if (
      (newStartHourFormatted &&
        newStartHourFormatted > prevRuleEndHourFormatted) ||
      (newEndHourFormatted && newEndHourFormatted < prevRuleStartHourFormatted)
    ) {
      return null
    }
    // Si la nueva hora de inicio es mayor a la hora de inicio actual y menor a la hora de fin del actual
    if (
      newStartHourFormatted &&
      newStartHourFormatted > prevRuleStartHourFormatted &&
      newStartHourFormatted <= prevRuleEndHourFormatted
    ) {
      newRule.start_time = timeSettings[startKey]
    }
    // Si la nueva hora de fin es menor a la hora final actual y mayor a la hora de inicio actual
    if (
      newEndHourFormatted &&
      newEndHourFormatted < prevRuleEndHourFormatted &&
      newEndHourFormatted >= prevRuleStartHourFormatted
    ) {
      newRule.end_time = timeSettings[endKey]
    }
    return newRule
  }

  const updateSingleDay = (rule) => {
    const newRule = { ...rule }
    const startKey = timeKeys.start_time
    const endKey = timeKeys.end_time
    const newStartHourFormatted =
      timeSettings?.[startKey]?.toString()?.substring(0, 2) || 0
    const ruleStartHourFormatted =
      rule.start_time?.toString()?.substring(0, 2) || 0
    const newEndHourFormatted =
      timeSettings?.[endKey]?.toString()?.substring(0, 2) || 0
    const ruleEndHourFormatted = rule.end_time?.toString()?.substring(0, 2) || 0
    if (
      newStartHourFormatted &&
      timeSettings?.[startKey] &&
      rule.start_time !== timeSettings[startKey] &&
      newStartHourFormatted > ruleStartHourFormatted
    ) {
      newRule.start_time = timeSettings[startKey]
    }
    if (
      newEndHourFormatted &&
      timeSettings?.[endKey] &&
      rule.end_time !== timeSettings[endKey] &&
      newEndHourFormatted < ruleEndHourFormatted
    ) {
      newRule.end_time = timeSettings[endKey]
    }
    return newRule
  }

  const updateCalendarRules = async (needsToRedirect) => {
    try {
      const authToken = await firebase.getIdToken()
      let newRules = {}
      // const startKey =
      //   appointmentType === "virtual" ? "start_time" : "start_time_in_person"
      // const endKey =
      //   appointmentType === "virtual" ? "end_time" : "end_time_in_person"
      if (rulesHourChanged) {
        const days = [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ]
        const singleDays = days.filter(
          (day) =>
            currentRule?.weekly_periods?.filter((rule) => rule.day === day)
              .length === 1
        )
        const updatedPeriods = currentRule?.weekly_periods.map((rule) => {
          let updatedRule
          if (singleDays.includes(rule.day)) {
            updatedRule = updateSingleDay(rule)
          } else {
            const actualRule = rule
            // const nextRule = currentRule?.weekly_periods[index + 1]
            updatedRule = updateMultiDay(actualRule)
          }
          return updatedRule
        })
        newRules = {
          ...currentRule,
          weekly_periods: updatedPeriods.filter(
            (up) => up !== null && Object.keys(up)?.length > 0
          ),
        }
      } else {
        newRules = rulesToSave
      }
      await fetch(appointmentsUpdateUrl, {
        method: "POST",
        body: JSON.stringify({
          user: user.isImpersonating ? user.impersonatingEmail : user.email,
          availability_rule_id: newRules.availability_rule_id,
          tzid: newRules.tzid,
          weekly_periods: newRules.weekly_periods,
        }),
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      })
      await firebase.updateProvider(
        { allowScheduling: true, indexDocument: true },
        user.isImpersonating
          ? user.impersonatingEmail.toLowerCase()
          : user.email.toLowerCase()
      )
      setSaveRulesModal(false)
      setRulesHourChanged(false)
      checkRules()
      addToast(t("web_client.saved_successfully"), { appearance: "success" })
      if (needsToRedirect) {
        setTimeout(() => {
          history.push(locationPathToGo)
        }, 1000)
      } else {
        setEditMode(false)
      }
    } catch (error) {
      honeybadger.notify(error)
      setSaveRulesModal(false)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const handleModalCancelSaveRules = () => {
    setIsModified(false)
    setRulesToSave([])
    setSaveRulesModal(false)
    history.push(locationPathToGo)
  }

  const handleBlockedNavigation = (nextLocation) => {
    if (nextLocation.pathname !== "Agenda" && isModified) {
      setSaveRulesModal(true)
      setIsModified(false)
      setLocationPathToGo(nextLocation.pathname)
      return false
    }
    setLocationPathToGo(nextLocation.pathname)
    handleModalCancelSaveRules()
    return true
  }

  useEffect(() => {
    setLoadingAgendaData(true)
    setTimeout(() => {
      setLoadingAgendaData(false)
    }, 500)
  }, [i18n.language])

  const filteredOfficesByOrg = React.useMemo(() => {
    if (userOffices?.length > 0) {
      return userOffices?.filter((of) => of.owner === currentOrg)
    }
    return []
  }, [currentOrg, userOffices])

  useEffect(() => {
    if (filteredOfficesByOrg?.length > 0) {
      setCurrentOffice(filteredOfficesByOrg?.[0]?.value)
    } else {
      setCurrentOffice(null)
    }
  }, [filteredOfficesByOrg])

  return (
    <SectionsContainer>
      <>
        <Prompt when={isModified} message={handleBlockedNavigation} />
        {saveRulesModal && (
          <CustomModal
            modalOpen={saveRulesModal}
            setModalOpen={setIsModalOpen}
            showClose={false}
          >
            <ModalWrapper isFull>
              <ModalOptions>
                <section>
                  <p>{t("web_client.calendar_unsave_changes")}</p>
                  <div>
                    <button type="button" onClick={handleModalCancelSaveRules}>
                      {t("cancel")}
                    </button>
                    <button
                      type="button"
                      onClick={() => updateCalendarRules(true)}
                    >
                      {t("confirm")}
                    </button>
                  </div>
                </section>
              </ModalOptions>
            </ModalWrapper>
          </CustomModal>
        )}
      </>
      <Container>
        <TabsContainer>
          <ButtonTab
            type="button"
            isActive={ruleToShow === 0}
            onClick={() => setRuleToShow(0)}
          >
            {t("availability")}
          </ButtonTab>
          {/* <ButtonTab
            type="button"
            isActive={ruleToShow === 1}
            onClick={() => setRuleToShow(1)}
          >
            {t("agenda")}
          </ButtonTab> */}
          <ButtonTab
            type="button"
            isActive={ruleToShow === 2}
            onClick={() => setRuleToShow(2)}
          >
            {t("agenda")}
          </ButtonTab>
          <ButtonTab
            type="button"
            isActive={ruleToShow === 3}
            onClick={() => setRuleToShow(3)}
            withMinWidth
          >
            {t("calendar_view")}
          </ButtonTab>
          {ruleToShow === 0 && (
            <ActionsContainer>
              {!editMode ? (
                <>
                  <Action onClick={() => setEditMode(true)}>
                    <EditIcon />
                    {t("edit")}
                  </Action>
                  <Action>
                    <GearIcon />
                    {t("web_client.menu_settings")}
                  </Action>
                </>
              ) : (
                <>
                  <Action onClick={() => setEditMode(false)}>{`X ${t(
                    "cancel"
                  )}`}</Action>
                  <Action
                    onClick={async () => {
                      setLoadingAgendaData(true)
                      try {
                        await firebase.saveProviderSettings(
                          timeSettings,
                          "times",
                          user.isImpersonating
                            ? user.impersonatingEmail
                            : user.email
                        )
                        await firebase.saveFeesData({
                          email: user.isImpersonating
                            ? user.impersonatingEmail
                            : user.email,
                          data: {
                            virtual: {
                              consultationTime:
                                Number(feesFormValues.patient_time) || 0,
                            },
                            faceToFace: {
                              consultationTime:
                                feesFormValues.patient_time_in_person,
                            },
                            home: {
                              consultationTime:
                                Number(feesFormValues.homeVisitTime) || 0,
                            },
                          },
                        })
                        if (
                          rulesHourChanged ||
                          (rulesToSave && Object.keys(rulesToSave).length >= 1)
                        ) {
                          await updateCalendarRules(false)
                        } else {
                          setEditMode(false)
                        }
                        setLoadingAgendaData(false)
                      } catch {
                        setLoadingAgendaData(false)
                        addToast(t("unexpected_error"), { appearance: "error" })
                      }
                    }}
                  >
                    <CheckIcon />
                    {t("save")}
                  </Action>
                </>
              )}
            </ActionsContainer>
          )}
        </TabsContainer>
        {ruleToShow === 0 && (
          <ToolboxContainer>
            <ToolWrapper>
              <Tool>
                <UserIcon />
                <ItemsColumn isFirstRow>
                  <p>{t("client_label")}</p>
                  <Select
                    styles={customStyles}
                    defaultValue={userTypeView}
                    value={userTypeOptions.find(
                      (option) => option.value === userTypeView // get(timeSettings, "medical_visitor_time")
                    )}
                    options={userTypeOptions}
                    onChange={(option) => setUserTypeView(option.value)}
                    width="200px"
                    borderRadius="20px"
                    isDisabled
                  />
                </ItemsColumn>
              </Tool>
              <Tool>
                <VideoIcon />
                <ItemsColumn isFirstRow>
                  <p>{t("type_label")}</p>
                  <Select
                    label={t("type_label")}
                    styles={customStyles}
                    defaultValue={appointmentTypeOptions[0]}
                    value={appointmentTypeOptions.find(
                      (option) => option.value === appointmentType // get(timeSettings, "medical_visitor_time")
                    )}
                    options={appointmentTypeOptions}
                    onChange={(option) => {
                      setCurrentOffice(null)
                      setAppointmentType(option.value)
                    }}
                    width="250px"
                    borderRadius="20px"
                    isDisabled={
                      (!feesFormValues?.virtualAppointmentEnabled &&
                        !feesFormValues?.homeAppointmentEnabled) ||
                      appointmentTypeOptions?.length <= 1
                    }
                  />
                </ItemsColumn>
              </Tool>
              {docOrgs?.length >= 1 && (
                <Tool>
                  <Business size="24" />
                  <ItemsColumn isFirstRow>
                    <p>{t("web_client.office_owner")}</p>
                    <Select
                      label={t("web_client.office")}
                      styles={customStyles}
                      defaultValue={docOrgs[0]}
                      value={docOrgs.find(
                        (option) => option.value === currentOrg
                      )}
                      options={docOrgs}
                      onChange={(option) => setCurrentOrg(option.value)}
                      width="250px"
                      borderRadius="20px"
                      isDisabled={docOrgs?.length <= 1 || !editMode}
                    />
                  </ItemsColumn>
                </Tool>
              )}
              {filteredOfficesByOrg?.length > 0 &&
                appointmentType === "in_person" && (
                  <Tool>
                    <LocalHospital size="24" />
                    <ItemsColumn isFirstRow>
                      <p>{t("web_client.offices")}</p>
                      <Select
                        label={t("web_client.office")}
                        styles={customStyles}
                        defaultValue={filteredOfficesByOrg?.[0]}
                        value={
                          filteredOfficesByOrg?.find(
                            (option) => option.value === currentOffice
                          ) || filteredOfficesByOrg?.[0]
                        }
                        options={filteredOfficesByOrg}
                        onChange={(option) => setCurrentOffice(option.value)}
                        width="250px"
                        borderRadius="20px"
                        isDisabled={
                          filteredOfficesByOrg?.length <= 1 || !editMode
                        }
                      />
                    </ItemsColumn>
                  </Tool>
                )}
            </ToolWrapper>
            <ToolWrapper>
              <Tool>
                <VideoIcon />
                <ItemsColumn>
                  <p>{t("web_client.duration")}</p>
                  <Select
                    label={t("web_client.duration")}
                    styles={customStyles}
                    defaultValue={customdDurationOptions[0]}
                    value={customdDurationOptions.find(
                      (option) =>
                        option.value ===
                        get(
                          feesFormValues,
                          userTypeView === "medical_work_hours"
                            ? durationPatientKey
                            : appointmentType === "virtual"
                            ? "medical_visitor_time"
                            : "medical_visitor_time_in_person",
                          30
                        )
                    )}
                    options={customdDurationOptions}
                    onChange={(option) =>
                      onChangeDuration(
                        option,
                        userTypeView === "medical_work_hours"
                          ? durationPatientKey
                          : appointmentType === "virtual"
                          ? "medical_visitor_time"
                          : "medical_visitor_time_in_person"
                      )
                    }
                    width="200px"
                    borderRadius="20px"
                    isDisabled={!editMode}
                  />
                </ItemsColumn>
              </Tool>
              <Tool>
                <ClockIcon />
                <ItemsColumn>
                  <p>{t("start_time")}</p>
                  <Select
                    label={t("start_time")}
                    styles={customStyles}
                    defaultValue={timeOptions[0]}
                    value={timeOptions.find(
                      (option) =>
                        option.value ===
                        get(timeSettings, timeKeys.start_time, "09:00")
                    )}
                    options={timeOptions}
                    onChange={(option) =>
                      onChangeDuration(option, timeKeys.start_time)
                    }
                    width="200px"
                    borderRadius="20px"
                    isDisabled={!editMode}
                  />
                </ItemsColumn>
              </Tool>
              <Tool>
                <ClockIcon />
                <ItemsColumn>
                  <p>{t("end_time")}</p>
                  <Select
                    label={t("end_time")}
                    styles={customStyles}
                    defaultValue={timeOptions[0]}
                    value={timeOptions.find(
                      (option) =>
                        option.value ===
                        get(timeSettings, timeKeys.end_time, "19:00")
                    )}
                    options={timeOptions}
                    onChange={(option) =>
                      onChangeDuration(option, timeKeys.end_time)
                    }
                    width="200px"
                    borderRadius="20px"
                    isDisabled={!editMode}
                  />
                </ItemsColumn>
              </Tool>
              <Tool>
                <GlobeIcon />
                {timezones && timezones.length > 0 && (
                  <ItemsColumn>
                    <p>{t("timezone")}</p>
                    <button
                      style={{
                        marginTop: 11,
                        paddingLeft: 10,
                        cursor: editMode ? "pointer" : "default",
                      }}
                      type="button"
                      onClick={() => toggleTimezoneModal(true)}
                      disabled={!editMode}
                    >
                      {userTimezone}
                    </button>
                  </ItemsColumn>
                )}
              </Tool>
            </ToolWrapper>
          </ToolboxContainer>
        )}
        <CronofyElementsContainer isEdit={editMode} isAgenda={ruleToShow === 2}>
          {(userTypeView === "medical_work_hours" ||
            userTypeView === "medical_visitors_hours") &&
            ruleToShow === 0 &&
            !loadingAgendaData && (
              <div style={{ position: "relative" }} className="notranslate">
                <CronofyOverlay isEdit={editMode} />
                <LoadableCronofyWrapper
                  withOptions
                  isCalendar
                  cronofyPermissions={["managed_availability"]}
                  cronofyElement="AvailabilityRules"
                  cronofyTargetId="cronofy-availability-viewer"
                  cronofyOptions={{
                    locale: i18n.language || "es",
                    availability_rule_id: currentRuleId,
                    config: {
                      start_time: get(
                        timeSettings,
                        userTypeView === "medical_work_hours"
                          ? timeKeys.start_time
                          : appointmentType === "virtual"
                          ? "medical_visitor_start_time"
                          : "medical_visitor_start_time_in_person",
                        "09:00"
                      ),
                      end_time: get(
                        timeSettings,
                        userTypeView === "medical_work_hours"
                          ? timeKeys.end_time
                          : appointmentType === "virtual"
                          ? "medical_visitor_end_time"
                          : "medical_visitor_end_time_in_person",
                        "19:00"
                      ),
                      duration: get(
                        feesFormValues,
                        userTypeView === "medical_work_hours"
                          ? durationPatientKey
                          : appointmentType === "virtual"
                          ? "medical_visitor_time"
                          : "medical_visitor_time_in_person",
                        30
                      ),
                    },
                    styles: {
                      colors: {
                        available: Theme.color.white.regular,
                        unavailable: Theme.color.agenda.unavailable,
                      },
                      prefix: "DocRules",
                    },
                    callback: async (res) => {
                      if (
                        res.notification &&
                        res.notification.type.includes(
                          "availability_rule_edited"
                        )
                      ) {
                        setIsModified(true)
                        setRulesToSave(res.availability_rule)
                        setCurrentRule(res.availability_rule)
                      }
                      if (
                        res.notification &&
                        res.notification.type.includes(
                          "availability_rule_saved"
                        )
                      ) {
                        await firebase.updateProvider(
                          { allowScheduling: true, indexDocument: true },
                          user.isImpersonating
                            ? user.impersonatingEmail.toLowerCase()
                            : user.email.toLowerCase()
                        )
                        setIsModified(false)
                        setEditMode(false)
                        setRulesToSave([])
                        setCurrentRule(res?.availability_rule)
                        addToast(t("web_client.saved_successfully"), {
                          appearance: "success",
                        })
                      }
                      if (
                        res.notification &&
                        res.notification.type.includes(
                          "availability_rule_not_found"
                        )
                      ) {
                        setRulesToSave({
                          availability_rule_id: currentRuleId,
                          calendar_ids: [],
                          tzid: userTimezone,
                          weekly_periods: [
                            {
                              day: "monday",
                              start_time: "09:00",
                              end_time: "19:00",
                            },
                            {
                              day: "tuesday",
                              start_time: "09:00",
                              end_time: "19:00",
                            },
                            {
                              day: "wednesday",
                              start_time: "09:00",
                              end_time: "19:00",
                            },
                            {
                              day: "thursday",
                              start_time: "09:00",
                              end_time: "19:00",
                            },
                            {
                              day: "friday",
                              start_time: "09:00",
                              end_time: "19:00",
                            },
                          ],
                        })
                        setEditMode(true)
                        addToast(t("rulte_not_found"), {
                          appearance: "info",
                        })
                      }
                      setNotification(JSON.stringify(res.notification))
                    },
                    tzid: userTimezone,
                  }}
                  key="cronofy-availability-viewer"
                >
                  <div
                    style={{ width: "100%", height: "auto" }}
                    id="cronofy-availability-viewer"
                    className="notranslate"
                  />
                </LoadableCronofyWrapper>
              </div>
            )}
          {ruleToShow === 2 && (
            <LoadableCronofyWrapper
              isCalendar
              cronofyPermissions={["agenda"]}
              cronofyElement="Agenda"
              cronofyTargetId="cronofy-agenda"
              key="cronofy-agenda"
              cronofyOptions={{
                tzid: userTimezone,
              }}
            >
              <div
                style={{ width: "100%", height: "auto" }}
                id="cronofy-agenda"
              />
            </LoadableCronofyWrapper>
          )}
          {ruleToShow === 3 && <Scheduler />}
        </CronofyElementsContainer>
      </Container>
      {isModalOpen && (
        <CustomModal
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <p>{t("web_client.calendar_sync")}</p>
                <div>
                  <button type="button" onClick={handleModalCancel}>
                    {t("cancel")}
                  </button>
                  <button type="button" onClick={handleFinish}>
                    {t("confirm")}
                  </button>
                </div>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
      <TimeZoneModal
        user={user}
        firebase={firebase}
        isOpen={isTimezoneModalOpen}
        onFinish={(z) => {
          setUserTimezone(z)
          toggleTimezoneModal(false)
        }}
      />
    </SectionsContainer>
  )
}

Agenda.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const CompAgenda = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition)
)(Agenda)
export default CompAgenda
