import React from "react"

const AppStoreIcon = () => (
  <a target="__BLANK" href="https://apps.apple.com/us/app/doc-v3/id6444708488">
    <svg
      width="115"
      height="39"
      viewBox="0 0 115 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.11438 9.02193C0.11438 6.26218 0.11438 4.8823 0.565239 3.79382C1.16639 2.34253 2.31943 1.18948 3.77073 0.588334C4.8592 0.137474 6.23908 0.137474 8.99884 0.137474H105.23C107.99 0.137474 109.37 0.137474 110.458 0.588334C111.909 1.18948 113.062 2.34253 113.664 3.79382C114.114 4.8823 114.114 6.26218 114.114 9.02194V29.253C114.114 32.0128 114.114 33.3927 113.664 34.4811C113.062 35.9324 111.909 37.0855 110.458 37.6866C109.37 38.1375 107.99 38.1375 105.23 38.1375H8.99884C6.23908 38.1375 4.8592 38.1375 3.77073 37.6866C2.31943 37.0855 1.16639 35.9324 0.565239 34.4811C0.11438 33.3927 0.11438 32.0128 0.11438 29.253V9.02193Z"
        fill="white"
      />
      <path
        d="M77.564 18.3784V20.5548H76.1997V21.9821H77.564V26.8319C77.564 28.4879 78.3131 29.1503 80.1978 29.1503C80.529 29.1503 80.8444 29.1109 81.1204 29.0635V27.652C80.8839 27.6756 80.734 27.6914 80.4738 27.6914C79.63 27.6914 79.2594 27.2971 79.2594 26.3982V21.9821H81.1204V20.5548H79.2594V18.3784H77.564Z"
        fill="black"
      />
      <path
        d="M85.9216 29.2686C88.4293 29.2686 89.967 27.5889 89.967 24.8052C89.967 22.0373 88.4214 20.3498 85.9216 20.3498C83.4139 20.3498 81.8683 22.0373 81.8683 24.8052C81.8683 27.5889 83.4061 29.2686 85.9216 29.2686ZM85.9216 27.7624C84.447 27.7624 83.619 26.682 83.619 24.8052C83.619 22.9442 84.447 21.856 85.9216 21.856C87.3884 21.856 88.2242 22.9442 88.2242 24.8052C88.2242 26.6742 87.3884 27.7624 85.9216 27.7624Z"
        fill="black"
      />
      <path
        d="M91.2826 29.103H92.9781V24.0324C92.9781 22.8259 93.8849 22.0137 95.1703 22.0137C95.47 22.0137 95.9747 22.0689 96.1166 22.1162V20.4444C95.9352 20.3971 95.6119 20.3734 95.3596 20.3734C94.2398 20.3734 93.2856 20.9885 93.0412 21.8323H92.915V20.5154H91.2826V29.103Z"
        fill="black"
      />
      <path
        d="M100.326 21.7929C101.58 21.7929 102.4 22.6682 102.44 24.0167H98.1025C98.1972 22.6761 99.0725 21.7929 100.326 21.7929ZM102.432 26.7846C102.116 27.4548 101.415 27.8255 100.389 27.8255C99.0331 27.8255 98.1578 26.8713 98.1025 25.3651V25.2705H104.167V24.6791C104.167 21.9821 102.724 20.3498 100.334 20.3498C97.9133 20.3498 96.3756 22.0925 96.3756 24.8368C96.3756 27.581 97.8818 29.2686 100.342 29.2686C102.306 29.2686 103.678 28.3223 104.064 26.7846H102.432Z"
        fill="black"
      />
      <path
        d="M66.4456 25.9317C66.5763 28.0404 68.3336 29.389 70.9491 29.389C73.7444 29.389 75.4935 27.975 75.4935 25.7192C75.4935 23.9456 74.4963 22.9648 72.077 22.4008L70.7774 22.0821C69.2409 21.7224 68.6197 21.2402 68.6197 20.3984C68.6197 19.3358 69.5841 18.6411 71.0308 18.6411C72.4039 18.6411 73.352 19.3195 73.5237 20.4065H75.3055C75.1992 18.4204 73.4501 17.0228 71.0553 17.0228C68.4807 17.0228 66.7643 18.4204 66.7643 20.521C66.7643 22.2537 67.737 23.2835 69.8702 23.7821L71.3904 24.1499C72.9515 24.5177 73.6381 25.0572 73.6381 25.9562C73.6381 27.0024 72.5592 27.7625 71.088 27.7625C69.5106 27.7625 68.4153 27.0515 68.2601 25.9317H66.4456Z"
        fill="black"
      />
      <path
        d="M48.8826 20.3734C47.7155 20.3734 46.7061 20.957 46.1857 21.9348H46.0595V20.5154H44.4272V31.9576H46.1226V27.8018H46.2567C46.7061 28.7087 47.6761 29.2449 48.8984 29.2449C51.067 29.2449 52.447 27.5337 52.447 24.8052C52.447 22.0768 51.067 20.3734 48.8826 20.3734ZM48.4016 27.723C46.9821 27.723 46.0911 26.6032 46.0911 24.8131C46.0911 23.0152 46.9821 21.8954 48.4095 21.8954C49.8447 21.8954 50.7042 22.9915 50.7042 24.8052C50.7042 26.6268 49.8447 27.723 48.4016 27.723Z"
        fill="black"
      />
      <path
        d="M58.3796 20.3734C57.2125 20.3734 56.2031 20.957 55.6826 21.9348H55.5565V20.5154H53.9241V31.9576H55.6196V27.8018H55.7536C56.2031 28.7087 57.173 29.2449 58.3953 29.2449C60.5639 29.2449 61.9439 27.5337 61.9439 24.8052C61.9439 22.0768 60.5639 20.3734 58.3796 20.3734ZM57.8985 27.723C56.4791 27.723 55.588 26.6032 55.588 24.8131C55.588 23.0152 56.4791 21.8954 57.9064 21.8954C59.3416 21.8954 60.2012 22.9915 60.2012 24.8052C60.2012 26.6268 59.3416 27.723 57.8985 27.723Z"
        fill="black"
      />
      <path
        d="M41.3853 29.103H43.3305L39.0722 17.3088H37.1024L32.8441 29.103H34.724L35.811 25.9726H40.3064L41.3853 29.103ZM37.9933 19.4503H38.1323L39.8405 24.436H36.2769L37.9933 19.4503Z"
        fill="black"
      />
      <path
        d="M33.983 8.41286V14.1024H36.0373C37.7327 14.1024 38.7145 13.0576 38.7145 11.2399C38.7145 9.44984 37.7248 8.41286 36.0373 8.41286H33.983ZM34.8662 9.21721H35.9387C37.1176 9.21721 37.8155 9.96635 37.8155 11.2517C37.8155 12.5568 37.1294 13.2981 35.9387 13.2981H34.8662V9.21721Z"
        fill="black"
      />
      <path
        d="M41.7213 14.1852C42.9751 14.1852 43.744 13.3454 43.744 11.9536C43.744 10.5696 42.9712 9.72584 41.7213 9.72584C40.4675 9.72584 39.6947 10.5696 39.6947 11.9536C39.6947 13.3454 40.4635 14.1852 41.7213 14.1852ZM41.7213 13.4321C40.984 13.4321 40.57 12.892 40.57 11.9536C40.57 11.023 40.984 10.4789 41.7213 10.4789C42.4547 10.4789 42.8726 11.023 42.8726 11.9536C42.8726 12.888 42.4547 13.4321 41.7213 13.4321Z"
        fill="black"
      />
      <path
        d="M50.2915 9.80864H49.4438L48.6789 13.0852H48.6119L47.7287 9.80864H46.9164L46.0332 13.0852H45.9701L45.2013 9.80864H44.3417L45.5246 14.1024H46.396L47.2792 10.9402H47.3462L48.2334 14.1024H49.1126L50.2915 9.80864Z"
        fill="black"
      />
      <path
        d="M51.2678 14.1024H52.1155V11.5908C52.1155 10.9205 52.5137 10.5026 53.1407 10.5026C53.7676 10.5026 54.0672 10.8456 54.0672 11.5356V14.1024H54.9149V11.3227C54.9149 10.3015 54.3866 9.72584 53.4285 9.72584C52.7819 9.72584 52.356 10.0137 52.147 10.4908H52.084V9.80864H51.2678V14.1024Z"
        fill="black"
      />
      <path d="M56.25 14.1024H57.0977V8.13292H56.25V14.1024Z" fill="black" />
      <path
        d="M60.2859 14.1852C61.5398 14.1852 62.3086 13.3454 62.3086 11.9536C62.3086 10.5696 61.5358 9.72584 60.2859 9.72584C59.0321 9.72584 58.2593 10.5696 58.2593 11.9536C58.2593 13.3454 59.0281 14.1852 60.2859 14.1852ZM60.2859 13.4321C59.5486 13.4321 59.1346 12.892 59.1346 11.9536C59.1346 11.023 59.5486 10.4789 60.2859 10.4789C61.0193 10.4789 61.4372 11.023 61.4372 11.9536C61.4372 12.888 61.0193 13.4321 60.2859 13.4321Z"
        fill="black"
      />
      <path
        d="M64.8344 13.4597C64.3731 13.4597 64.038 13.235 64.038 12.8486C64.038 12.4701 64.3061 12.269 64.8975 12.2296L65.9463 12.1625V12.5213C65.9463 13.0536 65.4732 13.4597 64.8344 13.4597ZM64.6176 14.1734C65.1814 14.1734 65.6506 13.9289 65.9069 13.4992H65.9739V14.1024H66.7901V11.1689C66.7901 10.2621 66.1829 9.72584 65.1065 9.72584C64.1326 9.72584 63.4386 10.199 63.3519 10.9363H64.172C64.2666 10.6327 64.5939 10.4592 65.067 10.4592C65.6467 10.4592 65.9463 10.7155 65.9463 11.1689V11.5396L64.7832 11.6066C63.762 11.6697 63.1863 12.1152 63.1863 12.888C63.1863 13.6727 63.7896 14.1734 64.6176 14.1734Z"
        fill="black"
      />
      <path
        d="M69.6668 14.1734C70.2582 14.1734 70.759 13.8935 71.0153 13.4243H71.0823V14.1024H71.8945V8.13292H71.0468V10.4908H70.9837C70.7511 10.0176 70.2543 9.73767 69.6668 9.73767C68.5825 9.73767 67.8846 10.5972 67.8846 11.9536C67.8846 13.3139 68.5746 14.1734 69.6668 14.1734ZM69.9073 10.4986C70.617 10.4986 71.0626 11.0625 71.0626 11.9575C71.0626 12.8565 70.621 13.4124 69.9073 13.4124C69.1897 13.4124 68.7599 12.8644 68.7599 11.9536C68.7599 11.0506 69.1937 10.4986 69.9073 10.4986Z"
        fill="black"
      />
      <path
        d="M77.3917 14.1852C78.6455 14.1852 79.4144 13.3454 79.4144 11.9536C79.4144 10.5696 78.6416 9.72584 77.3917 9.72584C76.1378 9.72584 75.365 10.5696 75.365 11.9536C75.365 13.3454 76.1339 14.1852 77.3917 14.1852ZM77.3917 13.4321C76.6544 13.4321 76.2404 12.892 76.2404 11.9536C76.2404 11.023 76.6544 10.4789 77.3917 10.4789C78.125 10.4789 78.543 11.023 78.543 11.9536C78.543 12.888 78.125 13.4321 77.3917 13.4321Z"
        fill="black"
      />
      <path
        d="M80.5365 14.1024H81.3842V11.5908C81.3842 10.9205 81.7825 10.5026 82.4094 10.5026C83.0363 10.5026 83.3359 10.8456 83.3359 11.5356V14.1024H84.1837V11.3227C84.1837 10.3015 83.6553 9.72584 82.6972 9.72584C82.0506 9.72584 81.6247 10.0137 81.4158 10.4908H81.3527V9.80864H80.5365V14.1024Z"
        fill="black"
      />
      <path
        d="M88.0879 8.74012V9.82835H87.4058V10.542H88.0879V12.9669C88.0879 13.7949 88.4625 14.1261 89.4048 14.1261C89.5704 14.1261 89.7281 14.1064 89.8661 14.0827V13.3769C89.7478 13.3888 89.6729 13.3967 89.5428 13.3967C89.1209 13.3967 88.9356 13.1995 88.9356 12.75V10.542H89.8661V9.82835H88.9356V8.74012H88.0879Z"
        fill="black"
      />
      <path
        d="M91.004 14.1024H91.8518V11.5948C91.8518 10.9442 92.2382 10.5065 92.9321 10.5065C93.5314 10.5065 93.8508 10.8535 93.8508 11.5396V14.1024H94.6985V11.3306C94.6985 10.3094 94.1347 9.72978 93.2199 9.72978C92.5733 9.72978 92.1199 10.0176 91.9109 10.4986H91.8439V8.13292H91.004V14.1024Z"
        fill="black"
      />
      <path
        d="M97.7566 10.4474C98.3835 10.4474 98.7936 10.885 98.8133 11.5593H96.6447C96.692 10.889 97.1297 10.4474 97.7566 10.4474ZM98.8093 12.9432C98.6516 13.2784 98.3007 13.4637 97.7881 13.4637C97.11 13.4637 96.6723 12.9866 96.6447 12.2335V12.1862H99.6768V11.8905C99.6768 10.542 98.9552 9.72584 97.7605 9.72584C96.5501 9.72584 95.7812 10.5972 95.7812 11.9693C95.7812 13.3415 96.5343 14.1852 97.7645 14.1852C98.7463 14.1852 99.4323 13.7121 99.6255 12.9432H98.8093Z"
        fill="black"
      />
      <path
        d="M23.645 19.4233C23.6655 17.8263 24.5232 16.3152 25.8837 15.4788C25.0254 14.253 23.5878 13.4758 22.0921 13.429C20.4968 13.2616 18.9503 14.3836 18.1373 14.3836C17.3087 14.3836 16.0571 13.4456 14.7093 13.4734C12.9525 13.5301 11.3147 14.5289 10.46 16.0648C8.6227 19.2458 9.99316 23.9208 11.7531 26.4921C12.6337 27.7512 13.6628 29.1576 15.0094 29.1077C16.3271 29.0531 16.8192 28.2675 18.4098 28.2675C19.9856 28.2675 20.4474 29.1077 21.8213 29.076C23.2352 29.0531 24.1261 27.8114 24.9758 26.5403C25.6084 25.6432 26.0953 24.6517 26.4182 23.6025C24.7566 22.8997 23.6469 21.2274 23.645 19.4233Z"
        fill="black"
      />
      <path
        d="M21.0499 11.738C21.8208 10.8125 22.2007 9.62293 22.1087 8.42191C20.9308 8.54562 19.8428 9.10855 19.0615 9.99854C18.2974 10.8681 17.8998 12.0367 17.975 13.1919C19.1532 13.204 20.3116 12.6563 21.0499 11.738Z"
        fill="black"
      />
    </svg>
  </a>
)

export default AppStoreIcon
