import styled from "styled-components"

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  color: #000000;

  margin: 0;
  margin-bottom: 30px;
`
export default Title
