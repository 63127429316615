import React, { useEffect, createRef, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import PropTypes from "prop-types"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"
import { withFirebase } from "../../../utils/Firebase"
import { appointmentsGetListUrl } from "../../../utils/App"
import { AuthUserContext } from "../../../context/Session"

import { StyleWrapper, EventWrapper } from "./styles"

const renderEventContent = (eventInfo) => (
  <EventWrapper>
    <b>{eventInfo.timeText}</b>
    <i>{eventInfo.event.title}</i>
  </EventWrapper>
)

const Scheduler = ({ firebase }) => {
  const user = useContext(AuthUserContext)
  const [actualEvents, setActualEvents] = useState([])
  const calendarRef = createRef()
  const { t } = useTranslation()
  const { addToast } = useToasts()

  useEffect(() => {
    const getEvents = async () => {
      try {
        const authToken = await firebase.getIdToken()
        const response = await fetch(appointmentsGetListUrl, {
          method: "POST",
          body: JSON.stringify({
            user: user.isImpersonating ? user.impersonatingEmail : user.email,
          }),
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })
        const data = await response.json()
        if (data.pages.current < data.pages.total) {
          // eslint-disable-next-line no-console
          console.log("I need to handle extra pages")
        } else {
          setActualEvents(
            data.events.map((e) => {
              const client = e.attendees.find((a) =>
                a.email !== user.isImpersonating
                  ? user.impersonatingEmail
                  : user.email
              )
              return {
                id: e.event_id,
                title: client.display_name,
                start: new Date(e.start).valueOf(),
                end: new Date(e.end).valueOf(),
              }
            })
          )
        }
      } catch {
        addToast(t("unexpected_error"), {
          appearance: "error",
        })
      }
    }
    if (firebase && user) {
      getEvents()
    }
  }, [firebase, user])

  const handleEventSelect = async (eventData) => {
    eventData.jsEvent.preventDefault()
    // console.log("eventData.event.id", eventData.event.id)
  }

  return (
    <StyleWrapper>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        eventContent={renderEventContent}
        locale="es"
        height="calc(100vh - 100px)"
        allDaySlot={false}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
          hour12: true,
        }}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        events={actualEvents}
        selectMirror
        dayMaxEvents
        editable={false}
        unselectAuto={false}
        eventClick={handleEventSelect}
        buttonText={{
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día",
          list: "Lista",
        }}
        nowIndicator
      />
    </StyleWrapper>
  )
}

Scheduler.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(Scheduler)
