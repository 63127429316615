import styled from "styled-components"

export const CardWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
`

export const ApponitmentType = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 4px;
  background-color: ${(props) => props.bgColor || "white"};
  margin-bottom: 4px;
  padding-right: 45px;
`

export const AptTypeText = styled.span`
  margin-left: 5px;
  font-size: 16px;
  line-height: 18.75px;
  font-weight: 400;
  color: ${(props) => props.color || "black"};
`

export const TimeText = styled.span`
  margin-left: 5px;
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  color: ${(props) => props.color || "black"};
  text-transform: capitalize;
`

export const InfoWrapper = styled.div`
  display: inherit;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 21px;
  padding: 0px 5px;
  margin-bottom: 20px;
  margin-left: 7px;
`

export const PersonalWrapper = styled.div`
  display: inherit;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10px;
`

export const Name = styled.span`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
`

export const QuestionContainer = styled.div`
  overflow: hidden;
  width: 200px;
  height: 30px;
  margin-top: 10px;
`

export const QuestionText = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
`

export const InitialAvatar = styled.div`
  overflow: ${(props) => (props.isFromContacts ? "visible" : "hidden")};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.color.accent};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > span {
    ${(props) => props.theme.font_size.regular};
    color: ${(props) => props.theme.color.white.regular};
  }
`

export const Type = styled.div`
  position: absolute;
  top: 6px;
  right: 10px;
  height: 27px;
  width: 27px;
  background-color: #d69e2e;
  color: black;
  border-radius: 13.5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Actions = styled.div`
  width: 100%;
  height: 40px;
  border-top: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 50px;
`

export const Action = styled.button`
  width: 100%;
  height: 24px;
  border: 1px solid #00000014;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8.5px;
  padding: 10px, 8px;
  color: ${(props) => (props.disabled ? "#00000014" : "rgba(0, 0, 0, 0.64)")};
  font-size: 12px;
  font-weight: 500;
  cursor: ${(props) =>
    props.disabled ? "not-allowed" : props.cursor || "pointer"};
  &:hover {
    color: ${(props) => (props.disabled ? "#00000014" : "#FFF")};
    background: ${(props) => (props.disabled ? "#00000014" : "#38a169")};
  }
`
