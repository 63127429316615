import React, { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { FlagUs, FlagEsp } from "../Common"

const LanguageSelector = ({ isFromLogin }) => {
  const { i18n } = useTranslation()
  const [optionsVisible, setOptionsVisible] = useState(false)
  const [currentCode, setCurrentCode] = useState({
    flag: i18n.language.includes("en") ? "us" : "cr",
    code: i18n.language,
  })

  const onLanguageSelect = (value) => {
    setOptionsVisible(false)
    setCurrentCode(value)
    i18n.changeLanguage(value.code)
  }

  const getFlag = (code, isLanguage) => {
    let flags
    if (isLanguage) {
      flags = {
        en: <FlagUs />,
        es: <FlagEsp />,
      }
    } else {
      flags = {
        cr: <FlagEsp />,
        us: <FlagUs />,
      }
    }
    return flags[code]
  }

  const getLangCode = () => {
    if (currentCode && currentCode.code) {
      const code = currentCode.code.split("-")[0]
      return code.toUpperCase()
    }
    return null
  }

  return (
    <Selector
      isFromLogin={isFromLogin}
      onClick={() => setOptionsVisible(!optionsVisible)}
    >
      {isFromLogin && <p>{getLangCode()}</p>}
      <Current>{getFlag(currentCode.flag)}</Current>
      <Options isVisible={optionsVisible} isFromLogin={isFromLogin}>
        {[
          { code: "es", flag: "cr", value: "Costa Rica" },
          { code: "en", flag: "us", value: "USA" },
        ].map((code) => (
          <button
            key={code.flag}
            type="button"
            onClick={() => onLanguageSelect(code)}
          >
            {getFlag(code.flag)}
          </button>
        ))}
      </Options>
    </Selector>
  )
}

const Selector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${(props) => (props.isFromLogin ? "93px" : "100%")};
  height: ${(props) => (props.isFromLogin ? "48px" : "100%")};
  padding: ${(props) => (props.isFromLogin ? "12px" : "0")};
  border-radius: ${(props) => (props.isFromLogin ? "22px" : "0")};
  border: 1px solid white;
  position: relative;
  cursor: pointer;
  background-color: white;
`

const Current = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Options = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 30px;
  top: ${(props) => (props.isFromLogin ? "50px" : "-4px")};
  left: ${(props) => (props.isFromLogin ? "10px" : "30px")};
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  z-index: ${(props) => (props.isVisible ? "1" : "-1")};
  & > button {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    &:hover {
      transform: scale(1.4);
      transition: all 700ms;
    }
    svg {
      width: 25px;
      height: 25px;
      opacity: ${(props) => (props.isVisible ? "1" : "0")};
      transition: opacity 800ms ease-in-out;
    }
  }
`

export default LanguageSelector
