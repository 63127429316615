import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"

import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  // useToast,
  Table as ChakraTable,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
// Utils
import { honeybadger, deleteTokenUrl } from "../../../utils/App"

import TableSkeleton from "../TableSkeleton"

import AmexImg from "../../../static/images/cards/amex.svg"
import VisaImg from "../../../static/images/cards/visa.svg"
import MasterCardImg from "../../../static/images/cards/masterCard.svg"
import DinnersImg from "../../../static/images/cards/diners-club.png"
import DiscoverImg from "../../../static/images/cards/discover.png"
import JcbImg from "../../../static/images/cards/jcb.png"
import DefaultCardImg from "../../../static/images/cards/generic-card.png"

const CardContainer = styled.div`
  min-width: 265px;
  padding: 22px;
  height: 150px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 22px;
`

const CardHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  & > span {
    margin-left: 10px;
  }
`

const CardHolder = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  margin: 4px 0px;
`

const CardExpiration = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  margin: 4px 0px;
`

const CreditCardsTable = ({
  headers,
  tokens,
  isLoading = false,
  onRefreshTokens,
  user,
  firebase,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [crediCardTokens, setCrediCardTokens] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (tokens) {
      setCrediCardTokens(tokens)
    }
  }, [tokens])

  const deleteToken = async (tokenId, showMessage = true) => {
    try {
      const authToken = await firebase.getIdToken()
      const createPayload = {
        client: {
          uid: user?.medicProfile?.uid,
          email: user?.medicProfile?.email,
          userId: user?.medicProfile?.stripeUserId,
        },
        isOrg: false,
        tokenId,
      }
      const deletedCard = await fetch(deleteTokenUrl, {
        method: "POST",
        body: JSON.stringify(createPayload),
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      if (deletedCard.ok) {
        const parsedCard = await deletedCard.json()
        if (parsedCard.status === "deleted") {
          if (showMessage) {
            addToast("Tarjeta eliminada exitosamente!", {
              appearance: "success",
            })
          }
        }
      }
    } catch (error) {
      addToast(t("unexpected_error"), { appearance: "error" })
      honeybadger.notify("Register - CreditCardsTable component", {
        message: "Error on delete stripe card",
        action: "submit",
        context: {
          error,
        },
      })
    }
  }

  const getLogoPath = (cardBrand) => {
    let logo = null
    // Card brand. Can be American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown.
    switch (cardBrand) {
      case "MasterCard":
        logo = MasterCardImg
        break
      case "Visa":
        logo = VisaImg
        break
      case "American Express":
        logo = AmexImg
        break
      case "Diners Club":
        logo = DinnersImg
        break
      case "Discover":
        logo = DiscoverImg
        break
      case "JCB":
        logo = JcbImg
        break
      default:
        logo = DefaultCardImg
        break
    }
    return logo
  }

  return (
    <>
      <TableContainer
        mx={4}
        mt={6}
        overflowY="auto"
        maxH={{ base: "150px", md: "90%" }}
      >
        <ChakraTable variant="simple" border="1px" borderColor="gray.200">
          <TableCaption>{`${crediCardTokens.length} Token${
            crediCardTokens.length > 1 ? "es" : ""
          }`}</TableCaption>
          <Thead bg="gray.50" position="sticky">
            <Tr>
              {headers.map((header) => (
                <Th key={header.title}>
                  <Stack direction="row" align="center">
                    <Flex
                      w={2}
                      h={2}
                      align="center"
                      justify="center"
                      rounded="full"
                    >
                      {header.icon && <Icon as={header.icon} />}
                    </Flex>
                    <Text fontWeight="bold" fontSize="md">
                      {header.title.toUpperCase()}
                    </Text>
                  </Stack>
                </Th>
              ))}
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td colSpan={6}>
                  <TableSkeleton />
                </Td>
              </Tr>
            ) : (
              <>
                {crediCardTokens?.map((token) => (
                  <Tr key={token.id}>
                    <Td>
                      <CardContainer>
                        <CardHeader>
                          <img
                            alt={token.cardBrand}
                            src={getLogoPath(token.cardBrand)}
                          />
                          <span>{`**** ${token.cardLast4}`}</span>
                        </CardHeader>
                        <CardHolder>{token.cardHolder}</CardHolder>
                        <CardExpiration>{token.cardExpiration}</CardExpiration>
                      </CardContainer>
                    </Td>
                    <Td>{new Date(token.createdAt).toLocaleString("es-ES")}</Td>
                    <Td>
                      <Button
                        variant="ghost"
                        disabled={
                          crediCardTokens && crediCardTokens.length === 1
                        }
                        onClick={async () => {
                          onOpen()
                          await deleteToken(token?.id)
                          if (onRefreshTokens) onRefreshTokens()
                          onClose()
                        }}
                      >
                        {t("web_client.delete")}
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </ChakraTable>
      </TableContainer>

      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("deleting_card")}</ModalHeader>
          <ModalBody>
            <TableSkeleton />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreditCardsTable
