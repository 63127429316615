import styled from "styled-components"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"
import { InitialAvatar } from "../../atoms/Card/styles"

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.color.paleGrey};
  border-bottom: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
  position: relative;
  & > div {
    display: inherit;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 15px;
    margin-left: 13px;
    & > ${InitialAvatar} {
      margin-right: 10px;
    }
  }
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-left: 10px;
`

export const ModalWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalOptions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 600px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  & > section {
    width: 100%;
    padding: 25px;
    p {
      text-align: center;
      ${(props) => props.theme.font_size.small};
    }
    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        border: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
        border-radius: 5px;
        width: 125px;
        height: 45px;
        margin: 0 8px;
        &:hover {
          color: ${(props) => props.theme.color.white.regular};
          background-color: ${(props) => props.theme.color.black.light};
          font-weight: bold;
        }
      }
    }
  }
`

export const TextAreaWrapper = styled.div`
  position: relative;
  & > span {
    pointer-events: none;
    position: absolute;
    left: 50px;
    top: 4px;
    ${(props) => props.theme.font_size.xxxsmall};
    line-height: 1.33;
    color: ${(props) => props.theme.color.darkPurple};
  }

  & > textarea {
    padding-left: 50px;
    padding-top: 16px;
    line-height: 1.5rem;
  }
`

export const TextArea = styled.textarea`
  outline: none;
  border: none;
  display: block;
  resize: none;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  line-height: 1.5;
  padding: 10px;
  margin-bottom: 15px;
  background: ${(props) =>
    props.disabled
      ? props.theme.color.background.gray
      : props.theme.color.background.gray30};
  color: ${(props) =>
    props.disabled ? "#999" : props.theme.color.background.black80};
  min-height: 80px;
  width: 100%;
  border: none;
  border-radius: 4px;
`

export const DiscountsWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  button {
    width: ${(props) => (props.isCancel ? "200px" : "105px")};
    min-width: ${(props) => (props.isCancel ? "200px" : "105px")};
    margin: 5;
  }
`

export const Discount = styled.div`
  cursor: pointer;
  height: 44px;
  padding: 10px;
  border: solid 1px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: ${(props) => props.theme.color.paleGrey};
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 250ms ease-in-out;
  &:hover {
    transform: scale3d(1.1, 1.1, 1.1);
    font-weight: bold;
  }
`

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  > a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.secondary};
  }
`

export const ContactLink = styled.div`
  border-bottom: ${({ theme }) => `1px solid  ${theme.color.secondary}`};
  padding-bottom: 2px;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform-style: preserve3d;
    transform: scale3d(1.05, 1.05, 1.05);
  }
`
