import styled from "styled-components"

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: ${(props) => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${(props) => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    max-width: 1200px;
  }

  ${(props) => props.fluid && "max-width: 1200px !important;"};
`

export const Section = styled.section`
  padding: 80px 0;
  overflow: hidden;
  background-color: ${(props) => {
    switch (props.accent) {
      case "secondary":
        return props.theme.color.white.dark
      case "main":
        return props.theme.color.primary
      default:
        return "white"
    }
  }};

  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding: 80px 0;
  }

  ${(props) =>
    props.accent &&
    `background-color: ${
      props.accent === "secondary"
        ? props.theme.color.white.dark
        : props.theme.color.primary
    }`};
`

export const TitleContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  padding: 20px 0 80px 0;
  position: relative;
  min-height: 340px;
`
export const SectionTitle = styled.h1`
  ${(props) => props.theme.font_size.xsmall};
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

export const Subtitle = styled.h3`
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

export const LinkButton = styled.button`
  padding: 5px 10px;
  a {
    font-family: ${(props) => props.theme.font.regular};
    ${(props) => props.theme.font_size.xxsmall};
    text-decoration: none;
    opacity: 0.9;
    color: ${(props) => props.theme.color.white.regular};
  }
`

export const SectionsDoubleContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 490px 1fr;
  grid-template-areas: "toolbar container";
  height: 100vh;
  &:after {
    display: none;
  }
`

export const SectionsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "container";
  &:after {
    display: none;
  }
`

export const Toolbar = styled.div`
  height: 100vh;
  grid-area: toolbar;
  padding-top: 49px;
  overflow-y: scroll;
`

export const GridContainer = styled.div`
  height: 100vh;
  overflow: auto;
  grid-area: container;
  padding-top: ${(props) => props.pt || "49px"};
  padding-left: ${(props) => props.pl || "60px"};
`
