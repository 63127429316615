import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import get from "lodash.get"
import capitalize from "lodash.capitalize"
import isArray from "lodash.isarray"
import { useTranslation } from "react-i18next"

// Components
import UploadButton from "../../../../atoms/UploadButton"
import Input from "../../../../atoms/Input"

import { TextArea } from "../../../../atoms/Forms"

// Styles
import {
  Section,
  StyledForm,
  StyledText,
  AvatarContainer,
  Avatar,
  Thumb,
  Image,
  SignButtonsContainer,
  SignButtonsLabel,
  SignButton,
  TextAreaWrapper,
  AllCenter,
  Loader,
} from "../../styles"
import Theme from "../../../../../styles/theme"

const customStyles = {
  control: (provided, selectState) => ({
    ...provided,
    width: selectState.selectProps.width,
    borderRadius: selectState.selectProps.borderRadius,
    borderColor: selectState.selectProps.showError
      ? Theme.color.alert
      : Theme.color.iceBlue,
    marginBottom: 25,
  }),
  singleValue: (provided, selectState) => {
    const opacity = selectState.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
}

export const Academic = ({
  selectedLanguagesState,
  isLicenseReadOnly,
  formValues,
  academicFormValues,
  propertiesMissing,
  isLoadedImage,
  setIsLoadedImage,
  imageURL,
  setIsModalOpen,
  handleReadFile,
  onChangePersonalView,
  onChangeView,
  handleAcademicInputChange,
  setSelectedLanguages,
  firebase,
  setWithChanges,
  isActive,
}) => {
  const { t, i18n } = useTranslation()
  const lang = get(i18n, "language", "es").split("-")

  const [titles, setTitles] = useState([])
  const [specialities, setSpecialities] = useState([])
  const [bureaus, setBureaus] = useState([])

  useEffect(() => {
    const getSpecialities = async () => {
      try {
        const titleOptions = await firebase.getFormOptions("titles")
        const specs = await firebase.getFormOptions("specialities")
        const specsData = specs.data()
        const titleOptionsData = titleOptions.data()
        setTitles(
          get(titleOptionsData, "mixed", []).map((title) => ({
            value: lang[0] === "en" ? title?.es : title?.en,
            label: capitalize(lang[0] === "en" ? title?.en : title?.es),
          }))
        )
        setSpecialities(
          get(specsData, "mixed", []).map((s) => ({
            value: s?.en,
            label: capitalize(lang[0] === "en" ? s?.en : s?.es),
          }))
        )
      } catch (e) {
        window.Honeybadger?.notify(e)
      }
    }
    if (firebase) {
      getSpecialities()
    }
  }, [firebase, i18n.language])

  useEffect(() => {
    const getBureaus = async () => {
      const currentCountry =
        get(formValues, "countryBusiness") || formValues?.country

      if (currentCountry) {
        const selectOptionsBureaus = []
        const bureausOptions = await firebase.getBureausByCountry(
          currentCountry
        )
        bureausOptions?.data()?.options.forEach((doc) => {
          selectOptionsBureaus.push({ value: doc, label: doc })
        })
        setBureaus(selectOptionsBureaus)
      }
    }
    if (firebase) {
      getBureaus()
    }
  }, [firebase])

  const languagesOptions = [
    { value: "german", label: t("languages.german") },
    { value: "spanish", label: t("languages.spanish") },
    { value: "french", label: t("languages.french") },
    { value: "english", label: t("languages.english") },
    { value: "italian", label: t("languages.italian") },
    { value: "portuguese", label: t("languages.portuguese") },
  ]

  const enLanguagesOptions = [
    { value: "english", label: t("languages.english") },
    { value: "french", label: t("languages.french") },
    { value: "german", label: t("languages.german") },
    { value: "italian", label: t("languages.italian") },
    { value: "portuguese", label: t("languages.portuguese") },
    { value: "spanish", label: t("languages.spanish") },
  ]

  const languagesData = lang[0] === "en" ? enLanguagesOptions : languagesOptions

  const getTitleDefaultValue =
    titles.find((title) => title?.label === get(academicFormValues, "title")) ||
    titles.find((title) => title?.value === get(academicFormValues, "title"))

  return (
    <Section>
      <AvatarContainer>
        <Avatar
          isLoaded={isLoadedImage.sign}
          showError={get(propertiesMissing, "personalSign", false)}
        >
          <Thumb isLoaded={isLoadedImage.sign}>
            <span>{t("web_client.signature")}</span>
          </Thumb>
          <Image
            onLoad={() => {
              setIsLoadedImage((currentValues) => ({
                ...currentValues,
                sign: true,
              }))
            }}
            alt={formValues.displayName}
            src={get(formValues, "personalSign", imageURL)}
            isLoaded={isLoadedImage.sign}
            isSign
          />
        </Avatar>
        <SignButtonsContainer>
          <SignButton onClick={() => setIsModalOpen(true)}>
            {t("web_client.signature_draw_button")}
          </SignButton>
          <SignButtonsLabel>
            {t("web_client.signature_local_or_label")}
          </SignButtonsLabel>
          <UploadButton onChange={handleReadFile}>
            {t("web_client.signature_local_file_button")}
          </UploadButton>
        </SignButtonsContainer>
      </AvatarContainer>
      {titles?.length > 0 && bureaus?.length > 0 && specialities?.length > 0 ? (
        <StyledForm>
          <Select
            styles={customStyles}
            defaultValue={getTitleDefaultValue}
            label={t("q1_academic_title_label")}
            options={titles}
            onChange={(option) => {
              onChangeView(option, "title")
              onChangePersonalView(option, "title")
            }}
            placeholder={t("q1_academic_title_label")}
            width="100%"
            borderRadius="25px"
            showError={get(propertiesMissing, "title", false)}
          />
          <Select
            styles={customStyles}
            defaultValue={specialities.find(
              (s) => s?.value === get(academicFormValues, "profession")
            )}
            label={t("q2_academic_profession_label")}
            options={specialities}
            onChange={(option) => onChangeView(option, "profession")}
            placeholder={t("q2_academic_profession_label")}
            width="100%"
            borderRadius="25px"
            isDisabled={isActive}
          />
          <Select
            styles={customStyles}
            defaultValue={bureaus.find(
              (b) => b.value === get(academicFormValues, "bureau")
            )}
            label={t("q3_academic_bureau_label")}
            options={bureaus}
            onChange={(option) => onChangeView(option, "bureau")}
            placeholder={t("q3_academic_bureau_label")}
            width="100%"
            borderRadius="25px"
            isDisabled={isActive}
          />
          <StyledText>
            {t("web_client.professional_languages_label")}
          </StyledText>
          <Select
            styles={customStyles}
            defaultValue={
              get(formValues, "languages") &&
              get(formValues, "languages").map((language) => ({
                value:
                  languagesData.find((langu) => langu.value === language)
                    .value || language,
                label:
                  languagesData.find((langu) => langu.value === language)
                    .label || language,
              }))
            }
            options={languagesData.filter(
              (language) =>
                !selectedLanguagesState.includes(get(language, "value"))
            )}
            onChange={(values) => {
              if (isArray(values)) {
                const selectedLanguages = []
                values.forEach((language) =>
                  selectedLanguages.push(language.value)
                )
                setSelectedLanguages(selectedLanguages)
                setWithChanges(true)
              }
            }}
            placeholder={t("web_client.register_select_languages")}
            width="100%"
            borderRadius="25px"
            isMulti
            showError={selectedLanguagesState.length <= 0}
          />
          <Input
            icon="id"
            value={get(academicFormValues, "license")}
            onChange={handleAcademicInputChange}
            labelName={t("q4_academic_license_label")}
            type="text"
            name="license"
            showError={get(propertiesMissing, "license", false)}
            readonly={isLicenseReadOnly || isActive}
            required
          />
          <Input
            icon="university"
            value={get(academicFormValues, "university")}
            onChange={handleAcademicInputChange}
            labelName={t("q5_academic_university_label")}
            type="text"
            name="university"
            showError={get(propertiesMissing, "university", false)}
            required
          />
          <TextAreaWrapper>
            <span>{t("q8_academic_biography_label")}</span>
            <TextArea
              value={get(academicFormValues, "biography")}
              onChange={handleAcademicInputChange}
              name="biography"
              id="biography"
              showError={get(propertiesMissing, "biography", false)}
            />
          </TextAreaWrapper>
          <TextAreaWrapper>
            <span>{t("additional_information")}</span>
            <TextArea
              value={get(academicFormValues, "other")}
              onChange={handleAcademicInputChange}
              name="other"
              id="other"
              showError={get(propertiesMissing, "other", false)}
            />
          </TextAreaWrapper>
        </StyledForm>
      ) : (
        <AllCenter>
          <Loader />
        </AllCenter>
      )}
    </Section>
  )
}

Academic.defaultProps = {
  imageURL: "",
}

Academic.propTypes = {
  imageURL: PropTypes.string,
  selectedLanguagesState: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLicenseReadOnly: PropTypes.bool.isRequired,
  formValues: PropTypes.shape().isRequired,
  academicFormValues: PropTypes.shape().isRequired,
  propertiesMissing: PropTypes.shape().isRequired,
  isLoadedImage: PropTypes.shape().isRequired,
  setIsLoadedImage: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  handleReadFile: PropTypes.func.isRequired,
  onChangePersonalView: PropTypes.func.isRequired,
  onChangeView: PropTypes.func.isRequired,
  handleAcademicInputChange: PropTypes.func.isRequired,
  setSelectedLanguages: PropTypes.func.isRequired,
  firebase: PropTypes.shape().isRequired,
  setWithChanges: PropTypes.func.isRequired,
}

export default Academic
