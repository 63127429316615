import styled from "styled-components"
import ButtonWithLoader from "../../../../atoms/ButtonWithLoader"

export const StyledForm = styled.form`
  margin: 0 40px;
  height: calc(100vh - 140px);
  width: 300px;
  overflow: auto;
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-top: 12px;
`

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & > div {
    width: 130px;
  }
`
