import styled from "styled-components"

export const Container = styled.div`
  height: 100%;
  width: 100%;
`

export const SectionTitle = styled.section`
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DetailContainer = styled.section`
  height: calc(100vh - 110px);
  overflow: auto;
`

export const Title = styled.p`
  margin: 0;
  padding-left: ${(props) => (props.withPadding ? "33px" : "0")};
  ${(props) => props.theme.font_size[props.size]};
  color: ${(props) =>
    props.isSub ? props.theme.color.gray["800"] : "inherit"};
`

export const LeftSection = styled.div`
  flex: 1;
`

export const RightSection = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`
