// Theme

const theme = {
  font: {
    primary: "Circular Std Book",
    secondary: "Circular Std Medium",
    light: "Circular Std Light",
    bold: "Circular Std Bold",
    extrabold: "Circular Std Black",
  },
  font_size: {
    xxxsmall: "font-size: 12px;",
    xxsmall: "font-size: 0.875rem;",
    xsmall: "font-size: 1rem;",
    small: "font-size: 1.125rem;",
    regular: "font-size: 1.375rem; line-height: 1.875rem;",
    large: "font-size: 1.875rem; line-height: normal;",
    larger: "font-size: 2.188rem; line-height: 2.813rem;",
    xlarge: "font-size: 3rem; line-height: 3rem;",
  },

  color: {
    primary: "#071435",
    secondary: "#4d66f5",
    palePuple: "#a19bc3",
    purple: "#bb86fc",
    darkPurple: "#6200ee",
    iceBlue: "#eef2f6",
    lightBlueResting: "#c2f1ff",
    accent: "#444444",
    accentV3: "#eaeaea",
    background: {
      white: "#ffffff",
      light: "#eaecf1",
      lightGray: "#f2f2f2",
      black80: "rgba(0,0,0,0.87)",
      gray30: "rgba(237, 237, 237, 0.3)",
      gray100: "#EDF2F7",
    },
    white: {
      regular: "#ffffff",
      lessdark: "#faf9f8",
      dark: "#f6f6f6",
      darker: "#eeeeee",
    },
    black: {
      lightest: "#ABA8AF",
      light: "#564F62",
      regular: "#071435",
      normal: "#000",
      medium: "rgba(0,0,0,0.6)",
      semi: "rgba(0,0,0,0.38)",
      twelve: "rgba(0,0,0,0.12)",
    },
    active: "#0abf63",
    ligthGreen10: "rgba(0,206,129, 0.10)",
    paleGrey: "#fafcfe",
    veryLightGrey: "#c6c7cb",
    cream: "#f8f8f8",
    alert: "#ff0000",
    gray: {
      200: "#E2E8F0",
      600: "#4A5568",
      500: "#718096",
      700: "#2D3748",
      800: "#4A5568",
      900: "#171923",
    },
    agenda: {
      unavailable: "rgba(77, 102, 245, 0.1)",
    },
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
    xlg: "1600px",
  },
}

export default theme
