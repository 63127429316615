import React, { useEffect, useContext, useState } from "react"
import * as CronofyElements from "cronofy-elements"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import get from "lodash.get"
import join from "lodash.join"
import flattenDeep from "lodash.flattendeep"
import isArray from "lodash.isarray"
import isString from "lodash.isstring"
import Loading from "../Loading"
import { withFirebase } from "../../../utils/Firebase"
import { AuthUserContext } from "../../../context/Session"
import constants from "../../../utils/constants"
import { cronofyAccessTokenUrl } from "../../../utils/App"

const CronofyWrapper = ({
  children,
  cronofyElement,
  cronofyTargetId,
  cronofyOptions,
  cronofyPermissions,
  withOptions,
  firebase,
  tmpCronofySub,
  isCalendar,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const user = useContext(AuthUserContext)
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState(null)
  const [profile, setProfile] = useState(null)
  const userType = get(user, ["medicProfile", "userType"])

  function parseError(err) {
    let errorText = t("unexpected_error")
    if (isArray(err)) {
      errorText = t("unexpected_error_with_msg", {
        error: join(flattenDeep(err), " "),
      })
    }
    if (isString(err)) {
      errorText = t("unexpected_error_with_msg", {
        error: err,
      })
    }
    addToast(errorText, { appearance: "error" })
  }

  useEffect(() => {
    const getData = async () => {
      const getProfile = async () => {
        if (userType === "provider") {
          return get(user, ["medicProfile"])
        }
        const local = await firebase.getProfile({
          email: user.impersonatingEmail,
        })
        return local.data()
      }
      const Profile = await getProfile()
      setProfile(Profile)
    }
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      getData()
    }
  }, [user, userType])

  useEffect(() => {
    const getToken = async () => {
      try {
        const idToken = await firebase.getIdToken()
        const cronofySub = get(profile, ["cronofySub"]) || tmpCronofySub
        if (cronofySub) {
          const response = await fetch(cronofyAccessTokenUrl, {
            method: "POST",
            body: JSON.stringify({
              redirectUrl: constants.cronofy.redirectURL,
              sub: cronofySub,
              permissions: JSON.stringify(cronofyPermissions),
            }),
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          })
          if (response.ok) {
            const data = await response.json()
            const tmpToken = get(data, "token", null)
            setLoading(false)
            setToken(tmpToken)
          }
        }
      } catch (e) {
        parseError(e)
      }
    }
    if (firebase && profile) {
      getToken()
    }
  }, [firebase, profile])

  useEffect(() => {
    if (token) {
      const CronofyComponent = CronofyElements[cronofyElement]
      const options = withOptions ? cronofyOptions : {}
      CronofyComponent({
        target_id: cronofyTargetId,
        element_token: token.element_token.token,
        styles: {
          prefix: cronofyElement,
        },
        locale: "es",
        ...options,
      })
    }
  }, [token])

  if ((loading || !token) && isCalendar) return <Loading isCalendar />
  if ((loading || !token) && !isCalendar)
    return (
      <span style={{ marginTop: 10 }}>{t("web_client.loading_cronofy")}</span>
    )
  return <>{children}</>
}

CronofyWrapper.defaultProps = {
  cronofyElement: "CalendarSync",
  cronofyTargetId: "cronofy-calendar-sync",
  cronofyPermissions: ["account_management"],
  cronofyOptions: {
    authorization: {
      redirect_uri: `${constants.cronofy.redirectURL}/settings`,
      client_id: process.env.REACT_APP_CRONOFY_CLIENT_ID,
      scope: "read_write",
    },
  },
  withOptions: false,
  tmpCronofySub: null,
  isCalendar: false,
}

export default withFirebase(CronofyWrapper)
