import styled from "styled-components"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"
import { GridContainer } from "../../atoms/Global"

export const SubmitButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-top: 12px;
`

export const StyledForm = styled.form`
  width: 100%;
`

// eslint-disable-next-line import/prefer-default-export
export const Container = styled(GridContainer)`
  padding: 0;
  background-color: ${(props) => props.theme.color.background.gray30};
`

export const ServicesContainer = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - 90px);
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  padding-left: 18px;

  > form {
    width: 100%;
    padding-right: 30px;
  }
`

export const VideoBackground = styled.video`
  position: fixed;
  z-index: -1;
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: opacity 1s ease-in-out;
  background-color: ${(props) => props.theme.color.background.gray30};
  @media (min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }
  @media (max-aspect-ratio: 16/9) {
    width: auto;
    height: 100%;
  }
`

export const ChatWrapper = styled.div`
  padding-top: 49px;
  padding-left: 60px;
  height: 100%;
  width: 100%;
`
