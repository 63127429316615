import styled, { css } from "styled-components"
import { rem } from "polished"

export const ErrorPageWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${rem(20)};
    color: ${theme.color.primary};
    text-align: center;
  `
)

export const ErrorPageContent = styled.div(
  ({ theme }) => css`
    max-height: 600px;
    max-width: 700px;
    text-align: center;
    > h1 {
      color: ${theme.color.primary};
      font-size: ${rem(72)};
      padding-top: ${rem(14)};
      line-height: 1;
      font-weight: bold;
    }

    > h2 {
      font-size: ${rem(16)};
      font-weight: bold;
      text-transform: uppercase;
    }

    > p {
      margin-bottom: 20px;
      font-size: ${rem(14)};
      line-height: 1.5;
      text-align: left;
    }
    ol > li {
      margin-bottom: 20px;
      font-size: ${rem(14)};
      line-height: 1.5;
      text-align: left;
    }
    ul {
      text-align: left;
      font-size: ${rem(14)};
      padding-left: ${rem(60)};
    }
  `
)

export const IconWrapper = styled.div`
  align-self: center;
  margin-left: ${rem(20)};
`

export const StyledLink = styled.a(
  ({ theme }) => css`
    display: block;
    text-decoration: none;
    color: ${theme.color.secondary};
    cursor: pointer;
  `
)
