import React, { useEffect, useState } from "react"
import { compose } from "recompose"
import { useHistory, useLocation } from "react-router-dom"
import { SectionsContainer, GridContainer } from "../../components/atoms/Global"
import ManageContacts from "../../components/organisms/ManageContacts"
import { withAuthorization } from "../../context/Session"

const Contacts = () => {
  const { state } = useLocation()
  const history = useHistory()
  const [paramContact, setParamContact] = useState(null)

  useEffect(() => {
    if (state && state.contact) {
      setParamContact(state.contact)
      const stateC = { ...history.location.state }
      delete stateC.contact
      history.replace({ ...history.location, state: stateC })
    }
  }, [state])

  return (
    <SectionsContainer>
      <GridContainer pt="0px" pl="30px">
        <ManageContacts paramContact={paramContact} />
      </GridContainer>
    </SectionsContainer>
  )
}

const condition = (authUser) => !!authUser
const CompContacts = compose(withAuthorization(condition))(Contacts)

export default CompContacts
