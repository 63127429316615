import Firebase from "./Firebase"

const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`

const sendEmail = async (templateId, email, variables) => {
  const token = await Firebase.getIdToken()
  return fetch(mailerUrl, {
    method: "POST",
    body: JSON.stringify({ data: { templateId, email, variables } }),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
}

export default sendEmail
