import React from "react"
import get from "lodash.get"
import PropTypes from "prop-types"
// import { useHistory } from "react-router-dom"
import { StarRate } from "@styled-icons/material/StarRate"
import { InitialAvatar } from "../../atoms/Card/styles"
import { CalendarIcon } from "../../atoms/Common"
import { capitalizeAllWords } from "../../../utils/helpers"
import {
  Container,
  ContactData,
  Name,
  Info,
  OtherInfo,
  Actions,
  SmallButton,
} from "./styles"

const ContactRow = ({
  contact,
  isSimple,
  handleClick,
  active,
  disabled,
  handleModalClick,
  translation,
}) => (
  <Container>
    <Info onClick={disabled ? null : handleClick} disabled={disabled}>
      <InitialAvatar isFromContacts>
        {get(contact, ["photoURL"], "local") !== "local" ? (
          <img
            src={get(contact, ["photoURL"])}
            alt="avatar"
            style={{ width: 40 }}
          />
        ) : (
          <span>{get(contact, ["displayName", "0"], "C").toUpperCase()}</span>
        )}
      </InitialAvatar>
      <ContactData isSimple={isSimple}>
        <Name disabled={disabled} active={active}>
          {capitalizeAllWords(contact.displayName)}
        </Name>
        <OtherInfo active={active} disabled={disabled}>
          {contact.email}
        </OtherInfo>
      </ContactData>
    </Info>
    <Actions isSimple={isSimple}>
      {contact.isFavorite && <StarRate size="14" />}
      {!isSimple && (
        <>
          <SmallButton disabled={!active} onClick={handleModalClick()}>
            <CalendarIcon />
            {translation("schedule_session")}
          </SmallButton>
        </>
      )}
    </Actions>
  </Container>
)

ContactRow.defaultProps = {
  active: false,
}

ContactRow.propTypes = {
  active: PropTypes.bool,
  isSimple: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleModalClick: PropTypes.func.isRequired,
}

export default ContactRow
