import React, { useState, useEffect, useRef, useContext } from "react"
import PropTypes from "prop-types"
import get from "lodash.get"
import { compose } from "recompose"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { Refresh } from "@styled-icons/material/Refresh"
import { useHistory } from "react-router-dom"
import { withAuthorization, AuthUserContext } from "../../../context/Session"
import { withFirebase } from "../../../utils/Firebase"
import { InitialAvatar } from "../Card/styles"
import {
  Wrapper,
  DoctorContainer,
  LogOutContainer,
  ActionsContainer,
  ResetPassword,
  Doctors,
  DoctorInfo,
} from "./styles"

// Images
import defaultPicture from "../../../static/images/others/no-photo.jpeg"

const DoctorList = ({ isModalVisible, handleModalStatus, firebase }) => {
  const { addToast } = useToasts()
  const [providers, setProviders] = useState([])
  const [resetting, setResetting] = useState(false)
  const authUser = useContext(AuthUserContext)
  const history = useHistory()
  const { t } = useTranslation()
  useEffect(() => {
    const getProviders = async () => {
      const providersData = await firebase.getAssistantsProviders(
        authUser.email
      )
      setProviders(providersData.docs.map((d) => ({ id: d.id, ...d.data() })))
    }
    if (firebase && get(authUser, "email")) {
      getProviders()
    }
  }, [firebase, authUser])

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.stopPropagation()
          handleModalStatus(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)
  if (!isModalVisible) return null
  return (
    <>
      <Wrapper />
      <DoctorContainer ref={wrapperRef}>
        <Doctors>
          {providers.map((p) => (
            <DoctorInfo
              onClick={() => {
                authUser.setUser({
                  ...authUser,
                  isImpersonating: true,
                  impersonatingEmail: p.email,
                  impersonatingUID: p.id,
                })
                handleModalStatus(false)
                history.push("/sessions")
              }}
            >
              <InitialAvatar>
                {get(p, ["photoURL"], false) !== "local" ? (
                  <img
                    src={get(p, ["photoURL"]) || defaultPicture}
                    alt="avatar"
                    style={{ width: 40 }}
                  />
                ) : (
                  <span>
                    {get(
                      p,
                      ["displayName", "0"],
                      authUser.email[0].toUpperCase()
                    )}
                  </span>
                )}
              </InitialAvatar>
              <p>
                {p.title
                  ? `${p.title} ${p.displayName
                      .replace("undefined", "")
                      .substring(0, 30)}`
                  : p.displayName.replace("undefined", "").substring(0, 30)}
              </p>
            </DoctorInfo>
          ))}
        </Doctors>
        <ActionsContainer>
          <LogOutContainer
            onClick={() => {
              authUser.setUser({
                ...authUser,
                isImpersonating: false,
                impersonatingEmail: null,
                impersonatingUID: null,
              })
              handleModalStatus(false)
              history.push("/dashboard")
            }}
          >
            {t("log_out")}
          </LogOutContainer>
          <ResetPassword
            resetting={resetting}
            onClick={async (e) => {
              setResetting(true)
              e.stopPropagation()
              try {
                await firebase.doPasswordReset(authUser.email)
                setResetting(false)
                addToast(t("web_client.email_reset_send"), {
                  appearance: "success",
                  placement: "top-right",
                })
              } catch (error) {
                setResetting(false)
                addToast(t("unexpected_error"), {
                  appearance: "error",
                  placement: "top-right",
                })
              }
            }}
          >
            <span>{t("web_client.reset_password")}</span>
            <Refresh size="18" />
          </ResetPassword>
        </ActionsContainer>
      </DoctorContainer>
    </>
  )
}

DoctorList.propTypes = {
  firebase: PropTypes.shape().isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  handleModalStatus: PropTypes.func.isRequired,
}

const condition = (authUser) => !!authUser
const CompoDoctorList = compose(
  withFirebase,
  withAuthorization(condition)
)(DoctorList)

export default CompoDoctorList
