/* eslint-disable no-unused-vars */
import moment from "moment"
import get from "lodash.get"
import { v4 as uuidv4 } from "uuid"
import { compose } from "recompose"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import React, { useState, useEffect, useMemo } from "react"
import { useToasts } from "react-toast-notifications"
import {
  Button,
  Box,
  Text,
  Image,
  HStack,
  Divider,
  VStack,
} from "@chakra-ui/react"

import { WithChakra } from "../../../atoms/WithChakra"
import { InitialAvatar } from "../../../atoms/Card/styles"
import { WaitingIcon, CheckCircleIcon } from "../../../atoms/Common"
import LoadableCronofyWrapper from "../../../atoms/CronofyWrapper"
import * as countries from "../../../../utils/countriesList.json"
import constants from "../../../../utils/constants"
import { withFirebase } from "../../../../utils/Firebase"
import { capitalizeAllWords } from "../../../../utils/helpers"
import { DatePickerContainer } from "../styles"
import {
  v3reservationsCreateUrl,
  v3reservationsConfirmUrl,
} from "../../../../utils/App"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "transparent",
    marginTop: "1px",
    marginBottom: "2px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
  menuPortal: (base) => ({ ...base, zIndex: 10000 }),
}

export const getCountryData = (firebase, code, lang) => {
  const supportedLanguages = firebase.getGlobalVar("supportedLanguages")
  const _country = get(countries.default, code)
  const _lang =
    supportedLanguages.indexOf(`${lang}`.toLowerCase()) === -1
      ? "es"
      : `${lang}`.toLowerCase()

  if (!_country) return {}
  return {
    name: _country?.names[_lang],
    iso2: _country?.iso2,
    iso3: _country?.iso3,
    currency: _country?.currency.iso,
    symbol: _country?.currency.symbol,
  }
}

const AddAppointment = ({
  currentContact,
  user,
  providerPricing,
  onModalClose,
  isModalOpen,
  firebase,
  userOffices,
  originalUserOffices,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [appointmentStep, setAppointmentStep] = useState(0)
  const [currentSlot, setCurrentSlot] = useState(null)
  const [paymentType, setPaymentType] = useState("2")
  const [contactData, setContactData] = useState(null)
  const [isReservation, setIsReservation] = useState(false)
  const [currentBusiness, setCurrentBusiness] = useState("private")
  const [currentOffice, setCurrentOffice] = useState([])
  const [contactOffices, setContactOffices] = useState([])
  const [contactBusiness, setContactBusiness] = useState([])
  const [loadingRule, setLoadingRule] = useState(true)
  const [profile, setProfile] = useState(null)
  const [sessionType, setSessionType] = useState("faceToFace")
  const userType = get(user, ["medicProfile", "userType"])
  const isBasicPlan = get(user, ["medicProfile", "plan"]) === "basic"

  const countryData = constants.countries.find(
    (country) => country.value === get(profile, ["countryBusiness"])
  )

  const totalKey =
    sessionType === "faceToFace" ? "sessionTotalCashPrice" : "sessionTotalPrice"

  console.log("contactOffices ==>", contactOffices)
  console.log("contactBusiness ==>", contactBusiness)

  useEffect(() => {
    const getTarget = async () => {
      try {
        setContactData(null)
        const target = await firebase.getProfile({
          email: currentContact.email,
        })
        setContactData(target.data())
      } catch {
        addToast(t("unexpected_error"), {
          appearance: "error",
        })
      }
    }
    if (currentContact) {
      getTarget()
    }
  }, [currentContact, firebase])

  useEffect(() => {
    if (contactData?.organizationId) {
      const { organizationId } = contactData

      console.log("contactData ==>", contactData)
      const filteredOrgsByContact =
        profile?.accessMode === "business" || profile?.accessMode === "mix"
          ? profile?.accessMode === "mix"
            ? [
                {
                  value: "private",
                  label: "Privado",
                },
              ]
            : []
          : [
              {
                value: "private",
                label: "Privado",
              },
            ]
      if (profile?.organizations) {
        Object.keys(profile?.organizations)
          ?.filter((o) => profile?.organizations[o].active)
          ?.forEach((org) => {
            if (profile?.organizations[org].orgId === organizationId) {
              filteredOrgsByContact.push({
                label: profile?.organizations?.[org]?.businessName,
                value: profile?.organizations?.[org]?.id,
              })
            }
          })
      }

      setContactBusiness(filteredOrgsByContact)
      setCurrentBusiness(filteredOrgsByContact[0]?.value)
    } else {
      // setContactBusiness([])
      setCurrentOffice(contactOffices?.[0]?.value)
      setCurrentBusiness("private")
    }
  }, [contactData])

  useEffect(() => {
    if (contactBusiness.length >= 1) {
      const filteredOfficesByContact = userOffices?.filter(
        (b) => b.owner === currentBusiness
      )
      if (filteredOfficesByContact?.length >= 1) {
        setContactOffices(filteredOfficesByContact)
        setCurrentOffice(filteredOfficesByContact?.[0]?.value)
      } else {
        setContactOffices([])
        setCurrentOffice(null)
      }
    }
  }, [currentBusiness, contactBusiness])

  const currentRuleId = useMemo(() => {
    const type = sessionType === "faceToFace" ? "in_person" : "virtual"

    // if (contactOffices?.length >= 1) {
    // }
    return contactOffices?.length >= 1
      ? `medical_work_hours_${type}${
          currentBusiness === "private"
            ? ""
            : `_${currentBusiness?.toString()?.toLowerCase()}`
        }${
          currentOffice && type !== "virtual"
            ? `_${currentOffice?.toString()?.toLowerCase()}`
            : ""
        }`
      : `medical_work_hours_${type}`
  }, [contactOffices, sessionType, currentOffice, currentBusiness])

  useEffect(() => {
    if (loadingRule) {
      setLoadingRule(false)
    }
  }, [currentRuleId])

  const handleAppointment = async () => {
    try {
      if (appointmentStep === 1) {
        const [name, surname] = currentContact.displayName.split(" ")
        const providerCountryDetails = getCountryData(
          firebase,
          providerPricing.country
        )
        const { local, dollars } =
          sessionType === "faceToFace"
            ? providerPricing?.pricing?.faceToFace?.totals
            : providerPricing?.pricing?.virtual?.totals
        const chargesPayload = {
          client: {
            id: currentContact.email,
            email: currentContact.email,
            name,
            surname,
            displayName: currentContact.displayName,
            country: profile?.countryBusiness,
            photoURL: "",
            customerId: "",
          },
          charge: {
            amount: dollars?.[totalKey],
            sessionPrice: dollars?.sessionBasePrice,
            sessionFee: dollars?.sessionDocFees,
            localAmount: local?.[totalKey],
            localSessionPrice: local.sessionBasePrice,
            localSessionFee: local.sessionDocFees,
            localCurrency: providerCountryDetails.currency,
            currency: "usd",
            description: `Professional Session [${sessionType}]: ${profile?.displayName}`,
            appointmentType: sessionType,
            paymentType: "card",
          },
          provider: {
            id: user?.isImpersonating ? user?.impersonatingUID : user?.uid,
            email: user?.isImpersonating
              ? user?.impersonatingEmail
              : user?.email,
            name: profile?.name,
            surname: profile?.surname1,
            displayName: profile?.displayName,
            profession: profile?.profession,
            title: "",
            country: profile?.countryBusiness,
            photoURL: profile?.photoURL,
          },
          slot: {
            ...currentSlot,
          },
        }
        setAppointmentStep((prevState) => prevState + 1)
        const payLater = paymentType === "2" || isReservation
        const authToken = await firebase.getIdToken()
        const charged = await fetch(
          `${v3reservationsConfirmUrl}?slotID=${currentSlot?.id}`,
          // payLater ? reservationsCreateUrl : chargesCreateUrl,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        if (charged.ok) {
          setAppointmentStep((prevState) => prevState + 1)
        } else {
          addToast(t("unexpected_error"), {
            appearance: "error",
          })
          setAppointmentStep((prevState) => prevState - 1)
        }
      }
    } catch (error) {
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  useEffect(() => {
    const getData = async () => {
      const getProfile = async () => {
        if (userType === "provider") {
          return get(user, ["medicProfile"])
        }
        const local = await firebase.getProfile({
          email: user.impersonatingEmail,
        })
        return local.data()
      }
      const Profile = await getProfile()
      console.log("profile 00>>", Profile)
      setProfile(Profile)
    }
    if (firebase && user && (userType === "provider" || user.isImpersonating)) {
      getData()
    }
  }, [user, userType])

  useEffect(() => {
    if (!isModalOpen) {
      setAppointmentStep(0)
    }
  }, [isModalOpen])

  const onResponse = async (response) => {
    if (response.notification.type !== "error") {
      const selectedSlot = {
        start: moment(response.notification?.slot?.start).unix(),
        end: moment(response.notification?.slot?.end).unix(),
        startString: response.notification?.slot?.start,
        endString: response.notification?.slot?.end,
        id: uuidv4(),
      }
      setCurrentSlot(selectedSlot)

      setIsReservation(
        moment.unix(selectedSlot.start).isSameOrAfter(moment().add(6, "days"))
      )

      const reservationsPaylod = {
        providerEmail: user?.isImpersonating
          ? user?.impersonatingEmail
          : user?.email,
        clientEmail: currentContact.email,
        slotID: selectedSlot?.id,
        slotStart: selectedSlot?.start,
        slotEnd: selectedSlot?.end,
        slotDuration:
          sessionType === "faceToFace"
            ? providerPricing?.pricing?.faceToFace?.duration
            : providerPricing?.pricing?.virtual?.duration,
        rescheduling: false,
        tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
        type: sessionType,
        ...(sessionType
          ? { pricing: providerPricing?.pricing[sessionType] }
          : {}),
        ...(providerPricing?.exchangeRate
          ? { exchangeRate: providerPricing.exchangeRate }
          : {}),
        ...(providerPricing?.subscriptionPlan
          ? { subscriptionPlan: providerPricing.subscriptionPlan }
          : {}),
        ...(providerPricing?.country
          ? { currency: providerPricing.country }
          : {}),
        ...(currentBusiness ? { organizationId: currentBusiness } : {}),
        ...(sessionType === "faceToFace" && currentOffice
          ? { office: originalUserOffices?.find((o) => o.id === currentOffice) }
          : {}),
      }

      try {
        const authToken = await firebase.getIdToken()
        const reserved = await fetch(v3reservationsCreateUrl, {
          method: "POST",
          body: JSON.stringify(reservationsPaylod),
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })
        if (reserved.ok) {
          setAppointmentStep((prevState) => prevState + 1)
        } else {
          addToast(t("unexpected_error"), {
            appearance: "error",
          })
        }
      } catch (error) {
        addToast(t("unexpected_error"), {
          appearance: "error",
        })
      }
    }
  }

  useEffect(() => {
    if (!!currentBusiness && contactOffices?.length >= 1) {
      setCurrentOffice(contactOffices[0].value)
    } else {
      setCurrentOffice(null)
    }
  }, [contactOffices, currentBusiness])

  return (
    <>
      <VStack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          h="276px"
          bg="#BFEAFF"
          width="100%"
          pl="42px"
          pr="90px"
        >
          <Box>
            <Text
              fontSize="30px"
              fontWeight={400}
              lineHeight="45px"
              color="black"
              width="100%"
            >
              {`Programar Cita (${
                sessionType === "faceToFace" ? "Presencial" : "Virtual"
              })`}
            </Text>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Button
                disabled={sessionType === "faceToFace" && !isBasicPlan}
                colorScheme="blue"
                onClick={() => setSessionType("faceToFace")}
                mr={3}
              >
                Presencial
              </Button>
              <Button
                colorScheme="blue"
                disabled={sessionType === "virtual" || isBasicPlan}
                mr={3}
                onClick={() => setSessionType("virtual")}
              >
                Virtual
              </Button>
            </Box>
          </Box>

          <Image
            srcSet="/images/appointments/instant-appoiment@2x.png 2x, /images/appointments/instant-appoiment@3x.png 3x"
            src="/images/appointments/instant-appoiment.png"
            alt="Instant Image"
            my={35}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
          zIndex="9999"
          css={{ gap: ".5rem", padding: "1rem" }}
        >
          {contactBusiness?.length >= 1 && (
            <Select
              label={t("web_client.office_owner")}
              styles={customStyles}
              defaultValue={contactBusiness[0]}
              value={contactBusiness.find(
                (option) => option.value === currentBusiness
              )}
              options={contactBusiness}
              onChange={(option) => setCurrentBusiness(option.value)}
              width="250px"
              borderRadius="20px"
              isDisabled={contactBusiness?.length <= 1}
            />
          )}
          {!!currentBusiness &&
            sessionType !== "virtual" &&
            contactOffices?.length >= 1 && (
              <Box>
                <Select
                  label={t("web_client.office")}
                  styles={customStyles}
                  defaultValue={currentOffice}
                  value={contactOffices.find(
                    (option) => option.value === currentOffice
                  )}
                  options={contactOffices}
                  onChange={(option) => {
                    setLoadingRule(true)
                    setCurrentOffice(option.value)
                  }}
                  width="250px"
                  borderRadius="20px"
                  isDisabled={contactOffices?.length <= 1}
                />
              </Box>
            )}
        </Box>
        <Box
          maxH="413px"
          h="100%"
          minH={appointmentStep >= 2 ? "100px" : "300px"}
          bg="white"
          overflow="hidden"
          display="flex"
          alignItems="center"
          width="100%"
        >
          {appointmentStep === 0 &&
            sessionType === "faceToFace" &&
            !loadingRule && (
              <LoadableCronofyWrapper
                withOptions
                cronofyElement="DateTimePicker"
                cronofyTargetId="cronofy-date-picker-face-to-face"
                cronofyPermissions={["availability"]}
                key="cronofy-date-picker-face-to-face"
                cronofyOptions={{
                  styles: {
                    prefix: "DOC_Cronofy",
                  },
                  availability_query: {
                    participants: [
                      {
                        required: "all",
                        members: [
                          {
                            sub: get(profile, ["cronofySub"], ""),
                            managed_availability: true,
                            availability_rule_ids: [currentRuleId],
                          },
                        ],
                      },
                    ],
                    required_duration: {
                      minutes: providerPricing?.pricing?.faceToFace?.duration,
                    },
                    query_periods: [
                      {
                        start: moment()
                          .add(4, "hour")
                          .format("YYYY-MM-DDTHH:mm:ssZ"),
                        end: moment()
                          .endOf("day")
                          .add(30, "days")
                          .format("YYYY-MM-DDTHH:mm:ssZ"),
                      },
                    ],
                  },
                  callback: onResponse,
                }}
              >
                <DatePickerContainer
                  style={{ width: "100%", height: "auto" }}
                  id="cronofy-date-picker-face-to-face"
                />
              </LoadableCronofyWrapper>
            )}
          {appointmentStep === 0 && sessionType === "virtual" && !loadingRule && (
            <LoadableCronofyWrapper
              withOptions
              cronofyElement="DateTimePicker"
              cronofyTargetId="cronofy-date-picker-virtual"
              cronofyPermissions={["availability"]}
              key="cronofy-date-picker-virtual"
              cronofyOptions={{
                styles: {
                  prefix: "DOC_Cronofy",
                },
                availability_query: {
                  participants: [
                    {
                      required: "all",
                      members: [
                        {
                          sub: get(profile, ["cronofySub"], ""),
                          managed_availability: true,
                          availability_rule_ids: [currentRuleId],
                        },
                      ],
                    },
                  ],
                  required_duration: {
                    minutes: providerPricing?.pricing?.virtual?.duration,
                  },
                  query_periods: [
                    {
                      start: moment()
                        .add(5, "days")
                        .format("YYYY-MM-DDTHH:mm:ssZ"),
                      end: moment()
                        .endOf("day")
                        .add(35, "days")
                        .format("YYYY-MM-DDTHH:mm:ssZ"),
                    },
                  ],
                },
                callback: onResponse,
              }}
            >
              <DatePickerContainer
                style={{ width: "100%", height: "auto" }}
                id="cronofy-date-picker-virtual"
              />
            </LoadableCronofyWrapper>
          )}
          {appointmentStep === 1 && (
            <Box height="100%" width="100%" ml="22px" mr="22px" mt="22px">
              <HStack justifyContent="space-between">
                <HStack>
                  <InitialAvatar>
                    {get(user, ["photoURL"], "local") !== "local" ? (
                      <img
                        src={get(user, ["photoURL"])}
                        alt="avatar"
                        style={{ width: 40 }}
                      />
                    ) : (
                      <span>
                        {get(profile, ["displayName", "0"], "U").toUpperCase()}
                      </span>
                    )}
                  </InitialAvatar>
                  <VStack>
                    <Text lineHeight="30px" fontSize="20px" fontWeight="bold">
                      {get(profile, ["displayName"], "Proveedor")}
                    </Text>
                  </VStack>
                </HStack>
                <HStack justifyContent="flex-end">
                  <VStack>
                    <Text width="100%" textAlign="right">
                      {capitalizeAllWords(
                        currentSlot
                          ? moment(currentSlot.startString).format(
                              "MMMM DD, h:mm:ss A"
                            )
                          : moment().format("MMMM DD, h:mm:ss A")
                      )}
                    </Text>
                    <Text
                      lineHeight="30px"
                      fontSize="20px"
                      fontWeight="bold"
                      width="100%"
                      textAlign="right"
                    >
                      {currentContact?.displayName}
                    </Text>
                    <Text width="100%" textAlign="right">
                      {currentContact?.email}
                    </Text>
                  </VStack>
                </HStack>
              </HStack>
              <Divider
                orientation="horizontal"
                borderColor="#E2E8F0"
                mt="12px"
                mb="12px"
              />
              <HStack justifyContent="space-between">
                <Text fontSize="18px" color="#000000">
                  Total
                </Text>
                <VStack>
                  <Text width="100%" textAlign="right">
                    {`${get(
                      providerPricing,
                      ["pricing", sessionType, "totals", "local", totalKey],
                      0
                    )}(${get(countryData, "currencyCode", "")})`}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="#3182CE"
                    width="100%"
                    textAlign="right"
                  >
                    {`$${get(
                      providerPricing,
                      ["pricing", sessionType, "totals", "dollars", totalKey],
                      0
                    )}(USD)`}
                  </Text>
                </VStack>
              </HStack>
              {/* <HStack justifyContent="flex-star" width="100%">
                <RadioGroup onChange={setPaymentType} value={paymentType}>
                  <Stack direction="row">
                    <Radio
                      value="2"
                      borderColor="#CCCCCC"
                      sx={{
                        "~ span": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      Pagar en DOC
                    </Radio>
                  </Stack>
                </RadioGroup>
              </HStack> */}
              <Divider
                orientation="horizontal"
                borderColor="#E2E8F0"
                mt="12px"
                mb="12px"
              />
            </Box>
          )}
          {appointmentStep === 2 && (
            <VStack width="100%" justifyContent="center" alignItems="center">
              <Text fontSize="18px" color="#000000">
                Espere por favor...
              </Text>
              <WaitingIcon />
            </VStack>
          )}
          {appointmentStep === 3 && (
            <HStack width="100%" justifyContent="center" alignItems="center">
              <Text fontSize="18px" color="#000000">
                Cita creada correctamente...
              </Text>
              <CheckCircleIcon />
            </HStack>
          )}
        </Box>
        <Box width="100%" backgroundColor="white" pb={1}>
          <HStack justifyContent="flex-end" alignItems="center">
            {appointmentStep >= 1 && appointmentStep !== 3 && (
              <Button colorScheme="blue" mr={3} onClick={handleAppointment}>
                {appointmentStep === 1 ? "Confirmar" : "Siguiente"}
              </Button>
            )}
            {appointmentStep === 3 && (
              <Button onClick={onModalClose}>Cerrar</Button>
            )}
          </HStack>
        </Box>
      </VStack>
    </>
  )
}

const ComponsedAddAppointment = compose(
  WithChakra,
  withFirebase
)(AddAppointment)

export default ComponsedAddAppointment
