const constants = {
  collections: {
    USERS: "users",
  },
  chats: {
    MESSAGE_LIMIT: 20,
  },
  firebase: {
    project: process.env.REACT_APP_FUNCTIONS_URL,
  },
  cronofy: {
    clientId: process.env.REACT_APP_CRONOFY_CLIENT_ID,
    redirectURL: process.env.REACT_APP_CRONOFY_REDIRECT_URL,
  },
  countries: [
    // {
    //   value: "BO",
    //   label: "Bolivia",
    //   currencyCode: "BOB",
    // },
    // {
    //   value: "CL",
    //   label: "Chile",
    //   currencyCode: "CLP",
    // },
    // { value: "COL", label: "Colombia", currencyCode: "COP" },
    // { value: "EC", label: "Ecuador", currencyCode: "USD" },
    {
      value: "CR",
      label: "Costa Rica",
      currencyCode: "CRC",
    },
    { value: "SV", label: "El Salvador", currencyCode: "USD" },
    { value: "GT", label: "Guatemala", currencyCode: "GTQ" },
    { value: "HN", label: "Honduras", currencyCode: "HNL" },
    { value: "MX", label: "México", currencyCode: "MXN" },
    { value: "NI", label: "Nicaragua", currencyCode: "NIO" },
    { value: "PA", label: "Panamá", currencyCode: "USD" },
    { value: "PE", label: "Perú", currencyCode: "SOL" },
    {
      value: "DO",
      label: "República Dominicana",
      currencyCode: "DOP",
    },
  ],
  accountsType: [
    {
      value: "checking_account",
      label: "web_client.checking_account",
      i18n: true,
    },
    {
      value: "savings_account",
      label: "web_client.savings_account",
      i18n: true,
    },
  ],
  accountsCurrencyType: [
    {
      value: "local",
      label: "local_currency_account",
      i18n: true,
    },
    {
      value: "dollar",
      label: "dollar_currency_account",
      i18n: true,
    },
  ],
  supportEmail: "soporte@doc.cr",
}

export default constants
