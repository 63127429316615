import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import Firebase, { FirebaseContext } from "./utils/Firebase"
import reportWebVitals from "./reportWebVitals"

require("react-progress-bar-plus/lib/progress-bar.css")

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={new Firebase()}>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
