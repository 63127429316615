import styled from "styled-components"
import ButtonWithLoader from "../../../components/atoms/ButtonWithLoader"

export const ModalWrapper = styled.div`
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > canvas {
    border: 1px solid black;
    width: 100%;
    min-height: 300px;
    background: white;
  }
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    width: 60vw;
    height: 50vh;
  }
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 200px;
  margin: 5px 0;
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    margin-right: 10px;
  }
`

export const Buttons = styled.div`
  height: auto;
  min-height: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: row;
    height: 90px;
    min-height: 65px;
  }
`

export const ButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  & input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`
