import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { ChatBubbleOutline } from "@styled-icons/material/ChatBubbleOutline"

const HelpButton = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <Button
      rightIcon={<ChatBubbleOutline size={24} />}
      colorScheme="black"
      variant="link"
      onClick={onClick}
    >
      {t("web_client.menu_help")}?
    </Button>
  )
}

HelpButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default HelpButton
