const Teeth = [
  // up
  {
    tooth_number: 18,
  },
  {
    tooth_number: 17,
  },
  {
    tooth_number: 16,
  },
  {
    tooth_number: 15,
  },
  {
    tooth_number: 14,
  },
  {
    tooth_number: 13,
  },
  {
    tooth_number: 12,
  },
  {
    tooth_number: 11,
  },
  {
    tooth_number: 21,
  },
  {
    tooth_number: 22,
  },
  {
    tooth_number: 23,
  },
  {
    tooth_number: 24,
  },
  {
    tooth_number: 25,
  },
  {
    tooth_number: 26,
  },
  {
    tooth_number: 27,
  },
  {
    tooth_number: 28,
  },
  // down
  {
    tooth_number: 48,
  },
  {
    tooth_number: 47,
  },
  {
    tooth_number: 46,
  },
  {
    tooth_number: 45,
  },
  {
    tooth_number: 44,
  },
  {
    tooth_number: 43,
  },
  {
    tooth_number: 42,
  },
  {
    tooth_number: 41,
  },
  {
    tooth_number: 31,
  },
  {
    tooth_number: 32,
  },
  {
    tooth_number: 33,
  },
  {
    tooth_number: 34,
  },
  {
    tooth_number: 35,
  },
  {
    tooth_number: 36,
  },
  {
    tooth_number: 37,
  },
  {
    tooth_number: 38,
  },
]

export default Teeth
