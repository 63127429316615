import styled from "styled-components"

const SubTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: #000000;

  margin: 0;
  margin-bottom: 4px;
`
export default SubTitle
