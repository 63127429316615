/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { compose } from "recompose"
import { withRouter, useParams } from "react-router-dom"
import CryptoJS from "crypto-js"

// Components
import { Flex, Text, Box, Spinner } from "@chakra-ui/react"
import { WithChakra } from "../../components/atoms/WithChakra"
import Odontogram from "../../components/molecules/ContactInfo/Children/odontogram"

// Utils
import { withFirebase } from "../../utils/Firebase"

const OdontogramBase = ({ firebase }) => {
  // const [customToken, setCustomToken] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [loading, setLoading] = useState(true)
  const [authenticaded, setAuthenticated] = useState(false)
  const { token } = useParams()

  useEffect(() => {
    if (token) {
      firebase.doSignInWithToken(token).then(() => {
        setAuthenticated(true)
      })
    }
    return () => firebase.doSignOut()
  }, [token])

  useEffect(() => {
    if (authenticaded) {
      ;(async () => {
        const user = await firebase.getIdTokenResult()
        setUserEmail(user?.claims?.contact)
        setLoading(false)
      })()
    }
  }, [authenticaded])

  return (
    <Flex
      width="full"
      height="100vh"
      overflowX="hidden"
      justifyContent="center"
      alignItems="flex-start"
      padding="20px"
    >
      {loading && (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      )}
      {!loading && !!userEmail && (
        <Box>
          <Odontogram
            firebase={firebase}
            user={{
              isImpersonating: true,
              impersonatingEmail: userEmail,
            }}
            contact={{
              email: userEmail,
            }}
            isReadOnly
          />
        </Box>
      )}
    </Flex>
  )
}

const OdontogramPage = compose(
  WithChakra,
  withFirebase,
  withRouter
)(OdontogramBase)

export default OdontogramPage
