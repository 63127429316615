/* eslint-disable import/prefer-default-export */
import styled from "styled-components"

export const Card = styled.div`
  height: 250px;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  transition: all 300ms ease-in-out;
  > p {
    font-size: 0.625rem;
    color: ${(props) => props.theme.color.black.semi};
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 20px -6px rgba(0, 0, 0, 0.54);
  }
`

export const DisplayNameLabel = styled.span`
  font-size: 1.25rem;
  color: ${(props) => props.theme.color.black.normal};
  letter-spacing: 0.25px;
`

export const ProfessionLabel = styled.span`
  font-size: 0.875rem;
  color: ${(props) => props.theme.color.black.normal};
  letter-spacing: 0.25px;
`

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  & > div {
    margin: 0 15px;
  }
`
