import React from "react"
import PropTypes from "prop-types"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, Configure } from "react-instantsearch-dom"
import Autocomplete from "../../atoms/Autocomplete"

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
)

const HealthProductSearch = ({
  placeholder,
  onSelectedProduct,
  onAnySugestions: onAnySugestionsHandler,
  onClearProduct,
  sku,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedProduct, setSelectedProduct] = React.useState({})

  const onAnySugestions = (_, { suggestion }) => {
    setSelectedProduct(suggestion)

    if (onAnySugestionsHandler) {
      onAnySugestionsHandler(suggestion)
    }
  }

  const onSuggestionSelected = (_, { suggestion }) => {
    setSelectedProduct(suggestion)

    if (onSelectedProduct) {
      onSelectedProduct(suggestion)
    }
  }

  const onSuggestionCleared = () => {
    if (onClearProduct) {
      onClearProduct()
    }
    setSelectedProduct({})
    onSelectedProduct({
      sku: "",
      presentations: "",
      dose: "",
      composition: "",
    })
  }

  return (
    <InstantSearch
      place
      indexName={process.env.REACT_APP_ALGOLIA_INDEX_NAME}
      searchClient={searchClient}
    >
      <Configure hitsPerPage={5} />
      <Autocomplete
        defaultValue={sku}
        placeholder={placeholder}
        onAnySuggestions={onAnySugestions}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionCleared={onSuggestionCleared}
      />
    </InstantSearch>
  )
}

HealthProductSearch.defaultProps = {
  sku: "",
}

HealthProductSearch.propTypes = {
  onSelectedProduct: PropTypes.func.isRequired,
  onClearProduct: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  onAnySugestions: PropTypes.string.isRequired,
  sku: PropTypes.string,
}

export default HealthProductSearch
