import React from "react"

const WaitingIcon = () => (
  <svg
    width="126"
    height="125"
    viewBox="0 0 126 125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M96.6724 62.86C96.6724 44.2632 81.5968 29.1877 63 29.1877C44.4032 29.1877 29.3276 44.2632 29.3276 62.86C29.3276 81.4569 44.4032 96.5324 63 96.5324C81.5968 96.5324 96.6724 81.4569 96.6724 62.86Z"
      stroke="#4D4D4D"
      strokeWidth="2.45922"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M116.777 62.86C116.777 33.1583 92.7013 9.08252 62.9996 9.08252C33.2979 9.08252 9.22217 33.1583 9.22217 62.86C9.22217 92.5617 33.2979 116.637 62.9996 116.637C92.7013 116.637 116.777 92.5617 116.777 62.86Z"
      stroke="#4D4D4D"
      strokeWidth="2.45922"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M123.86 62.86C123.86 29.2482 96.612 2 63.0002 2C29.3883 2 2.14014 29.2482 2.14014 62.86C2.14014 96.4719 29.3883 123.72 63.0002 123.72C96.612 123.72 123.86 96.4719 123.86 62.86Z"
      stroke="#4D4D4D"
      strokeWidth="2.45922"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M77.5713 40.8261C79.2695 42.5243 87.7977 42.7566 87.7977 42.7566C87.7977 42.7566 79.2695 42.989 77.5713 44.6871C75.8731 46.3853 75.6408 54.9135 75.6408 54.9135C75.6408 54.9135 75.4084 46.3853 73.7102 44.6871C72.0121 42.989 63.4839 42.7566 63.4839 42.7566C63.4839 42.7566 72.0121 42.5243 73.7102 40.8261C75.4084 39.1279 75.6408 30.5997 75.6408 30.5997C75.6408 30.5997 75.8731 39.1279 77.5713 40.8261Z"
      stroke="#4D4D4D"
      strokeWidth="1.96738"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M89.6438 27.7251C90.9708 29.0521 94.5794 29.2337 94.5794 29.2337C94.5794 29.2337 90.9708 29.4152 89.6438 30.7423C88.3167 32.0693 88.1352 35.6779 88.1352 35.6779C88.1352 35.6779 87.9536 32.0693 86.6266 30.7423C85.2995 29.4152 81.6909 29.2337 81.6909 29.2337C81.6909 29.2337 85.2995 29.0521 86.6266 27.7251C87.9536 26.398 88.1352 22.7894 88.1352 22.7894C88.1352 22.7894 88.3167 26.398 89.6438 27.7251Z"
      stroke="#4D4D4D"
      strokeWidth="1.56633"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.3774 78.4423C52.8977 81.8646 57.703 83.9722 63.0008 83.9722C68.3764 83.9722 73.2453 81.8022 76.7788 78.2902"
      stroke="#4D4D4D"
      strokeWidth="2.81914"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.4517 66.5193C47.193 67.6651 48.4822 68.4235 49.9485 68.4235C51.425 68.4235 52.7219 67.6547 53.4606 66.4955"
      stroke="#4D4D4D"
      strokeWidth="2.45922"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M72.0376 66.5193C72.779 67.6652 74.0682 68.4235 75.5344 68.4235C77.0109 68.4235 78.3079 67.6548 79.0466 66.4955"
      stroke="#4D4D4D"
      strokeWidth="2.45922"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default WaitingIcon
