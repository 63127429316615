// Comentar eso cambio assistant
import Honeybadger from "@honeybadger-io/js"

const config = {
  apiKey: "hbp_tq2GyQButyyJ6qvKOU6ILgAEgwt24K3lpie5",
  environment: process.env.REACT_APP_DOC_ENV || process.env.NODE_ENV,
  // reportData: true,
  breadcrumbsEnabled: true,
  // environment: "stage",
}
export const honeybadger = Honeybadger.configure(config)

/* eslint-disable import/prefer-default-export */
export const generatePassword = () => {
  const length = 8
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  let retVal = ""
  // eslint-disable-next-line no-plusplus
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return retVal
}

export const cronofyRefreshTokenUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/appointmentsRefreshToken`
export const mailerUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/mailerSend`
export const refundsCreateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/paymentsStripeRefundsCreate`
export const getPdfUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/pdfCreate`
export const cronofyAccessTokenUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/appointmentsGetToken`
export const videoGenerateAccessTokenUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/videoGenerateAccessToken`
export const appointmentsDeleteUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/appointmentsDelete`
export const appointmentsGetUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/appointmentsGetAvailabilityRules`
// export const appointmentsCancelUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/appointmentsCancel`
export const appointmentsGetListUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/appointmentsGetList`
export const cancelSessionUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/chatSessionCancel`
export const appointmentsUpdateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/appointmentsUpdateAvailabilityRule`
export const createProvider = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserProvider`
export const setupProvider = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserProviderSetup`
export const activeProvider = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserProviderActivate`
export const sessionsCloseUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/sessionsClose`
export const appointmentsCreateCalendarUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/appointmentsCreateCalendar`
export const sessionsDiscountUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/sessionsDiscount`
export const reservationsCreateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/reservationsCreate`
// export const reservationsCreateNewUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/reservations-https-create`
export const reservationsCreateNewUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/reservations-https-create`
export const appointmentsCreateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/appointmentsCreate`
export const chargesCreateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/paymentsStripeChargesCreate`
// export const getProviderPricingUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/providers-https-getPricing`
export const getProviderPricingUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/providers-https-getPricing`
export const postAnswerQuestionUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/questions-https-answer`
export const postQuestionPushNotification = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/notifications-https-createNotification`
// export const postAnswerQuestionUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/questions-https-answer`
// export const sessionsActivateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/sessionsActivate`
export const newUserUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserCreateNew`
export const deleteUserUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserOnDelete`
export const reportDashboardUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/paymentsReportsDashboard`
export const sessionsDonateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/sessionsDonate`
export const collaboratorRegisterUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/organizations-https-collaboratorRegister`
export const orgRegisterUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/organizations-https-register`
export const createCustomTokenUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/authUserCustomToken`
export const linksCreateUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/linksCreate`
export const v3sessionsCloseUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/appointments-https-close`
export const v3reservationsCreateUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/reservations-https-create`
export const v3reservationsConfirmUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/reservations-https-confirm`
export const v3sessionsActivateUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/appointments-https-start`
export const v3appointmentsCancelUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/appointments-https-cancel`

// Stripe
export const createTokenUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/payments-stripe-https-createToken`
export const deleteTokenUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/payments-stripe-https-deleteToken`
export const customerCreateUrl = `${process.env.REACT_APP_FUNCTIONS_URL_V2}/payments-stripe-https-createCustomer`
