import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 50px;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  :hover {
    transform: ${({ disabled }) => (disabled ? "none" : "scale(1.01)")};
  }
`

export const Info = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column: 1;
  overflow: hidden;
`

export const ContactData = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  width: ${(props) => (props.isSimple ? "310px" : "100%")};
`

export const Name = styled.span`
  color: ${({ theme, disabled, active }) =>
    disabled && !active ? theme.color.gray["200"] : theme.color.gray["900"]};
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: ${({ active }) => (active ? "800" : "400")};
`

export const OtherInfo = styled.span`
  color: ${({ theme, disabled, active }) =>
    disabled && !active ? theme.color.gray["200"] : theme.color.gray["600"]};
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 500;
`

export const Actions = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isSimple ? "flex-start" : "flex-end")};
  align-items: center;
  grid-column: 2;
`

export const SmallButton = styled.button`
  background-color: ${(props) =>
    props.filled ? props.theme.color.secondary : "transparent"};
  color: ${(props) =>
    props.disabled
      ? props.theme.color.gray["200"]
      : props.filled
      ? props.theme.color.white.regular
      : props.theme.color.gray["700"]};
  border: ${(props) =>
    props.filled ? "none" : `1px solid ${props.theme.color.gray["200"]}`};
  font-size: 0.875rem;
  line-height: 1.05rem;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  width: ${(props) => (props.filled ? "95px" : "125px")};
  height: 32px;
  border-radius: 6px;
  margin: 0px 6px;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  :hover {
    transform: scale(1.05);
    :disabled {
      transform: scale(1);
    }
  }

  & > svg {
    margin-right: 4px;
  }
`
