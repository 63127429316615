const images = {
  radiology: {
    title: "Radiología",
    options: [
      {
        title: "Cráneo P => Lateral",
        type: "other",
      },
      {
        title: "Senos parasanales",
        type: "checkbox",
      },
      {
        title: "Oídos",
        type: "checkbox",
      },
      {
        title: "Cavum",
        type: "checkbox",
      },
      {
        title: "Tórax Pa",
        type: "checkbox",
      },
      {
        title: "Tórax Óseo",
        type: "checkbox",
      },
      {
        title: "Abdomen Ap",
        type: "checkbox",
      },
      {
        title: "Columna Cervical AP lateral",
        type: "checkbox",
      },
      {
        title: "Columna Dorsal AP lateral",
        type: "checkbox",
      },
      {
        title: "Columna Sacrococcigea",
        type: "checkbox",
      },
      {
        title: "Extremidades",
        type: "checkbox",
      },
      {
        title: "Esofagograma",
        type: "checkbox",
      },
      {
        title: "Serie Gastroduodenal",
        type: "checkbox",
      },
      {
        title: "Tránsito Intestinal",
        type: "checkbox",
      },
      {
        title: "Colon por Enema",
        type: "checkbox",
      },
      {
        title: "Cistografía",
        type: "checkbox",
      },
      {
        title: "Pielograma Intravenoso",
        type: "checkbox",
      },
      {
        title: "Sialografía",
        type: "checkbox",
      },
      {
        title: "Histerosalpingografía",
        type: "checkbox",
      },
      {
        title: "Tomografía Lineal",
        type: "checkbox",
      },
      {
        title: "Desintometría Osea",
        type: "checkbox",
      },
      {
        title: "Mamografía",
        type: "checkbox",
      },
      {
        title: "Biopsia",
        type: "checkbox",
      },
      {
        title: "Tipo de Biopsia",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  us: {
    title: "Ultrasonido",
    options: [
      {
        title: "Abdomen General",
        type: "checkbox",
      },
      {
        title: "Abdomen Superior",
        type: "checkbox",
      },
      {
        title: "Ginecología",
        type: "checkbox",
      },
      {
        title: "Obstetricia",
        type: "checkbox",
      },
      {
        title: "Vias Urinarias",
        type: "checkbox",
      },
      {
        title: "Mamas",
        type: "checkbox",
      },
      {
        title: "Tiroides",
        type: "checkbox",
      },
      {
        title: "Cuello",
        type: "checkbox",
      },
      {
        title: "Testículos",
        type: "checkbox",
      },
      {
        title: "Tejidos Blandos =>",
        type: "other",
      },
      {
        title: "Próstata Transrectal con Biopsia",
        type: "checkbox",
      },
      {
        title: "Próstata Transrectal sin Biopsia",
        type: "checkbox",
      },
      {
        title: "U.S Doppler Carótidas",
        type: "checkbox",
      },
      {
        title: "U.S Doppler Extremidades (Arterial o Venoso)",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  tac: {
    title: "T.A.C",
    options: [
      {
        title: "Cráneo",
        type: "checkbox",
      },
      {
        title: "Fosa Posterior",
        type: "checkbox",
      },
      {
        title: "Silla Turca",
        type: "checkbox",
      },
      {
        title: "Órbitas",
        type: "checkbox",
      },
      {
        title: "Oídos",
        type: "checkbox",
      },
      {
        title: "Tórax",
        type: "checkbox",
      },
      {
        title: "Abdomen",
        type: "checkbox",
      },
      {
        title: "Pelvis",
        type: "checkbox",
      },
      {
        title: "Tercera dimensión (3D)",
        type: "checkbox",
      },
      {
        title: "Extremidad",
        type: "checkbox",
      },
      {
        title: "Contraste l.V (Si - NO) =>",
        type: "other",
      },
      {
        title: "Contraste Oral (Si – NO) =>",
        type: "other",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  mri: {
    title: "M.R.I",
    options: [
      {
        title: "Sistema Nervioso Central",
        type: "checkbox",
      },
      {
        title: "Neurovascular",
        type: "checkbox",
      },
      {
        title: "Vascular Periférico",
        type: "checkbox",
      },
      {
        title: "Tórax",
        type: "checkbox",
      },
      {
        title: "Columna",
        type: "checkbox",
      },
      {
        title: "ORL",
        type: "checkbox",
      },
      {
        title: "Abdomen",
        type: "checkbox",
      },
      {
        title: "Pelvis",
        type: "checkbox",
      },
      {
        title: "Músculo Esquelético",
        type: "checkbox",
      },
      {
        title: "Mamas",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
}
export default images
