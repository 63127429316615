import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import {
  Container,
  SectionTitle,
  DetailContainer,
  Title,
  LeftSection,
  RightSection,
} from "./styles"

const ProfileDetail = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <SectionTitle>
        <LeftSection>
          <Title size="large">{t("general_info")}</Title>
        </LeftSection>
        <RightSection />
      </SectionTitle>
      <DetailContainer>
        <div>INFO</div>
      </DetailContainer>
    </Container>
  )
}

ProfileDetail.defaultProps = {
  user: "",
}

ProfileDetail.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: PropTypes.string,
}

export default ProfileDetail
