import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import styled from "styled-components"
import { StarRate } from "@styled-icons/material/StarRate"
import { useTranslation } from "react-i18next"

const MessageBubble = ({ children, isPatient, type, content, handleClick }) => {
  const { t } = useTranslation()
  return (
    <MessageWrapper
      isPatient={isPatient}
      onClick={() => handleClick && handleClick(content)}
    >
      <Bubble isPatient={isPatient} type={type}>
        {children}
        <span>{moment(content.createdAt).format("hh:mm a")}</span>
      </Bubble>
      {type === "tip" && (
        <Info>
          <StarRate size="8" />
          <span>{t("professional_advice")}</span>
        </Info>
      )}
    </MessageWrapper>
  )
}

const Info = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 5px;
  font-style: italic;
  > span {
    margin-left: 6px;
    color: ${(props) => props.theme.color.iceBlue};
  }
  > svg {
    color: ${(props) => props.theme.color.iceBlue};
  }
`
const MessageWrapper = styled.div`
  width: 100%;
  min-height: 50px;
  height: auto;
  display: flex;
  justify-content: ${(props) => (props.isPatient ? "flex-start" : "flex-end")};
  align-items: center;
  position: relative;
  ${Info} {
    top: 0;
  }
`

const getBgColor = (props) => {
  switch (props.type) {
    case "system":
      return props.theme.color.background.gray30
    case "text":
    case "image":
    case "video":
    case "audio":
    case "document":
    case "other":
      return props.isPatient
        ? props.theme.color.lightBlueResting
        : props.theme.color.ligthGreen10
    case "prescription":
    case "clinical":
    case "medicalImages":
    case "location":
    case "medical":
    case "loaderPrescription":
    case "loaderMedicalImages":
    case "loaderClinical":
    case "loaderRecomendations":
      return props.theme.color.palePuple
    case "tip":
      return props.theme.color.palePuple
    default:
      return props.theme.color.background.gray30
  }
}

const Bubble = styled.div`
  width: ${(props) => (props.type === "system" ? "100%" : "auto")};
  max-width: ${(props) => (props.type === "system" ? "unset" : "260px")};
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => getBgColor(props)};
  border-radius: 15px;
  border-bottom-right-radius: ${(props) =>
    props.isPatient && props.type !== "system" ? "20px" : "0"};
  border-bottom-left-radius: ${(props) =>
    props.isPatient && props.type !== "system" ? "0" : "20px"};
  padding: 8px;
  margin-bottom: 13px;
  & > span {
    margin-top: ${(props) => (props.type === "tip" ? "20px" : 0)};
    padding: 0 8px;
    ${(props) => props.theme.font_size.small};
    color: ${(props) => props.theme.color.black.normal};
    &:nth-child(2) {
      font-size: 0.625rem;
      width: 100%;
      text-align: ${(props) =>
        props.type === "system"
          ? "center"
          : props.isPatient
          ? "left"
          : "right"};
      padding: 0;
      margin-top: 5px;
      color: gray;
    }
  }
`

MessageBubble.defaultProps = {
  isPatient: false,
  handleClick: null,
}

MessageBubble.propTypes = {
  isPatient: PropTypes.bool,
  children: PropTypes.element.isRequired,
  type: PropTypes.oneOf([
    "system",
    "text",
    "image",
    "video",
    "document",
    "prescription",
    "clinical",
    "medicalImages",
    "location",
    "medical",
    "loaderPrescription",
    "loaderClinical",
    "loaderMedicalImages",
    "other",
    "loaderRecomendations",
  ]).isRequired,
  content: PropTypes.shape().isRequired,
  handleClick: PropTypes.func,
}

export default MessageBubble
