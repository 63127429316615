import styled from "styled-components"

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  padding-top: 49px;
  box-sizing: border-box;
`

export const CardsContainer = styled.section`
  display: grid;
  align-items: flex-start;
  /* grid-template-columns: repeat(auto-fit, minmax(330px, 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-template-rows: repeat(1, 260px);
  height: calc(100% - 65px);
  width: 100%;
  padding: 20px;
  grid-gap: 1rem;
  overflow-x: scroll;
  grid-auto-flow: dense;
  box-sizing: border-box;
`
