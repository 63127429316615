/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import moment from "moment"
import get from "lodash.get"
import isEmpty from "lodash.isempty"
import { v4 as uuidv4 } from "uuid"
import { customAlphabet } from "nanoid"
import ProgressBar from "react-progress-bar-plus"
import { Edit } from "@styled-icons/material/Edit"
import { Close } from "@styled-icons/material/Close"
import { AddCircle } from "@styled-icons/material/AddCircle"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"
import CustomModal from "../../atoms/CustomModal"
import Theme from "../../../styles/theme"
import { Name } from "../../atoms/Card/styles"
import Input from "../../atoms/Input"
import SectionTitle from "../../atoms/SectionTitle"
import HealthProductSearch from "../../molecules/HealthProductSearch"
import { SectionsDoubleContainer, Toolbar } from "../../atoms/Global"
import Text from "../../atoms/Text"
import { withFirebase } from "../../../utils/Firebase"
import { getPdfUrl } from "../../../utils/App"
import { AuthUserContext } from "../../../context/Session"
import ChatContext from "../../../context/Chat"
import { addMessage } from "../../../context/Chat/reducer"
import {
  Container,
  EditableSection,
  EditableHeader,
  Number,
  EditableWrapper,
  MedicalHeader,
  CloseButton,
  HeaderTools,
  MedicalInfo,
  InfoCard,
  MedicalPrescription,
  Button,
  TextArea,
} from "../../atoms/Forms"
import { secondsToDate, prepareMessage } from "../../../utils/helpers"
import {
  Avatar,
  Thumb,
  Image,
  ModalWrapper,
  ModalOptions,
  Images,
  ProductImage,
  ProductVideo,
  ProductLinkWrapper,
  ProductModalWrapper,
  ProductModalColumn,
  FilePreview,
  FilePreviewLink,
  AbsoluteSendButton,
} from "./styles"

import getPrescriptionTemplate from "./template"

const prescriptionTemplate = getPrescriptionTemplate()

const Prescription = ({ firebase }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { state } = useLocation()
  const history = useHistory()
  const user = useContext(AuthUserContext)
  const nanoid = customAlphabet("1234567890ABCDEF", 8)
  const currentYear = new Date().getFullYear()
  const [uuid, setUUID] = useState("")
  const [formValues, setFormValues] = useState({
    editDiagnosis: true,
    editRecommendations: true,
    valuesIncluded: false,
    diagnosis: "",
    recommendations: "",
    ...prescriptionTemplate,
  })
  const [isLoadedImage, setIsLoadedImage] = useState({
    sign: false,
    company: false,
  })
  const [medicines, setMedicines] = useState([])
  const [company, setCompany] = useState({})
  const [academic, setAcademic] = useState({})
  const [target, setTarget] = useState({})
  const [dependentData, setDependentData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [prescriptionDetails, setPrescriptionDetails] = useState({})
  const [isProductModalOpen, setProductModal] = useState(false)
  const [isProductInfoModalOpen, setProductInfoModal] = useState(false)
  const [productInfo, setProduct] = useState({})
  const [progress, setProgress] = useState(0)

  const { dispatch, state: chatState } = useContext(ChatContext)

  useEffect(() => {
    const getBusinessForm = async () => {
      const Business = await firebase.getBusiness({ email: state.user.email })
      setCompany(Business.data())
      const Academic = await firebase.getAcademic({ email: state.user.email })
      setAcademic(Academic.data())
      const Target = await firebase.getProfile({
        email: state.info.clientEmail,
      })
      setTarget(Target.data())
      setUUID(nanoid())
      if (state?.dependent) {
        if (state?.dependent) {
          const dataDp = await firebase.getDependentProfile(
            state.info.clientEmail,
            state?.dependent?.profileOwner
          )
          if (dataDp && dataDp.docs) {
            setDependentData({
              value: dataDp.docs[0].id,
              ...JSON.parse(state?.dependent?.form),
            })
          }
        }
      }
    }
    if (firebase) {
      getBusinessForm()
    }
  }, [firebase])

  const generatePDF = async (prepMsg) => {
    try {
      const authToken = await firebase.getIdToken()
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat: {
            id: state.session,
            data: prepMsg,
          },
          type: prepMsg.type,
          dependent: dependentData,
          isFromQuestions: state?.isFromQuestions,
          questionData: state?.question,
        }),
      }
      if (state?.isFromQuestions) {
        const response = await fetch(getPdfUrl, options)
        const jsonData = await response.json()
        if (jsonData) {
          history.push("/questions", {
            question: state.question,
            prescriptions: jsonData,
          })
        } else {
          addToast(t("unexpected_error"), { appearance: "error" })
        }
      } else {
        fetch(getPdfUrl, options)
      }
      setProgress(100)
      setIsButtonLoading(false)
    } catch (error) {
      setProgress(100)
      setIsButtonLoading(false)
    }
  }

  const removeDrug = (id) => {
    setMedicines(medicines.filter((m) => m.id !== id))
  }

  const handleClose = () => {
    if (formValues.valuesIncluded || medicines.length > 0) {
      setIsModalOpen(true)
    } else if (state?.isFromQuestions) {
      history.push("/questions", {
        question: state.question,
      })
    } else {
      history.push("/sessions", { session: state.session })
    }
  }

  const handleClick = async () => {
    setIsButtonLoading(true)

    const tokens = localStorage.getItem(`targetTokens-${state.session}`)
    const infoMsg = {
      _id: uuidv4(),
      form: JSON.stringify({
        id: uuidv4(),
        diagnostic: formValues.diagnosis,
        recommendations: formValues.recommendations,
        code: uuid,
        medicines,
      }),
      type: "loaderPrescription",
      targetTokens: JSON.parse(tokens),
      sentDate: moment().format("DD/MM/YY hh:mm a"),
    }
    const prepMsg = prepareMessage(
      user,
      null,
      infoMsg,
      infoMsg.type || "text",
      infoMsg.targetTokens || []
    )
    if (state?.isFromQuestions) {
      await generatePDF(prepMsg)
    } else {
      dispatch(addMessage(prepMsg, state.session))
      setTimeout(async () => {
        await generatePDF(prepMsg)
        history.push("/sessions", {
          session: state.session,
        })
      }, 300)
    }
  }

  const handleInputChange = (e) => {
    e.persist()
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
      valuesIncluded: true,
    }))
  }

  const handleProductSearch = (selectedProduct) => {
    // eslint-disable-next-line prettier/prettier
    const { clinicalStudyURL, mainPhotoURL, videoURL, URLInsert } =
      // eslint-disable-next-line prettier/prettier
      selectedProduct

    setPrescriptionDetails({
      clinicalStudyURL,
      mainPhotoURL,
      videoURL,
      URLInsert,
    })

    setFormValues((currentValues) => ({
      ...currentValues,
      ...getPrescriptionTemplate(selectedProduct),
      valuesIncluded: true,
    }))

    setProduct(selectedProduct)
  }

  const handleSugestedProductSearch = (sugestedSKU) => {
    setFormValues((currentValues) => ({
      ...currentValues,
      name: sugestedSKU.sku,
      valuesIncluded: true,
    }))

    setProduct({ sku: sugestedSKU.sku })
  }

  const handleAddRecipe = () => {
    setPrescriptionDetails({})
    setMedicines([
      ...medicines,
      {
        id: uuidv4(),
        dci: formValues.dci,
        name: formValues.name,
        type: formValues.type,
        dosis: formValues.dosis,
        instructions: formValues.instructions,
      },
    ])
  }

  const handleEdit = (type) => {
    setFormValues((currentValues) => ({
      ...currentValues,
      [type]: !currentValues[type],
    }))
  }

  const handleCloseConfirmation = () => {
    if (state?.isFromQuestions) {
      history.push("/questions", {
        question: state.question,
      })
    } else {
      history.push("/sessions", { session: state.session })
    }
  }

  const handleClearProduct = () => {
    setProductModal("")
    setPrescriptionDetails({})
    setFormValues({
      ...formValues,
      ...prescriptionTemplate,
      type: "",
      valuesIncluded: true,
    })
  }

  useEffect(() => {
    setFormValues((currentValues) => ({
      ...currentValues,
      name: "",
      type: "",
      dosis: "",
      instructions: "",
      dci: "",
    }))
  }, [medicines])

  const getBirthdate = () => {
    let age = "0"
    const birthDate =
      dependentData && Object.keys(dependentData).length > 0
        ? secondsToDate(get(dependentData, "birthDate", null), "seconds")
        : secondsToDate(get(target, "birthDate", null), "seconds")
    if (birthDate) {
      age = currentYear - birthDate.getFullYear()
    }
    return age
  }

  const getProductField = (field) => get(productInfo, [field], "N/A") || "N/A"

  const renderProductMedia = (source, isFull) => {
    if (source.includes(".pdf")) {
      return (
        <FilePreviewLink href={source} rel="noreferrer" target="_blank">
          <button type="button">
            <FilePreview
              width={isFull ? "100%" : "200px"}
              height={isFull ? "100%" : "200px"}
              title="pdf preview"
              data={source}
            />
          </button>
        </FilePreviewLink>
      )
    }
    return (
      <ProductImage
        style={{
          width: isFull ? "100%" : "200px",
          height: isFull ? "100%" : "200px",
        }}
        alt="url insert"
        onClick={() => setProductModal(source)}
        src={source}
      />
    )
  }

  return (
    <SectionsDoubleContainer>
      {isButtonLoading && (
        <ProgressBar
          spinner={false}
          percent={progress}
          autoIncrement
          intervalTime={417}
        />
      )}
      <Toolbar>
        <SectionTitle withPadding>{t("medical_prescription")}</SectionTitle>
        <EditableWrapper>
          <EditableSection>
            <EditableHeader>
              <button type="button" onClick={() => handleEdit("editDiagnosis")}>
                <span>
                  {formValues.editDiagnosis ? t("edit") : t("cancel")}
                </span>
                <Edit size="16" />
              </button>
            </EditableHeader>
            <p>{t("diagnosis")}</p>
            {formValues.editDiagnosis && <span>{formValues.diagnosis}</span>}
            {!formValues.editDiagnosis && (
              <TextArea
                placeholder={t("diagnosis")}
                value={formValues.diagnosis}
                onChange={handleInputChange}
                name="diagnosis"
              />
            )}
          </EditableSection>
          <EditableSection>
            <EditableHeader>
              <button
                type="button"
                onClick={() => handleEdit("editRecommendations")}
              >
                <span>
                  {formValues.editRecommendations ? t("edit") : t("cancel")}
                </span>
                <Edit size="16" />
              </button>
            </EditableHeader>
            <p>{t("recommendations")}</p>
            {formValues.editRecommendations && (
              <span>{formValues.recommendations}</span>
            )}
            {!formValues.editRecommendations && (
              <TextArea
                placeholder={t("recommendations")}
                value={formValues.recommendations}
                onChange={handleInputChange}
                name="recommendations"
              />
            )}
          </EditableSection>
          {medicines.map((m, i) => (
            <EditableSection key={`m-${m.id}`}>
              <EditableHeader>
                <Number>{i + 1}</Number>
                <button type="button" onClick={() => removeDrug(m.id)}>
                  <span>{t("web_client.remove")}</span>
                  <Close size="16" />
                </button>
              </EditableHeader>
              <p> {m.name}</p>
              <div>
                <span>
                  {t("web_client.dci_label")}
                  <b> {m.dci}</b>
                </span>
              </div>
              <div>
                <span>
                  {t("product_fields.pharmaceutical_presentation_label")}
                  <b> {m.type}</b>
                </span>
              </div>
              <div>
                <span>
                  {`${t("frequency")}:`}
                  <b> {m.dosis}</b>
                </span>
              </div>
              <div>
                <span>
                  {t("treatment_duration")}
                  <b> {m.instructions}</b>
                </span>
              </div>
            </EditableSection>
          ))}
        </EditableWrapper>
      </Toolbar>
      <Container>
        <MedicalHeader>
          <CloseButton onClick={handleClose}>
            <Close size="36" />
          </CloseButton>
          <span>{`# ${t("web_client.prescription")}: ${uuid}`}</span>
          <HeaderTools>
            <ButtonWithLoader
              isLoading={isButtonLoading}
              type="button"
              onClick={handleClick}
              color={Theme.color.secondary}
              small
              disabled={medicines.length <= 0}
            >
              {t("send")}
            </ButtonWithLoader>
          </HeaderTools>
        </MedicalHeader>
        <MedicalInfo>
          <p>{company.companyName}</p>
          <div style={{ overflow: "hidden" }}>
            <InfoCard>
              <Name>{`${get(academic, "title", "")} ${state.user.name}`}</Name>
              <span>
                {`${t("q2_academic_profession_label")}: ${
                  get(academic, "professionLangLabel", "") ||
                  get(academic, "profession", "----")
                }`}
              </span>
              <span>
                {`${t("q4_academic_license_label")}: ${get(
                  academic,
                  "license",
                  "N/A"
                )}`}
              </span>
            </InfoCard>
            <InfoCard>
              <Name>
                {dependentData && Object.keys(dependentData).length > 0
                  ? get(dependentData, "dependentName", null)
                  : get(state, ["info", "clientName"], "")}
              </Name>
              <span>{t("target_age", { age: getBirthdate() })}</span>
              <span>
                {`${t("q4_info_gender_label")}: ${
                  dependentData && Object.keys(dependentData).length > 0
                    ? get(dependentData, "dependentGender", "")
                    : get(target, "gender", "")
                }`}
              </span>
            </InfoCard>
            <InfoCard>
              <Images>
                <Avatar isLoaded={isLoadedImage.company}>
                  <Thumb isLoaded={isLoadedImage.company}>
                    <span>Logo</span>
                  </Thumb>
                  <Image
                    onLoad={() => {
                      setIsLoadedImage((currentValues) => ({
                        ...currentValues,
                        company: true,
                      }))
                    }}
                    alt={company.companyName}
                    src={get(company, ["companyLogo"], "")}
                    isLoaded={isLoadedImage.company}
                  />
                </Avatar>
                <Avatar isLoaded={isLoadedImage.company}>
                  <Thumb isLoaded={isLoadedImage.company}>
                    <span>{t("web_client.medical_stamp_label")}</span>
                  </Thumb>
                  <Image
                    onLoad={() => {
                      setIsLoadedImage((currentValues) => ({
                        ...currentValues,
                        company: true,
                      }))
                    }}
                    alt={company.companyName}
                    src={get(company, ["companyStamp"], "")}
                    isLoaded={isLoadedImage.company}
                  />
                </Avatar>
                <Avatar isLoaded={isLoadedImage.sign}>
                  <Thumb isLoaded={isLoadedImage.sign}>
                    <span>{t("web_client.signature")}</span>
                  </Thumb>
                  <Image
                    onLoad={() => {
                      setIsLoadedImage((currentValues) => ({
                        ...currentValues,
                        sign: true,
                      }))
                    }}
                    alt={get(user, ["medicProfile", "displayName"], "")}
                    src={get(user, ["medicProfile", "personalSign"], "")}
                    isLoaded={isLoadedImage.sign}
                    isSign
                  />
                </Avatar>
              </Images>
            </InfoCard>
          </div>
        </MedicalInfo>
        <MedicalPrescription>
          <section>
            <TextArea
              placeholder={t("diagnosis")}
              value={formValues.diagnosis}
              onChange={handleInputChange}
              name="diagnosis"
            />
            <TextArea
              placeholder={t("recommendations")}
              value={formValues.recommendations}
              onChange={handleInputChange}
              name="recommendations"
            />
            <p>{t("product_complete_information")}</p>
            <ButtonWithLoader
              type="button"
              iconName="info"
              onClick={() => setProductInfoModal(true)}
              color={Theme.color.black.lightest}
              small
            >
              {t("product_information_button")}
            </ButtonWithLoader>
            {prescriptionDetails.mainPhotoURL && (
              <>
                <p>{t("product_images_label")}</p>
                {renderProductMedia(prescriptionDetails.mainPhotoURL)}
              </>
            )}
            {prescriptionDetails.URLInsert &&
              renderProductMedia(prescriptionDetails.URLInsert)}
            {prescriptionDetails.videoURL && (
              <>
                <p>{t("product_videos_label")}</p>
                <ProductVideo controls src={prescriptionDetails.videoURL} />
              </>
            )}

            {prescriptionDetails.clinicalStudyURL && (
              <>
                <p>{t("product_links_label")}</p>
                <ProductLinkWrapper>
                  <a
                    href={prescriptionDetails.clinicalStudyURL}
                    target="__BLANK"
                  >
                    {prescriptionDetails.clinicalStudyURL}
                  </a>
                </ProductLinkWrapper>
                <ProductLinkWrapper>
                  <a href={prescriptionDetails.URLInsert} target="__BLANK">
                    {prescriptionDetails.URLInsert}
                  </a>
                </ProductLinkWrapper>
              </>
            )}
          </section>
          <section>
            <HealthProductSearch
              onSelectedProduct={handleProductSearch}
              onClearProduct={handleClearProduct}
              onAnySugestions={handleSugestedProductSearch}
              placeholder={t("web_client.drug_name_label")}
            />
            <Input
              value={formValues.dci}
              onChange={handleInputChange}
              labelName={t("web_client.dci_label")}
              type="text"
              name="dci"
              required
              noMargin
            />
            <Input
              value={formValues.type}
              onChange={handleInputChange}
              labelName={t("pharmaceutical_presentation")}
              type="text"
              name="type"
              required
              noMargin
            />
            <Input
              value={formValues.dosis}
              onChange={handleInputChange}
              labelName={`${t("frequency")}:`}
              type="text"
              name="dosis"
              required
              noMargin
            />
            <Input
              value={formValues.instructions}
              onChange={handleInputChange}
              labelName={t("treatment_duration")}
              type="text"
              name="instructions"
              required
              noMargin
            />
            <Button>
              <button
                type="button"
                onClick={handleAddRecipe}
                disabled={
                  !formValues.name || !formValues.dci || !formValues.type
                }
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  background: "black",
                  color: "white",
                  borderRadius: 23,
                  marginTop: 5,
                  padding: "5px 10px",
                }}
              >
                <AddCircle size="40" />
                Añadir a Receta
              </button>
            </Button>
          </section>
          <AbsoluteSendButton>
            <ButtonWithLoader
              isLoading={isButtonLoading}
              type="button"
              onClick={handleClick}
              color={Theme.color.secondary}
              disabled={medicines.length <= 0}
              small
            >
              {t("send")}
            </ButtonWithLoader>
          </AbsoluteSendButton>
        </MedicalPrescription>
      </Container>
      {isModalOpen && (
        <CustomModal
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <p>{t("web_client.unsaved_data")}</p>
                <div>
                  <button type="button" onClick={() => setIsModalOpen(false)}>
                    {t("cancel")}
                  </button>
                  <button type="button" onClick={handleCloseConfirmation}>
                    {t("confirm")}
                  </button>
                </div>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
      {!isEmpty(isProductModalOpen) && (
        <CustomModal
          modalOpen={!isEmpty(isProductModalOpen)}
          setModalOpen={() => setProductModal("")}
          showClose
        >
          <ModalWrapper isFull>
            {renderProductMedia(isProductModalOpen, true)}
          </ModalWrapper>
        </CustomModal>
      )}
      {isProductInfoModalOpen && (
        <CustomModal
          modalOpen={isProductInfoModalOpen}
          setModalOpen={() => setProductInfoModal(!isProductInfoModalOpen)}
          showClose
        >
          <ProductModalWrapper>
            <ProductModalColumn margin="0 30px 0 0">
              <Text.Title>{t("product_info_title")}</Text.Title>
              <Text.SubTitle>{t("product_fields.country_label")}</Text.SubTitle>
              <Text.Body>{getProductField("country")}</Text.Body>

              <Text.SubTitle>{t("product_fields.sku_label")}</Text.SubTitle>
              <Text.Body>{getProductField("sku")}</Text.Body>

              <Text.SubTitle>{t("product_fields.lab_name")}</Text.SubTitle>
              <Text.Body>{getProductField("labName")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.several_restricted_label")}
              </Text.SubTitle>
              <Text.Body>
                {productInfo.severalRestricted
                  ? t("product_fields.yes")
                  : t("product_fields.no")}
              </Text.Body>

              <Text.SubTitle>
                {t("product_fields.prescription_restricted_label")}
              </Text.SubTitle>
              <Text.Body>
                {productInfo.prescriptionRestricted
                  ? t("product_fields.yes")
                  : t("product_fields.no")}
              </Text.Body>

              <Text.SubTitle>
                {t("product_fields.composition_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("composition")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.composition_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("composition")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.pharmaceutical_presentation_label")}
              </Text.SubTitle>
              <Text.Body>
                {getProductField("pharmaceuticalPresentation")}
              </Text.Body>

              <Text.SubTitle>{t("product_fields.cie11_label")}</Text.SubTitle>
              <Text.Body>{getProductField("cie11")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.therapeutic_indications_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("therapeuticIndications")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.pharmacological_action_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("pharmacologicalAction")}</Text.Body>
            </ProductModalColumn>
            <ProductModalColumn margin="60px 0 0 0">
              <Text.SubTitle>
                {t("product_fields.excipients_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("excipients")}</Text.Body>

              <Text.SubTitle>{t("product_fields.dose_label")}</Text.SubTitle>
              <Text.Body>{getProductField("dose")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.contraindications_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("contraindications")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.drug_interactions_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("drugInteractions")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.restrictions_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("restrictions")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.reactions_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("reactions")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.warnings_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("warnings")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.precautions_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("precautions")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.overdose_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("overDoseIndications")}</Text.Body>

              <Text.SubTitle>{t("product_fields.storage_label")}</Text.SubTitle>
              <Text.Body>{getProductField("storageIndications")}</Text.Body>

              <Text.SubTitle>
                {t("product_fields.expiration_label")}
              </Text.SubTitle>
              <Text.Body>{getProductField("expirationIndications")}</Text.Body>
            </ProductModalColumn>
          </ProductModalWrapper>
        </CustomModal>
      )}
    </SectionsDoubleContainer>
  )
}

export default withFirebase(Prescription)
