import React from "react"
import PropTypes from "prop-types"
import Select from "../CustomSelect"

const TimezoneSelect = ({ timezones, zone, defaultZone, onChange }) => (
  <Select
    formKey="cz"
    items={timezones.map((z) => ({ value: z.name, label: z.name }))}
    value={zone}
    defaultValue={defaultZone}
    handleChange={(formKey, z) => onChange(z)}
  />
)

TimezoneSelect.propTypes = {
  zone: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultZone: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
}

export default TimezoneSelect
