import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  margin: 22px 0;
  padding-left: 22px;
`

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 300px;
  overflow: auto;
`

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 50px;
  margin-top: 44px;
  width: 50%;
`

export const Tab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
  border-radius: 100px;
  margin: 0 26px;
  text-align: center;
  cursor: pointer;
  background: ${(props) =>
    props.isActive ? props.theme.color.accentV3 : "none"};
  & > span {
    ${(props) => props.theme.font_size.xxxsmall};
    color: ${(props) => props.theme.color.black.normal};
    opacity: 0.6;
    margin-left: 16.5px;

    @media (min-width: ${(props) => props.theme.screen.md}) {
      ${(props) => props.theme.font_size.xsmall};
    }
    @media (min-width: ${(props) => props.theme.screen.xlg}) {
      ${(props) => props.theme.font_size.xsmall};
    }
  }
`
