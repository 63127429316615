import styled from "styled-components"

export const EventWrapper = styled.div``

export const StyleWrapper = styled.div`
  .fc td {
    background: white;
  }
  .fc .fc-button-primary {
    background-color: ${(props) => props.theme.color.secondary};
    border-color: white;
    :hover {
      background-color: ${(props) => props.theme.color.palePuple};
    }
    :not(:disabled).fc-button-active {
      background-color: ${(props) => props.theme.color.palePuple};
      border-color: white;
    }
  }
`
