/* eslint-disable no-console */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from "react"
import PropTypes from "prop-types"
import has from "lodash.has"
import get from "lodash.get"
import isEmpty from "lodash.isempty"
import { compose } from "recompose"
import { v4 as uuidv4 } from "uuid"
import { withTrans } from "../../../i18n/withTrans"
import MedicalForm from "../../molecules/MedicalForm"
import PreviewMessage from "../../molecules/PreviewMessage"
import Room from "../../molecules/Room"
import ChatHeader from "../../molecules/ChatHeader"
import ChatContainer from "../../molecules/ChatContainer"
import ChatFooter from "../../molecules/ChatFooter"
import Modal from "../../atoms/CustomModal"
import { showBrowserNotification, prepareMessage } from "../../../utils/helpers"
import { withFirebase } from "../../../utils/Firebase"

import {
  videoGenerateAccessTokenUrl,
  cancelSessionUrl,
} from "../../../utils/App"
import { ChatWrapper, Overlay, ModalWrapper, ModalOptions } from "./styles"

class Chat extends Component {
  constructor(props) {
    super(props)
    this.messagesRef = props.firebase.subscribeToMessages({
      chat: props.session,
    })
    this.serverTimeOffsetRef = null
    this.state = {
      medicalForm: null,
      medicalFormVisible: false,
      currentMessages: [],
      targetTokens: [],
      twilioToken: null,
      videoVisible: false,
      messagesLoaded: false,
      content: null,
      fullMessage: null,
      previewMessageVisible: false,
      messageType: null,
      newVideoSession: null,
      serverTimeOffset: 0,
      referenceKeys: [],
      messageKey: null,
      sessionNoShow: false,
      isModalOpen: false,
      displayWaitButton: true,
      isVideoModalOpen: false,
      isExtendTimeOpen: false,
      isNotesVisible: false,
    }
  }

  componentDidMount() {
    const { firebase, history } = this.props
    this.getTargetTokens()
    this.fetchTwilioToken()
    if (history && history.location.state && history.location.state.session) {
      const state = { ...history.location.state }
      delete state.session
      history.replace({ ...history.location, state })
    }
    if (firebase) {
      this.serverTimeOffsetRef = firebase.getServerTimeOffsetRef()
      this.serverTimeOffsetRef.on("value", this.onServerTimeValue, this.onError)
    }
    // this.handleLoadMore()
  }

  componentDidUpdate(prevProps, prevState) {
    const { session, firebase, messages, customLimit } = this.props
    const { messagesLoaded } = this.state
    if (prevProps.session !== session) {
      this.setState({ messagesLoaded: false })
      if (this.messagesRef) {
        this.messagesRef.off("child_added", this.onChildAdded)
      }
      this.setState({
        currentMessages: [],
      })
      this.messagesRef = firebase.subscribeToMessages({
        chat: session,
      })
    }
    if (messages !== prevProps.messages) {
      // eslint-disable-next-line no-unused-vars
      const noShowMessages = messages.filter(
        (m) => m.flag === "noshowclient" && m.showTo === "provider"
      )
      this.setState(
        {
          currentMessages: messages,
        },
        () => {
          this.setState({ messagesLoaded: true })
        }
      )
    }
    if (messagesLoaded !== prevState.messagesLoaded && messagesLoaded) {
      if (this.messagesRef) {
        this.messagesRef
          .orderByChild("createdAt")
          .limitToLast(customLimit)
          .on("child_added", this.onChildAdded, this.onError)
      }
    }
  }

  componentWillUnmount() {
    if (this.messagesRef) {
      this.messagesRef.off("child_added", this.onChildAdded)
    }
    if (this.serverTimeOffsetRef) {
      this.serverTimeOffsetRef.off("value", this.onServerTimeValue)
    }
  }

  getTargetTokens() {
    const { session, firebase, info } = this.props
    const { targetTokens } = this.state
    if (isEmpty(targetTokens)) {
      const storedTargetTokens = localStorage.getItem(`targetTokens-${session}`)
      if (storedTargetTokens === null && info) {
        firebase
          .getProfile({
            email: info.clientEmail,
          })
          .then((snapshot) => {
            if (snapshot.exists) {
              const targetData = snapshot.data() || []
              const { messagingTokens } = targetData || {}
              this.setState({
                targetTokens: messagingTokens,
              })
              localStorage.setItem(
                `targetTokens-${session}`,
                JSON.stringify(messagingTokens)
              )
            }
          })
      } else {
        this.setState({
          targetTokens: JSON.parse(storedTargetTokens),
        })
      }
    }
  }

  onServerTimeValue = (snapshot) => {
    this.setState({
      serverTimeOffset: snapshot.val(),
    })
  }

  onError = (_error) => {
    console.error("Error: -- chats --", _error)
  }

  onChildAdded = async (snapshot) => {
    // eslint-disable-next-line no-unused-vars
    const { messageKeys, info, firebase, t } = this.props

    // eslint-disable-next-line react/destructuring-assignment
    const { sessionNoShow, videoVisible } = this.state
    const messageData = snapshot.val()
    const messageId = messageData.uploadId || messageData._id
    if (!has(messageKeys, messageId)) {
      if (
        messageData.flag === "noshowclient" &&
        messageData.showTo === "provider" &&
        info.clientWarningsCount <= 2 && // empty messages prop indicates that is coming from (Prescription, Clinical, Images)
        !sessionNoShow
      ) {
        this.setState({
          sessionNoShow: true,
          isModalOpen: true,
          displayWaitButton: messageData.displayedWarnings < 2,
        })
        const Settings = await firebase.getSettings({
          email: info.providerEmail,
          key: "notifications",
        })
        if (Settings.data() && Settings.data().onNoShowBrowser) {
          showBrowserNotification(
            t("session_noshow_alert", { name: get(info, "clientName", "") })
          )
        }
      }

      // Check if there is an incoming videocall request
      if (
        messageData.system &&
        /^video_session_started\|/.test(messageData.text) &&
        info.providerEmail !== messageData.user._id &&
        !videoVisible
      ) {
        const msgArr = messageData.text.split("|")
        this.videoMsg = t("join_video_call", {
          name: msgArr[2],
        })
        this.setState({ isVideoModalOpen: true })
      }

      if (
        messageData.system &&
        messageData.text === "session_timeout_warning"
      ) {
        this.setState({ isExtendTimeOpen: true })
      }

      if (messageData.user && info.providerEmail !== messageData.user._id) {
        this.setState({
          sessionNoShow: false,
          isModalOpen: false,
          displayWaitButton: false,
        })
      }
      this.setState((prevState) => ({
        currentMessages: [
          ...prevState.currentMessages,
          { ...messageData, key: snapshot.key },
        ],
        newVideoSession: null,
      }))
    }
    this.setState((prevState) => ({
      referenceKeys: [
        ...prevState.referenceKeys.filter((rk) => rk.key !== snapshot.key),
        {
          id: messageId,
          key: snapshot.key,
        },
      ],
    }))
  }

  handleNotesClick = () => {
    const { isNotesVisible: currentVisible } = this.state
    this.setState({ isNotesVisible: !currentVisible })
  }

  handleMessageClick = (message) => {
    const { readOnlyChat, isFromContacts } = this.props
    const { referenceKeys } = this.state
    const msgKey = referenceKeys.find((rk) => rk.id === message._id)
    if (!readOnlyChat || isFromContacts) {
      if (message.type === "medical") {
        this.setState({
          medicalForm: {
            data: message.form,
            owner: message.profileOwner,
            type: message.profileType,
          },
          medicalFormVisible: true,
        })
      } else if (message.type === "prescription") {
        this.setState({
          fullMessage: message,
          content: message.prescription,
          previewMessageVisible: true,
          messageType: "prescription",
          messageKey: msgKey.key,
        })
      } else if (message.type === "clinical") {
        this.setState({
          fullMessage: message,
          content: message.clinical,
          previewMessageVisible: true,
          messageType: "clinical",
          messageKey: msgKey.key,
        })
      } else if (message.type === "medicalImages") {
        this.setState({
          fullMessage: message,
          content: message.medicalImages,
          previewMessageVisible: true,
          messageType: "medicalImages",
          messageKey: msgKey.key,
        })
      }
    }
  }

  handleLoadMore = () => {
    const { setLoadMore } = this.props
    setLoadMore()
  }

  handleVideoClick = () => {
    this.setState({
      videoVisible: true,
      isVideoModalOpen: false,
    })
  }

  handleMedicalClose = () => {
    this.setState({
      medicalForm: null,
      medicalFormVisible: false,
    })
  }

  handlePreviewClose = () => {
    this.setState({
      fullMessage: null,
      content: null,
      previewMessageVisible: false,
      messageType: null,
    })
  }

  handleLogout = () => {
    this.setState({
      videoVisible: false,
    })
  }

  handleNewVideoSession = (displayName) => {
    this.setState({
      newVideoSession: displayName || null,
    })
  }

  sendMessage = async (msg) => {
    const { session, user, firebase } = this.props
    const { serverTimeOffset } = this.state
    const prepMsg = prepareMessage(
      user,
      serverTimeOffset,
      msg,
      msg.type || "text",
      msg.targetTokens || []
    )
    await firebase.pushMessage({ chat: session, message: prepMsg })
  }

  handleFinishSession = async () => {
    const { firebase, session, history, info } = this.props
    try {
      const authToken = await firebase.getIdToken()
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chatId: session,
          clientEmail: info.clientEmail,
          clientName: info.clientName,
          providerEmail: info.providerEmail,
          noShow: true,
        }),
      }
      const response = await fetch(cancelSessionUrl, options)
      const data = await response.json()
      if (data) {
        this.setState({ isModalOpen: false })
        history.push("/sessions")
      }
    } catch (e) {
      window.Honeybadger?.notify(e)
    }
  }

  handleExtendSession = async () => {
    const { firebase, session } = this.props
    const { targetTokens } = this.state
    try {
      await firebase.addWaitingMinutes({
        chat: session,
      })
      await this.sendMessage({
        _id: uuidv4(),
        text: `session_extended`,
        type: "system",
        targetTokens,
      })
      this.setState({
        isExtendTimeOpen: false,
      })
    } catch (error) {
      window.Honeybadger?.notify(error)
    }
  }

  // Get a Twilio token if it doesn't have one
  async fetchTwilioToken() {
    const { session, user, firebase } = this.props
    const { twilioToken } = this.state
    if (twilioToken === null) {
      const storedToken = localStorage.getItem(`twilioToken-${session}`)
      if (storedToken === null) {
        firebase
          .getIdToken()
          .then((token) => {
            fetch(videoGenerateAccessTokenUrl, {
              method: "POST",
              body: JSON.stringify({
                client: user.isImpersonating
                  ? user.impersonatingEmail
                  : user.email,
                room: session,
              }),
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            })
              .then((response) => {
                if (response.ok) {
                  return response.json()
                }
                throw new Error(
                  `Unexpected Response from Server: ${response.status}`
                )
              })
              .then((data) => {
                const tmpToken = get(data, "token", null)
                localStorage.setItem(
                  `twilioToken-${session}`,
                  JSON.stringify(tmpToken)
                )
                this.setState({
                  twilioToken: tmpToken,
                })
              })
              .catch((err) => {
                window.Honeybadger?.notify(err)
              })
          })
          .catch((err) => {
            window.Honeybadger?.notify(err)
          })
      } else {
        this.setState({
          twilioToken: JSON.parse(storedToken),
        })
      }
    }
  }

  render() {
    const { user, firebase, info, session, readOnlyChat, t } = this.props
    const {
      medicalFormVisible,
      currentMessages,
      medicalForm,
      twilioToken,
      videoVisible,
      content,
      previewMessageVisible,
      messageType,
      newVideoSession,
      messageKey,
      serverTimeOffset,
      isModalOpen,
      displayWaitButton,
      fullMessage,
      isVideoModalOpen,
      isExtendTimeOpen,
      isNotesVisible,
    } = this.state

    const isDependentChat =
      currentMessages &&
      currentMessages.length > 0 &&
      currentMessages.find(
        (m) => m.profileType === "Dependent" || m.profileType === "Dependiente"
      )

    return (
      <ChatWrapper>
        {!medicalFormVisible && !videoVisible && !previewMessageVisible && (
          <>
            <ChatHeader
              user={user}
              session={session}
              info={info}
              name={get(info, "clientEmail", "")}
              handleClick={this.handleVideoClick}
              showButton={!readOnlyChat}
              serverTimeOffset={serverTimeOffset}
              orderId={get(info, "orderId")}
              donated={get(info, "donated")}
              sessionDiscount={get(info, "sessionDiscount")}
              isDependentChat={isDependentChat}
            />
            <ChatContainer
              key="chat-container"
              messages={currentMessages}
              user={user}
              handleClick={this.handleMessageClick}
              handleLoadMore={this.handleLoadMore}
              session={session}
              isDependentChat={isDependentChat}
              isNotesVisible={isNotesVisible}
              info={info}
              handleNotesClick={this.handleNotesClick}
            />
            {!readOnlyChat && (
              <ChatFooter
                user={user}
                session={session}
                info={info}
                newVideoSession={newVideoSession}
                serverTimeOffset={serverTimeOffset}
                isDependentChat={isDependentChat}
                handleNotesClick={this.handleNotesClick}
              />
            )}
          </>
        )}
        {medicalFormVisible && (
          <>
            <MedicalForm
              handleClose={this.handleMedicalClose}
              message={medicalForm}
              info={info}
            />
            <Overlay />
          </>
        )}
        {previewMessageVisible && (
          <>
            <PreviewMessage
              handleClose={this.handlePreviewClose}
              messageKey={messageKey}
              content={content}
              info={info}
              user={user}
              firebase={firebase}
              type={messageType}
              fullMessage={fullMessage}
            />
            <Overlay />
          </>
        )}
        {videoVisible && (
          <>
            <Room
              token={twilioToken}
              roomName={session}
              handleLogout={this.handleLogout}
              info={info}
              user={user}
              onNewSession={this.handleNewVideoSession}
            />
            <Overlay />
          </>
        )}
        {isVideoModalOpen && (
          <Modal
            modalOpen={isVideoModalOpen}
            setModalOpen={(status) => {
              this.setState({ isVideoModalOpen: status })
            }}
            showClose={false}
          >
            <ModalWrapper isFull>
              <ModalOptions>
                <section>
                  <p>{this.videoMsg}</p>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({
                          isVideoModalOpen: false,
                        })
                      }}
                    >
                      {t("cancel")}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        this.handleVideoClick()
                      }}
                    >
                      {t("confirm")}
                    </button>
                  </div>
                </section>
              </ModalOptions>
            </ModalWrapper>
          </Modal>
        )}
        <Modal
          modalOpen={isModalOpen}
          setModalOpen={(status) => {
            this.setState({ isModalOpen: status })
          }}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <p>{t("no_show_message_client")}</p>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      this.handleFinishSession()
                    }}
                  >
                    {t("session_cancel")}
                  </button>
                  {displayWaitButton && (
                    <button
                      type="button"
                      onClick={async () => {
                        firebase.dismissClientWarning({
                          chat: session,
                        })
                        this.setState({
                          isModalOpen: false,
                          sessionNoShow: false,
                        })
                      }}
                    >
                      {t("wait")}
                    </button>
                  )}
                </div>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </Modal>
        <Modal
          modalOpen={isExtendTimeOpen}
          setModalOpen={(status) => {
            this.setState({ isExtendTimeOpen: !status })
          }}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <p>{t("extend_session_message")}</p>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      this.setState({ isExtendTimeOpen: false })
                    }}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      this.handleExtendSession()
                    }}
                  >
                    {t("extend_button")}
                  </button>
                </div>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </Modal>
      </ChatWrapper>
    )
  }
}

Chat.defaultProps = {
  readOnlyChat: false,
  isFromContacts: false,
}

Chat.propTypes = {
  t: PropTypes.func.isRequired,
  firebase: PropTypes.shape().isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  messageKeys: PropTypes.shape().isRequired,
  readOnlyChat: PropTypes.bool,
  history: PropTypes.func.isRequired,
  isFromContacts: PropTypes.bool,
}

const ChatCompose = compose(withTrans, withFirebase)(Chat)

export default ChatCompose
