import styled from "styled-components"

const Body = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  margin-bottom: 4px;
`
export default Body
