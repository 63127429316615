const clinical = {
  hematology: {
    title: "Hematología y coagulación",
    options: [
      {
        title: "Ácido Fólico",
        type: "checkbox",
      },
      {
        title: "Biometría",
        type: "checkbox",
      },
      {
        title: "Calcio Iónico",
        type: "checkbox",
      },
      {
        title: "Calcio Sérico",
        type: "checkbox",
      },
      {
        title: "Células L E",
        type: "checkbox",
      },
      {
        title: "Citología Nasal",
        type: "checkbox",
      },
      {
        title: "Coagulación y Sangría",
        type: "checkbox",
      },
      {
        title: "Cuenta de plaquetas",
        type: "checkbox",
      },
      {
        title: "Cuenta de Reticulocitos",
        type: "checkbox",
      },
      {
        title: "Eritrosedimentación (VSG)",
        type: "checkbox",
      },
      {
        title: "Ferritina",
        type: "checkbox",
      },
      {
        title: "Fibrinógeno",
        type: "checkbox",
      },
      {
        title: "Fórmula Roja",
        type: "checkbox",
      },
      {
        title: "Fórmula Blanca",
        type: "checkbox",
      },
      {
        title: "Grupo Sanguíneo y Factor RH",
        type: "checkbox",
      },
      {
        title: "Hemoglobina Glicosilada",
        type: "checkbox",
      },
      {
        title: "Hemoglobina/ HT",
        type: "checkbox",
      },
      {
        title: "Hemográma Completo Hgma",
        type: "checkbox",
      },
      {
        title: "Hierro Sérico",
        type: "checkbox",
      },
      {
        title: "Plasmódium (Hematozoarios)",
        type: "checkbox",
      },
      {
        title: "Pruebas Cruzadas",
        type: "checkbox",
      },
      {
        title: "Tiempo de Coagulación",
        type: "checkbox",
      },
      {
        title: "Tiempo de Protombina",
        type: "checkbox",
      },
      {
        title: "Tiempo de Sangrado",
        type: "checkbox",
      },
      {
        title: "Tiempo de Tromboplastina Parcial",
        type: "checkbox",
      },
      {
        title: "Tiempo de Trombina",
        type: "checkbox",
      },
      {
        title: "Vitamina B12",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  immunology: {
    title: "Serología e inmunología",
    options: [
      {
        title: "Ac. Anti Cardiolipinas IgG e lgM",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Cisticerco",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Citomegalovirus lgG e IgM",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Chlamydia",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Dengue IgG e IgM",
        type: "checkbox",
      },
      {
        title: "Ac. Anti DNA nativo / IF",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Espermatozoides",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Helicobacter Pylori",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Herpes 2 IgG e IgM",
        type: "checkbox",
      },
      {
        title: "Ac. Anti HIV 1 y 2",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Músculo Liso",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Microsomales / iF",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Nucleares / IF",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Peroxidasa",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Rubéola IgG e IgM",
        type: "checkbox",
      },
      {
        title: "Ac. Anti RNP (Enfermedad Mixta)",
        type: "checkbox",
      },
      {
        title: "Ac. Anti SSA (Ro)",
        type: "checkbox",
      },
      {
        title: "Ac. Anti SSB (La)",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Sm (Smith)",
        type: "checkbox",
      },
      {
        title: "Ac. Anti SCL -70 (Esclerodermia)",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Tiroides / iF",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Tiroglobulina / iF",
        type: "checkbox",
      },
      {
        title: "Ac. Anti Toxoplasma IgG e IgM",
        type: "checkbox",
      },
      {
        title: "Ac. Anti HIV (Combo)",
        type: "checkbox",
      },
      {
        title: "Anca C",
        type: "checkbox",
      },
      {
        title: "Anca P",
        type: "checkbox",
      },
      {
        title: "Antiestreptolisinas",
        type: "checkbox",
      },
      {
        title: "Anticoagulante Lúpico",
        type: "checkbox",
      },
      {
        title: "Complemento Hemolítico C3, C4, CH50, CH100",
        type: "checkbox",
      },
      {
        title: "Coombs Directo",
        type: "checkbox",
      },
      {
        title: "Coombs Indirecto",
        type: "checkbox",
      },
      {
        title: "Cuantificación de Gonadotrofina Coriónica",
        type: "checkbox",
      },
      {
        title: "Factor Reumatoide",
        type: "checkbox",
      },
      {
        title: "Monotest",
        type: "checkbox",
      },
      {
        title: "Perfil Torch IgG =>, IgM =>",
        type: "other",
      },
      {
        title: "Proteína C reactiva (Alta sensibilidad)",
        type: "checkbox",
      },
      {
        title: "Prueba de embarazo en Sangre (HCG)",
        type: "checkbox",
      },
      {
        title: "Prueba de embarazo en Orina",
        type: "checkbox",
      },
      {
        title: "Reacciones Febriles",
        type: "checkbox",
      },
      {
        title: "V.D.R.L",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  pathology: {
    title: "Patología",
    options: [
      {
        title: "Biopsia por Aspiración (BAAF) mama, tiroides, tejidos blandos",
        type: "checkbox",
      },
      {
        title: "Biopsia de =>",
        type: "other",
      },
      {
        title: "Citología de líquidos Corporales",
        type: "checkbox",
      },
      {
        title: "(Orina, Expectoración, ascitis)",
        type: "checkbox",
      },
      {
        title: "ThinPrep (Papanicolaou en Base Líquida)",
        type: "checkbox",
      },
      {
        title: "Papanicolaou Convencional",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  biochemistry: {
    title: "Bioquímica",
    options: [
      {
        title: "Ácido Úrico",
        type: "checkbox",
      },
      {
        title: "Anti. Fosfolípidos Panel IgG",
        type: "checkbox",
      },
      {
        title: "Anti. Fosfolípidos Panel IgM",
        type: "checkbox",
      },
      {
        title: "BUN (Nitrógeno Ureico)",
        type: "checkbox",
      },
      {
        title: "Calcio",
        type: "checkbox",
      },
      {
        title: "Citoquímico de líquido",
        type: "checkbox",
        options: [
          {
            title: "Cefalorraquídeo",
            type: "checkbox",
          },
          {
            title: "Sinovial",
            type: "checkbox",
          },
          {
            title: "Pleural",
            type: "checkbox",
          },
          {
            title: "Otros",
            type: "other",
          },
        ],
      },
      {
        title: "Cloro",
        type: "checkbox",
      },
      {
        title: "Colesterol total",
        type: "checkbox",
      },
      {
        title: "Colesterol HDL",
        type: "checkbox",
      },
      {
        title: "Colesterol LDL",
        type: "checkbox",
      },
      {
        title: "Colesterol VLDL",
        type: "checkbox",
      },
      {
        title: "Creatinina",
        type: "checkbox",
      },
      {
        title: "Electroforesis de proteínas.",
        type: "checkbox",
      },
      {
        title: "Fósforo",
        type: "checkbox",
      },
      {
        title: "Gases Arteriales",
        type: "checkbox",
      },
      {
        title: "Glucosa",
        type: "checkbox",
      },
      {
        title: "Hemoglobina Glicosilada y Fracción A1C",
        type: "checkbox",
      },
      {
        title: "Lípidos Totales",
        type: "checkbox",
      },
      {
        title: "Litio",
        type: "checkbox",
      },
      {
        title: "Magnesio",
        type: "checkbox",
      },
      {
        title: "Perfil de Lípidos",
        type: "checkbox",
      },
      {
        title: "Potasio",
        type: "checkbox",
      },
      {
        title: "Proteína Totales, Albúmina y Relación A/G",
        type: "checkbox",
      },
      {
        title: "Química Sanguínea (Glucosa, Urea y Creatinina)",
        type: "checkbox",
      },
      {
        title: "Riesgo Coronario",
        type: "checkbox",
      },
      {
        title: "Sodio",
        type: "checkbox",
      },
      {
        title: "Triglicéridos",
        type: "checkbox",
      },
      {
        title: "Urea",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  functionals: {
    title: "Pruebas funcionales",
    options: [
      {
        title: "Amilasa",
        type: "checkbox",
      },
      {
        title: "Bilirrubinas (Dir. Ind. Total)",
        type: "checkbox",
      },
      {
        title: "Curva de Tolerancia a la Glucosa",
        type: "checkbox",
      },
      {
        title: "Curva de Tolerancia a la Lactosa",
        type: "checkbox",
      },
      {
        title: "Creatinfosfoquinasa Total (CPK)",
        type: "checkbox",
      },
      {
        title: "Creatinfosfoquinasa Fracción MB (CK -MB)",
        type: "checkbox",
      },
      {
        title: "Deshidrogenasa Láctica",
        type: "checkbox",
      },
      {
        title: "Depuración endógena de creatinina",
        type: "checkbox",
      },
      {
        title: "Elastasa Pancréatica",
        type: "checkbox",
      },
      {
        title: "Eliminación de D-Xilosa",
        type: "checkbox",
      },
      {
        title: "Espermatobioscopía Directa",
        type: "checkbox",
      },
      {
        title: "Espermatobioscopía Post - Coito (Sims- Hühner)",
        type: "checkbox",
      },
      {
        title: "Fibromax",
        type: "checkbox",
      },
      {
        title: "Fibrotest",
        type: "checkbox",
      },
      {
        title: "Fosfatasa Alcalina",
        type: "checkbox",
      },
      {
        title: "Fosfatasa Ácida Total",
        type: "checkbox",
      },
      {
        title: "Fosfatasa Ácida Fracción Prostática",
        type: "checkbox",
      },
      {
        title: "Gama Glutamil Transferasa",
        type: "checkbox",
      },
      {
        title: "Glucosa pre y post Prandial",
        type: "checkbox",
      },
      {
        title: "Proteínas Totales y fraccionadas",
        type: "checkbox",
      },
      {
        title: "Transaminasa Glutámico Pirúvica (ALAT)",
        type: "checkbox",
      },
      {
        title: "Transaminasa Glutámico Oxalacética (ASAT)",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  hepatitis: {
    title: "Marcadores de hepatitis",
    options: [
      {
        title: "AC. Anti HAV IgM",
        type: "checkbox",
      },
      {
        title: "AC. Anti HAV Total",
        type: "checkbox",
      },
      {
        title: "AC. Anti Ag. Nuclear Hepatitis B (Core IgG)",
        type: "checkbox",
      },
      {
        title: "AC. Anti HVC Total",
        type: "checkbox",
      },
      {
        title: "AC. de superficie Hepatitis B",
        type: "checkbox",
      },
      {
        title: "Hepatitis A, Anticuerpos IgG",
        type: "checkbox",
      },
      {
        title: "Hepatitis A, Anticuerpos IgM",
        type: "checkbox",
      },
      {
        title: "Hepatitis Autoinmune, Panel",
        type: "checkbox",
      },
      {
        title: "Hepatitis B, Anti HB (Ag (Anti E))",
        type: "checkbox",
      },
      {
        title: "Hepatitis B, Anti-HBsAg",
        type: "checkbox",
      },
      {
        title: "Hepatitis B, HB (Ag E)",
        type: "checkbox",
      },
      {
        title: "Hepatitis B, HB (Anti Core)",
        type: "checkbox",
      },
      {
        title: "Hepatitis B, HB (Anti Core) IgM",
        type: "checkbox",
      },
      {
        title: "Hepatitis B, HBsAg (Ag.Austr.)",
        type: "checkbox",
      },
      {
        title: "Hepatitis C, HVC",
        type: "checkbox",
      },
      {
        title: "Hepatitis C, IgM",
        type: "checkbox",
      },
      {
        title: "Hepatitis D, IgG",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  gynecology: {
    title: "Ginecología / Obstetricia",
    options: [
      {
        title: "AC. Anti Espermatozoides",
        type: "checkbox",
      },
      {
        title: "AC. Anti Zona Pelúcida",
        type: "checkbox",
      },
      {
        title: "AC. Anti Ovario",
        type: "checkbox",
      },
      {
        title: "Capacitación Espermática",
        type: "checkbox",
      },
      {
        title:
          "Criopreservación de Células Madre de Cordón y placenta (DNA VITA, Baby care)",
        type: "checkbox",
      },
      {
        title: "Cultivo Secreción Vaginal",
        type: "checkbox",
      },
      {
        title: "Esperma, Estudio Químico",
        type: "checkbox",
      },
      {
        title: "Espermatograma",
        type: "checkbox",
      },
      {
        title: "Espermatozoides, Ac. Semen",
        type: "checkbox",
      },
      {
        title: "Espermatozoides, Ac. Suero",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  markers: {
    title: "Marcadores Tumorales",
    options: [
      {
        title: "AFP Cáncer de Hígado",
        type: "checkbox",
      },
      {
        title: "Alfa Feto Proteína (AFP)",
        type: "checkbox",
      },
      {
        title: "Ag. de Tumor de vejiga (BTA)",
        type: "checkbox",
      },
      {
        title: "Ag. Prostático Específico Total (PSA)",
        type: "checkbox",
      },
      {
        title: "Ag. Prostático Específico Libre (PFSA)",
        type: "checkbox",
      },
      {
        title: "Ag. CA - 125 (Ovario)",
        type: "checkbox",
      },
      {
        title: "Ag. CA - 15.3 (Mama)",
        type: "checkbox",
      },
      {
        title: "Ag. CA - 19.9 (Páncreas y colon)",
        type: "checkbox",
      },
      {
        title: "Ag. CA - 21.1 (Pulmón)",
        type: "checkbox",
      },
      {
        title: "Ag. Carcinoembrionario (CEA)",
        type: "checkbox",
      },
      {
        title: "BETA - 2 Microglobulina",
        type: "checkbox",
      },
      {
        title: "CA 72-4 (Estómago)",
        type: "checkbox",
      },
      {
        title:
          "Cuantificación de Gonadotrofina Coriónica (Fracción Beta en Sangre)",
        type: "checkbox",
      },
      {
        title: "Panel Tumores Mieloides",
        type: "checkbox",
      },
      {
        title: "TNF-Alpha (Factor Necrosis Tumoral)",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  hormones: {
    title: "Hormonas",
    options: [
      {
        title: "Cortisol",
        type: "checkbox",
      },
      {
        title: "Dehidroeplandrosterona (DHEA)",
        type: "checkbox",
      },
      {
        title: "Estradiol (E2)",
        type: "checkbox",
      },
      {
        title: "Hormona Folículo Estimulante (FSH)",
        type: "checkbox",
      },
      {
        title: "Hormona crecimiento",
        type: "checkbox",
      },
      {
        title: "Hormona Luteinizante (LH)",
        type: "checkbox",
      },
      {
        title: "Insulina",
        type: "checkbox",
      },
      {
        title: "Parathormona (PTH)",
        type: "checkbox",
      },
      {
        title: "Perfil Ginecológico",
        type: "checkbox",
      },
      {
        title: "Perfil Tiroideo",
        type: "checkbox",
      },
      {
        title: "Prolactina",
        type: "checkbox",
      },
      {
        title: "Progesterona => día del ciclo",
        type: "other",
      },
      {
        title: "Prueba de estimulación de => con TRH",
        type: "other",
      },
      {
        title: "Testosterona Total => Libre => ",
        type: "other",
      },
      {
        title: "T3 Captación",
        type: "checkbox",
      },
      {
        title: "T3 Total",
        type: "checkbox",
      },
      {
        title: "T3 Libre",
        type: "checkbox",
      },
      {
        title: "T4 Libre",
        type: "checkbox",
      },
      {
        title: "THS US",
        type: "checkbox",
      },
      {
        title: "TSH Ultrasensible",
        type: "checkbox",
      },
      {
        title: "17 Cetoesteroides",
        type: "checkbox",
      },
      {
        title: "17 Hidroxiesteroides",
        type: "checkbox",
      },
      {
        title: "17 Hidroxiprogesterona",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  utorhino: {
    title: "Otorrinolaringología",
    options: [
      {
        title: "Audiometría",
        type: "checkbox",
      },
      {
        title: "Audiometría tonal",
        type: "other",
      },
      {
        title: "Audiometría vocal/logoaudiometría",
        type: "other",
      },
      {
        title:
          "Ecografía (senos paranasales, nódulos linfáticos, glándulas salivales)",
        type: "other",
      },
      {
        title: "Electromiografía (laringe)",
        type: "checkbox",
      },
      {
        title: "Endoscopía (nariz, faringe, laringe)",
        type: "other",
      },
      {
        title: "Fibroscopía",
        type: "checkbox",
      },
      {
        title: "Impedanciometría",
        type: "checkbox",
      },
      {
        title: "Microscopía del oído",
        type: "checkbox",
      },
      {
        title: "Nasoscopía",
        type: "checkbox",
      },
      {
        title: "Prueba vestibular",
        type: "checkbox",
      },
      {
        title: "Pruebas supraliminares",
        type: "checkbox",
      },
      {
        title: "Reflejo acústico",
        type: "checkbox",
      },
      {
        title: "Rinomanometría",
        type: "checkbox",
      },
      {
        title: "Timpanometria",
        type: "checkbox",
      },
    ],
  },
  drugs: {
    title: "Drogas",
    options: [
      {
        title: "Alcohol en saliva",
        type: "checkbox",
      },
      {
        title: "Anfetaminas",
        type: "checkbox",
      },
      {
        title: "Ácido Valproico (Depakene)",
        type: "checkbox",
      },
      {
        title: "Barbitúricos",
        type: "checkbox",
      },
      {
        title: "Benzodiacepinas",
        type: "checkbox",
      },
      {
        title: "Cannabinoides (Marihuana, Hashish)",
        type: "checkbox",
      },
      {
        title: "Cocaína",
        type: "checkbox",
      },
      {
        title: "Cocaína Semicuantitativo",
        type: "checkbox",
      },
      {
        title: "Carbamazepina (Tegretol)",
        type: "checkbox",
      },
      {
        title: "Difenilhidantoina (Fenitoína, Epamín)",
        type: "checkbox",
      },
      {
        title: "Digoxina",
        type: "checkbox",
      },
      {
        title: "Drogas Panel 3D",
        type: "checkbox",
      },
      {
        title: "Drogas Panel 5D",
        type: "checkbox",
      },
      {
        title: "Drogas Panel 10D",
        type: "checkbox",
      },
      {
        title: "Etanol",
        type: "checkbox",
      },
      {
        title: "Éxtasis",
        type: "checkbox",
      },
      {
        title: "Fenobarbital",
        type: "checkbox",
      },
      {
        title: "Litio",
        type: "checkbox",
      },
      {
        title: "Metanfetaminas",
        type: "checkbox",
      },
      {
        title: "Opiaceos",
        type: "checkbox",
      },
      {
        title: "Primidona",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  urines: {
    title: "Orina",
    options: [
      {
        title: "Ac. Úrico",
        type: "checkbox",
      },
      {
        title: "Antígeno prostático específico / PSA 3era Generación",
        type: "checkbox",
      },
      {
        title: "Calcio 24 horas",
        type: "checkbox",
      },
      {
        title: "Creatinina",
        type: "checkbox",
      },
      {
        title: "Depuración de Creatinina",
        type: "checkbox",
      },
      {
        title: "Dismorfismo Eritrocitario",
        type: "checkbox",
      },
      {
        title: "Examen General de Orina",
        type: "checkbox",
      },
      {
        title: "Microalbuminuria",
        type: "checkbox",
      },
      {
        title: "Proteinuria, orina 24 horas",
        type: "checkbox",
      },
      {
        title: "Proteínas Totales en Orina de => horas.",
        type: "other",
      },
      {
        title: "Proteína de Bence Jones",
        type: "checkbox",
      },
      {
        title: "PSA Libre",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  feces: {
    title: "Heces",
    options: [
      {
        title: "Actividad Tríptica en heces",
        type: "checkbox",
      },
      {
        title: "Amiba en Fresco",
        type: "checkbox",
      },
      {
        title: "Adenovirus",
        type: "checkbox",
      },
      {
        title: "Ag. Específico de Entamoeba Histolytica",
        type: "checkbox",
      },
      {
        title: "Ag. Giardia Lamblia",
        type: "checkbox",
      },
      {
        title: "Ag. Helicobacter Pylori",
        type: "checkbox",
      },
      {
        title: "Calprotectina heces",
        type: "checkbox",
      },
      {
        title: "Clostridium Difficile Gdh, Antígeno en Heces",
        type: "checkbox",
      },
      {
        title: "Coprocultivo",
        type: "checkbox",
      },
      {
        title: "Coprológico",
        type: "checkbox",
      },
      {
        title: "Coproparasitoscópico => muestras",
        type: "other",
      },
      {
        title: "Crysptosporidium sp",
        type: "checkbox",
      },
      {
        title: "Eosinofilos en Heces",
        type: "checkbox",
      },
      {
        title: "Heces, Alfa 1 Antitripsina",
        type: "checkbox",
      },
      {
        title: "Heces, Baerman para Strongyloides",
        type: "checkbox",
      },
      {
        title: "Heces, Dosaje de Grasas / 24h",
        type: "checkbox",
      },
      {
        title: "Heces, Dosaje de Grasas / 72h",
        type: "checkbox",
      },
      {
        title: "Heces, Esteatocrito Ácido",
        type: "checkbox",
      },
      {
        title: "Heces, Grasas (Cualitativo)",
        type: "checkbox",
      },
      {
        title: "Heces, Reacción Inflamatoria",
        type: "checkbox",
      },
      {
        title: "Heces, Sustancias Reductoras",
        type: "checkbox",
      },
      {
        title: "Heces, Tripsina",
        type: "checkbox",
      },
      {
        title: "Leucocitos en Heces",
        type: "checkbox",
      },
      {
        title: "Parasitológico Simples",
        type: "checkbox",
      },
      {
        title: "Parásito seriado, 2 muestras",
        type: "checkbox",
      },
      {
        title: "Parásito seriado, 3 muestras",
        type: "checkbox",
      },
      {
        title: "pH en Heces",
        type: "checkbox",
      },
      {
        title: "Raspado Perianal (Técnica de Graham)",
        type: "checkbox",
      },
      {
        title: "Rotavirus Sustancias Reductoras en heces",
        type: "checkbox",
      },
      {
        title: "Sangre oculta en Heces",
        type: "checkbox",
      },
      {
        title: "Thevenon, reacción de",
        type: "checkbox",
      },
      {
        title: "Thevenon 3 muestras",
        type: "checkbox",
      },
      {
        title: "Thevenon Inmunológico (Sangre en heces)",
        type: "checkbox",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
  bacteriology: {
    title: "Bacteriología",
    options: [
      {
        title: "Cultivo con Antibiograma de",
        type: "text",
      },
      {
        title: "Baciloscopía (BAAAR) de",
        type: "text",
      },
      {
        title: "Otros",
        type: "other",
      },
    ],
  },
}

export default clinical
