import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { compose } from "recompose"
import { Formik, Form } from "formik"
import { useHistory, withRouter } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Visibility } from "@styled-icons/material/Visibility"
import { VisibilityOff } from "@styled-icons/material/VisibilityOff"
import {
  Stack,
  VStack,
  Box,
  Image,
  Flex,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react"

import { withFirebase } from "../../utils/Firebase"
import { getParameterByName } from "../../utils/helpers"

import Background from "../../static/images/lines_background.svg"
import LogoV2 from "../../static/images/logoV2.svg"
import Brand from "../../static/images/brand.svg"

import { WithChakra } from "../../components/atoms/WithChakra"
import CustomInput from "../../components/chakra/CustomInput"
import HelpButton from "../../components/chakra/HelpButton"
import LanguageSelector from "../../components/atoms/LanguageSelector"

const ResetPasswordBase = ({ firebase }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()

  const [actionCode, setActionCode] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  const goToHelp = () => {
    history.push("/help", { isFromLogin: true })
  }

  const goToLogin = () => {
    history.push("/login")
  }

  useEffect(() => {
    const requestActionCode = getParameterByName("oobCode")
    if (requestActionCode) {
      setActionCode(requestActionCode)
    } else {
      addToast(t("unexpected_error"), { appearance: "error" })
      setTimeout(() => {
        goToLogin()
      }, 2000)
    }
  }, [])

  const getUserEmail = async () => {
    const getEmail = await firebase.handleVerifyPasswordResetCode(actionCode)
    if (getEmail && !getEmail.code) {
      setUserEmail(getEmail)
    } else {
      addToast(t("unexpected_error"), { appearance: "error" })
      setTimeout(() => {
        goToLogin()
      }, 2000)
    }
  }

  useEffect(() => {
    if (actionCode) {
      getUserEmail()
    }
  }, [actionCode])

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      justify="center"
      width="full"
      height="100vh"
      backgroundSize="cover"
      background={`url(${Background}) no-repeat center / cover, linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)`}
    >
      <Box
        boxSize="sm"
        position="absolute"
        right="20px"
        top="12px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="200px"
        height="50px"
      >
        <HelpButton onClick={() => goToHelp()} />
        <LanguageSelector isFromLogin />
      </Box>
      <Box
        boxSize="sm"
        position="absolute"
        left="22px"
        top="12px"
        cursor="pointer"
      >
        <Image src={LogoV2} alt="logoV2" onClick={() => history.push("/")} />
      </Box>
      <Formik
        initialValues={{ newPassword: "" }}
        onSubmit={async (values) => {
          const result = await firebase.handleResetPassword(
            actionCode,
            values.newPassword
          )
          if (result && result.code) {
            addToast(t("unexpected_error"), { appearance: "error" })
            setTimeout(() => {
              goToLogin()
            }, 2000)
          } else {
            addToast(t("web_client.saved_successfully"), {
              appearance: "success",
            })
            setTimeout(() => {
              goToLogin()
            }, 2000)
          }
        }}
        validationSchema={Yup.object({
          newPassword: Yup.string().required(t("error_all_fields_required")),
        })}
      >
        {(props) => (
          <Form>
            <VStack
              background="white"
              borderRadius="8px"
              width="468px"
              height="400px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
              padding="60px"
            >
              <Box mb="20px">
                <Image src={Brand} alt="DOC" />
              </Box>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel htmlFor="email" color="gray.700">
                    {t("reset")} {t("password")} {t("to")}:
                  </FormLabel>
                  {userEmail && (
                    <FormLabel
                      htmlFor="email"
                      color="gray.700"
                      textDecoration="underline"
                      mt="10px"
                      mb="10px"
                    >
                      {userEmail}
                    </FormLabel>
                  )}
                </FormControl>
                <FormControl>
                  <InputGroup size="md">
                    <CustomInput
                      id="newPassword"
                      name="newPassword"
                      type={showPassword ? "text" : "password"}
                      placeholder={t("new_password")}
                      showErrorMessage={
                        props.errors.newPassword && props.submitCount > 0
                      }
                      errorMessage={props.errors.newPassword}
                      handleInputChange={(e) =>
                        props.setFieldValue("newPassword", e.target.value)
                      }
                    />

                    <InputRightElement>
                      <Button size="sm" onClick={toggleShowPassword}>
                        {showPassword ? (
                          <Visibility size="24" />
                        ) : (
                          <VisibilityOff size="24" />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Stack>
              <Button
                colorScheme="blue"
                width="348px"
                height="48px"
                background="#071435"
                borderRadius="6px"
                color="#FFF"
                fontSize="18px"
                fontWeight="normal"
                type="submit"
                isLoading={props.isSubmitting}
              >
                {t("save")}
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

ResetPasswordBase.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const ResetPassword = compose(
  withRouter,
  withFirebase,
  WithChakra
)(ResetPasswordBase)

export default ResetPassword
