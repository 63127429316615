import React, { useState, useEffect, useContext, useMemo } from "react"
import PropTypes from "prop-types"
import { useHistory, Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
// import debounce from "lodash.debounce"
import { v4 as uuidv4 } from "uuid"
import get from "lodash.get"
import Dropzone from "react-dropzone-uploader"

// Icons
import { AttachFile } from "@styled-icons/material/AttachFile"
import { NoteAdd } from "@styled-icons/material/NoteAdd"
import { Edit } from "@styled-icons/material/Edit"
import { AddComment } from "@styled-icons/material/AddComment"
import { Notepad } from "@styled-icons/fluentui-system-filled/Notepad"
import { Stars } from "@styled-icons/bootstrap/Stars"
import { Sparkles } from "@styled-icons/ionicons-solid/Sparkles"

// Atoms
import { MedicImages } from "../../atoms/Common"

// Utils
import { withFirebase } from "../../../utils/Firebase"
import { prepareMessage } from "../../../utils/helpers"

// Context
import { setLastMessageKey } from "../../../context/Chat/reducer"
import { AuthUserContext } from "../../../context/Session"
import ChatContext from "../../../context/Chat"

// Styles
import "react-dropzone-uploader/dist/styles.css"
import { Container, Tools, InputContainer, StyledSpan } from "./styles"

const ChatFooter = ({
  serverTimeOffset,
  firebase,
  session,
  info,
  newVideoSession,
  handleNotesClick,
  isDependentChat,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { dispatch } = useContext(ChatContext)
  const history = useHistory()
  const user = useContext(AuthUserContext)
  const [value, setValue] = useState("")
  const [isContact, setIsContact] = useState(false)

  useEffect(() => {
    const checkContact = async () => {
      const data = await firebase.checkContact(
        user.isImpersonating ? user.impersonatingEmail : user.email,
        info.clientEmail
      )
      if (data && data.docs) {
        setIsContact(true)
      }
    }
    if (user && info) {
      checkContact()
    }
  }, [user, info])

  const sendMessage = async (msg) => {
    const prepMsg = prepareMessage(
      user,
      serverTimeOffset,
      msg,
      msg.type || "text",
      msg.targetTokens || []
    )
    const key = await firebase.pushMessage({ chat: session, message: prepMsg })
    dispatch(setLastMessageKey(key))
  }

  function checkNewMessages() {
    if (newVideoSession) {
      history.push("/sessions", {
        session,
        clinical: null,
      })
    }
  }

  useEffect(() => {
    checkNewMessages()
  }, [])

  function keyPress(e) {
    if (e.keyCode === 13) {
      const tokens = localStorage.getItem(`targetTokens-${session}`)
      sendMessage({
        _id: uuidv4(),
        text: e.target.value,
        type: "text",
        targetTokens: tokens ? JSON.parse(tokens) : [],
      })
      setValue("")
    }
  }

  const getType = (fileExtension) => {
    switch (fileExtension) {
      case "mp4":
        return "video"
      case "png":
      case "jpg":
      case "jpeg":
        return "image"
      case "pdf":
        return "document"
      default:
        return "other"
    }
  }

  const handleChangeStatus = React.useCallback((image, status) => {
    if (status === "headers_received") {
      const fileExtension = image.file.name.split(".").pop() || "unknown"
      const fileName = `${uuidv4()}.${fileExtension.toLowerCase()}`
      const uploadDir = `uploads/${session}/${fileExtension.toLowerCase()}`
      const uploadTask = firebase.uploadMediaFile({
        file: image.file,
        uploadDir,
        fileName,
      })
      uploadTask.on("state_changed", null, null, () => {
        firebase
          .getMediaURL({ uploadDir, fileName })
          .then((url) => {
            const tokens = localStorage.getItem(`targetTokens-${session}`)
            sendMessage({
              type: getType(fileExtension),
              url,
              uploadId: uuidv4(),
              mime: image.meta.type,
              originalPath: image.meta.previewUrl,
              originalFileName: image.meta.name,
              targetTokens: JSON.parse(tokens),
            })
            image.remove()
          })
          .catch(() => {
            addToast("Ocurrió un error en la carga del archivo.", {
              appearance: "error",
            })
          })
      })
    } else if (status === "aborted") {
      addToast(`Se canceló la carga del archivo ${image.meta.name}`, {
        appearance: "error",
      })
    }
  }, [])

  const Layout = ({ input, previews, dropzoneProps }) => (
    <div>
      {previews}
      <div {...dropzoneProps}>{input}</div>
    </div>
  )

  const isFaceToFace = useMemo(() => get(info, "type") === "faceToFace", [info])

  function handleChange(e) {
    setValue(e.target.value)
  }

  const toolEnabled =
    isFaceToFace || (info && Object.keys(info?.messages || {})?.length > 0)

  const isOdontoUser =
    user?.medicProfile?.profession === "odontology" ||
    user?.medicProfile?.profession === "orthodontics"

  return (
    <Container>
      <Tools isEnabled={toolEnabled}>
        {isContact && (
          <div
            style={{ userSelect: "none" }}
            aria-hidden="true"
            onClick={handleNotesClick}
          >
            <div>
              <Notepad size="24" />
              <span>{t("add_medical_note")}</span>
            </div>
          </div>
        )}
        {isContact && (
          <div style={{ userSelect: "none" }} aria-hidden="true">
            <Link
              to={{
                pathname: "/contacts",
                state: {
                  contact: {
                    email: info.clientEmail,
                    displayName: info.clientName,
                  },
                  session,
                  subpath: "medical_follow",
                },
              }}
            >
              <div>
                <Stars size="16" color="#ECC94B" />
                <span>{t("add_medical_follow")}</span>
              </div>
            </Link>
          </div>
        )}
        {isContact && isOdontoUser && (
          <div style={{ userSelect: "none" }} aria-hidden="true">
            <Link
              to={{
                pathname: "/contacts",
                state: {
                  contact: {
                    email: info.clientEmail,
                    displayName: info.clientName,
                  },
                  session,
                  subpath: "odontogram",
                },
              }}
            >
              <div>
                <StyledSpan right="10px">{t("add_odontogram")}</StyledSpan>
                <Sparkles size="16" color="#9F7AEA" />
              </div>
            </Link>
          </div>
        )}
        <div>
          <Link
            to={{
              pathname: "/recommendations",
              state: {
                session,
                user: {
                  name: user.medicProfile.displayName,
                  email: user.email,
                },
                info,
                ...(isDependentChat && {
                  dependent: {
                    ...isDependentChat,
                  },
                }),
              },
            }}
          >
            <AddComment size="24" />
            <span>{t("add_tip")}</span>
          </Link>
        </div>
        <div>
          <Link
            to={{
              pathname: "/medical-images",
              state: {
                session,
                user: {
                  name: user.medicProfile.displayName,
                  email: user.email,
                },
                info,
                ...(isDependentChat && {
                  dependent: {
                    ...isDependentChat,
                  },
                }),
              },
            }}
          >
            <MedicImages />
            <span>{t("web_client.medical_images")}</span>
          </Link>
        </div>
        <div>
          <Link
            to={{
              pathname: "/clinical",
              state: {
                session,
                user: {
                  name: user.medicProfile.displayName,
                  email: user.email,
                },
                info,
                ...(isDependentChat && {
                  dependent: {
                    ...isDependentChat,
                  },
                }),
              },
            }}
          >
            <Edit size="24" />
            <span>{t("web_client.clinical_studies")}</span>
          </Link>
        </div>
        <div>
          <Link
            to={{
              pathname: "/prescription",
              state: {
                session,
                user: {
                  name: user.medicProfile.displayName,
                  email: user.email,
                },
                info,
                ...(isDependentChat && {
                  dependent: {
                    ...isDependentChat,
                  },
                }),
              },
            }}
          >
            <NoteAdd size="24" />
            <span>{t("web_client.prescription")}</span>
          </Link>
        </div>
        {/* <div>
          <LocationOn size="24" />
        </div> */}
        <div>
          <Dropzone
            disabled={!isFaceToFace && info && info.clientMsgCount < 1}
            getUploadParams={() => ({ url: "https://httpbin.org/post" })}
            onChangeStatus={handleChangeStatus}
            accept="image/png,image/jpeg,video/mp4,application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            maxFiles={1}
            canCancel={false}
            submitButtonContent={null}
            LayoutComponent={Layout}
            inputContent={() => <AttachFile size="24" />}
            inputWithFilesContent={() => <AttachFile size="24" />}
            styles={{
              dropzone: {
                color: "black",
                border: "none",
                width: "45px",
                height: "45px",
                minHeight: "unset",
                overflow: "hidden",
              },
              inputLabel: {
                color: "#071435",
                overflow: "hidden",
              },
              inputLabelWithFiles: {
                color: "#071435",
                backgroundColor: "transparent",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                fontSize: "20px",
                padding: 0,
                margin: 0,
                overflow: "hidden",
              },
              preview: {
                position: "fixed",
                width: "400px",
                overflow: "scroll",
                height: "auto",
                bottom: "15%",
                right: "0px",
                zIndex: 1002,
              },
            }}
          />
        </div>
      </Tools>
      <InputContainer>
        <input
          value={value}
          onKeyDown={keyPress}
          onChange={handleChange}
          placeholder={t("type_a_message")}
        />
      </InputContainer>
    </Container>
  )
}

ChatFooter.propTypes = {
  firebase: PropTypes.shape().isRequired,
  session: PropTypes.string.isRequired,
}

export default withFirebase(ChatFooter)
