import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Howl, Howler } from "howler"
import { KeyboardVoice } from "@styled-icons/material/KeyboardVoice"
import { Pause } from "@styled-icons/material/Pause"

const MessageAudio = ({ currentMessage }) => {
  const [playing, setPlaying] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [paused, setPaused] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)
  const sound = new Howl({
    src: [currentMessage.audio],
    html5: true,
  })

  function playSound() {
    if (!playing) {
      setDuration(sound.duration())
      Howler.volume(0.7)
      sound.play()
      setPlaying(true)
    } else {
      sound.pause()
      setPaused(true)
    }
  }

  useEffect(() => {
    if (sound) {
      sound.on("end", () => {
        setCurrentProgress(0)
        setPlaying(false)
      })
    }
  }, [sound])

  useEffect(() => {
    if (playing) {
      const interval = setInterval(() => {
        setCurrentProgress((currentValue) => {
          if (playing && currentValue <= 85) {
            const intervalFactor = 100 / duration / 2
            return currentValue + intervalFactor
          }
          clearInterval(interval)
          return 0
        })
      }, 500)
    }
  }, [playing])

  return (
    <>
      <Wrapper onClick={() => playSound()}>
        {!playing ? <KeyboardVoice size="80" /> : <Pause size="80" />}
        <progress id="audio" value={currentProgress} max="100" />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & > svg {
    color: white;
  }
`

MessageAudio.propTypes = {
  currentMessage: PropTypes.shape({
    audio: PropTypes.string,
  }).isRequired,
}

export default MessageAudio
