import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import get from "lodash.get"
import truncate from "lodash.truncate"
import moment from "moment"
import Select from "react-select"

// Components
import { Flex, Button, Box } from "@chakra-ui/react"
import { RemoveRedEye } from "@styled-icons/material/RemoveRedEye"

import AddMedicalNotes from "../../../AddMedicalNotes"
import AllMedicalNotes from "../allMedicalNotesModal"

// Styles
import { CustomText, DataRow, EmptyMessage } from "../../styles"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "#E2E8F0", // Theme.color.iceBlue,
    marginTop: "5px",
    marginBottom: "2px",
    marginLeft: 10,
    marginRight: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"
    return { ...provided, opacity, transition }
  },
}

const Notes = ({
  contact,
  currentDependent,
  currentNote,
  dependents,
  dependentsWithOwner,
  medicalNotes,
  isAddNoteVisible,
  setIsAddNoteVisible,
  setCurrentDependent,
  setCurrentNote,
  isImpersonating = false,
}) => {
  const { t } = useTranslation()

  const [isShowAllMedicalNotes, setShowAllMedicalNotes] = useState(false)
  return (
    <>
      {medicalNotes.length === 0 ? (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 240px)"
          width="100%"
        >
          <EmptyMessage>{`Anotaciones al ${
            contact?.type === "medical_visitor" ? "visitador" : "paciente"
          }.`}</EmptyMessage>
          <Button
            width="99%"
            padding="10px 16px"
            border="1px solid rgba(226, 232, 240, 1)"
            position="absolute"
            bottom="1px"
            onClick={() => setIsAddNoteVisible(!isAddNoteVisible)}
            zIndex={1005}
            disabled={isImpersonating}
          >
            {t("web_client.create")}
          </Button>
        </Flex>
      ) : (
        <Flex
          key="main-notes"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="calc(100vh - 200px)"
          width="100%"
          position="relative"
          overflow="hidden"
        >
          <Flex alignItems="flex-end" justifyContent="flex-end" width="100%">
            <Button
              width="146px"
              height="36px"
              mt="30px"
              mb="30px"
              mr="20px"
              padding="10px 16px"
              border="1px solid rgba(226, 232, 240, 1)"
              _focus={{ outline: "none" }}
              _hover={{
                background: "#4d66f5",
                color: "#FFF",
                opacity: 1,
              }}
              onClick={() => setShowAllMedicalNotes(!isShowAllMedicalNotes)}
            >
              {t("show_all")}
            </Button>
          </Flex>
          <Flex
            key="notes-container"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            height="calc(100vh - 260px)"
            width="100%"
            overflowY="auto"
          >
            <Flex
              justifyContent="flex-end"
              alignItems="center"
              borderBottom="1px solid #edf2f7"
              width="100%"
            >
              {dependents.length > 0 && (
                <Flex justifyContent="center" alignItems="center" my="5px">
                  <Box as="span" ml="10px">
                    Filtrar:
                  </Box>
                  <Select
                    styles={customStyles}
                    defaultValue={currentDependent}
                    value={dependentsWithOwner.find(
                      (option) =>
                        option.value === get(currentDependent, "value")
                    )}
                    options={dependentsWithOwner}
                    onChange={(option) => setCurrentDependent(option || null)}
                    placeholder="Seleccione para filtrar"
                    isClearable
                    width="240px"
                  />
                </Flex>
              )}
            </Flex>
            <Flex mt="30px" width="100%" flexDirection="column">
              {medicalNotes
                .filter((n) => {
                  if (currentDependent && !currentDependent.isParent) {
                    return n.dependentContact === currentDependent.value
                  }
                  if (currentDependent && currentDependent.isParent) {
                    return !n.dependentContact
                  }
                  return true
                })
                .sort((a, b) => b.createdAt - a.createdAt)
                .map((c) => (
                  <DataRow mb="15px" key={c.id}>
                    <CustomText>
                      {c.dependentContact
                        ? c.dependentName
                        : contact.displayName}
                    </CustomText>
                    <CustomText>
                      {moment(c.createdAt).format("MMM DD, YYYY").toUpperCase()}
                      {/* HH:mm:ss */}
                    </CustomText>
                    <CustomText>
                      {moment(c.createdAt).format("HH:mm:ss").toUpperCase()}
                    </CustomText>
                    <CustomText flex={2}>
                      {truncate(c.note, { length: 38 })}
                    </CustomText>
                    <Button
                      h="35px"
                      width="48px"
                      borderRadius="8px"
                      padding="10px, 12px, 10px, 12px"
                      background="#EDF2F7"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      margin="0px"
                      opacity="0.8"
                      _hover={{
                        background: "#EDF2F7",
                        transform: "scale(1.1)",
                        opacity: 1,
                      }}
                      onClick={() => {
                        setCurrentNote(c.note)
                        setIsAddNoteVisible(true)
                      }}
                    >
                      <RemoveRedEye size="22px" />
                    </Button>
                  </DataRow>
                ))}
            </Flex>
          </Flex>
          <Button
            width="99%"
            padding="10px 16px"
            border="1px solid rgba(226, 232, 240, 1)"
            position="absolute"
            bottom="1px"
            onClick={() => setIsAddNoteVisible(!isAddNoteVisible)}
            zIndex={1005}
          >
            {t("web_client.create")}
          </Button>
        </Flex>
      )}
      <AddMedicalNotes
        contact={contact}
        isAddNoteVisible={isAddNoteVisible}
        setIsAddNoteVisible={setIsAddNoteVisible}
        contactDependents={dependents}
        currentNote={currentNote}
      />
      {isShowAllMedicalNotes && (
        <AllMedicalNotes
          isShowAllNotes={isShowAllMedicalNotes}
          medicalNotes={medicalNotes}
          setShowMedicalNotes={setShowAllMedicalNotes}
        />
      )}
    </>
  )
}

export default Notes
