import React from "react"
import PropTypes from "prop-types"
import { Input, Box, Text } from "@chakra-ui/react"

const handleAvoidCopyAndPaste = (e) => {
  e.preventDefault()
  return false
}

const CustomInput = ({
  placeholder,
  placeholderColor,
  handleInputChange,
  type,
  name,
  isRequired,
  width,
  height,
  showErrorMessage,
  errorMessage,
  handleInputOnBlur,
  isReadOnly,
  avoidCopyAndPaste,
  countryCurrency,
  autoComplete,
  defaultValue,
  containerWidth,
  containerHeight,
  containerJustifyContent,
  props,
}) => (
  <Box
    alignItems="center"
    justifyContent={containerJustifyContent}
    width={containerWidth || width}
    height={containerHeight || height}
  >
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent={containerJustifyContent}
    >
      {countryCurrency && (
        <Text fontSize="12px" color="gray.500" fontWeight="500" mr="20px">
          {countryCurrency}
        </Text>
      )}
      <Input
        {...props}
        type={type}
        name={name}
        placeholder={placeholder}
        borderColor="#E2E8F0"
        focusBorderColor="#E2E8F0"
        borderRadius="6px"
        color="gray.500"
        fontSize="16px"
        width={width}
        height={height}
        background="white"
        onChange={handleInputChange}
        onBlur={handleInputOnBlur}
        defaultValue={defaultValue}
        isRequired={isRequired}
        _hover={{}}
        variant="outline"
        _placeholder={{
          color: placeholderColor,
        }}
        readOnly={isReadOnly}
        onCopy={(e) => avoidCopyAndPaste && handleAvoidCopyAndPaste(e)}
        onPaste={(e) => avoidCopyAndPaste && handleAvoidCopyAndPaste(e)}
        autoComplete={autoComplete}
      />
    </Box>
    {showErrorMessage && (
      <Text
        fontSize="12px"
        color="rgb(98, 0, 238)"
        fontWeight="500"
        width="500px"
      >
        {errorMessage}
      </Text>
    )}
  </Box>
)

CustomInput.defaultProps = {
  placeholder: "",
  placeholderColor: "gray.500",
  type: "text",
  name: "",
  width: "348px",
  containerWidth: "348px",
  containerHeight: "40px",
  height: "40px",
  errorMessage: "",
  showErrorMessage: false,
  handleInputOnBlur: () => {},
  isReadOnly: false,
  handleInputChange: () => {},
  avoidCopyAndPaste: false,
  countryCurrency: "",
  autoComplete: "off",
  defaultValue: "",
  containerJustifyContent: "center",
}

CustomInput.propTypes = {
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
  handleInputChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  containerWidth: PropTypes.string,
  containerHeight: PropTypes.string,
  containerJustifyContent: PropTypes.string,
  errorMessage: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  handleInputOnBlur: PropTypes.func,
  isReadOnly: PropTypes.bool,
  avoidCopyAndPaste: PropTypes.bool,
  countryCurrency: PropTypes.string,
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape(),
  ]),
}

export default CustomInput
