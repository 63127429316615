import styled from "styled-components"
import { InitialAvatar } from "../../atoms/Card/styles"

export const MedicalHeader = styled.div`
  height: 88px;
  display: flex;
  align-items: center;
  & > span {
    ${(props) => props.theme.font_size.regular};
  }
  & > div {
    margin-left: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > ${InitialAvatar} {
      margin-right: 10px;
    }
  }
`

export const CloseButton = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 5px;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
  margin-right: 20px;
  cursor: pointer;
`

export const Title = styled.h2`
  font-size: 1.875rem;
`

export const Label = styled.p`
  ${(props) => props.theme.font_size.regular};
  margin-bottom: 15px;
`

export const TextArea = styled.textarea`
  outline: none;
  border: none;
  background-color: ${(props) => props.theme.color.white.regular};
  display: block;
  resize: none;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  line-height: 1.5;
  padding: 10px;
  margin-bottom: 15px;
`

export const MedicalData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 58px;
  width: 40%;
`
