import styled from "styled-components"

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
`

export const DoctorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: absolute;
  top: -220px;
  left: 19px;
  width: 400px;
  height: 300px;
  overflow: hidden;
  z-index: 1002;
  background-color: white;
  justify-content: space-between;
`

export const Doctors = styled.section`
  height: calc(100% - 60px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

export const DoctorInfo = styled.div`
  width: 100%;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: all 150ms;
  padding-left: 0px;
  & > p {
    padding-left: 5px;
  }
  &:hover {
    transform: scale3d(1.1, 1.1, 1.1);
    padding-left: 17px;
    font-weight: bold;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border-top: 1px solid rgba(33, 33, 33, 0.08);
`

export const LogOutContainer = styled.div`
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
`

export const ResetPassword = styled.div`
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  display: inline-flex;
  cursor: pointer;
  z-index: 1001;
  transition: all 200ms ease-in-out;
  color: ${(props) => props.theme.color.onSurface};
  &:hover {
    transform: scale(1.1);
  }
  & > svg {
    animation: ${(props) =>
      props.resetting ? "rotate 2s linear infinite" : "none"};
    color: ${(props) =>
      props.resetting
        ? props.theme.color.primary
        : props.theme.color.onSurface};
  }
`
