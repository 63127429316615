import styled from "styled-components"

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin-left: 124px;
  margin-top: 42px;
  > img {
    margin-right: 30px;
  }
`

export const ItemsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & > h5 {
    margin: 0;
    ${(props) => props.theme.font_size.regular};
    color: ${(props) => props.theme.color.black.normal};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: 400;
  }
  & > p {
    margin: 0;
    ${(props) => props.theme.font_size.xxsmall};
    color: ${(props) => props.theme.color.black.normal};
    font-family: ${(props) => props.theme.font.primary};
    font-weight: 400;
    opacity: 0.6;
  }
`

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const CalendarAccountsContainer = styled.div`
  width: 80%;
  padding: 22px;
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 10px;
  .CalendarSync__title {
    display: none;
  }
  .CalendarSync__profiles {
    width: 100%;
  }
  .CalendarSync__add-toggle-text {
    display: none;
    color: rgb(153, 153, 153);
  }
  .CalendarSync__add-toggle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.color.background.gray100};
    border-radius: 100px;
    width: 48px;
    height: 48px;
    padding: 12px;
  }
  .CalendarSync__add-toggle-icon {
    margin: 0;
  }
  .CalendarSync__provider-auth {
    &:nth-child(4) {
      /* Remove exchange from options */
      display: none;
    }
  }
`
export const ModalWrapper = styled.div`
  width: 60vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > canvas {
    border: 1px solid black;
    width: 100%;
    min-height: 300px;
    background: white;
  }
`

export const ModalOptions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  & > section {
    width: 100%;
    padding: 25px;
    p {
      text-align: center;
      ${(props) => props.theme.font_size.small};
    }
    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        background-color: ${(props) => props.theme.color.paleGrey};
        border: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
        border-radius: 5px;
        width: 125px;
        height: 45px;
        margin: 0 8px;
        &:hover {
          color: ${(props) => props.theme.color.white.regular};
          background-color: ${(props) => props.theme.color.black.light};
        }
      }
    }
  }
`

export const SaveChangesButton = styled.button`
  width: 247px;
  height: 36px;
  margin-top: 22px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  background-color: transparent;
  color: ${(props) => props.theme.color.secondary};
  ${(props) => props.theme.font_size.small};
  &:hover {
    background-color: ${(props) => props.theme.color.secondary};
    & > span {
      color: ${(props) => props.theme.color.white.regular};
    }
  }
`

export const CalendarLink = styled.div`
  cursor: pointer;
  p {
    color: #1500ed;
    text-decoration: underline;
  }
`
