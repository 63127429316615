/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import moment from "moment"
import get from "lodash.get"
import { v4 as uuidv4 } from "uuid"
import { customAlphabet } from "nanoid"
import ProgressBar from "react-progress-bar-plus"
import { Edit } from "@styled-icons/material/Edit"
import { Close } from "@styled-icons/material/Close"
import { AddCircle } from "@styled-icons/material/AddCircle"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"
import CustomModal from "../../atoms/CustomModal"
import Theme from "../../../styles/theme"
import { Name } from "../../atoms/Card/styles"
import SectionTitle from "../../atoms/SectionTitle"
import { SectionsDoubleContainer, Toolbar } from "../../atoms/Global"
import { withFirebase } from "../../../utils/Firebase"
import { getPdfUrl } from "../../../utils/App"
import { AuthUserContext } from "../../../context/Session"
import ChatContext from "../../../context/Chat"
import { addMessage } from "../../../context/Chat/reducer"
import {
  Container,
  EditableSection,
  EditableHeader,
  EditableWrapper,
  MedicalHeader,
  CloseButton,
  HeaderTools,
  MedicalInfo,
  InfoCard,
  MedicalInstructions,
  TextArea,
} from "../../atoms/Forms"
import { secondsToDate, prepareMessage } from "../../../utils/helpers"
import {
  Avatar,
  Thumb,
  Image,
  ModalWrapper,
  ModalOptions,
  Images,
  AbsoluteSendButton,
} from "./styles"

const Recommendations = ({ firebase }) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const history = useHistory()
  const user = useContext(AuthUserContext)
  const nanoid = customAlphabet("1234567890ABCDEF", 8)
  const currentYear = new Date().getFullYear()
  const [uuid, setUUID] = useState("")
  const [formValues, setFormValues] = useState({
    editRecommendations: true,
    valuesIncluded: false,
    recommendations: "",
  })
  const [isLoadedImage, setIsLoadedImage] = useState({
    sign: false,
    company: false,
  })
  const [company, setCompany] = useState({})
  const [academic, setAcademic] = useState({})
  const [target, setTarget] = useState({})
  const [dependentData, setDependentData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const { dispatch } = useContext(ChatContext)

  useEffect(() => {
    const getBusinessForm = async () => {
      const Business = await firebase.getBusiness({ email: state.user.email })
      setCompany(Business.data())
      const Academic = await firebase.getAcademic({ email: state.user.email })
      setAcademic(Academic.data())
      const Target = await firebase.getProfile({
        email: state.info.clientEmail,
      })
      setTarget(Target.data())
      setUUID(nanoid())
      if (state?.dependent) {
        if (state?.dependent) {
          const dataDp = await firebase.getDependentProfile(
            state.info.clientEmail,
            state?.dependent?.profileOwner
          )
          if (dataDp && dataDp.docs) {
            setDependentData({
              value: dataDp.docs[0].id,
              ...JSON.parse(state?.dependent?.form),
            })
          }
        }
      }
    }
    if (firebase) {
      getBusinessForm()
    }
  }, [firebase])

  const generatePDF = async (prepMsg) => {
    try {
      const authToken = await firebase.getIdToken()
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat: {
            id: state.session,
            data: prepMsg,
          },
          type: prepMsg.type,
          dependent: dependentData,
        }),
      }
      fetch(getPdfUrl, options)
      setProgress(100)
      setIsButtonLoading(false)
    } catch (error) {
      setProgress(100)
      setIsButtonLoading(false)
    }
  }

  const handleClose = () => {
    if (formValues.valuesIncluded) {
      setIsModalOpen(true)
    }
    history.push("/sessions", { session: state.session })
  }

  const handleClick = async () => {
    setIsButtonLoading(true)

    const tokens = localStorage.getItem(`targetTokens-${state.session}`)
    const infoMsg = {
      _id: uuidv4(),
      form: JSON.stringify({
        id: uuidv4(),
        recommendations: formValues.recommendations,
        code: uuid,
      }),
      type: "loaderRecomendations",
      targetTokens: JSON.parse(tokens),
      sentDate: moment().format("DD/MM/YY hh:mm a"),
    }
    const prepMsg = prepareMessage(
      user,
      null,
      infoMsg,
      infoMsg.type || "text",
      infoMsg.targetTokens || []
    )
    dispatch(addMessage(prepMsg, state.session))
    setTimeout(async () => {
      await generatePDF(prepMsg)
      history.push("/sessions", {
        session: state.session,
      })
    }, 300)
  }

  const handleInputChange = (e) => {
    e.persist()
    setFormValues((currentValues) => ({
      ...currentValues,
      [e.target.name]: e.target.value,
      valuesIncluded: true,
    }))
  }

  const handleEdit = (type) => {
    setFormValues((currentValues) => ({
      ...currentValues,
      [type]: !currentValues[type],
    }))
  }

  useEffect(() => {
    setFormValues((currentValues) => ({
      ...currentValues,
      recommendations: "",
    }))
  }, [])

  const getBirthdate = () => {
    let age = "0"
    const birthDate =
      dependentData && Object.keys(dependentData).length > 0
        ? secondsToDate(get(dependentData, "birthDate", null), "seconds")
        : secondsToDate(get(target, "birthDate", null), "seconds")
    if (birthDate) {
      age = currentYear - birthDate.getFullYear()
    }
    return age
  }

  const handleCloseConfirmation = () => {
    history.push("/sessions", { session: state.session })
  }

  return (
    <SectionsDoubleContainer>
      {isButtonLoading && (
        <ProgressBar
          spinner={false}
          percent={progress}
          autoIncrement
          intervalTime={417}
        />
      )}
      <Toolbar>
        <SectionTitle withPadding>{t("professional_advice")}</SectionTitle>
        <EditableWrapper>
          <EditableSection>
            <EditableHeader>
              <button
                type="button"
                onClick={() => handleEdit("editRecommendations")}
              >
                <span>
                  {formValues.editRecommendations ? t("edit") : t("cancel")}
                </span>
                <Edit size="16" />
              </button>
            </EditableHeader>
            <p>{t("recommendations")}</p>
            {formValues.editRecommendations && (
              <span>{formValues.recommendations}</span>
            )}
            {!formValues.editRecommendations && (
              <TextArea
                placeholder={t("recommendations")}
                value={formValues.recommendations}
                onChange={handleInputChange}
                name="recommendations"
              />
            )}
          </EditableSection>
        </EditableWrapper>
      </Toolbar>
      <Container>
        <MedicalHeader>
          <CloseButton onClick={handleClose}>
            <Close size="36" />
          </CloseButton>
          <span>{`# ${t("professional_advice")}: ${uuid}`}</span>
          <HeaderTools>
            <ButtonWithLoader
              isLoading={isButtonLoading}
              type="button"
              onClick={handleClick}
              color={Theme.color.secondary}
              small
              disabled={!formValues?.recommendations?.length > 0}
            >
              {t("send")}
            </ButtonWithLoader>
          </HeaderTools>
        </MedicalHeader>
        <MedicalInfo>
          <p>{company.companyName}</p>
          <div style={{ overflow: "hidden" }}>
            <InfoCard>
              <Name>{`${get(academic, "title", "")} ${state.user.name}`}</Name>
              <span>
                {`${t("q2_academic_profession_label")}: ${
                  get(academic, "professionLangLabel", "") ||
                  get(academic, "profession", "----")
                }`}
              </span>
              <span>
                {`${t("q4_academic_license_label")}: ${get(
                  academic,
                  "license",
                  "N/A"
                )}`}
              </span>
            </InfoCard>
            <InfoCard>
              <Name>
                {dependentData && Object.keys(dependentData).length > 0
                  ? get(dependentData, "dependentName", null)
                  : get(state, ["info", "clientName"], "")}
              </Name>
              <span>{t("target_age", { age: getBirthdate() })}</span>
              <span>
                {`${t("q4_info_gender_label")}: ${
                  dependentData && Object.keys(dependentData).length > 0
                    ? get(dependentData, "dependentGender", "")
                    : get(target, "gender", "")
                }`}
              </span>
            </InfoCard>
            <InfoCard>
              <Images>
                <Avatar isLoaded={isLoadedImage.company}>
                  <Thumb isLoaded={isLoadedImage.company}>
                    <span>Logo</span>
                  </Thumb>
                  <Image
                    onLoad={() => {
                      setIsLoadedImage((currentValues) => ({
                        ...currentValues,
                        company: true,
                      }))
                    }}
                    alt={company.companyName}
                    src={get(company, ["companyLogo"], "")}
                    isLoaded={isLoadedImage.company}
                  />
                </Avatar>
                <Avatar isLoaded={isLoadedImage.company}>
                  <Thumb isLoaded={isLoadedImage.company}>
                    <span>{t("web_client.medical_stamp_label")}</span>
                  </Thumb>
                  <Image
                    onLoad={() => {
                      setIsLoadedImage((currentValues) => ({
                        ...currentValues,
                        company: true,
                      }))
                    }}
                    alt={company.companyName}
                    src={get(company, ["companyStamp"], "")}
                    isLoaded={isLoadedImage.company}
                  />
                </Avatar>
                <Avatar isLoaded={isLoadedImage.sign}>
                  <Thumb isLoaded={isLoadedImage.sign}>
                    <span>{t("web_client.signature")}</span>
                  </Thumb>
                  <Image
                    onLoad={() => {
                      setIsLoadedImage((currentValues) => ({
                        ...currentValues,
                        sign: true,
                      }))
                    }}
                    alt={get(user, ["medicProfile", "displayName"], "")}
                    src={get(user, ["medicProfile", "personalSign"], "")}
                    isLoaded={isLoadedImage.sign}
                    isSign
                  />
                </Avatar>
              </Images>
            </InfoCard>
          </div>
        </MedicalInfo>
        <MedicalInstructions>
          <section>
            <TextArea
              placeholder={t("recommendations")}
              value={formValues.recommendations}
              onChange={handleInputChange}
              name="recommendations"
            />
          </section>
          <AbsoluteSendButton>
            <ButtonWithLoader
              isLoading={isButtonLoading}
              type="button"
              onClick={handleClick}
              color={Theme.color.secondary}
              disabled={!formValues?.recommendations?.length > 0}
              small
            >
              {t("send")}
            </ButtonWithLoader>
          </AbsoluteSendButton>
        </MedicalInstructions>
      </Container>
      {isModalOpen && (
        <CustomModal
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          showClose={false}
        >
          <ModalWrapper isFull>
            <ModalOptions>
              <section>
                <p>{t("web_client.unsaved_data")}</p>
                <div>
                  <button type="button" onClick={() => setIsModalOpen(false)}>
                    {t("cancel")}
                  </button>
                  <button type="button" onClick={handleCloseConfirmation}>
                    {t("confirm")}
                  </button>
                </div>
              </section>
            </ModalOptions>
          </ModalWrapper>
        </CustomModal>
      )}
    </SectionsDoubleContainer>
  )
}

export default withFirebase(Recommendations)
