import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

const BackIcon = ({ isBack, onBack, fill = "#071435" }) => {
  const history = useHistory()
  const handleBack = () => {
    if (isBack) {
      history.goBack()
    } else {
      onBack()
    }
  }
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleBack}
    >
      <path
        fill={fill}
        d="M16.4905 7H4.32048L9.91048 1.41L8.49048 0L0.490479 8L8.49048 16L9.90048 14.59L4.32048 9H16.4905V7Z"
      />
    </svg>
  )
}

BackIcon.defaultProps = {
  isBack: true,
  onBack: null,
}

BackIcon.propTypes = {
  isBack: PropTypes.bool,
  onBack: PropTypes.func,
}

export default BackIcon
