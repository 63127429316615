import React from "react"
import PropTypes from "prop-types"

import { ButtonWrapper, Button } from "./styles"

const UploadButton = ({ children, onChange, isUploading, onlyImages }) => (
  <ButtonWrapper>
    <Button disabled={isUploading}>{children}</Button>
    <input
      type="file"
      name="file"
      accept={onlyImages ? ".jpg, .jpeg, .png" : "*"}
      onChange={onChange}
    />
  </ButtonWrapper>
)

UploadButton.defaultProps = {
  isUploading: false,
  onlyImages: true,
}

UploadButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  isUploading: PropTypes.bool,
  onlyImages: PropTypes.bool,
}

export default UploadButton
