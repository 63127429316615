import styled from "styled-components"

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 33px 33px 33px;
`

export const Title = styled.span`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  letter-spacing: 0em;
  text-align: left;
  color: #171923;
  padding: 0px 10px;
  box-sizing: border-box;
  margin-bottom: 6px;
`

export const Label = styled.span`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  flex: 1;
`

export const CustomText = styled.span`
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 10px;
  flex: ${({ flex }) => flex || 1};
  color: ${({ color }) => color || "#000"};
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 23px;
`

export const DataRow = styled.section`
  display: flex;
  background: ${(props) => (props.isChild ? "#F7FAFC" : "#ffffff")};
  align-items: center;
  margin-bottom: ${(props) => props.mb || "0px"};
  padding: 1px 5px;
  width: 100%;

  ${Label} {
    font-weight: ${(props) => (props.isChild ? "normal" : "bold")};
  }

  ${CustomText} {
    font-weight: ${(props) => (props.isChild ? "400" : "800")};
  }
`

export const EmptyMessage = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
  color: rgba(0, 0, 0, 0.48);
`

export const ToothStyle = styled.div`
  rect {
    fill: #000;
  }

  rect:hover {
    fill: #9747ff;
    cursor: pointer;
    fill-opacity: 1;
  }
`
