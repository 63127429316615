/* eslint-disable no-undef */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import usePalacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"
import { useTranslation } from "react-i18next"
import get from "lodash.get"

import { Center, Input, ListItem, UnorderedList } from "@chakra-ui/react"

const Search = ({ panTo, addressFromOut }) => {
  const { t } = useTranslation()
  const defaultLocation = new google.maps.LatLng(9.934739, -84.087502)
  const [alreadyShowInitialAddress, setAlreadyShowInitialAddress] = useState(
    false
  )

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePalacesAutocomplete({
    requestOptions: {
      location: defaultLocation,
      radius: 200 * 1000,
    },
    debounce: 300,
  })

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value)
  }

  useEffect(() => {
    setValue(addressFromOut)
  }, [addressFromOut])

  useEffect(() => {
    if (!alreadyShowInitialAddress) {
      getGeocode({ address: get(data[0], "description") })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          panTo(lat, lng)
          setAlreadyShowInitialAddress(true)
        })
        .catch((error) => {
          window.Honeybadger?.notify(error)
        })
    }
  }, [data, alreadyShowInitialAddress])

  const handleSelect = (prediction) => () => {
    const { description } = prediction
    setValue(prediction.description, false)
    clearSuggestions()

    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        panTo(lat, lng)
      })
      .catch((error) => {
        window.Honeybadger?.notify(error)
      })
  }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion

      return (
        <ListItem
          _hover={{ backgroundColor: "gray.100", cursor: "pointer" }}
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </ListItem>
      )
    })

  const getCordinates = value?.latitude
    ? `${value.latitude}, ${value.longitude}`
    : ""
  return (
    <>
      <Input
        value={value?.latitude ? getCordinates : value}
        onChange={handleInput}
        disabled={!ready}
        placeholder={t("address_map_placeholder")}
        border="1px solid #E2E8F0 !important"
      />
      {status === "OK" && (
        <Center
          display="table-column"
          w={{ base: "auto", md: "470px" }}
          zIndex={9999}
          position="absolute"
          backgroundColor="white"
          opacity="0.9"
          textAlign="left"
        >
          <UnorderedList>{renderSuggestions()}</UnorderedList>
        </Center>
      )}
    </>
  )
}

export default Search
