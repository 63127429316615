import styled from "styled-components"
// import Img from 'gatsby-image'
import { GridContainer } from "../../atoms/Global"

// eslint-disable-next-line import/prefer-default-export
export const Container = styled(GridContainer)`
  padding: 0;
  background-color: ${(props) => props.theme.color.background.lightGray};
`

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - 170px);
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding-left: 18px;
  padding-right: 18px;

  & > p {
    text-align: center;
    ${(props) => props.theme.font_size.large};
    line-height: normal;
    width: 374px;
  }
`

export const ChatWrapper = styled.div`
  padding-top: ${(props) => (props.isChat ? "0" : "49px")};
  padding-left: ${(props) => (props.isChat ? "0" : "60px")};
  height: 100%;
  width: 100%;
`

export const Waiting = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  & > p {
    width: 100%;
    text-align: center;
    ${(props) => props.theme.font_size.regular};
    color: ${(props) => props.theme.color.primary};
  }
`

export const Search = styled.div`
  width: 100%;
  padding: 5px 15px;
  margin-top: 20px;
`

export const BackContainer = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 0.75rem;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${(props) => props.pl || "30px"};
  color: ${(props) => props.theme.color.secondary};
  > span {
    margin-left: 5px;
  }

  > svg {
    color: ${(props) => props.theme.color.secondary};
  }
`
