import styled from "styled-components"

import { EditableSection, MedicalHeader } from "../../atoms/Forms"

export const ImagesMedicalHeader = styled(MedicalHeader)`
  & > span {
    min-width: 300px;
  }
`

export const ImagesEditableSection = styled(EditableSection)`
  width: auto;
  margin: 0 27px 32px 30px;
  padding: 0 20px;
  min-height: unset;
  height: auto;
  & > div {
    display: flex;
    flex-direction: column;
    & > span:last-child {
      margin-bottom: 10px;
    }
  }
`

export const ImagesStudy = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 200px;
  & > section {
    min-height: 300px;
    width: 50%;
    margin: 34px 58px;
    height: 100%;
    & > div {
      margin-bottom: 5px;
    }
  }
`

export const ImagesTitle = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  background-color: ${(props) =>
    props.isActive ? props.theme.color.white.regular : "transparent"};
  border: ${(props) =>
    props.isActive ? `solid 1px ${props.theme.color.veryLightGrey}` : "none"};
  cursor: pointer;
  & > span {
    font-size: 1.188rem;
  }
`

export const Option = styled.div`
  label {
    padding-left: 5px;
  }
  input[type="checkbox"] {
    border: ${(props) => `solid 1px ${props.theme.color.veryLightGrey}`};
  }
`

export const ModalWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalOptions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  & > section {
    width: 100%;
    padding: 25px;
    p {
      text-align: center;
      ${(props) => props.theme.font_size.small};
    }
    & > div {
      width: 100%100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        background-color: ${(props) => props.theme.color.paleGrey};
        border: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
        border-radius: 5px;
        width: 125px;
        height: 45px;
        margin: 0 8px;
        &:hover {
          color: ${(props) => props.theme.color.white.regular};
          background-color: ${(props) => props.theme.color.black.light};
        }
      }
    }
  }
`

export const Images = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const AbsoluteSendButton = styled.div`
  position: absolute;
  bottom: 15px;
  right: 20px;
`
