import React from "react"

const WarningIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1.72868C5.03582 1.72868 4.09329 2.0146 3.2916 2.55027C2.48991 3.08594 1.86507 3.84731 1.49609 4.7381C1.12711 5.62889 1.03057 6.60909 1.21867 7.55475C1.40678 8.50041 1.87108 9.36905 2.55286 10.0508C3.23464 10.7326 4.10328 11.1969 5.04894 11.385C5.99459 11.5731 6.97479 11.4766 7.86558 11.1076C8.75637 10.7386 9.51775 10.1138 10.0534 9.31209C10.5891 8.5104 10.875 7.56787 10.875 6.60368C10.8736 5.31117 10.3596 4.07199 9.44564 3.15805C8.5317 2.2441 7.29251 1.73005 6 1.72868ZM5.625 4.35368C5.625 4.25423 5.66451 4.15884 5.73484 4.08852C5.80516 4.01819 5.90055 3.97868 6 3.97868C6.09946 3.97868 6.19484 4.01819 6.26517 4.08852C6.33549 4.15884 6.375 4.25423 6.375 4.35368V6.97868C6.375 7.07814 6.33549 7.17352 6.26517 7.24385C6.19484 7.31418 6.09946 7.35368 6 7.35368C5.90055 7.35368 5.80516 7.31418 5.73484 7.24385C5.66451 7.17352 5.625 7.07814 5.625 6.97868V4.35368ZM6 9.22868C5.88875 9.22868 5.78 9.19569 5.68749 9.13388C5.59499 9.07208 5.52289 8.98423 5.48032 8.88144C5.43775 8.77866 5.42661 8.66556 5.44831 8.55645C5.47001 8.44733 5.52359 8.3471 5.60225 8.26844C5.68092 8.18977 5.78115 8.1362 5.89026 8.11449C5.99938 8.09279 6.11248 8.10393 6.21526 8.1465C6.31804 8.18908 6.4059 8.26117 6.4677 8.35368C6.52951 8.44618 6.5625 8.55493 6.5625 8.66618C6.5625 8.81537 6.50324 8.95844 6.39775 9.06393C6.29226 9.16942 6.14919 9.22868 6 9.22868Z"
      fill="black"
      fillOpacity="0.36"
    />
  </svg>
)

export default WarningIcon
