import React, { useContext, useEffect, useState } from "react"
import { compose } from "recompose"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import get from "lodash.get"
import { useHistory } from "react-router-dom"
import { Button, Text } from "@chakra-ui/react"
// import { Close } from "@styled-icons/material/Close"
import SectionTitle from "../../atoms/SectionTitle"
import Input from "../../atoms/Input"
// import { InitialAvatar } from "../../atoms/Card/styles"
import { SectionsDoubleContainer, Toolbar } from "../../atoms/Global"
// import { MedicalHeader, MedicalInfo, CloseButton } from "../../atoms/Forms"
import { withFirebase } from "../../../utils/Firebase"
import { withAuthorization, AuthUserContext } from "../../../context/Session"

import {
  Container,
  PaymentsContainer,
  HistoryContainer,
  RecipesContainer,
  // Recipe,
  // HeaderOrderTools,
} from "./styles"

const Payments = ({ firebase }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [bankAccount, setBankAccount] = useState("")
  const user = useContext(AuthUserContext)
  useEffect(() => {
    const getData = async () => {
      const data = await firebase.getBusiness({ email: user.email })
      const info = data.data()
      setBankAccount(get(info, "bankAccount", "N/A"))
    }
    if (user) {
      getData()
    }
  }, [user])
  return (
    <SectionsDoubleContainer>
      <Toolbar>
        <SectionTitle withPadding>
          {t("q9_business_bankAccount_label")}
        </SectionTitle>
        <PaymentsContainer>
          <div>
            <span>{t("q9_business_bankAccount_error")}</span>
            <p />
            <Input
              icon="payment"
              value={bankAccount}
              labelName={t("q9_business_bankAccount_label")}
              type="text"
              name="bankAccount"
              readonly
            />
          </div>
          <Button
            borderRadius="5px"
            width="200px"
            height="50px"
            backgroundColor="#4d66f5"
            _hover={{
              background: "#5e75f6",
              opacity: 1,
            }}
            alignSelf="center"
            marginRight="50px"
            onClick={() =>
              history.push("/account", {
                activeTab: 2,
              })
            }
          >
            <Text color="#FFF">{t("my_profile")}</Text>
          </Button>
        </PaymentsContainer>
        <SectionTitle withPadding>
          {t("web_client.payment_history")}
        </SectionTitle>
        <RecipesContainer>
          {/* <Recipe>
            <div>
              <span>#Orden: A12</span>
              <span>10:00 AM</span>
            </div>
            <div>
              <span>Medicina General</span>
              <span>10/12/20</span>
            </div>
            <div>
              <span />
              <span>¢25000</span>
            </div>
            <div>
              <div>
                <InitialAvatar>
                  <span>A</span>
                </InitialAvatar>
                <span>Andy Adams</span>
              </div>
            </div>
          </Recipe> */}
        </RecipesContainer>
      </Toolbar>
      <Container>
        <HistoryContainer>
          {/* <MedicalHeader>
            <CloseButton>
              <Close size="36" />
            </CloseButton>
            <span># Orden: A12 </span>
            <HeaderOrderTools>
              <span>Fecha de creación 08/05/2020</span>
              <span>Hora: 05:00 PM</span>
            </HeaderOrderTools>
          </MedicalHeader>
          <MedicalInfo>
            <div />
          </MedicalInfo> */}
        </HistoryContainer>
      </Container>
    </SectionsDoubleContainer>
  )
}

Payments.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser

const ConnectedPayments = compose(
  withFirebase,
  withAuthorization(condition)
)(Payments)
export default ConnectedPayments
