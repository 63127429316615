import React from "react"
import moment from "moment"
import Select from "react-select"
import get from "lodash.get"
import { useTranslation } from "react-i18next"

// Components
import { Flex, Button, Box } from "@chakra-ui/react"
import { PlusCircle } from "@styled-icons/fa-solid"
import { RemoveRedEye } from "@styled-icons/material/RemoveRedEye"
import { SpeakerNotes } from "@styled-icons/material/SpeakerNotes"

// Styles
import { CustomText, DataRow, EmptyMessage } from "../../styles"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "#E2E8F0", // Theme.color.iceBlue,
    marginTop: "5px",
    marginBottom: "2px",
    marginLeft: 10,
    marginRight: 10,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"
    return { ...provided, opacity, transition }
  },
}

const MedicalImages = ({
  medicalImages,
  setCurrentCode,
  onOpen,
  currentDependent,
  dependents,
  dependentsWithOwner,
  setCurrentDependent,
}) => {
  const { t } = useTranslation()
  return (
    <Flex
      key="main-notes"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      height="calc(100vh - 200px)"
      width="100%"
      position="relative"
      overflow="hidden"
    >
      <Flex
        key="notes-container"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        height="calc(100vh - 260px)"
        width="100%"
        overflowY="auto"
      >
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          borderBottom="1px solid #edf2f7;"
          width="100%"
        >
          {dependents?.length > 0 && (
            <Flex justifyContent="center" alignItems="center" my="5px">
              <Box as="span" ml="10px">
                Filtrar:
              </Box>
              <Select
                styles={customStyles}
                defaultValue={currentDependent}
                value={dependentsWithOwner.find(
                  (option) => option.value === get(currentDependent, "value")
                )}
                options={dependentsWithOwner}
                onChange={(option) => setCurrentDependent(option || null)}
                placeholder="Seleccione para filtrar"
                isClearable
                width="240px"
              />
            </Flex>
          )}
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent={
            medicalImages && medicalImages.length === 0
              ? "center"
              : "flex-start"
          }
          alignItems={
            medicalImages && medicalImages.length === 0
              ? "center"
              : "flex-start"
          }
          height="calc(100vh - 240px)"
          width="100%"
          overflow="auto"
        >
          {medicalImages && medicalImages.length === 0 ? (
            <EmptyMessage>{t("web_client.medical_images_empty")}</EmptyMessage>
          ) : (
            <Flex mt="30px" width="100%" flexDirection="column">
              {medicalImages
                .filter((n) => {
                  if (currentDependent && !currentDependent.isParent) {
                    return n.dependentContact === currentDependent.value
                  }
                  if (currentDependent && currentDependent.isParent) {
                    return !n.dependentContact
                  }
                  return true
                })
                .sort((a, b) => b.createdAt - a.createdAt)
                .map((c) => (
                  <DataRow mb="15px" key={c?.id}>
                    <CustomText>
                      {moment(c.createdAt).format("MMM DD, YYYY").toUpperCase()}
                    </CustomText>
                    <CustomText>{c.code}</CustomText>
                    {c.resultUrl ? (
                      <Button
                        h="35px"
                        width="48px"
                        borderRadius="8px"
                        padding="10px, 12px, 10px, 12px"
                        background="#EDF2F7"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        margin="0px"
                        marginRight="10px"
                        opacity="0.8"
                        _hover={{
                          background: "#EDF2F7",
                          transform: "scale(1.1)",
                          opacity: 1,
                        }}
                        onClick={() => window.open(c.resultUrl, "_blank")}
                      >
                        <SpeakerNotes size="22px" />
                      </Button>
                    ) : (
                      <Button
                        h="35px"
                        width="48px"
                        borderRadius="8px"
                        padding="10px, 12px, 10px, 12px"
                        background="#EDF2F7"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        margin="0px"
                        marginRight="10px"
                        opacity="0.8"
                        _hover={{
                          background: "#EDF2F7",
                          transform: "scale(1.1)",
                          opacity: 1,
                        }}
                        onClick={() => {
                          setCurrentCode({ id: c.id, code: c.code })
                          onOpen()
                        }}
                      >
                        <PlusCircle size="22px" />
                      </Button>
                    )}
                    <Button
                      h="35px"
                      width="48px"
                      borderRadius="8px"
                      padding="10px, 12px, 10px, 12px"
                      background="#EDF2F7"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      margin="0px"
                      opacity="0.8"
                      _hover={{
                        background: "#EDF2F7",
                        transform: "scale(1.1)",
                        opacity: 1,
                      }}
                      onClick={() => window.open(c.pdfUrl, "_blank")}
                    >
                      <RemoveRedEye size="22px" />
                    </Button>
                  </DataRow>
                ))}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default MedicalImages
