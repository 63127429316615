import styled from "styled-components"
import { InitialAvatar } from "../../atoms/Card/styles"

export const Avatar = styled.div`
  margin: 5px;
  width: 100px;
  min-height: 100px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0.04);
  & > span {
    pointer-events: none;
    position: absolute;
    top: 4px;
    ${(props) => props.theme.font_size.xxxsmall};
    line-height: 1.33;
    transition: opacity 0ms ease 400ms;
    color: ${(props) => props.theme.color.darkPurple};
    opacity: ${(props) => (props.isLoaded ? "1" : "0")};
  }
`

export const Thumb = styled(InitialAvatar)`
  width: 100px;
  height: 100px;
  transition: opacity 0ms ease 400ms;
  background-color: rgba(0, 0, 0, 0.04);
  opacity: ${(props) => (props.isLoaded ? "0" : "1")};
  position: absolute;
  top: 0;
  left: 0;
`

export const Image = styled.img`
  cursor: pointer;
  width: 100px;
  height: ${(props) => (props.isSign ? "90px" : "100px")};
  opacity: ${(props) => (props.isLoaded ? "1" : "0")};
  transition: opacity 400ms ease 0ms;
  z-index: 0;
`

export const ModalWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductModalWrapper = styled.div`
  width: 80%;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
  background: white;
  border-radius: 10px;
  padding: 30px;
`

export const ProductModalColumn = styled.div`
  width: 50%;
  height: 500px;
  margin: ${(props) => props.margin};
`

export const ModalOptions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  & > section {
    width: 100%;
    padding: 25px;
    p {
      text-align: center;
      ${(props) => props.theme.font_size.small};
    }
    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        background-color: ${(props) => props.theme.color.paleGrey};
        border: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
        border-radius: 5px;
        width: 125px;
        height: 45px;
        margin: 0 8px;
        &:hover {
          color: ${(props) => props.theme.color.white.regular};
          background-color: ${(props) => props.theme.color.black.light};
        }
      }
    }
  }
`

export const Images = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ProductImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 12px;
  &:hover {
    cursor: pointer;
  }
`

export const ProductVideo = styled.video`
  width: 100% !important;
  height: auto !important;
  border-radius: 4px;
`

export const ProductLinkWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
`

export const FilePreview = styled.object`
  pointer-events: none;
  border-radius: "22px";
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow: hidden;
`

export const FilePreviewLink = styled.a`
  display: inline-block;
`

export const AbsoluteSendButton = styled.div`
  position: absolute;
  bottom: 15px;
  right: 20px;
`
