import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import Sessions from "../../../pages/Sessions"
import Clinical from "../../organisms/Clinical"
import MedicalImages from "../../organisms/MedicalImages"
import Agenda from "../../organisms/Agenda"
import Help from "../../organisms/Help"
import History from "../../organisms/History"
import Questions from "../../organisms/Questions"
import Prescription from "../../organisms/Prescription"
import Account from "../../organisms/Account"
import Payments from "../../organisms/Payments"
import Services from "../../organisms/Services"
import NotFound from "../../../pages/NotFound"
import Layout from "../../organisms/Layout"
import Login from "../../../pages/Login"
import Confirm from "../../../pages/Confirm"
import Contacts from "../../../pages/Contacts"
import Profile from "../../../pages/Profile"
import Settings from "../../organisms/Settings"
import AssistantDashboard from "../../organisms/AssistantDashboard"
import ForgotPassword from "../../../pages/ForgotPassword"
import WhoRegister from "../../../pages/WhoRegister"
import DataVerification from "../../../pages/DataVerification"
import Register from "../../../pages/Register"
import ResetPassword from "../../../pages/ResetPassword"
import EmployeeRegister from "../../../pages/EmployeeRegister"
import BusinessRegister from "../../../pages/BusinessRegister"
import Odontogram from "../../../pages/Odontogram"
import Recommendations from "../../organisms/Recommendations"

import * as ROUTES from "../../../utils/Constants/Routes"

function Routes() {
  return (
    <Router>
      <Route
        render={(routeProps) => (
          <Layout {...routeProps}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to={ROUTES.SESSIONS} />}
              />
              <Route default exact path={ROUTES.SESSIONS}>
                <Sessions />
              </Route>
              <Route path={ROUTES.AGENDA}>
                <Agenda />
              </Route>
              <Route path={ROUTES.HELP}>
                <Help {...routeProps} />
              </Route>
              <Route exact path={ROUTES.HISTORY}>
                <History />
              </Route>
              <Route exact path={ROUTES.QUESTIONS}>
                <Questions />
              </Route>
              <Route exact path={ROUTES.PRESCRIPTION}>
                <Prescription />
              </Route>
              <Route exact path={ROUTES.RECOMMENDATIONS}>
                <Recommendations />
              </Route>
              <Route exact path={ROUTES.CLINICAL}>
                <Clinical />
              </Route>
              <Route path={ROUTES.MEDICAL}>
                <MedicalImages />
              </Route>
              <Route exact path={ROUTES.PAYMENT}>
                <Payments />
              </Route>
              <Route exact path={ROUTES.SERVICES}>
                <Services />
              </Route>
              <Route path={ROUTES.ACCOUNT}>
                <Account />
              </Route>
              <Route path={ROUTES.LOGIN}>
                <Login />
              </Route>
              <Route path={ROUTES.OLD_LOGIN}>
                <Login />
              </Route>
              <Route path={ROUTES.CONFIRM}>
                <Confirm />
              </Route>
              <Route path={ROUTES.DASHBOARD}>
                <AssistantDashboard />
              </Route>
              <Route path={ROUTES.SETTINGS}>
                <Settings />
              </Route>
              <Route path={ROUTES.CONTACTS}>
                <Contacts />
              </Route>
              <Route path={ROUTES.PROFILE}>
                <Profile />
              </Route>
              <Route path={ROUTES.FORGOT_PASSWORD}>
                <ForgotPassword />
              </Route>
              <Route path={ROUTES.SETUP_ACCOUNT_USER_TYPE}>
                <WhoRegister />
              </Route>
              <Route path={ROUTES.VERIFY}>
                <DataVerification />
              </Route>
              <Route path={ROUTES.SETUP_ACCOUNT}>
                <Register />
              </Route>
              <Route path={ROUTES.ORG_EMPLOYEE_REGISTER}>
                <EmployeeRegister />
              </Route>
              <Route path={ROUTES.BUSINESS_REGISTER}>
                <BusinessRegister />
              </Route>
              <Route path={ROUTES.RESET_PASSWORD}>
                <ResetPassword />
              </Route>
              <Route path={ROUTES.ODONTOGRAM}>
                <Odontogram />
              </Route>
              <Route component={NotFound} />
            </Switch>
          </Layout>
        )}
      />
    </Router>
  )
}

export default Routes
