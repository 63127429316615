import React from "react"

const Logo = ({ className, height, width, fill }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 95 41"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="group-2" fill={fill}>
        <path
          d="M26.852653,0 L31.7829901,0.0363601147 L31.5936342,24.5879373 C31.532247,33.2142565 24.551105,40.1878431 15.9479241,40.1878431 C7.30743863,40.1878431 0.30268615,33.1528692 0.30268615,24.4746071 C0.30268615,15.7963449 7.30743863,8.76137101 15.9479241,8.76137101 C20.135476,8.76137101 23.9386495,10.4136313 26.7468781,13.1042798 L26.852653,0 Z M15.9479241,13.7134297 C10.0301974,13.7134297 5.23349547,18.5313811 5.23349547,24.4750793 C5.23349547,30.4178331 10.0301974,35.2357844 15.9479241,35.2357844 C21.8656508,35.2357844 26.6628249,30.4178331 26.6628249,24.4746071 C26.6628249,18.5313811 21.8656508,13.7134297 15.9483963,13.7134297 L15.9479241,13.7134297 Z M51.2073189,40.1878431 C59.9006918,40.1878431 66.9474709,33.141064 66.9474709,24.4476911 C66.9474709,15.7543183 59.9002196,8.70753915 51.2073189,8.70753915 C42.513946,8.70753915 35.4671669,15.7547905 35.4671669,24.4476911 C35.4671669,33.141064 42.5144183,40.1878431 51.2073189,40.1878431 L51.2073189,40.1878431 Z M51.2073189,35.2272846 C45.2537043,35.2272846 40.4277254,30.4013058 40.4277254,24.4476911 C40.4277254,18.4940765 45.2537043,13.6680977 51.2073189,13.6680977 C57.1609335,13.6680977 61.9869124,18.4940765 61.9869124,24.4476911 C61.9869124,30.4013058 57.1609335,35.2272846 51.2073189,35.2272846 L51.2073189,35.2272846 Z M94.4092739,8.70753915 L86.0846965,8.70753915 C77.3653521,8.70753915 70.2968513,15.7547905 70.2968513,24.4476911 C70.2968513,33.141064 77.3653521,40.1878431 86.0846965,40.1878431 L94.4097461,40.1878431 L94.4097461,35.2272846 L86.0846965,35.2272846 C80.1131379,35.2272846 75.2725205,30.4013058 75.2725205,24.4476911 C75.2725205,18.4940765 80.1131379,13.6680977 86.0846965,13.6680977 L94.4097461,13.6680977 L94.4097461,8.70753915 L94.4092739,8.70753915 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
)

Logo.defaultProps = {
  width: 95,
  height: 41,
  fill: "#000",
}

export default Logo
