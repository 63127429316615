import React from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import get from "lodash.get"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"

// Components
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
  useDisclosure,
  useMediaQuery,
  Input as ChakraInput,
} from "@chakra-ui/react"

import "react-phone-number-input/style.css"
import es from "react-phone-number-input/locale/es.json"
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input"

import ImageUploader from "../../../../molecules/ImageUploader"
import Input from "../../../../atoms/Input"
import Tooltip from "../../../../atoms/Tooltip"

// Utils
import countries from "../../../../../utils/Constants/Countries"
import { withFirebase } from "../../../../../utils/Firebase"

// Styles
import { Section, StyledForm, CitizenshipLabel, CenterDiv } from "../../styles"
import Theme from "../../../../../styles/theme"

const customStyles = {
  control: (provided, selectState) => ({
    ...provided,
    width: selectState.selectProps.width,
    borderRadius: selectState.selectProps.borderRadius,
    borderColor: selectState.selectProps.showError
      ? Theme.color.alert
      : Theme.color.iceBlue,
    marginBottom: 25,
  }),
  singleValue: (provided, selectState) => {
    const opacity = selectState.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"

    return { ...provided, opacity, transition }
  },
}

export const Personal = ({
  formValues,
  propertiesMissing,
  setAvatarUrl,
  setFormValues,
  handleInputChange,
  onChangePersonalView,
  setWithChanges,
  firebase,
  onChangePhoneNumber,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isMobile] = useMediaQuery("(max-width: 800px)")

  const genderOptions = [
    { value: "M", label: t("male") },
    { value: "F", label: t("female") },
  ]

  const deleteMyAccount = async () => {
    try {
      const date = new Date()
      date.setDate(date.getDate() + 30)

      await firebase.savePersonalData({
        email: formValues?.email,
        data: {
          status: "disabled_by_provider",
          deactivation_day: new Date().getTime(),
          deactivation_day_period_end: date.getTime(),
          active: false,
          online: false,
        },
      })
      onClose()
      firebase.logout()
    } catch (error) {
      window.Honeybadger?.notify(error)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  return (
    <Section>
      <ImageUploader
        src={
          formValues?.photoURL
            ? get(formValues, ["photoURL", "blobUrl"], formValues.photoURL)
            : null
        }
        onImageLoaded={(field, data) => {
          setAvatarUrl(data)
          setFormValues((currentValues) => ({
            ...currentValues,
            [field]: data,
          }))
          setWithChanges(true)
        }}
        fieldName="photoURL"
        width={130}
        height={130}
        showError={get(propertiesMissing, "photoURL", false)}
        emptyMessage={t("q3_profile_photoURL_label")}
      />
      <StyledForm>
        <Input
          icon="user"
          value={formValues.name}
          onChange={handleInputChange}
          labelName={t("name")}
          type="text"
          name="name"
          id="name"
          showError={get(propertiesMissing, "name", false)}
          required
        />
        <Input
          icon="user"
          value={get(formValues, "surname1")}
          onChange={handleInputChange}
          labelName={t("lastname")}
          type="text"
          name="surname1"
          showError={get(propertiesMissing, "surname1", false)}
          required
        />
        <Input
          icon="user"
          value={get(formValues, "surname2")}
          onChange={handleInputChange}
          labelName={t("q3_info_surname2_label")}
          type="text"
          name="surname2"
          showError={get(propertiesMissing, "surname2", false)}
          required
        />
        <Select
          styles={customStyles}
          defaultValue={genderOptions.find(
            (c) => c.value === formValues.gender
          )}
          label={t("q4_info_gender_label")}
          options={genderOptions}
          onChange={(option) => onChangePersonalView(option, "gender")}
          placeholder={t("q4_info_gender_label")}
          width="100%"
          showError={get(propertiesMissing, "gender", false)}
          borderRadius="25px"
        />
        <Tooltip title={t("web_client.personal_id_tooltip")}>
          <Input
            icon="id"
            value={formValues.idCard}
            onChange={handleInputChange}
            labelName={t("web_client.personal_id")}
            type="text"
            name="idCard"
            id="idCard"
            showError={get(propertiesMissing, "idCard", false)}
            required
          />
        </Tooltip>
        <CitizenshipLabel>{t("web_client.citizenship")}</CitizenshipLabel>
        <Select
          styles={customStyles}
          defaultValue={countries.find((c) => c.value === formValues.country)}
          label={t("q7_business_country_label")}
          options={countries}
          onChange={(option) => onChangePersonalView(option, "country")}
          placeholder={t("q7_business_country_label")}
          width="100%"
          borderRadius="25px"
          showError={get(propertiesMissing, "country", false)}
          required
        />
        <>
          <CitizenshipLabel>
            {t("q4_business_companyPhone_label")}
          </CitizenshipLabel>
          <PhoneInput
            placeholder={t("q7_info_phone_help")}
            defaultCountry={formValues?.phoneNumber?.country || "PE"}
            value={formValues?.phoneNumber?.formattedNumber}
            labels={es}
            style={{
              borderRadius: "6px",
              borderColor: "#E2E8F0",
              fontSize: "16px",
              color: "gray.500",
              width: "320px",
              height: "32px",
              marginBottom: "40px",
            }}
            inputComponent={ChakraInput}
            _focus={{ outline: "none" }}
            _hover={{ borderColor: "#E2E8F0" }}
            withCountryCallingCode
            onCountryChange={(country) => {
              if (country) {
                onChangePhoneNumber("country", country)
              }
            }}
            onChange={(value) => {
              if (value) {
                const phoneParsed = parsePhoneNumber(value)
                onChangePhoneNumber("number", phoneParsed?.nationalNumber || "")
                onChangePhoneNumber(
                  "formattedNumber",
                  phoneParsed?.number || value
                )
              }
            }}
          />
        </>
        <Input
          icon="email"
          value={formValues.email}
          onChange={handleInputChange}
          labelName={t("email_address")}
          type="text"
          name="email"
          id="email"
          showError={get(propertiesMissing, "email", false)}
          readonly
          required
        />
        <CenterDiv>
          <Button
            disabled={formValues?.status === "disabled_by_provider"}
            onClick={onOpen}
            mt="30px"
            backgroundColor={Theme.color.alert}
            color="#FFFFFF"
            width="200px"
            borderRadius="5px"
            minH="40px"
            opacity="0.8"
            _focus={{ outline: "none" }}
            _hover={{
              background: Theme.color.alert,
              transform:
                formValues?.status === "disabled_by_provider"
                  ? "scale(1)"
                  : "scale(1.1)",
              opacity: formValues?.status === "disabled_by_provider" ? 0.4 : 1,
            }}
          >
            {t("web_client.remove_account")}
          </Button>
        </CenterDiv>
      </StyledForm>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
          minW={isMobile ? "auto" : "690px"}
          minH={isMobile ? "auto" : "400px"}
          padding="20px"
          backgroundColor="white"
        >
          <ModalHeader backgroundColor="white">
            {`${t("hello")}, ${formValues?.title} ${formValues?.name} ${
              formValues?.surname1
            }`}
          </ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} />
          <ModalBody backgroundColor="white">
            <Text lineHeight="20px" fontSize="16px">
              {t("web_client.remove_account_body1")}
            </Text>
            <Text mt="30px" color="black" fontSize="16px">
              {t("web_client.remove_account_body2")}
            </Text>
            <Text color="red" decoration="underline" fontSize="16px">
              {t("web_client.remove_account_body3")}
            </Text>
            <Text color="black" mb="30px" lineHeight="25px" fontSize="16px">
              {`${t("web_client.remove_account_body4")} ${moment()
                .add(30, "days")
                .format("DD/MM/YYYY")}.`}
            </Text>
          </ModalBody>
          <ModalFooter backgroundColor="white">
            <Button
              backgroundColor="brand.primary"
              mr={3}
              onClick={onClose}
              color="#FFFFFF"
              _focus={{ outline: "none" }}
              opacity={0.8}
              _hover={{
                backgroundColor: "brand.primary",
                opacity: 1,
              }}
            >
              {t("web_client.cancel")}
            </Button>
            <Button
              onClick={() => deleteMyAccount()}
              backgroundColor={Theme.color.alert}
              color="#FFFFFF"
              borderRadius="5px"
              opacity={0.8}
              _focus={{ outline: "none" }}
              _hover={{
                background: Theme.color.alert,
                opacity: 1,
              }}
            >
              {t("web_client.remove_account_attempt")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Section>
  )
}

Personal.propTypes = {
  formValues: PropTypes.shape().isRequired,
  propertiesMissing: PropTypes.shape().isRequired,
  setAvatarUrl: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  onChangePersonalView: PropTypes.func.isRequired,
  setWithChanges: PropTypes.func.isRequired,
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(Personal)
