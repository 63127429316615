import styled from "styled-components"

export const ChatWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const Overlay = styled.div`
  height: 100vh;
  width: 560px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
`

export const ModalWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalOptions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  & > section {
    width: 100%;
    padding: 25px;
    p {
      text-align: center;
      ${(props) => props.theme.font_size.small};
    }
    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        background-color: ${(props) => props.theme.color.paleGrey};
        border: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
        border-radius: 5px;
        width: 150px;
        height: 45px;
        margin: 0 8px;
        &:hover {
          color: ${(props) => props.theme.color.white.regular};
          background-color: ${(props) => props.theme.color.black.light};
        }
      }
    }
  }
`
