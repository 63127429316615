import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import BackIcon from "../BackIcon"

const Drawer = ({ children, openDrawer, closeDrawer, title, internal }) => {
  const handleBack = () => {
    closeDrawer()
  }
  return (
    <Container
      openDrawer={openDrawer}
      leftPosition={internal ? "-74px" : "0px"}
    >
      <DrawerContainer openDrawer={openDrawer}>
        <section>
          <Back>
            <BackIcon isBack={false} onBack={handleBack} />
            {title}
          </Back>
          {children}
        </section>
      </DrawerContainer>
    </Container>
  )
}

const Back = styled.span`
  height: 50px;
  padding: 5px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.5px;
  & svg {
    margin-right: 20px;
    cursor: pointer;
  }
`

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: ${(props) => (props.openDrawer ? props.leftPosition : "100%")};
  opacity: ${(props) => (props.openDrawer ? 1 : 0)};
  top: 0px;
  transition: ${(props) =>
    props.openDrawer ? "opacity 0.4s ease-in-out" : "all 0.5s ease-in-out"};
  background-color: ${(props) => props.theme.color.black.medium};
  z-index: 1001;
`

const DrawerContainer = styled.div`
  width: 425px;
  height: 100vh;
  position: absolute;
  right: ${(props) => (props.openDrawer ? 0 : "-500px")};
  opacity: ${(props) => (props.openDrawer ? 1 : 0)};
  top: 0px;
  transition: ${(props) =>
    props.openDrawer ? "all 0.8s ease-in-out" : "all 0.6s ease-in-out"};
  background-color: #fff;
  z-index: ${(props) => (props.openDrawer ? 1002 : "-10")};
`

Drawer.defaultProps = {
  openDrawer: false,
  internal: false,
  title: null,
}

Drawer.propTypes = {
  children: PropTypes.element.isRequired,
  openDrawer: PropTypes.bool,
  closeDrawer: PropTypes.func.isRequired,
  title: PropTypes.string,
  internal: PropTypes.bool,
}
export default Drawer
