export const initialChatState = {
  keys: 0,
  chats: [],
  modals: {},
  currentSession: null,
  lastMessageKey: null,
  firstMessageKey: null,
}
export const chatReducer = (state, action) => {
  switch (action.type) {
    case "AddMessage":
      return {
        ...state,
        chats: [
          ...state.chats,
          { ...action.payload.message, chatId: action.payload.chatId },
        ],
      }
    case "RemoveMessage":
      return {
        ...state,
        chats: state.chats.filter((c) => c._id !== action.payload.messageId),
      }

    case "ToogleModal":
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.modalKey]: action.payload.toogleState,
        },
        currentSession: action?.payload?.session || null,
      }

    case "LastMessage":
      return {
        ...state,
        lastMessageKey: action.payload.lastMessageKey,
      }

    case "FirstMessage":
      return {
        ...state,
        firstMessageKey: action.payload.firstMessageKey,
      }

    default:
      return state
  }
}

export const addMessage = (message, chatId) => ({
  type: "AddMessage",
  payload: { message, chatId },
})

export const removeMessage = (messageId, chatId) => ({
  type: "RemoveMessage",
  payload: { chatId, messageId },
})

export const toggleModal = (modalKey, toogleState, session) => ({
  type: "ToogleModal",
  payload: { modalKey, toogleState, session },
})

export const setLastMessageKey = (lastMessageKey) => ({
  type: "LastMessage",
  payload: { lastMessageKey },
})

export const setFirstMessageKey = (firstMessageKey) => ({
  type: "FirstMessage",
  payload: { firstMessageKey },
})

export const MODALS = {
  CONFIRM_PAYMENT: "confirm_payment",
  ACTIVATE_SESSION: "activate_session",
}
