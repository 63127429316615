import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "white",
        color: "black",
      },
    },
  },
  colors: {
    brand: {
      primary: "#4D66F5",
    },
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: "light",
  },
})

export default theme
