import React from "react"

import { Box, Skeleton } from "@chakra-ui/react"

const TableSkeleton = () => (
  <Box>
    <Skeleton height="30px" mt={4} />
    <Skeleton height="30px" mt={4} />
    <Skeleton height="30px" mt={4} />
  </Box>
)

export default TableSkeleton
