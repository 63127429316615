import styled from "styled-components"
import { GridContainer } from "../../atoms/Global"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"

// eslint-disable-next-line import/prefer-default-export
export const ContainerFromStyles = styled(GridContainer)`
  padding: 0;
  background-color: ${(props) => props.theme.color.background.white};
  border-left: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
`

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  height: calc(100% - 220px);
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding-left: 18px;
  padding-right: 18px;
  overflow: auto;

  & > p {
    text-align: center;
    ${(props) => props.theme.font_size.large};
    line-height: normal;
    width: 374px;
  }
`

export const ChatWrapper = styled.div`
  padding-top: ${(props) => (props.isChat ? "0" : "49px")};
  padding-left: ${(props) => (props.isChat ? "0" : "60px")};
  height: 100%;
  width: 100%;
`

export const Waiting = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  & > p {
    width: 100%;
    text-align: center;
    ${(props) => props.theme.font_size.regular};
    color: ${(props) => props.theme.color.primary};
  }
`

export const Search = styled.div`
  width: 100%;
  padding: 5px 15px;
  margin-top: 20px;
`

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-bottom: ${(props) => `1px solid ${props.theme.color.black.twelve}`};
  position: fixed;
  top: 0;
  z-index: 200;
`

export const DonateButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  width: auto;
  height: 36px;
  background: #d69e2e;
  color: #ffffff;
  border-radius: 100px;
  flex: none;
  order: 4;
  flex-grow: 0;
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
  &:hover {
    background: ${(props) => (props.isDisabled ? "#D69E2E" : "#38a169")};
  }
`

export const AnswerButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  gap: 8px;
  width: 95px;
  height: 36px;
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
  background: #4299e1;
  color: #ffffff;
  border-radius: 100px;
  flex: none;
  order: 3;
  margin-right: 22px;
  flex-grow: 0;
  text-align: center;

  &:hover {
    background: ${(props) => (props.isDisabled ? "#4299e1" : "#38a169")};
  }
`

export const DateBubble = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  left: 40%;
  right: 0;
  top: 74px;
  bottom: 0;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 100px;
  width: 100%;
  max-width: 213px;
  height: 32px;
  z-index: 2000;
`

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
  height: 100%;
`

export const QuestionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`

export const QuestionContentBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 60px;
  width: 70%;
`

export const AnswerContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

export const AnswerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 98%;
  padding-left: 30px;
  border-radius: 19px;
  background: #ebf8ff;
`

export const AnswerContentBody = styled.div`
  margin-left: 53px;
  margin-bottom: 30px;
  width: 374px;
`

export const AlignLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20px;
  margin-top: 100px;
`

export const AlignRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

export const Time = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
`

export const UserName = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-right: 12px;
  margin-left: 12px;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FilterQuestions = styled.button`
  margin-top: 22px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  width: 130px;
  height: 40px;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: ${({ active }) => (active ? "#FFFFFF" : "rgba(0, 0, 0, 0.6)")};
  border-radius: ${({ active }) => (active ? "100px" : "none")};
  background: ${({ active }) => (active ? "#4299e1" : "transparent")};
`

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5px;
  > a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.secondary};
  }
`

export const ModalWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalOptions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 450px;
  height: 262px;
  background-color: white;
  border-radius: 10px;
  & > section {
    width: 100%;
    padding: 25px;
    p {
      text-align: center;
      ${(props) => props.theme.font_size.small};
    }
    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      button {
        background-color: rgba(0, 0, 0, 0.08);
        border-radius: 8.5px;
        width: 150px;
        height: 40px;
        margin: 0 20px;
        &:hover {
          color: ${(props) => props.theme.color.white.regular};
          background-color: #4299e1;
        }
      }
    }
  }
`

export const TextAreaWrapper = styled.div`
  position: relative;
  & > textarea {
    width: 100%;
    border: none;
    ${(props) => props.theme.font_size.regular};
    outline: none;
    background-color: ${(props) => props.theme.color.paleGrey};
  }
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-left: 10px;
`

export const MedicalFormContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 560px;
  background: #e1e2e4;
  z-index: 2001;
  overflow: scroll;
`
