/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { NotesMedical } from "@styled-icons/fa-solid/NotesMedical"

const getMessageText = (type) => {
  const messages = {
    medical: "view_medical_form",
    prescription: "view_prescription_form",
    clinical: "view_clinical_form",
    medicalImages: "view_images_form",
  }
  return messages[type]
}

const MessageMedical = ({ currentMessage, type }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <p>{t(getMessageText(type))}</p>
      <NotesMedical size="80px" />
      <span>{currentMessage.profileOwner}</span>
      <p>({currentMessage.profileType})</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 180px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & > svg {
    color: white;
  }
  & > p {
    ${(props) => props.theme.font_size.xxxsmall};
    color: white;
    margin: 3px;
  }

  & > span {
    ${(props) => props.theme.font_size.xxxsmall};
    color: black;
    margin-top: 10px;
  }
`

MessageMedical.propTypes = {
  type: PropTypes.string.isRequired,
}

export default MessageMedical
