import React from "react"

const EditIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.75 5.39207L12.608 2.25005C12.5036 2.14558 12.3795 2.06271 12.243 2.00617C12.1066 1.94963 11.9603 1.92053 11.8125 1.92053C11.6648 1.92053 11.5185 1.94963 11.382 2.00617C11.2455 2.06271 11.1215 2.14558 11.017 2.25005L9.16487 4.10221L9.16476 4.10231L9.16466 4.10242L2.57956 10.6875C2.47475 10.7917 2.39166 10.9157 2.33509 11.0523C2.27852 11.1888 2.2496 11.3352 2.25 11.483V14.6251C2.25035 14.9233 2.36898 15.2093 2.57989 15.4202C2.79079 15.6311 3.07674 15.7497 3.375 15.7501H6.51702C6.66482 15.7505 6.81123 15.7215 6.94777 15.665C7.08432 15.6084 7.20829 15.5253 7.3125 15.4205L15.75 6.98303C15.9607 6.77188 16.079 6.4858 16.079 6.18755C16.079 5.88929 15.9607 5.60321 15.75 5.39207ZM13.5 7.64207L10.358 4.50005L11.8125 3.04553L14.9545 6.18755L13.5 7.64207Z"
      fill="black"
    />
  </svg>
)

export default EditIcon
