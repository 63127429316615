import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const BubbleText = ({ children, withDate }) => (
  <Bubble withDate={withDate}>
    <span>{children}</span>
  </Bubble>
)

const Bubble = styled.div`
  width: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.withDate
      ? props.theme.color.lightBlueResting
      : props.theme.color.ligthGreen10};
  border-radius: 20px;
  & > span {
    padding: 0 10px;
    ${(props) => props.theme.font_size.small};
    color: ${(props) => props.theme.color.black.normal};
    font-size: 0.875rem;
  }
`

BubbleText.defaultProps = {
  withDate: false,
}

BubbleText.propTypes = {
  withDate: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element.isRequired,
  ]).isRequired,
}

export default BubbleText
