import React from "react"
import PropTypes from "prop-types"
import { Box, Text } from "@chakra-ui/react"

const ProgressBar = ({
  barColor,
  borderColor,
  backgroundColor,
  percentage,
}) => (
  <Box
    backgroundColor={backgroundColor}
    height="14px"
    border={`3px solid ${borderColor}`}
    width="100%"
    borderRadius="10px"
    position="relative"
  >
    <Box
      backgroundColor={barColor}
      width={percentage}
      height="8px"
      borderRadius="10px"
    />
    {percentage !== "0%" && (
      <Box
        position="absolute"
        marginTop="10px"
        left={`calc(${percentage} - 35px)`}
      >
        <Text color={barColor} fontWeight="500">
          {percentage}
        </Text>
      </Box>
    )}
  </Box>
)

ProgressBar.defaultProps = {
  barColor: "#58D68D",
  borderColor: "#000000",
  backgroundColor: "transparent",
  percentage: "10%",
}

ProgressBar.propTypes = {
  barColor: PropTypes.string,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  percentage: PropTypes.string,
}

export default ProgressBar
