import styled from "styled-components"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"

export const Container = styled.div`
  height: 100%;
  width: 100%;
`

export const SectionTitle = styled.section`
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ContactsContainer = styled.section`
  height: calc(100vh - 110px);
  overflow: auto;
  display: flex;
`

export const RightContainerSection = styled.div`
  flex: 1;
  position: relative;
  padding: 10px 16px 33px 10px;
`

export const LeftContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  min-width: 515px;
  height: calc(100vh - 115px);
  border: 1px solid rgba(0, 0, 0, 0.08);
  overflow-x: hidden;
  overflow-y: auto;
`

export const Title = styled.p`
  margin: 0;
  padding-left: ${(props) => (props.withPadding ? "33px" : "0")};
  ${(props) => props.theme.font_size[props.size]};
  color: ${(props) =>
    props.isSub ? props.theme.color.gray["800"] : "inherit"};
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 200px;
  margin: 5px 0;
  margin-right: ${(props) => (props.noRightMargin ? "0" : "10px")};
`

export const LeftTitleSection = styled.div`
  flex: 1;
`

export const RightTitleSection = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`

export const EmptyMessage = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 0px;
  > span {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: 0.0094rem;
    text-align: center;
  }
`

export const AddButton = styled.button`
  color: #2d3748;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.0625rem;
  letter-spacing: 0;
  text-align: left;
  margin: 0 10px;
`

export const DatePickerContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  & .DOC_Cronofy__details {
    padding: 0 !important;
  }

  & .DOC_Cronofy__wrapper {
    overflow: hidden;
    height: 100%;
    max-height: 413px;
  }

  & .DOC_Cronofy__column--left {
    height: 100%;
  }

  & .DOC_Cronofy__column--right {
    height: 100%;
    max-height: 350px;
    overflow-y: scroll;
  }

  & .DOC_Cronofy__calendar-grid--button {
    padding: 0 !important;
  }

  & .DOC_Cronofy__column--right {
    height: 100%;
  }
`
