import styled from "styled-components"
import ButtonWithLoader from "../../atoms/ButtonWithLoader"

export const StyledForm = styled.form`
  width: 345px;
  overflow: auto;
  height: 70vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    width: 500px;
  }
  > h1 {
    ${(props) => props.theme.font_size.large};
    margin-bottom: 10px;
    @media (min-width: ${(props) => props.theme.screen.sm}) {
      ${(props) => props.theme.font_size.larger};
    }
  }
  > div {
    margin-bottom: 15px;
  }
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-top: 12px;
  margin-bottom: ${(props) => (props.withMargin ? "40px" : 0)};
`

export const RequiredFields = styled.p`
  ${(props) => props.theme.font_size.small};
  margin-bottom: 39px;
  color: ${(props) => props.theme.color.darkPurple};
`

export const HelpLink = styled.a`
  display: flex;
  align-items: center;
  margin: 20px 0 20px 0;
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.darkPurple};
`

export const RowPosition = styled.div`
  width: 320px;
  position: relative;
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    width: 500px;
  }
`

export const InfoItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0px;
  @media (min-width: ${(props) => props.theme.screen.sm}) {
    display: none;
  }
`

export const PasswordMessagesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const PasswordInfoMessage = styled.li`
  ${(props) => props.theme.font_size.small};
  color: ${(props) =>
    props.isEmpty ? "gray" : props.isValid ? "green" : "red"};
`

export const MobileBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.75rem;
  background: #f3be001a;
  @media (min-width: ${(props) => props.theme.screen.md}) {
    display: none;
  }
  > svg {
    min-width: 20px;
    margin-right: 5px;
  }
`

export const BannerImage = styled.div`
  position: relative;
  height: 275px;
  background: linear-gradient(90deg, #c1dfc4 0%, #deecdd 100%);
  border-radius: 10px;
  overflow: hidden;
  dislpay: block;
  @media (min-width: ${(props) => props.theme.screen.md}) {
    display: none;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 225.92px;
  height: 191.14px;
  left: 34px;
  top: 50px;
  @media (min-width: ${(props) => props.theme.screen.md}) {
    display: none;
  }
  > svg {
    &:first-child {
      width: 62px;
    }
    margin-top: 10px;
  }
  > p {
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.4375rem;
    letter-spacing: 0em;
    text-align: left;
  }
`

export const RightImage = styled.img`
  position: absolute;
  right: -18px;
  top: 21.46px;
`
