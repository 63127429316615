import React, { useContext, useState, useEffect } from "react"
import get from "lodash.get"
import { createClient } from "pexels"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Computer } from "@styled-icons/material/Computer"
import { ChatBubble } from "@styled-icons/material/ChatBubble"
import { Event } from "@styled-icons/material/Event"
import { Settings } from "@styled-icons/material/Settings"
import { Payment } from "@styled-icons/material/Payment"
import { Login } from "@styled-icons/material/Login"
// import { MedicalServices } from '@styled-icons/material/MedicalServices'
import { Email } from "@styled-icons/material/Email"
import { QuestionAnswer } from "@styled-icons/material/QuestionAnswer"
import { StarRate } from "@styled-icons/material/StarRate"
import SectionTitle from "../../atoms/SectionTitle"
import { SectionsDoubleContainer, Toolbar } from "../../atoms/Global"
import constants from "../../../utils/constants"
import { AuthUserContext } from "../../../context/Session"
import {
  Container,
  HelpContainer,
  VideoBackground,
  ChatWrapper,
  Item,
  ContentBox,
  ItemContact,
  TitleContainer,
} from "./styles"

const client = createClient(
  "563492ad6f917000010000019934912416c54d1c85e76f19b7ef7628"
)

const Help = (props) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const authUser = useContext(AuthUserContext)
  const [backgrounds, setBackgrounds] = useState([])
  const [FaqUrl, setUrl] = useState("")

  useEffect(() => {
    client.videos
      .search({ query: "Nature", per_page: 15, max_duration: 60 })
      .then((results) => {
        setBackgrounds(results.videos)
      })
  }, [])

  const setFAQsUrl = () => {
    const getUrl =
      language === "es" ? "https://doc.cr/es-ES/faqs" : "https://doc.cr/faqs"
    setUrl(getUrl)
  }

  useEffect(() => {
    setFAQsUrl()
  }, [language])

  return (
    <SectionsDoubleContainer>
      <Toolbar>
        <SectionTitle withPadding>Ayuda</SectionTitle>
        <HelpContainer>
          <ContentBox minHeight="350px">
            <TitleContainer>
              <p>{t("web_client.menu_explain_video")}</p>
            </TitleContainer>

            <Item>
              <a target="blank" href="https://youtu.be/11A_hmAafy8">
                <span>{t("web_client.menu_show_doc")}</span>
              </a>
            </Item>
            <Item>
              <a target="blank" href="https://youtu.be/o6m8oY0uUlw">
                <span>{t("web_client.menu_first_time_doctor")}</span>
              </a>
            </Item>
            <Item>
              <a target="blank" href="https://youtu.be/-kI7J9JQlFo">
                <span>{t("web_client.menu_make_a_date")}</span>
              </a>
            </Item>
            <Item>
              <a target="blank" href="https://youtu.be/5se64Ugyw7A">
                <span>{t("web_client.menu_create_account")}</span>
              </a>
            </Item>
          </ContentBox>

          <ContentBox minHeight="250px">
            <TitleContainer>
              <p>{t("web_client.menu_contact")}</p>
            </TitleContainer>
            <ItemContact>
              <a target="blank" href={`mailto:${constants.supportEmail}`}>
                <>
                  <Email size="24" />
                  <span>{t("ask_for_help")}</span>
                </>
              </a>
            </ItemContact>
            <ItemContact>
              <a target="blank" href={FaqUrl}>
                <>
                  <QuestionAnswer size="24" />
                  <span>{t("faq")}</span>
                </>
              </a>
            </ItemContact>
          </ContentBox>

          <ContentBox minHeight="400px">
            <TitleContainer>
              <p>{t("web_client.menu_navigation")}</p>
            </TitleContainer>

            {(!get(props, "location.state.isFromLogin", false) &&
              get(authUser, ["medicProfile", "userType"]) === "provider") ||
            get(authUser, ["medicProfile", "userType"]) ===
              "medicalAssistant" ? (
              <>
                <Item>
                  <Link to="/sessions">
                    <Computer size="24" />
                    <span>{t("web_client.menu_next")}</span>
                  </Link>
                </Item>
                <Item>
                  <Link to="/history">
                    <ChatBubble size="24" />
                    <span>{t("web_client.menu_hist")}</span>
                  </Link>
                </Item>
                {/* <Item>
          <Link to="app/services">
            <MedicalServices size="24" />
            <span>Mis servicios médicos</span>
          </Link>
        </Item> */}
                <Item>
                  <Link to="/agenda">
                    <Event size="24" />
                    <span>{t("web_client.menu_agenda")}</span>
                  </Link>
                </Item>
                <Item>
                  <Link to="/payment">
                    <Payment size="24" />
                    <span>{t("web_client.menu_payment")}</span>
                  </Link>
                </Item>
                <Item>
                  <Link to="/settings">
                    <>
                      <Settings size="24" />
                      <span>{t("web_client.menu_settings")}</span>
                    </>
                  </Link>
                </Item>
                <Item>
                  <Link to="/account">
                    <>
                      <StarRate size="24" />
                      <span>{t("web_client.menu_account")}</span>
                    </>
                  </Link>
                </Item>
              </>
            ) : (
              <Item>
                <Link to="/login">
                  <>
                    <Login size="24" />
                    <span>{t("log_in")}</span>
                  </>
                </Link>
              </Item>
            )}
          </ContentBox>
        </HelpContainer>
      </Toolbar>
      <Container isVisible={backgrounds.length > 0}>
        <VideoBackground
          isVisible={backgrounds.length > 0}
          id="videoBG"
          poster={backgrounds.length > 0 ? backgrounds[4].image : ""}
          autoPlay
          muted
          loop
        >
          {backgrounds.length > 0 && (
            <source src={backgrounds[4].video_files[1].link} type="video/mp4" />
          )}
        </VideoBackground>
        <ChatWrapper />
      </Container>
    </SectionsDoubleContainer>
  )
}

export default Help
