import React, { useState, useEffect, useContext } from "react"
import { compose } from "recompose"
import PropTypes from "prop-types"
import { createClient } from "pexels"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import { useFormik } from "formik"
import get from "lodash.get"
import * as Yup from "yup"
import { SectionsDoubleContainer, Toolbar } from "../../atoms/Global"
import Input from "../../atoms/Input"
import SectionTitle from "../../atoms/SectionTitle"
import { withFirebase } from "../../../utils/Firebase"
import { withAuthorization, AuthUserContext } from "../../../context/Session"
import {
  Container,
  ServicesContainer,
  VideoBackground,
  ChatWrapper,
  SubmitButton,
  StyledForm,
} from "./styles"

const client = createClient(
  "563492ad6f917000010000019934912416c54d1c85e76f19b7ef7628"
)

const randomIntFromInterval = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min)

const Services = ({ firebase }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const user = useContext(AuthUserContext)
  const [backgrounds, setBackgrounds] = useState([])

  useEffect(() => {
    client.videos
      .search({ query: "Nature", per_page: 15, max_duration: 60 })
      .then((results) => {
        setBackgrounds(results.videos)
      })
  }, [])

  const formikServices = useFormik({
    initialValues: {
      sessionPrice: get(user, ["medicProfile", "sessionPrice"], 0),
      currentPrice: get(user, ["medicProfile", "sessionPrice"], 0),
      profession: get(user, ["medicProfile", "professionLangLabel"], "N/A"),
    },
    validationSchema: Yup.object({
      sessionPrice: Yup.number()
        .lessThan(999.99, t("current_price_error"))
        .moreThan(0, t("error_all_fields_required"))
        .required(t("error_all_fields_required")),
      profession: Yup.string().required(t("error_all_fields_required")),
    }),
    onSubmit: (values, actions) => {
      firebase
        .saveAcademicData({
          email: user.email,
          data: {
            profession: formikServices.values.profession,
          },
        })
        .then(() => {
          firebase
            .savePersonalData({
              email: user.email,
              data: {
                sessionPrice: formikServices.values.sessionPrice,
              },
            })
            .then(() => {
              formikServices.setFieldValue(
                "currentPrice",
                formikServices.values.sessionPrice
              )
              // setUser((currentValues) => ({
              //   ...currentValues,
              //   medicProfile: {
              //     ...currentValues.medicProfile,
              //     profession: formikServices.values.profession,
              //     sessionPrice: formikServices.values.sessionPrice,
              //   },
              // }))
              addToast(t("web_client.saved_successfully"), {
                appearance: "success",
              })
              actions.setSubmitting(false)
            })
        })
        .catch(() => {
          addToast(t("unexpected_error"), { appearance: "error" })
          actions.setSubmitting(false)
        })
    },
  })

  const currentBg =
    backgrounds.length > 0 && randomIntFromInterval(1, backgrounds.length - 1)
  return (
    <SectionsDoubleContainer>
      <Toolbar>
        <SectionTitle withPadding>{t("web_client.menu_services")}</SectionTitle>
        <ServicesContainer>
          <StyledForm onSubmit={formikServices.handleSubmit}>
            <div>
              <Input
                id="profession"
                type="text"
                icon="medical"
                labelName={t("q2_academic_profession_label")}
                touched={formikServices.touched.profession}
                errors={formikServices.errors.profession}
                required
                {...formikServices.getFieldProps("profession")}
              />
              <Input
                id="currentPrice"
                type="number"
                icon="account"
                labelName={t("current_price_label")}
                readonly
                {...formikServices.getFieldProps("currentPrice")}
              />
              <Input
                id="sessionPrice"
                type="number"
                icon="account"
                labelName={t("session_pricing")}
                touched={formikServices.touched.sessionPrice}
                errors={formikServices.errors.sessionPrice}
                required
                {...formikServices.getFieldProps("sessionPrice")}
              />
              <SubmitButton
                disabled={Object.keys(formikServices.errors).length > 0}
                isLoading={formikServices.isSubmitting}
                type="submit"
              >
                {t("save")}
              </SubmitButton>
            </div>
          </StyledForm>
        </ServicesContainer>
      </Toolbar>
      <Container isVisible={currentBg}>
        <VideoBackground
          isVisible={currentBg}
          id="videoBG"
          poster={currentBg ? backgrounds[currentBg].image : ""}
          autoPlay
          muted
          loop
        >
          {currentBg && (
            <source
              src={backgrounds[currentBg].video_files[1].link}
              type="video/mp4"
            />
          )}
        </VideoBackground>
        <ChatWrapper />
      </Container>
    </SectionsDoubleContainer>
  )
}

Services.propTypes = {
  firebase: PropTypes.shape().isRequired,
}
const condition = (authUser) => !!authUser
const CompServices = compose(
  withFirebase,
  withAuthorization(condition)
)(Services)
export default CompServices
