import React from "react"
import PropTypes from "prop-types"
import { Highlight, connectAutoComplete } from "react-instantsearch-dom"
import AutoSuggest from "react-autosuggest"

const AutoComplete = ({
  currentRefinement,
  onSuggestionCleared,
  onSuggestionSelected,
  placeholder,
  refine,
  hits,
  defaultValue,
  onAnySuggestions,
}) => {
  const [refinement, setRefinement] = React.useState(currentRefinement)

  const onChangeHandler = (_, { newValue }) => {
    if (!newValue) {
      onSuggestionCleared()
    }

    setRefinement(newValue)

    onAnySuggestions(null, { suggestion: { sku: newValue } })
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    refine(value)
  }

  const onSuggestionsClearRequested = () => {
    refine()
  }

  const getSuggestionValue = (hit) => hit.sku

  const renderSuggestion = (hit) => (
    <Highlight attribute="sku" hit={hit} tagName="mark" />
  )

  const inputProps = {
    onChange: onChangeHandler,
    placeholder,
    type: "search",
    value: defaultValue || refinement,
    autocomplete: "off",
  }

  return (
    <AutoSuggest
      suggestions={hits}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  )
}

AutoComplete.defaultProps = {
  defaultValue: undefined,
}

AutoComplete.propTypes = {
  defaultValue: PropTypes.string,
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onSuggestionCleared: PropTypes.func.isRequired,
  onAnySuggestions: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default connectAutoComplete(AutoComplete)
