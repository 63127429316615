import React from "react"

const Logo = () => (
  <svg width="47" height="20" viewBox="0 0 47 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2852 0L15.7244 0.0179887L15.6307 12.1646C15.6003 16.4323 12.1465 19.8824 7.89018 19.8824C3.61541 19.8824 0.149902 16.4019 0.149902 12.1085C0.149902 7.81503 3.61541 4.33457 7.89018 4.33457C9.96192 4.33457 11.8435 5.15201 13.2328 6.48317L13.2852 0ZM7.89015 6.78454C4.96243 6.78454 2.58933 9.16816 2.58933 12.1087C2.58933 15.0488 4.96243 17.4324 7.89015 17.4324C10.8179 17.4324 13.1912 15.0488 13.1912 12.1085C13.1912 9.16816 10.8179 6.78454 7.89038 6.78454H7.89015ZM25.3343 19.8824C29.6353 19.8824 33.1216 16.3961 33.1216 12.0952C33.1216 7.79425 29.635 4.30795 25.3343 4.30795C21.0334 4.30795 17.5471 7.79448 17.5471 12.0952C17.5471 16.3961 21.0336 19.8824 25.3343 19.8824ZM25.3343 17.4282C22.3888 17.4282 20.0012 15.0406 20.0012 12.0952C20.0012 9.1497 22.3888 6.76212 25.3343 6.76212C28.2798 6.76212 30.6673 9.1497 30.6673 12.0952C30.6673 15.0406 28.2798 17.4282 25.3343 17.4282ZM46.7079 4.30795H42.5894C38.2756 4.30795 34.7786 7.79448 34.7786 12.0952C34.7786 16.3961 38.2756 19.8824 42.5894 19.8824H46.7081V17.4282H42.5894C39.635 17.4282 37.2402 15.0407 37.2402 12.0952C37.2402 9.14971 39.635 6.76212 42.5894 6.76212H46.7081V4.30795H46.7079Z"
      fill="black"
    />
  </svg>
)

export default Logo
