import styled from "styled-components"
import ButtonWithLoader from "../../../../atoms/ButtonWithLoader"

export const Section = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  margin-top: 20px;
`

export const ForgotButton = styled.button`
  margin: 10px 0;
  > span {
    color: ${(props) => props.theme.color.darkPurple};
  }
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 200px;
  margin: 5px 0;
  margin-right: ${(props) => (props.noRightMargin ? "0" : "10px")};
`
