import React from "react"
import truncate from "lodash.truncate"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { PictureAsPdf } from "@styled-icons/material/PictureAsPdf"
import { DocumentArrowDown } from "@styled-icons/fluentui-system-filled/DocumentArrowDown"

const MessageDocument = ({ currentMessage }) => {
  const { t } = useTranslation()
  const fileExtension =
    currentMessage && currentMessage.filename
      ? currentMessage.documentType ||
        currentMessage.filename.split(".").pop() ||
        "PDF"
      : "..."
  return (
    <>
      <Wrapper>
        <a href={currentMessage.document} target="blank">
          {fileExtension === "pdf" ? (
            <PictureAsPdf size="60" />
          ) : (
            <DocumentArrowDown size="60" />
          )}
          <p>{t(fileExtension)}</p>
          <p>{truncate(currentMessage.filename, { length: 24 })}</p>
        </a>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & > a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > svg {
      color: white;
    }
    &:visited {
      color: white;
    }
    & > p {
      ${(props) => props.theme.font_size.xxxsmall};
      color: black;
      margin: 0;
      margin-bottom: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 85%;
      text-align: center;
    }
  }
`

MessageDocument.propTypes = {
  currentMessage: PropTypes.shape({
    document: PropTypes.string,
  }).isRequired,
}

export default MessageDocument
